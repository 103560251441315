import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { UtilHelper } from '../helpers/util-helper';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[selectJsonValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SelectJsonValidateDirective, multi: true }]
})

export class SelectJsonValidateDirective implements Validator {
    @Input('selectJsonValidate') fieldValidator: string;
// tslint:disable-next-line: no-input-rename
    @Input('selectJsonDefault') defaultValue: string;
    @Input('isNotReq') isNotReq: boolean =false;
    constructor() { }

    validate(control: AbstractControl): { [key: string]: boolean } | null {
        if (!this.isNotReq) {
            control.setErrors(null);
            if (UtilHelper.isDefinedOrNotNull(control.value)) {
                if (this.defaultValue === control.value[this.fieldValidator]) {
                    control.setErrors({ invalid: true });
                    return { invalidoption: true };
                }
            }
        }

        return null;
    }
}
