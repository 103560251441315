<div class="card mt-3 mb-3 defaultText" *ngIf="show">
  <div class="row">
    <div class="col-12">
      <div class="col-12 card-header font-white">
        <div class="row">
          <div class="col-10 ps-4">
            <b>Success</b>
          </div>
          <button class="col-1 pe-4 btn text-end" id="collapseBtn" (click)="isCollapsed = !isCollapsed"
            [ngSwitch]="isCollapsed">
            <i *ngSwitchCase="false" id="plus" class="font-white fa fa-plus fa-lg"></i>
            <i *ngSwitchCase="true" id="minus" class="font-white fa fa-minus fa-lg"></i>
          </button>
          <button class="col-1 pe-4 btn text-end" id="showBtn" (click)="show = false">
            <i id="minus" class="font-white fa fa-close fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="collapse row" id="collapseComment" [ngbCollapse]="!isCollapsed">
    <div class="col-12 card-body">
      <div class="form-group mb-3 row g-0 justify-content-center">
        <div class="col-12 text-center">
          <span class="defaultText">{{message}}</span>
        </div>
      </div>
    </div>
  </div>
</div>