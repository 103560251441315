import { Injectable } from '@angular/core';
import { AppConfigService } from '../../configuration/app-config.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GetUserRolesApiResponse } from './models/GetUserRolesApiResponse';
import { BankUserApiResponseModel } from './models/BankUserApiResponseModel';
import { IgetuserPreference } from './models/IgetuserPreference';
import { IChangePassword } from './models/change-password.model';
import { IUpdateUserContactResponseModel } from './models/IUpdateUserContactResponseModel';
import { IupdateUserPreferences } from "./models/IupdateUserPreferences";
import { ILastLoginModel } from './models/ILastLoginModel';
import { IContactRequest } from './models/IContactRequest';

@Injectable({
  providedIn: 'root'
})
export class UserService {
 private MICROSERVICE_PATH =`${AppConfigService.Settings.Mpf.ApiEndpoint}`;
 private BUILT_API_PATH = `${this.MICROSERVICE_PATH}/api/miscellaneous/ext`
 private API_SERVICE_EXT_PATH = `${this.BUILT_API_PATH}/user`;

private MICROSERVICE_PATH_ORG =`${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH_ORG = `${this.MICROSERVICE_PATH_ORG}/api/organization/ext`;
    private ORG_API_SERVICE_EXT_PATH = `${this.BUILT_API_PATH_ORG}/organization`;
private CONTACT_API_SERVICE_EXT_PATH = `${this.BUILT_API_PATH_ORG}/contact`;
private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) {

  }

  getUserContact(): Observable<any> {
    const endPointURL = this.ORG_API_SERVICE_EXT_PATH; 
    return this.http.get(`${endPointURL}/getusercontact`)
      .pipe(map(response => response)
      );
  }

  checkUserRoles(): Observable<GetUserRolesApiResponse> {
    return this.http.get<GetUserRolesApiResponse>(`${this.API_SERVICE_EXT_PATH}/${'checkUserRoles'.toLowerCase()}`)
      .pipe(map(response => response)
      );
  }
  getBankUser(): Observable<BankUserApiResponseModel> {
    return this.http.get<BankUserApiResponseModel>(`${this.CONTACT_API_SERVICE_EXT_PATH}/getBankUser`)
      .pipe(map(response => response)
      );
  }

  updateUserContact(req: IContactRequest): Observable<IUpdateUserContactResponseModel> {
    return this.http.post<IUpdateUserContactResponseModel>(`${this.API_SERVICE_EXT_PATH}/updateusercontact`, req)
      .pipe(map(response => response)
      );
  }

  getUserPreferences(): Observable<IgetuserPreference> {
    return this.http.get<IgetuserPreference>(`${this.API_SERVICE_EXT_PATH}/${'getUserPreferences'.toLowerCase()}`)
      .pipe(map(response => response)
      );
  }

  updateUserPreferences(req: IupdateUserPreferences): Observable<any> {
    return this.http.post<any>(`${this.API_SERVICE_EXT_PATH}/${'updateUserPreferences'.toLowerCase()}`, req)
      .pipe(map(response => response)
      );

  }

  getLastLogin(): Observable<ILastLoginModel> {
    return this.http.get<ILastLoginModel>(`${this.API_SERVICE_EXT_PATH}/getlastlogin`)
      .pipe(map(response => response)
      );
  }
}
