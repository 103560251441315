<div role="document" class="modal-dialog d-inline">
  <div class="modal-content modal-hr modal-center" [ngClass]="topBorder">
    <div class="modal-header" *ngIf="showHeader">
      <h1 class="modal-title pageHeader">{{title}}</h1>
      <ng-content select="[extraHeaderContent]"></ng-content>
      <button *ngIf="!hideCloseButton" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" class="fa fa-times fa-lg"></span>
      </button>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
