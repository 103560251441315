import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';


import { IGetDCSummaryApiResponse } from './treasury/models/GetDcSummaryApiResponse';
import { IDCDetailsRateRequest } from './treasury/models/GetDcDetailByRateApiRequest';
import { IGetDCDetailsRateResponse } from './treasury/models/GetDcDetailByRateResponse';
import { IGetLFViewResponse } from './treasury/models/GetLFViewResponse';
import { GetTreasurySummaryResponseModel } from './treasury/models/GetTreasurySummaryResponseModel';
import { IGetProgramtypesAPIResponse } from './treasury/models/GetProgramTypesAPIResponse';
import { IDownloadTsLfRequest } from './treasury/models/DownloadTsLfRequest';
import { AppConfigService } from '@app-shared/services';
import { EncryptedFhlBankUserModel } from './treasury/models/EncryptedFhlBankUserModel';



@Injectable({
    providedIn: 'root'
})
export class TreasuryService {
    private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.API_PATH}/api/treasury/ext`
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/treasury`;

    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };


    constructor(private http: HttpClient) {

    }


    getDcSummaryData(): Observable<IGetDCSummaryApiResponse> {
        return this.http.post<IGetDCSummaryApiResponse>(`${this.API_SERVICE_PATH}/getdcsummarydata`, this.options)
            .pipe(map(response => response)
            );

    }

    getDcDetailByRate(req: IDCDetailsRateRequest): Observable<IGetDCDetailsRateResponse> {
        return this.http.post<IGetDCDetailsRateResponse>(`${this.API_SERVICE_PATH}/getdcdetailbyrate`, req)
            .pipe(map(response => response)
            );

    }
    getTreasurySummaryDcData(): Observable<GetTreasurySummaryResponseModel> {
        return this.http.post<GetTreasurySummaryResponseModel>(`${this.API_SERVICE_PATH}/gettreasurysummarydcdata`,
            this.options)
            .pipe(map(response => response)
            );

    }
    getLfSummaryData(): Observable<GetTreasurySummaryResponseModel> {
        return this.http.post<GetTreasurySummaryResponseModel>(`${this.API_SERVICE_PATH}/getlfsummarydata`,
            this.options)
            .pipe(map(response => response)
            );
    }
    getTreasuryLFDetail(): Observable<IGetLFViewResponse> {
        return this.http.post<IGetLFViewResponse>(`${this.API_SERVICE_PATH}/gettreasurylfdetail`,this.options)
            .pipe(map(response => response)
            );

    }

    downloadTsrLfReports(request: IDownloadTsLfRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/downloadtsrlfreports`, request, { observe: 'response', responseType: 'arraybuffer' })
          .pipe(map(response => response)
          );
      }

    getPrograms(): Observable<IGetProgramtypesAPIResponse> {
        return this.http.get<IGetProgramtypesAPIResponse>(`${this.API_SERVICE_PATH}/getprograms`,this.options)
            .pipe(map(response => response)
            );

    }

    
    getEncryptedHLB(reqObj:{fhlBankId:number,fhlBankName:string}): Observable<EncryptedFhlBankUserModel> {
          return this.http.get<EncryptedFhlBankUserModel>(`${this.API_SERVICE_PATH}/getencryptedfhlbankuser?MpfBankKey=`+reqObj.fhlBankId +"&MpfBankName="+reqObj.fhlBankName)
           .pipe(map(response => response)
           );
      }
     

}
