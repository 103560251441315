<fhlb-modal-2 [topBorder]="topBorderClass" class="modal-hr">
    <div class="confirmation-message">
        {{ message }}
    </div>
    <div class="row">
        <div class="col-6">
            <button type="submit" id='ok-btn' class="btn btn-dark-blue btn-primary col-12" (click)="okClicked()">
                <b>
                    OK
                </b>
            </button>
        </div>
        <div class="col-6">
            <button type="submit" id='cancel-btn' class="btn btn-light-blue btn-primary col-12" (click)="cancelClick()">
                <b>
                    CANCEL
                </b>
            </button>
        </div>
    </div>
</fhlb-modal-2>