import {
  APP_BOOTSTRAP_LISTENER,
  InjectionToken,
  Inject,
  Type
} from '@angular/core';
import { EffectSources } from '@ngrx/effects';




export function bootstrapEffects(effects: Type<any>[], sources: EffectSources) {
  return () => {
    effects.forEach(effect => sources.addEffects(effect));
  };
}

export function createInstances(...instances: any[]) {
  return instances;
}

export function provideBootstrapEffects(effects: Type<any>[], injToken :InjectionToken<any>) {
  return [
    effects,
    { provide: injToken, deps: effects, useFactory: createInstances },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(injToken)], EffectSources]
    }
  ];
}
