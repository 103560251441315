import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[mersMinValueValidate]'
})
export class MersMinValueValidateDirective implements OnInit {
    private ngControl: NgControl;
    private htmlEl: HTMLElement;
    @Input('mersMinValueValidate') mersMinValue: string;
    pattern = /^[0-9-]+$/;
    constructor(public el: ElementRef, public renderer: Renderer2, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
        this.htmlEl = el.nativeElement;
    }

    ngOnInit() {
        setTimeout(() => {
            this.format(this.el.nativeElement.value);
        }, 3000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    format(val: string) {
        if (val) {
            let setErrors = false;
            this.ngControl.control.setErrors({ pattern: false });
            this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
            if (!this.htmlEl.classList.contains('is-invalid')) {
                this.htmlEl.classList.remove('is-invalid');
            }
            let output = val.match(this.pattern);
            if(val.includes('-')){
                val = val.replace(/-/g,'');
            }
            if (val.length < 18) {
                setErrors = true;
            }
            else if (output === null) {
                setErrors = true;
            }
            if (setErrors) {
                this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                this.ngControl.control.setErrors({ pattern: true });
                if (!this.htmlEl.classList.contains('is-invalid')) {
                    this.htmlEl.classList.add('is-invalid');
                }
            }
            else {
                    let str = val.substring(0, 7) + "-" + val.substring(7);  
                    str = str.substring(0, 18) + "-" + str.substring(18);
                    this.renderer.setProperty(this.el.nativeElement, 'value', str);
                    this.ngControl.control.setErrors(null);
                    if (!this.htmlEl.classList.contains('is-invalid')) {
                        this.htmlEl.classList.remove('is-invalid');
                    }
            }
        }
    }
}
