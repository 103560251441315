import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.sass']
})
export class AlertModalComponent implements OnInit {

  topBorderClass = 'top-border-blue';
  title:string;
  message: string;
  confirmBtnText: string;
  cancelBtnText:string;
  messageTitle: string;
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal,
    public router: Router) { }

  ngOnInit() {
 
  }

  ok() {

    this.activeModal.close('ok');
  }

  cancel() {
    this.activeModal.close('CANCEL');
  }

}
