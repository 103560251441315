import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Observer, Subject, Subscription, timer } from "rxjs";
import { AnonymousSubject } from "rxjs/internal/Subject";
import { map, take } from "rxjs/operators";
import { AppState } from "src/store/reducers";
import { getAccessTokenFromStore } from "src/store/selectors";
import { AppConfigService } from "../configuration/app-config.service";

@Injectable()
export class WebsocketService {
  private awsEncodedIdToken : string;
  constructor() { }

  private subject: Subject<MessageEvent>;
  private ping$: Observable<number>;
  public ws: WebSocket;
  private subs: Subscription[] = []

  public connect(store:Store<AppState>): Subject<MessageEvent> {
    let url = `${AppConfigService.Settings.Mpf.WebSocket}`
    if (!this.subject) {
      store.select(getAccessTokenFromStore).pipe(take(1), map(x => this.awsEncodedIdToken = x)).subscribe();
      this.subject = this.create(url);
      let subject = this.subject

      this.ws.onopen = ((e: Event) => {
        let pingPayload: any = { action: "ping" };
        subject.next(pingPayload)
      })

      console.log("Successfully connected: " + url);
    }
    this.startKeepAlive(this.subject)
    return this.subject;
  }

  private create(url: string): AnonymousSubject<MessageEvent> {
    let protocols: string[] = [ this.awsEncodedIdToken, "mpf-protocol" ];
    this.ws = new WebSocket(url, protocols);
    let ws = this.ws

    let observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    let observer = {
      next: (data: any) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
      error: (data: Object) => { console.log(data) },
      complete: () => { console.log('WebSocket connection closed.') }
    };
    return new AnonymousSubject<MessageEvent>(observer, observable)
  }

  //AWS WebSocket API times out after 10 minutes of no activity. Ping every 5 minutes.
  private startKeepAlive(subject: Subject<MessageEvent>) {
    this.ping$ = timer(300000, 300000);
    let s = this.ping$.subscribe(() => {
      let pingPayload: any = { action: "ping" };
      subject.next(pingPayload)
    });
    this.subs.push(s);
  }

  close() {
    this.ws.close()
    this.subs.forEach(x => x.unsubscribe())
  }
}