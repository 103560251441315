import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { isNullOrUndefined } from 'is-what';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[compareValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CompareValidatorDirective, multi: true }]
})

export class CompareValidatorDirective implements Validator {
    @Input('compareValidator') compareWith: string;
    @Input('compareOperator') compareOperator: string;
    

    validate(control: AbstractControl): { [key: string]: boolean } | null {
        let controlValue = control.value;
        control.setErrors(null);
        if (!isNullOrUndefined(controlValue) && controlValue !== '') {
            let replacedVal = controlValue.toString().replace(/[% , bps]/g, '');
            let compareResult = this.evaluate(+replacedVal, +this.compareWith, this.compareOperator);
            if (!compareResult) {
                control.setErrors({ invalid: true });
                return { range: true };
            }
        }
        control.setErrors(null);

        return null;
    }

    evaluate(param1, param2, op) {
        switch (op) {
            case 'LessThanEqual':
                return param1 <= param2;
            case 'GreaterThanZeroLessThanEqual':
                return (param1>=0 && param1 <= param2);
            case 'GreaterThanOneLessThanEqual':
                return (param1>=1 && param1 <=param2)
             // add more operators when needed
             default:
                return true;
        }

        
    }
}
