import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpUtilHelper } from "@app-shared/helpers/http-util-helper";
import { AppConfigService } from "@app-shared/services";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ICreateCeEstimatorBatchHeaderApiRequest } from "./models/CreateCeEstimatorBatchHeaderApiRequest";
import { ICreateCeEstimacsstorBatchHeaderApiResponse } from "./models/CreateCeEstimatorBatchHeaderApiResponse";
import { DownloadCEEstimatorFileToSharedPathApiRequest } from "./models/DownloadCEEstimatorFileToSharedPathApiRequest";
import { IDownloadCEEstimatorFileToSharedPathApiResponse } from "./models/DownloadCEEstimatorFileToSharedPathApiResponse";
import { GetCeEstimatorBatchDetailsApiRequest } from "./models/GetCeEstimatorBatchDetailsApiRequest";
import { GetCeEstimatorBatchDetailsApiResponse } from "./models/GetCeEstimatorBatchDetailsApiResponse";
import { IPollForCeEstimatorBatchCompletionApiRequest } from "./models/PollForCeEstimatorBatchCompletionApiRequest";
import { IPollForCeEstimatorBatchCompletionApiResponse } from "./models/PollForCeEstimatorBatchCompletionApiResponse";
import { SendCeEstimatorEmailNotificationApiRequest } from "./models/SendCeEstimatorEmailNotificationApiRequest";
import { IUploadCeEstimatorBatchHeaderAPIResponse } from "./models/UploadCeEstimatorBatchHeaderAPIResponse";
import { ICreateCeEstimatorBatchHeaderApiResponse } from "./websocket/models/CreateCeEstimatorBatchHeaderApiResponse";

@Injectable()
export class CEEstimatorService {
    private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.API_PATH}/api/ceestimator`
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/ceestimator`;
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }
    constructor(private http: HttpClient) {
    }

    getCeEstimatorBatchDetails(apiRequest: GetCeEstimatorBatchDetailsApiRequest): Observable<GetCeEstimatorBatchDetailsApiResponse> {
        return this.http.get<GetCeEstimatorBatchDetailsApiResponse>(`${this.API_SERVICE_PATH}/${"GetCeEstimatorBatchDetails".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(apiRequest)}`, this.options)
            .pipe(map(response => response));
    }

    sendCeEstimatorEmailNotification(apiRequest: SendCeEstimatorEmailNotificationApiRequest) {
        return this.http.post(`${this.API_SERVICE_PATH}/${"SendCeEstimatorEmailNotification".toLowerCase()}`, apiRequest , this.options)
            .pipe(map(response => response));
    }

    createCEEstimator(connectionId: string, req: ICreateCeEstimatorBatchHeaderApiRequest): Observable<ICreateCeEstimatorBatchHeaderApiResponse>  {
        return this.http.post<ICreateCeEstimatorBatchHeaderApiResponse>(`${this.API_SERVICE_PATH}/${"CreateCeEstimatorBatchHeader".toLowerCase()}`, req, this.websocketOptions(connectionId))
          .pipe(map(response => response));
      }
      
      
  uploadCeEstimatorBatchHeaderFile(ceEstimatorBatchFiles: any): Observable<IUploadCeEstimatorBatchHeaderAPIResponse> {
    const formData = new FormData();
      //add all of the assigned files
      if (ceEstimatorBatchFiles && ceEstimatorBatchFiles.length > 0) {
        let fileToUpload = <File>ceEstimatorBatchFiles[0];
        formData.append('CEEstimatorBatchFile', fileToUpload, fileToUpload.name);
      }      
      const headers = new HttpHeaders();
      headers.set('Content-Type', 'multipart/form-data');
      return this.http.post<IUploadCeEstimatorBatchHeaderAPIResponse>(`${this.API_SERVICE_PATH}/${"UploadCeEstimatorBatchHeader".toLowerCase()}`, formData,{headers})
      .pipe(map(response => response));
     }

     DownloadCEEstimatorFileToSharedPath(req:DownloadCEEstimatorFileToSharedPathApiRequest): Observable<IDownloadCEEstimatorFileToSharedPathApiResponse> {
      return this.http.post<IDownloadCEEstimatorFileToSharedPathApiResponse>(`${this.API_SERVICE_PATH}/${"DownloadCEEstimatorFileToSharedPath".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
        .pipe(map(response => response));
    }

    pollForCeEstimatorBatchCompletion(connectionId: string, apiRequest: IPollForCeEstimatorBatchCompletionApiRequest): Observable<IPollForCeEstimatorBatchCompletionApiResponse> {
      return this.http.get<IPollForCeEstimatorBatchCompletionApiResponse>(`${this.API_SERVICE_PATH}/${"PollForCeEstimatorBatchCompletion".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(apiRequest)}`, this.websocketOptions(connectionId))
          .pipe(map(response => response));
  }
}
