//import { isNullOrUndefined } from 'is-what';

export const EmpfRoutingHelper = {
    Unauthorized: () => "/empf/error",
    FooterAboutUsLandingPath: () => "/empf/footer/aboutus",
    FooterCopyrightLandingPath: () => "/empf/footer/copyright",
    FooterPrivacyPolicyLandingPath: () => "/empf/footer/privacypolicy",
    FooterTermsAndConditiontLandingPath: () => "/empf/footer/termsandconditions",
    ResourcesMpfProductInformationLandingPath: () => "/empf/resources/mpfproductinformation",
    ResourcesMpfProductInformationXtra3DLandingPath: () => "/empf/resources/mpfproductinformation/mpfxtra3d",
    EmpfTutorialLandingPath: () => "/empf/resources/helpandtraining/tutorial",
    FaqsLandingPath: () => "/empf/resources/helpandtraining/faqs",
    HeaderContactUsLandingPath: () => "/empf/header/contactus",
    ReportsDeliveryCommitmentLandingPath: () => "/empf/reports/deliverycommitmentreports",
    OriginationAndServicingGuidesLandingPath: () => "/empf/resources/OriginationAndServicingGuides",
    PFILevelAdjustmentsLandingPath: () => "/empf/pricing/PfiLevelAdjustments",
    DocumentUploadLandingPath: () => "/empf/documents/documentupload",
    ResourcesSalesToolsLandingPath: () => "/empf/resources/salestools",
    GlossaryLandingPath: () => "/empf/resources/helpandtraining/glossary",
    ReportsMPFLoanFundingLandingPath: () => "/empf/reports/mpfbankreports/loanfunding",
    TreasuryDeliverycommitmentLandingPath: () => "/empf/treasury/deliverycommitmentdetail/",
    TreasuryLFViewLandingPath: () => "/empf/treasury/loanfundingdetail/loanFundingView",
    TreasuryLFDownloadLandingPath: () => "/empf/treasury/loanfundingdetail/loanfundingdownload",
    FooterFeedbackFormLandingPath: () => "/empf/footer/feedbackform",
    CurrentPricingLandingPath: () => "/empf/pricing/currentpricing",
    CurrentSRPPricingLandingPath: () => "/empf/pricing/currentsrppricing",
    XtraMBSLLPAGridsLandingPath: () => "/empf/pricing/xtrambsllpagrids",
    ArchivedPricingLandingPath: () => "/empf/pricing/archivedpricing",
    PricingAdjustmentFactorLandingPath: () => "/empf/pricing/pricingadjustmentfactor",
    MpfbankPricingSchdulesLandingPath: () => "/empf/pricing/mpfbankpricingschdules",
    TreasurySummaryLandingPath: () => "/empf/treasury/treasurysummary",
    CEEstimatorFileUploadLandingPath: () => "/empf/transaction/creditenhancementestimator/fileupload",
    FormExhibitsOriginationLandingPath: () => "/empf/exhibits/origination",
    FormExhibitsDocumentCustodyLandingPath: () => "/empf/exhibits/documentcustody",
    FormExhibitsServicingLandingPath: () => "/empf/exhibits/servicing",
    OgForm1LandingPath: () => "/empf/exhibits/og1form",
    Og2FormLandingPath: () => "/empf/exhibits/og2form",
    CEEstimatorInteractiveEntryLandingPath: () => "/empf/transaction/creditenhancementestimator/interactiveentry",
    SubmitBatchLandingPath: () => "/empf/transaction/submitbatch",
    TransactionsServicingTransferLandingPath: () => "/empf/transaction/servicingtransfer",
    ReportsBatchLandingPath: () => "/empf/reports/batchreports",
    LoanFundingLandingPath: () => "/empf/transaction/loanfunding",
    DeliveryCommitmentLandingPath: () => "/empf/transaction/deliverycommitment",
    AecActivityReportLandingPath: () => "/empf/reports/pfiannualcertification/aecActivityReport",
    AecReportLandingPath: () => "/empf/reports/pfiannualcertification/aecReport",
    CreditEnhancementEstimatorReportLandingPath: () => "/empf/reports/creditenhancement",
    ServicingTransferReportLandingPath: () => "/empf/reports/servicingtransferreports",
    OtherPfiReportLandingPath: () => "/empf/reports/otherpfireport",
    MpfBankMasterCommitmentrReportsLandingPath: () => "/empf/reports/mpfbankreports/mpfbankmastercommitmentreports",
    MpfBankDeliveryCommitmentrReportsLandingPath: () => "/empf/reports/mpfbankreports/mpfbankdeliverycommitmentreports",
    MpfBankCustodyReportsLandingPath: () => "/empf/reports/mpfbankreports/mpfbankcustodyreports",
    PfiCustodyReportsLandingPath: () => "/empf/reports/pfispecificreports/pficustodyreports",
    OtherPfiSpecificReportsLandingPath: () => "/empf/reports/pfispecificreports/otherpfispecificreports",
    PfiMasterCommitmentReportsLandingPath: () => "/empf/reports/pfispecificreports/pfimastercommitmentreports",
    PFISpecificBatchReportsLandingPath: () => "/empf/reports/pfispecificreports/pfibatchreports",
    ReportsMasterCommitmentLandingPath: () => "/empf/reports/mastercommitment",
    HomeLandingPath: () => "/empf/home",
    ProfileLandingPath: () => "/empf/header/profile",
    LoanPresentmentLandingPath: () => "/empf/transaction/loanpresentment",
    TransactionsSummaryLandingPath: () => "/empf/transaction/summary",
    HeaderMessagesLandingPath: () => "/empf/header/messages",
    HeaderManageUsersLandingPath: (orgKey: string) => `/empf/header/manageusers/${orgKey}`,
    CustodyReportsLandingPath: () => "/empf/reports/custodyreports",
    ReportsMPFAccountingLandingPath: () => "/empf/reports/mpfbankreports/accounting",
    ReportsOtherMPFBankReportsLandingPath: () => "/empf/reports/mpfbankreports/othermpfbankreports",
    AecLandingPath: () => "/empf/exhibits/aecReport",
    PropertyDemographicsLandingPath: () => "/empf/resources/propertydemographics"
}
