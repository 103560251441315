import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CustomToastService } from '@app-shared/services';

@Component({
  selector: 'loan-funding',
  templateUrl: './loan-funding-modal.component.html',
  styleUrls: ['./loan-funding-modal.component.sass']

})

export class LoanFundingModalComponent implements OnInit {
  accordionStatus = true;
  title: string;
  message: string;
  confirmBtnText: string;
  stopBtnText: string;
  messageTitle: string;
  cancelBtnText: string;
  showcancel: boolean;
  disablestopBtnText: boolean;
  hidestopButton: boolean = true;


  constructor(private router: Router, private toastr: CustomToastService,
    public activeModal: NgbActiveModal, private modalService: NgbModal) {
  }


  ngOnInit() {
    this.toastr.remove();
    if (this.disablestopBtnText) {
      this.hidestopButton = false;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
  }
  close() {
    this.activeModal.close("close");
  }
  continue() {
    this.activeModal.close("continue");
  }
  cancel() {
    this.activeModal.close("closeCancelAlert");
  }



}