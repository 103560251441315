import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { ToastComponent } from '../../components/toastr-component/error/toastr.component';
import { SuccessToastComponent } from '../../components/toastr-component/success/success-toastr.component';
import { WarningToastComponent } from '../../components/toastr-component/warning/warning-toastr.component';

@Injectable({
  providedIn: 'root'
})
export class CustomToastService {
  successTitle: string = "Success";
  failureTitle: string = "Please resolve the following errors:";
  warningTitle: string = "Warning";

  errorConfig: Partial<IndividualConfig> = {
    timeOut: 0,
    positionClass: 'toast-top-center',
    tapToDismiss: false,
    enableHtml: true,
    toastComponent: ToastComponent,
    extendedTimeOut: 0
  };

  successConfig: Partial<IndividualConfig> = {
    timeOut: 0,
    positionClass: 'toast-top-center',
    tapToDismiss: false,
    enableHtml: true,
    toastComponent: SuccessToastComponent,
    extendedTimeOut: 0
  };

  warningConfig: Partial<IndividualConfig> = {
    timeOut: 0,
    positionClass: 'toast-top-center',
    tapToDismiss: false,
    enableHtml: true,
    toastComponent: WarningToastComponent,
    extendedTimeOut: 0
  };

  constructor(private toastr: ToastrService) { }

  show(errors: Array<string>) {
    let messages = errors.join('<br/>');
    this.toastr.show(messages, this.failureTitle, this.errorConfig);
  }


  remove(toastId?: number) {
    this.toastr.clear(toastId);
  }
  showMessage(message: string) {
    this.toastr.show(message, this.failureTitle, this.errorConfig);
  }

  showSuccessMessage(message: string) {
    this.toastr.show(message, this.successTitle, this.successConfig);
  }

  showSuccessMessageWithTitle(message: string, title: string) {
    this.toastr.show(message, title, this.successConfig);
  }


  showMessageWithTitle(message: string, title: string) {
    this.toastr.show(message, title, this.errorConfig);
  }
  showWarningMessage(message: string) {
    this.toastr.show(message, this.warningTitle, this.warningConfig);
  }
  showWarningMessages(warnings: Array<string>) {
    let messages = warnings.join('<br/>');
    this.toastr.show(messages, this.warningTitle, this.warningConfig);
  }

  showWarningsMessageWithTitle(message: string, title: string) {
    let activeToast = this.toastr.show(message, title, this.warningConfig);
    return activeToast;
  }

}
