<div class="card mt-3 defaultText">
  <div class="row">
    <div class="col-12">
      <div class="col-12 card-header">
        <div class="row">
          <div class="col-11 ps-4">
            <b>Override</b>
          </div>
          <button class="toastr-command col-0 pe-1 btn text-end defaultText" id="collapseBtn" (click)="isCollapsed= !isCollapsed"
            [ngSwitch]="isCollapsed">
            <i *ngSwitchCase="false" id="plus" class="fa fa-plus fa-lg"></i>
            <i *ngSwitchCase="true" id="minus" class="fa fa-minus fa-lg"></i>
          </button>
          <button type="button" class="toastr-command col-0 btn text-end defaultText btnccross" aria-label="Close"
                        (click)="close()">
                        <span aria-hidden="true" id="cross" class="fa fa-times fa-lg"></span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="collapse row" id="collapseComment" [ngbCollapse]="!isCollapsed">
    <div class="col-12 card-body">
      <form name="enableoverride" id="enableoverride" #enableoverride="ngForm">
        <div class="justify-content-center mb-0 ps-2">
          Your login has been granted SuperUser privileges. You may attempt to override the system validations or business rules that produce these errors,
          however doing so may produce unpredictable results.
          In the event that these rules or validations are unable to be overridden, this message will reappear.
        </div>
        <div class="row mt-3 ps-2">
          <div class="col-5">
            <button type="submit" id="enableoverridebutton" (click)="EnableoverrideButton()" class="btn btn-dark-blue btn-primary">
              <b>
                ENABLE OVERRIDE
              </b>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
