<fhlb-modal-2 id="successmsg" [title]="title" [topBorder]="topBorderClass" class="" [hideCloseButton]="hideCloseButton">
    <div class="modal-modalheight j">
        <div class="row justify-content-center text-center">
            <i class="fa fa-check-circle-o  fa-4x font-green" aria-hidden="true"></i>
        </div>
        <div class="row justify-content-center ">
            <div class="col-7 col-form-label message text-center " id="msg">
                {{message}}
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 col-form-label fieldLabel text-center" id="additionalInfo" >
                {{additionalInformation}}
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="returnToHome">
            <button type="submit" class="btn col-7 btn-light-blue btn-primary" (click)="returnToHomeClick()">
                <b>
                    RETURN TO HOME
                </b>
            </button>
        </div>
    </div>
</fhlb-modal-2>