<div class="row">
    <div class="col-12">
        <div class="col-12 card-header">
            <div class="row">
                <div class="col-12  fontcolor">
                    <label class="col-10  btn text-start fontcolor font-bold mb-0"
                        style="white-space: pre-line">{{messageTitle}}</label>
                    <button type="button" class="col-1 pe-4 btn text-end">
                        <span id="minus" class="fa fa-minus fa-lg fontcolor"
                            [ngClass]="{ 'fa-minus': accordionStatus=== true, 'fa-plus': accordionStatus=== false }"
                            data-bs-toggle="collapse" (click)="accordionStatus = !accordionStatus"></span>
                    </button>
                    <button type="button" class="col-1 pe-4 btn text-end btnccross" aria-label="Close"
                        (click)="activeModal.dismiss()">
                        <span aria-hidden="true" class="fa fa-times fa-lg fontcolor"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

 

 


<div class="bordererror show" id="loan-funding">

 

 

    <div class="row">
        <div class="col-12 col-form-label message text-start ps-4 pe-4" [innerHTML]="message">

        </div>
    </div>

 

 

    <div class="row justify-content-center pt-3 pb-4">
        <div class="col-3">
            <button class="btn btn1 btn-dark-blue btn-primary col-12" (click)="continue()"><b>
                    {{confirmBtnText}} </b>
            </button>
        </div>

 

        <div class="col-3" *ngIf="hidestopButton">
            <button class="btn btn1 btn-light-blue btn-primary col-12" (click)="close()"><b>
                    {{stopBtnText}} </b>
            </button>
        </div>
        <div class="col-3" *ngIf="showcancel">
            <button class="btn btn1 btn-light-blue btn-primary col-12" (click)="cancel()"><b>
                    {{cancelBtnText}} </b>
            </button>
        </div>

 

    </div>

 

</div>