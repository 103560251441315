import { Pipe, PipeTransform } from '@angular/core';
import { pricingHelper } from '../helpers/pricing-helper';
import { UtilHelper } from '../helpers/util-helper';
import { pricingConstant } from 'src/shared/constants/pricing-constant';
@Pipe({
  name: 'findSchedules'
})
export class FindSchedules implements PipeTransform {

  transform(items, pricingCriteria, pricingSourceName, masterRemittances, masterDeliveryTypes, masterProducts): any {
    {

      if (UtilHelper.isUndefinedOrNull(items) || items.length === 0) {
        return items;
      }

      var filteredSchedules = [];

      //get restricted products from pricing criteria
      var restrictedPricingCriteria = pricingHelper.getRestrictedPricingCriteria(pricingCriteria, masterProducts, masterRemittances);

      //figure out the missing products based on preferredPricingSources
      var missingProductTracker = [];
      pricingCriteria.preferredPricingSources.forEach((preferredPricingSource) => {
        if (pricingSourceName === "Mpx") {
          var newPricingSource = [];
          if (preferredPricingSource.deliveryTypes.length === 1) {
            newPricingSource.push({
              deliveryTypes: [],
              preferredProducts: [],
              pricingSourceDetail: preferredPricingSource.pricingSourceDetail,
              remittanceTypes: preferredPricingSource.remittanceTypes,
              selectAll: false
            });
          }
          else {
            newPricingSource.push({
              deliveryTypes: [],
              preferredProducts: preferredPricingSource.preferredProducts,
              pricingSourceDetail: preferredPricingSource.pricingSourceDetail,
              remittanceTypes: preferredPricingSource.remittanceTypes,
              selectAll: false
            });
          }
          preferredPricingSource.deliveryTypes.forEach((delTypes) => {
            newPricingSource[0].deliveryTypes.push(delTypes);
          });
          //angular.copy(newDeliveryType, preferredPricingSource);
          if (UtilHelper.isDefinedOrNotNull(newPricingSource[0].pricingSourceDetail.value)) {
            if (newPricingSource[0].pricingSourceDetail.value === pricingSourceName) {
              //iteration - preferredPricingSource remittanceTypes
              newPricingSource[0].remittanceTypes.forEach((remittanceType) => {
                //iteration - preferredPricingSource deliveryTypes
                newPricingSource[0].deliveryTypes.forEach((deliveryType) => {

                  if (deliveryType !== pricingConstant.selectAll) {

                    if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts.preferredSubProducts) && preferredPricingSource.deliveryTypes.length === 1) {
                      preferredPricingSource.preferredProducts.forEach((pProducts) => {
                        if (preferredPricingSource.deliveryTypes == pProducts.deliveryTypes)
                          newPricingSource[0].preferredProducts.push({
                            preferredSubProducts: [
                              {
                                productCode: pProducts.subProducts[0].productCode,
                                productName: pProducts.subProducts[0].productName,
                                sortOrder: pProducts.subProducts[0].sortOrder
                              }
                            ],
                            productCode: pProducts.productCode,
                            productName: pProducts.productName
                          });
                      });
                    }

                    //iteration - preferredPricingSource product
                    newPricingSource[0].preferredProducts.forEach((preferredProduct) => {
                      if (preferredProduct.preferredSubProducts.length > 0) {
                        //iteration - subProducts
                        preferredProduct.preferredSubProducts.forEach((preferredSubProduct, index) => {
                          var productFoundInScheduleDetail = false;
                          var areAllNoteRatesOutofRange = false;

                          //iteration - schedule
                          items.forEach((schedule) => {
                            if (schedule.pricingSourceDetail.value === pricingSourceName &&
                              schedule.deliveryTypeDetail.value === deliveryType) {


                              //iteration - scheduleDetail
                              schedule.scheduleDetail.forEach((scheduleDetail) => {
                                if (preferredProduct.productCode === scheduleDetail.productCode &&
                                  preferredSubProduct.productCode === scheduleDetail.subProductCode &&
                                  remittanceType === scheduleDetail.remittanceType) {
                                  productFoundInScheduleDetail = true;
                                  return;
                                }
                              });


                              if (schedule.areAllNoteRatesOutofRange && (schedule.subProductCode === preferredSubProduct.productCode)) {
                                areAllNoteRatesOutofRange = schedule.areAllNoteRatesOutofRange;
                                return;
                              }

                              if (productFoundInScheduleDetail) {
                                return;
                              }
                            }
                            else {
                              schedule.scheduleDetail.forEach((scheduleDetail) => {
                                if ((deliveryType === "Mandatory" && scheduleDetail.subProductCode.match('MPXBE'))
                                  || (deliveryType === "BestEfforts" && !scheduleDetail.subProductCode.match('MPXBE'))) {
                                  productFoundInScheduleDetail = true;
                                  return;
                                }
                                if (productFoundInScheduleDetail) {
                                  return;
                                }
                              });
                            }
                          });

                          if (!productFoundInScheduleDetail && preferredProduct.productCode !== pricingConstant.selectAll &&
                            preferredSubProduct.productCode !== pricingConstant.selectAll &&
                            remittanceType !== pricingConstant.selectAll &&
                            deliveryType !== pricingConstant.selectAll) {

                            //check if the product falls under restricted category
                            var isRestrictedProduct = false;
                            restrictedPricingCriteria.forEach((rpc) => {
                              if (rpc.pricingSourceName === pricingSourceName) {
                                rpc.restrictedProducts.forEach((restrictedProduct) => {
                                  if (restrictedProduct.productCode === preferredProduct.productCode) {
                                    isRestrictedProduct = true;
                                    return;
                                  }
                                });

                                if (isRestrictedProduct) {
                                  return;
                                }
                              }
                            });

                            if (!isRestrictedProduct) {
                              missingProductTracker.push({
                                'pricingSourceName': pricingSourceName,
                                'deliveryType': deliveryType,
                                'remittanceType': remittanceType,
                                'parentProductCode': preferredProduct.productCode,
                                'subProductCode': preferredSubProduct.productCode,
                                'subProductName': preferredSubProduct.productName,
                                'unavailable': true,
                                'authorized': true,
                                'areAllNoteRatesOutofRange': areAllNoteRatesOutofRange
                              });
                            } else {
                              missingProductTracker.push({
                                'pricingSourceName': pricingSourceName,
                                'deliveryType': deliveryType,
                                'remittanceType': remittanceType,
                                'parentProductCode': preferredProduct.productCode,
                                'subProductCode': preferredSubProduct.productCode,
                                'subProductName': preferredSubProduct.productName,
                                'unavailable': true,
                                'authorized': false,
                                'areAllNoteRatesOutofRange': false
                              });
                            }
                          }
                        });
                      }
                    });
                  }
                });
              });
            }
          }
        } else {
          if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
            if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {
              //iteration - preferredPricingSource remittanceTypes
              preferredPricingSource.remittanceTypes.forEach((remittanceType) => {
                //iteration - preferredPricingSource deliveryTypes
                preferredPricingSource.deliveryTypes.forEach((deliveryType) => {

                  if (deliveryType !== pricingConstant.selectAll) {
                    //iteration - preferredPricingSource product
                    preferredPricingSource.preferredProducts.forEach((preferredProduct) => {
                      if (preferredProduct.preferredSubProducts.length > 0) {
                        //iteration - subProducts
                        preferredProduct.preferredSubProducts.forEach((preferredSubProduct, index) => {
                          var productFoundInScheduleDetail = false;
                          var areAllNoteRatesOutofRange = false;

                          //iteration - schedule
                          items.forEach((schedule) => {
                            if (schedule.pricingSourceDetail.value === pricingSourceName && 
                              schedule.deliveryTypeDetail.value === deliveryType) {
                              
                              //iteration - scheduleDetail
                              schedule.scheduleDetail.forEach((scheduleDetail) => {
                                if (preferredProduct.productCode === scheduleDetail.productCode &&
                                  preferredSubProduct.productCode === scheduleDetail.subProductCode &&
                                  remittanceType === scheduleDetail.remittanceType) {
                                  productFoundInScheduleDetail = true;
                                  return;
                                }
                              });

                              if (schedule.areAllNoteRatesOutofRange && (schedule.subProductCode === preferredSubProduct.productCode)) {
                                areAllNoteRatesOutofRange = schedule.areAllNoteRatesOutofRange;
                                return;
                              }

                              if (productFoundInScheduleDetail) {
                                return;
                              }
                            }
                          });

                          if (!productFoundInScheduleDetail && preferredProduct.productCode !== pricingConstant.selectAll &&
                            preferredSubProduct.productCode !== pricingConstant.selectAll &&
                            remittanceType !== pricingConstant.selectAll &&
                            (deliveryType !== pricingConstant.selectAll && preferredSubProduct.deliveryType == deliveryType)) {

                            //check if the product falls under restricted category
                            var isRestrictedProduct = false;
                            restrictedPricingCriteria.forEach((rpc) => {
                              if (rpc.pricingSourceName === pricingSourceName) {
                                rpc.restrictedProducts.forEach((restrictedProduct) => {
                                  if (restrictedProduct.productCode === preferredProduct.productCode) {
                                    isRestrictedProduct = true;
                                    return;
                                  }
                                });

                                if (isRestrictedProduct) {
                                  return;
                                }
                              }
                            });
                            
                            if (!isRestrictedProduct) {
                              missingProductTracker.push({
                                'pricingSourceName': pricingSourceName,
                                'deliveryType': deliveryType,
                                'remittanceType': remittanceType,
                                'parentProductCode': preferredProduct.productCode,
                                'subProductCode': preferredSubProduct.productCode,
                                'subProductName': preferredSubProduct.productName,
                                'unavailable': true,
                                'authorized': true,
                                'areAllNoteRatesOutofRange': areAllNoteRatesOutofRange
                              });
                            } else {
                              missingProductTracker.push({
                                'pricingSourceName': pricingSourceName,
                                'deliveryType': deliveryType,
                                'remittanceType': remittanceType,
                                'parentProductCode': preferredProduct.productCode,
                                'subProductCode': preferredSubProduct.productCode,
                                'subProductName': preferredSubProduct.productName,
                                'unavailable': true,
                                'authorized': false,
                                'areAllNoteRatesOutofRange': false
                              });
                            }
                          }
                        });
                      }
                    });
                  }
                });
              });
            }
          }
        }
      });

      
      filteredSchedules = items
      //add missing or restricted sub products
      if (missingProductTracker.length > 0) {
        missingProductTracker.forEach((product) => {

          var schedule = {
            'pricingSourceDetail': {
              'description': product.pricingSourceName,
              'value': product.pricingSourceName
            },
            'deliveryTypeDetail': {
              'description': this.getDeliveryTypeDetails(masterDeliveryTypes, product.deliveryType, pricingSourceName).description,
              'value': product.deliveryType
            },
            'scheduleDetail': [
              {
                'productCode': product.parentProductCode,
                'subProductCode': product.subProductCode,
                'subProductName': product.subProductName,
                'remittanceType': product.remittanceType,
                'remittanceDescription': this.getRemittanceDetails(masterRemittances, product.remittanceType, pricingSourceName).longDescription,
                'unavailable': product.unavailable,
                'authorized': product.authorized
              }
            ],
            'areAllNoteRatesOutofRange': product.areAllNoteRatesOutofRange
          };

          filteredSchedules.push(schedule);
        });
      }

      //add index for tracking purpose in DOM instead of using ng-repeat $index as it volatile variable
      filteredSchedules.forEach((schedule, index) => {
        schedule.index = index;
      });

      return filteredSchedules;
    }


  }

  getDeliveryTypeDetails(masterDeliveryTypes, deliveryType, pricingSourceName) {
    var matchedDeliveryTypeDetail = null;
    masterDeliveryTypes.forEach((masterDeliveryType) => {
      if (masterDeliveryType.pricingSourceDetail.value === pricingSourceName) {
        masterDeliveryType.deliveryTypeDetails.forEach((deliveryTypeDetail) => {
          if (deliveryTypeDetail.value === deliveryType) {
            matchedDeliveryTypeDetail = deliveryTypeDetail;
            return;
          }
        });
      }
    });

    return matchedDeliveryTypeDetail;
  }

  getRemittanceDetails(masterRemittances, remittanceType, pricingSourceName) {
    {
      var matchedRemittanceTypeDetail = null;
      masterRemittances.forEach((masterRemittance) => {
        if (masterRemittance.pricingSourceDetail.value === pricingSourceName) {
          masterRemittance.productRemittances.forEach((productRemittance) => {
            if (productRemittance.remittanceType === remittanceType) {
              matchedRemittanceTypeDetail = productRemittance;
              return;
            }
          });
        }
      });

      return matchedRemittanceTypeDetail;
    }

  }

}
