export class SortHelper {
    public static sortColumn(column, sort): any {
        return column === sort.column && 'sort-' + sort.descending;
    }

    public static changeSorting(column,sort): any {
        if (sort.column === column) {
            sort.descending = !sort.descending;
        } else {
            sort.column = column;
           
            sort.descending = false;
        }
    }

    public static changeMultiSorting(column, column2,column3, sort): any {
        if (sort.column === column) {
            sort.descending = !sort.descending;
        } else {
            sort.column = column;
            sort.column2 = column2;
            sort.column3 = column3;
            sort.descending = false;
        }
    }

}
