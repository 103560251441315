import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class Filter implements PipeTransform {

    transform(value: any[], args: any, field?: string): any {
        //if (typeof args == "string"){​​​​
        if (field && field != "" && field != null) {
            var a = value.filter(item => item[field].toString().toLowerCase().indexOf(args.toString().toLowerCase()) !== -1);
            return a;
        } else {
            var a = value.filter(item => item.anchor.toString().toLowerCase().indexOf(args.toString().toLowerCase()) !== -1);
            return a;
        }
        //}​​​​
    }
}