

import { Directive, ElementRef, OnInit, Self, HostListener, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';




@Directive({
    selector: 'input[validNumber]'
})




export class ValidNumberDirective implements OnInit {



    @Input() input: any;
    @Input() allowPercentage: string;
    @Input() allowPositiveNegative: string;
    @Input() allowDecimal: string;
    @Input() decimalUpto: number;
    @Input() allowdLength: number;
    @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();



    constructor(private el: ElementRef) { }


    ngOnInit(){
        this.numberFormat('inputChange');
    }
       




    @HostListener('keydown', ['$event']) onInputChange(event) {
        var map = { 17: false, 67: false, 86: false, 39: false, 37: false, 16: false, 65: false, 36: false, 35: false };
        var key = event.keyCode;
        var oVal = this.el.nativeElement.value || '';
        var caretPosition = this.el.nativeElement.selectionStart;




        if (event.which in map) {
            map[event.which] = false;
            if (
                (!(map[17] && (map[67] || map[86] || map[65]))) && //check its not (ctrl+c/ctrl+v/ctrl+a)
                (!(map[16] && (map[37] || map[39] || map[36] || map[35]))) && //check its not (shift+ arrow left/arrow right/end/home)
                (!(map[35] || map[36] || map[37] || map[39]) && (map[65] || map[67] || map[87] || map[86]))//check its not arrow keys (it should be with mapped characters key down either)
            ) {
                event.preventDefault();
            }
        }
        else if (event.shiftKey && key !== 187) {
            if (!(this.allowPercentage === "true" && key === 53) && !(key === 9)) {
                event.preventDefault();
            }
        }
        else if (this.allowDecimal === "false" && key === 110) {
            event.preventDefault();
        }
        else if (this.allowDecimal === "true" && key === 110 && oVal.indexOf('.') !== -1) {
            event.preventDefault();
        }
        else if (this.allowPositiveNegative === "false" && (key === 189 || key === 109 || key === 187 || key === 107)) {
            event.preventDefault();
        }
        else if (this.allowDecimal === "false" && key === 190) {
            event.preventDefault();
        }
        else if (!event.shiftKey && key === 187) { //Not shift key and equal sign
            event.preventDefault();
        }
        else if (((key === 107 || key === 109) || (event.shiftKey && key === 187) || key === 189) && caretPosition !== 0) { //+ - signs should be at first position 
            event.preventDefault();
        }
        else {
            if (!((key === 8) || (key === 32) || (key === 46) || (key === 9) || (key === 46) || (key === 110) ||
                (key === 190) || (key >= 35 && key <= 40) || (key >= 48 && key <= 57) || (key >= 96 && key <= 105) || (key === 13)
                || (key === 109) || (key === 189) || (key === 107) || (key === 187) || (key === 53) || (key === 190))) {
                event.preventDefault();
            }
        }
    }




    @HostListener('keyup', ['$event']) keyup(event) {
        var map = { 17: false, 67: false, 86: false, 39: false, 37: false, 16: false, 65: false, 36: false, 35: false };
        if (event.which in map) {
            map[event.keyCode] = false;
        }
    };




    numberFormat(event:string) {
        var text;
        if (event==='blur')
        { text = this.el.nativeElement.value;}
        else{
            text=this.input;  
        }
            
        {
            var parsedText = parseFloat(text);
            if (text === "") {
            } else if (text === "0") {
                this.ngModelChange.emit("0");
            } else if ((text === "0.00000" || parsedText === 0) && this.allowDecimal === "true") {
                this.ngModelChange.emit(parseFloat(text).toFixed(this.decimalUpto))
            } else if (isNaN(text)) {
                this.ngModelChange.emit(text);
            } else if (this.allowDecimal === "true") {
                if (parseFloat(text).toString().indexOf('.') === -1) {
                    var toFixedDigits = (Number(this.allowdLength) - 1) - parseFloat(text).toString().length;
                    if (toFixedDigits > this.decimalUpto) {
                        toFixedDigits = this.decimalUpto;
                    }
                    this.ngModelChange.emit(parseFloat(text).toFixed(toFixedDigits).toString());
                } else {
                    var totalLength = parseFloat(text).toString().length;
                    var afterDecimalLength = (parseFloat(text).toString()).split('.')[1].length;




                    if (totalLength < Number(this.allowdLength) && afterDecimalLength <= this.decimalUpto) {
                        this.ngModelChange.emit(parseFloat(text).toFixed(this.decimalUpto).toString());
                    } else if ((totalLength === Number(this.allowdLength)) || (totalLength < Number(this.allowdLength))) {
                        this.ngModelChange.emit(parseFloat(text).toString());
                    }
                }
            }
            else {
                this.ngModelChange.emit(parseFloat(text).toString());
            }
        }
    }
   
}
