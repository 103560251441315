import { Injectable } from '@angular/core';
import { CustomToastService } from '@app-shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IWebSocketErrorResponse } from '@origination-shared/helpers/models/web-socket-error-response';
import { LosServiceWebSocketUtilHelper } from '@origination-shared/helpers/web-socket-util-error-helper';
import { GeographicInfoService } from '@origination-shared/services/geographic-info/geographic-info-service';
import { RunGeographicProcessWebSocket } from '@origination-shared/services/geographic-info/websocket/run-geographic-process.websocket';
import * as moment from 'moment-timezone';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AppState } from 'src/store/reducers';
import { isNullOrUndefined } from 'is-what';
import { CompleteGeoProcessAction, GeoProcessActionTypes, GeoStatus, RunGeoProcessAction } from '../actions/geo-process.actions';

@Injectable()
export class GeoProcessEffects {
    private geProcessWebSocket: RunGeographicProcessWebSocket;
    constructor(
        private actions: Actions,
        private geoService: GeographicInfoService,
        private toastr: CustomToastService,
        private store: Store<AppState>
    ) {


    }


    
    callGeoProcess$ = createEffect(() => this.actions.pipe(
        ofType(GeoProcessActionTypes.RUN_GEO),
        switchMap((action: RunGeoProcessAction) => {
            let warningToast = this.toastr.showWarningsMessageWithTitle("Geographic Information is processing, you will be notified by Success/Failure message upon completion. Please note that other functionalities can be utilized while the Geographic Information is being processed.", "Processing Geographic Information");
            // Web Socket Initialisation For Each Attempt Seperatly
            this.geProcessWebSocket = new RunGeographicProcessWebSocket(this.geoService);
            //Creating New Connection On Each Attempt 
            this.geProcessWebSocket.connect(this.store);

            let req = { startDate: action.payload.startDate, endDate: action.payload.endDate };

            let resp = this.geProcessWebSocket.getMessages().pipe(
                filter(x => !isNullOrUndefined(x)),
                map((response) => {
                    if (response && response.Value && response.Value.Value) {
                        let sDate = moment(action.payload.startDate).format('MM/DD/YYYY');
                        let eDate = moment(action.payload.endDate).format('MM/DD/YYYY');
                        let unKnownErrorMessage = `Unknown error while processing geographic information between ${sDate} and ${eDate}`

                        if (isNullOrUndefined(response) || isNullOrUndefined(response.Value) || isNullOrUndefined(response.Value.Value)) {
                            this.toastr.remove(warningToast.toastId);
                            this.toastr.showMessage(unKnownErrorMessage);
                            //Closing Connection
                            this.geProcessWebSocket.close();
                            return new CompleteGeoProcessAction({ status: GeoStatus.Error, errors: [unKnownErrorMessage] })
                        }

                        if (response.Value.Value.CallGeographicCentrusEODProcessResult) {
                            let isSameDay = moment(action.payload.startDate).isSame(action.payload.endDate, 'day')
                            let mess = isSameDay ? `Geographic Information process has been run for ${sDate}`
                                : `Geographic Information process has been run for ${sDate} to ${eDate}`;
                            this.toastr.remove(warningToast.toastId);
                            this.toastr.showSuccessMessage(mess);
                            //Closing Connection
                            this.geProcessWebSocket.close();
                            return new CompleteGeoProcessAction({ status: GeoStatus.Successful, errors: [] })
                        }
                        else {
                          let errorMess = response.Value.Value.finalErrorMessage;
                            let isSameDay = moment(action.payload.startDate).isSame(action.payload.endDate, 'day')
                            if (isNullOrUndefined(errorMess) || errorMess == ''){
                                errorMess = 'Geographic Process has failed';}
                                else{
                           
                                    errorMess=errorMess.replace('Centrus ','')
               
                             }

                            this.toastr.remove(warningToast.toastId);
                            this.toastr.showMessage(errorMess);
                            //Closing Connection
                            this.geProcessWebSocket.close();
                            return new CompleteGeoProcessAction({ status: GeoStatus.Error, errors: [errorMess] })
                        }
                    }
                    else {
                        let sDate = moment(action.payload.startDate).format('MM/DD/YYYY');
                        let eDate = moment(action.payload.endDate).format('MM/DD/YYYY');
                        let unKnownErrorMessage = `Unknown error while processing geographic information between ${sDate} and ${eDate}`;
                        this.toastr.remove(warningToast.toastId);
                        let webSocketErrorResponse = <IWebSocketErrorResponse>JSON.parse(JSON.stringify(response));
                        if (isNullOrUndefined(webSocketErrorResponse.Message)) {
                            this.toastr.showMessage(unKnownErrorMessage);
                        }
                        else {
                            this.toastr.show(['Geographic Process has failed']);
                        }
                        //Closing Connection
                        this.geProcessWebSocket.close();
                        return new CompleteGeoProcessAction({ status: GeoStatus.Error, errors: [unKnownErrorMessage] });
                    }
                }),
                catchError(error => {
                    //Closing Connection
                    this.geProcessWebSocket.close();
                    return of(new CompleteGeoProcessAction({ status: GeoStatus.Error, errors: [error] }));
                })
            )
            // Subscribing To the Connection ID Response From The WebSocket
            // And Then Making REST Request Only When the Connection Is OPEN
            this.geProcessWebSocket.connectionDetails.subscribe(
                (response) => {
                    this.geProcessWebSocket.sendRestRequest(req, response);
                }
            ),
                catchError(error => {
                    return of(new CompleteGeoProcessAction({ status: GeoStatus.Error, errors: [error] }));
                });

            return resp;

        }
        )
    ));
}
