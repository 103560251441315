import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, forwardRef, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'fhlbc-switch',
  templateUrl: `./fhlbc-switch.component.html`,
  styleUrls: [`./fhlbc-switch.component.sass`],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FhlbSwitchComponent),
      multi: true
    }
  ]
})
export class FhlbSwitchComponent  implements ControlValueAccessor{
  @HostBinding('attr.id')
  externalId = '';

  @Input() disabled : boolean = false;

  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }

  private _ID = '';

  @Input('value') _value = false;
  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
  }
  onBlur() {
    this.onTouched();
  } 
  constructor() {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
      this.value = value;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  switch() {
    this.value = !this.value;
    this.onTouched();
  }
  
}
