import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild, Input } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { NgControl } from '@angular/forms';
import { UtilHelper } from '@app-shared/services';

@Directive({
    selector: '[formatDate]'
})

export class FormatDateDirective {
    private ngControl: NgControl;
    defaultAssumeNearbyYear: string = '50';

    @Input('assumeNearbyYear') assumeNearbyYear: string;

    constructor(public el: ElementRef, public renderer: Renderer2, private percentPipe: PercentPipe, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        if(e !== '') {
          var formatValue=e.split('/')[2];
            if (e !== '' && (new Date(e)).toString() != 'Invalid Date' && 
                (e.split('/')[2].length == 2 || e.split('/')[2].length == 4 ||  e.split('/')[2].length == 1) && (formatValue !='0' && formatValue !='00')) {
                this.format(e);
            } else if ((e !== '' && (new Date(e)).toString() == 'Invalid Date') || 
            !(e.split('/')[2].length == 2 || e.split('/')[2].length == 4)) {
                this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                this.ngControl.control.setErrors({ invalidDate: true });
            }
        }
    }

    format(val: string) {
        let dateFields = val.split('/');
        if (UtilHelper.isUndefinedOrNull(this.assumeNearbyYear)) {
            this.assumeNearbyYear = this.defaultAssumeNearbyYear;
        }

        if(dateFields[2].length == 4) {
            dateFields[2] = dateFields[2].substr(dateFields[2].length - 2);
        }

        let month: string;
        let day: string;
        let year: string;

        month = dateFields[0] ? parseInt(dateFields[0]) < 10 ? '0' + parseInt(dateFields[0]) : dateFields[0] : '';
        day = dateFields[1] ? parseInt(dateFields[1]) < 10 ? '0' + parseInt(dateFields[1]) : dateFields[1] : '';

        if (dateFields[2].toString().length == 1 || dateFields[2].toString().length == 2) {
            var currentYear = new Date().getFullYear();
            var last2Digits = currentYear % 100;

            var max2DigitCutOff = parseInt(this.assumeNearbyYear);
            var maxYear = currentYear + max2DigitCutOff;

            var currentCentury = currentYear - last2Digits;
            var previousCentury = currentCentury - 100;

            if (currentCentury + parseInt(dateFields[2]) > maxYear) {
                year = (previousCentury + parseInt(dateFields[2])).toString();
            } else {
                year = (currentCentury + parseInt(dateFields[2])).toString();
            }
        }

        let newDate = dateFields[0] + '/' + dateFields[1] + '/' + year;
        this.renderer.setProperty(this.el.nativeElement, 'value', newDate);
        this.ngControl.viewToModelUpdate(newDate);
    }
}
