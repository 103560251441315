import { Component, OnInit, Output} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouteDataSites, SiteName } from "@app-shared/models/routingdata";
import { Store } from "@ngrx/store";
import { UserService } from '@app-shared/services';
import { SetSiteAction } from "src/store/actions/auth.actions";
import { AppState } from "src/store/reducers";
import { map, take } from 'rxjs/operators'
import { getUserOrgKeyFromStore } from 'src/store/selectors';
import { GetUserRolesApiResponse } from '@app-shared/services/legacy-session-management/user/models/GetUserRolesApiResponse';

@Component({
  selector: 'sites-wrapping',
  templateUrl: './sites-wrapping.container.component.html',
  styleUrls: ['/sites-wrapping.container.component.sass']
})
export class SitesWrappingContainerComponent implements OnInit {
  isEmpf= false;
  isOrigination = false;
  userRoles: GetUserRolesApiResponse;
  private userOrgKey: string;
  @Output() isRoles: boolean;

  constructor(private userService: UserService,private activatedRoute: ActivatedRoute, private store: Store<AppState>) {
  }

  ngOnInit() {
    this.getUserRoles();
    let siteData = <RouteDataSites>this.activatedRoute.routeConfig.data;
     console.log("Site Data:", siteData);
    if (siteData.siteName == SiteName.Empf) {
      this.isEmpf = true
      this.store.dispatch(new SetSiteAction(SiteName.Empf))
    } else if (siteData.siteName == SiteName.Origination) {
      this.isOrigination = true
      this.store.dispatch(new SetSiteAction(SiteName.Origination))
    }
    
    this.store.select(getUserOrgKeyFromStore).pipe(take(1),
      map(x => {
        this.userOrgKey = x
      })
    ).subscribe();
  }
  
  getUserRoles() {
    this.userService.checkUserRoles().subscribe((response) => {
      let roles = {} as GetUserRolesApiResponse;
      roles.isFhlBankRole = response.isFhlBankRole;
      roles.isHlbRole = response.isHlbRole;
      roles.isPfiRole = response.isPfiRole;
      roles.isScuRole = response.isScuRole;
      this.userRoles = roles;
      if(this.userRoles.isPfiRole || this.userRoles.isHlbRole || this.userRoles.isScuRole || this.userRoles.isFhlBankRole){
        if(this.userRoles.isHlbRole && this.userOrgKey == "3"){
          this.isRoles = false
        }
        else{
          this.isRoles = true 
        }
      }
      return this.isRoles;
    })    
  }
}