
export enum ServicingModelType {
    /// <summary>
    ///   The unspecified
    /// </summary>
    Unspecified = 0,

    /// <summary>
    ///   The servicing retained
    /// </summary>
    ServicingRetained = 1,

    /// <summary>
    ///   The servicing released
    /// </summary>
    ServicingReleased,

    /// <summary>
    ///   The concurrent sale servicing
    /// </summary>
    ConcurrentSaleServicing,

    /// <summary>
    ///   The shared funding
    /// </summary>
    SharedFunding,

    /// <summary>
    ///   The bifurcated servicing released
    /// </summary>
    BifurcatedServicingReleased
}
