import { Pipe, PipeTransform } from '@angular/core';
import { UtilHelper } from '../services';

@Pipe({
    name: 'loanPresentmentListFilter'
})
export class LoanPresentmentListFilter implements PipeTransform {

    transform(items: any[], searchText: string): any {
        var filtered = []
        if (UtilHelper.isUndefinedOrNull(searchText) || searchText === '') {
            return items;
        }
        items.forEach(row => {
            var results =
                (((row.pfiLoanNumber).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.mcNumber).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.borrowerInfo.lastName).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.entryDate).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.mcDetail.programCode).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.mcDetail.subProgramCode).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.mcDetail.subProgramLoanType).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.mcDetail.servicingModel).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.mcDetail.deliveryType).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.originalLoanAmount).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.outstandingLoanBalance).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.productCode).toString().toLowerCase()).indexOf((searchText) || '') !== -1 ||
                    ((row.ceStatus).toString().toLowerCase()).indexOf((searchText) || '') !== -1
                );
            if (results) {
                filtered.push(row);
            }
        });
        return filtered;

    }
}

