<fhlb-modal-2 [title]="title" [topBorder]="topBorderClass" id="ContactList"  class="font-color-gray">
    <div>
        <div class="row" id="contactEmail" *blockUI="'contactMailingList'">
            <table id="contactMailingList" class="table table-striped table-responsive table-bordered  table-vertical-align scroll">
                <thead class="font-open-sans-bold text-uppercase text-center submit-batch-header">
                    <tr class="submit-batch-header">
                        <td class="sort-container empty line-height-15px">
                            <div>SELECT</div>
                        </td>
                        <td class="sort-container line-height-15px" (mouseover)="showSort=true" (mouseout)="showSort=false" [ngClass]="{ 'sort-desc': showSort && isNameSortBtnDesc, 'sort-asc': showSort && isNameSortBtnAsc }"  (click)="sort('name');isNameSortBtnDesc=!isNameSortBtnDesc"> 
                            <div>CONTACT NAME</div>
                        </td>
                        <td class="sort-container line-height-15px" (mouseover)="showEmailSort=true" (mouseout)="showEmailSort=false" [ngClass]="{ 'sort-desc': showEmailSort && isEmailAddressCodeSortBtnDesc, 'sort-asc': showEmailSort && isEmailAddressCodeSortBtnAsc }"  (click)="sort('emailAddress');isEmailAddressCodeSortBtnDesc=!isEmailAddressCodeSortBtnDesc">
                            <div>EMAIL ADDRESS</div>
                        </td>
                    </tr>
                </thead>
                <tbody class="text-center text-lighter max-height-376px">
                    <tr *ngFor="let item of contacts | orderBy: {property: sortingName, direction: isDesc}">
                        <td class="font-size-4">
                            <div>
                                <span class="fa fa-square-o font-color-light-grey" tabindex="0" [hidden]="item.isSelectedEmail" (click)="item.isSelectedEmail = true;recordSelected(item)" (keydown.enter)="item.isSelectedEmail = true;recordSelected(item)"></span>
                                <span class="fa fa-check-square font-color-blue" tabindex="0" [hidden]="!item.isSelectedEmail" (click)="item.isSelectedEmail = false;recordSelected(item)" (keydown.enter)="item.isSelectedEmail = false;recordSelected(item)"></span>
                            </div>
                        </td>
                        <td><div>{{item.name}}</div></td>
                        <td><div>{{item.emailAddress}}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center">
            <input type="submit" class="btn btn-default send-email-button float-none font-open-sans-bold fw-bold"
                   value="SEND EMAIL" (click)="sendEmail()" />
        </div>
    </div>
</fhlb-modal-2>
