<fhlb-modal-2 id="viewDCListId" [title]="title" [topBorder]="topBorderClass">
    <div class="row margin-0px">
        <div id="dc-list-placeholder" block-ui="dcListView" class="ps-0">
            <div class="font-home-error padding-left-10px" *ngIf="!hasRecords">
                <br />
                <div>{{ noRecordError }}</div>
            </div>
            <div *ngIf="hasRecords" class="modalList max-height-300px">
                <table id="viewdcList"
                    class="exhibit-table-striped table-bordered table-border-black table-vertical-align font-size-1">
                    <thead fix-head class="font-open-sans-bold text-center active-dc-header font-size-0">
                        <tr>
                            <th class="sort-container line-height-10px min-width-max-width-100px"
                                [ngClass]="sortColumn('dcNumber')" (click)="changeSorting('dcNumber')">
                                <div [innerHtml]="cmsModel.DCNumberWithBreak"></div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-100px"
                                [ngClass]="sortColumn('mcNumber')" (click)="changeSorting('mcNumber')">
                                <div [innerHtml]="cmsModel.MasterCommitmentNumberWithBreak">{{
                                    cmsModel.MasterCommitmentNumberWithBreak }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-85px"
                                [ngClass]="sortColumn('programCode')" (click)="changeSorting('programCode')">
                                <div>{{ cmsModel.ProgramCodeText }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-107px"
                                [ngClass]="sortColumn('subProgramCode')" (click)="changeSorting('subProgramCode')">
                                <div>{{ cmsModel.SubProgramText }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width--80px"
                                [ngClass]="sortColumn('deliveryType')" (click)="changeSorting('deliveryType')">
                                <div>{{ cmsModel.DeliveryTypeText }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-80px"
                                [ngClass]="sortColumn('dcAmount')" (click)="changeSorting('dcAmount')">
                                <div>{{ cmsModel.Amount }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-80px"
                                [ngClass]="sortColumn('availableAmount')" (click)="changeSorting('availableAmount')">
                                <div>{{ cmsModel.Available }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-69px"
                                [ngClass]="sortColumn('entryDate')" (click)="changeSorting('entryDate')">
                                <div>{{ cmsModel.EntryDate }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-125px"
                                [ngClass]="sortColumn('product')" (click)="changeSorting('product')">
                                <div>{{ cmsModel.LoanProduct }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-174px"
                                [ngClass]="sortColumn('subProduct')" (click)="changeSorting('subProduct')">
                                <div>{{ cmsModel.LoanSubProduct }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-81px "
                                [ngClass]="sortColumn('deliveryDate')" (click)="changeSorting('deliveryDate')">
                                <div>{{ cmsModel.LoanDeliveryDate }}</div>
                            </th>
                            <th class="sort-container line-height-10px min-width-max-width-62px"
                                [ngClass]="sortColumn('noteRate')" (click)="changeSorting('noteRate')">
                                <div>{{ cmsModel.LoanNoteRate }}</div>
                            </th>
                        </tr>
                    </thead>

                    <tbody class="text-center text-lighter font-size-1">
                        <tr *ngFor="let item of dcList | orderBy: {property: sort.column, direction: sort.descending} ">
                            <td><a href="javascript:void(0)" class="font-color-dark-orange font-open-sans-bold"
                                    (click)="selectedDc(item.dcNumber)"><u>{{item.dcNumber}}</u></a></td>
                            <td>{{item.mcNumber}}</td>
                            <td>{{item.programCode}}</td>
                            <td>{{item.subProgramCode}}</td>
                            <td>{{item.deliveryType}}</td>
                            <td>{{item.dcAmount | currency}}</td>
                            <td>{{item.availableAmount | currency}}</td>
                            <td>{{item.entryDate | date:'M/d/yyyy'}}</td>
                            <td>{{item.product}}</td>
                            <td>{{item.subProduct}}</td>
                            <td>{{item.deliveryDate | date:'M/d/yyyy'}}</td>
                            <td>{{item.noteRate | number : '.4-4'}}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</fhlb-modal-2>