import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class CSVDownloadService {
    downloadFile(data, filename='data',headerList) {
        let csvData = this.ConvertToCSV(data,headerList );
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let url = URL.createObjectURL(blob);
        let dwldLink = document.createElement("a");

        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob,filename + ".csv");
        }
        else {
            let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
            if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
                dwldLink.setAttribute("target", "_blank");
            }
            dwldLink.setAttribute("href", url);
            dwldLink.setAttribute("download", filename + ".csv");
            dwldLink.style.visibility = "hidden";
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        }
    }

    ConvertToCSV(objArray, headerList) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
        for (let index in headerList) {
           row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            var h=1;
            for (let index in headerList) {
                let head = headerList[index];
                if(h<headerList.length)
                {
                    line +=  array[i][head]+',';
                }
                else  { line +=  array[i][head]; }
            h=h+1;
            }
        str += line + '\r\n';
        }
        return str;
    }
}