<fhlb-modal-2 [topBorder]="topBorderClass" [showHeader]="false">
    <div class="row text-center p-3">
        <div class="col-sm-12 defaultText">
            Your session has already expired. Click OK to return to the login page.
        </div>
    </div>
    <div class="row p-3 justify-content-center">
        <div class="col-4">
            <button type="submit" class="btn btn-success col w-100" (click)="logout()"><b>OK</b></button>
        </div>
    </div>
</fhlb-modal-2>