import { Component } from '@angular/core';

@Component({
// tslint:disable-next-line: component-selector
  selector: 'block-ui-template',
  styles: [`
    :host {
      text-align: center;
    }
  `],
  template: `
    <div class="block-ui-spinner">
      <img src="assets/ajax-loader.gif"/>
    </div>
  `
})
export class BlockTemplateComponent { }
