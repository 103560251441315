import { Component, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'reason-for-update-modal',
    templateUrl: './reason-for-update-modal.component.html',
    styleUrls: ['./reason-for-update-modal.component.sass']

})

export class ReasonForUpdateModalComponent implements OnInit {
    topBorderClass = 'top-border-yellow';

    title = 'Update Loan Presentment'
    constructor(    public activeModal: NgbActiveModal
        ) {

    }


    ngOnInit() {

    }

    close(){
        this.activeModal.close('cancel')
    }
}

