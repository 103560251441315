<div class="container-fluid">
    <div class="row">
        <sites-header-container class="col-sm-12" [isRoles]="isRoles"></sites-header-container>
    </div>
</div>
<sites-banner-container>
</sites-banner-container>
<sites-menu-container [isOrigination]="isOrigination"></sites-menu-container>
<section id="mainbody">
    <router-outlet></router-outlet>
</section>
<sites-footer-container></sites-footer-container>