import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { fhlbConstants } from '@app-shared/constants/fhlb-constants';
import { DecodeAccessToken } from 'src/store/reducers/models';
import { AppConfigService } from '@app-shared/services';
import { Store } from '@ngrx/store';
import { AppState } from 'src/store/reducers';
import { getDecodedIdTokenFromStore, getSiteForUserFromStore } from 'src/store/selectors';
import { catchError, map, take, withLatestFrom } from 'rxjs/operators';
import { RouteDataAuthenication, SiteName } from '@app-shared/models/routingdata';
import { isNullOrUndefined } from 'is-what';
import { of } from 'rxjs/internal/observable/of';
import { OriginationRoutingHelper } from '@origination-shared/navigation/origination.navigation';
import { EmpfRoutingHelper } from '@empf-shared/navigation/empf.navigation';

@Injectable()

export class EditOrgGuard implements CanActivate {
    @BlockUI() blockUI: NgBlockUI;
    constructor(private router: Router, private store: Store<AppState>) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let isAuthorized = false;
        this.blockUI.start();
        let orgKey = route.params.orgKey;
        let routeData: RouteDataAuthenication = <RouteDataAuthenication>route.data;

        return this.store.select(getDecodedIdTokenFromStore).pipe(
            withLatestFrom(this.store.select(getSiteForUserFromStore)),
            take(1),
            map(([currentUser, y]) => {
                if (isNullOrUndefined(currentUser)) {
                    return this.userUnauthorized(y)
                }

                if (!isNullOrUndefined(routeData.losAuth)) {
                    for (let routeRole of routeData.losAuth.roles) {
                        let isRoleAuthorized = this.authorizeRole(currentUser, routeRole);
                        if (isRoleAuthorized)
                            isAuthorized = true
                    }
                }

                if (!isNullOrUndefined(routeData.empfAuth) && !isAuthorized) {
                    for (let routeRole of routeData.empfAuth.roles) {
                        let isRoleAuthorized = this.authorizeRole(currentUser, routeRole);
                        // If the user's role is authorized, do additional checking.
                        if (isRoleAuthorized) {
                            // If the user's role is authorized and the route does not require behaviors,
                            // then authorize the user immediately.
                            if (!routeData.empfAuth.behaviors) {
                                isAuthorized = true;
                            }
                            else if (routeData.empfAuth.behaviors) {
                                // User does not have any behaviors, so break checking.
                                if (!isNullOrUndefined(currentUser['custom:Behavior']) && currentUser['custom:Behavior'].length > 0) {
                                    var hasCorrectBehavior = false;
                                    var hasCorrectOrgKey = false;
                                    for (let routeBehavior of routeData.empfAuth.behaviors) {
                                        hasCorrectBehavior = this.authorizeBehavior(currentUser, routeBehavior);
                                        // If user has correct role and correct behavior, check for org key
                                        if (hasCorrectBehavior) {
                                            hasCorrectOrgKey = this.checkOrgKey(currentUser, orgKey);
                                            if (hasCorrectOrgKey) {
                                                isAuthorized = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                if (isAuthorized) {
                    return this.userAuthorized();
                }

                // If authorization reaches this point, user was not authorized so redirect.
                this.blockUI.stop();
                return this.userUnauthorized(y)
            }),
            catchError(() => {
                this.blockUI.stop();
                let result = this.userUnauthorized(null)
                return of(result);
            })
        )
    }

    userAuthorized() {
        this.blockUI.stop();
        return true;
    }

    userUnauthorized(siteName: SiteName) {
        if (siteName == SiteName.Empf)
            this.router.navigate([EmpfRoutingHelper.Unauthorized()], { skipLocationChange: true });
        else if (siteName == SiteName.Origination)
            this.router.navigate([OriginationRoutingHelper.UnauthorizedRoute()], { skipLocationChange: true });
        else
            this.router.navigate([fhlbConstants.unauthorizedRoute]);
        return false;
    }

    authorizeRole(currentUser: DecodeAccessToken, routeRole) {
        var upperCaseUserGroups = currentUser['custom:UserGroup'].map((function(roleString) { return roleString.toUpperCase(); } ));
        if (upperCaseUserGroups.indexOf(AppConfigService.Settings.Empf[routeRole].toUpperCase()) != -1) {
            return true;
        }
        return false;
    }

    authorizeBehavior(currentUser: DecodeAccessToken, routeBehavior) {
        if (currentUser['custom:Behavior'].indexOf(routeBehavior) != -1) {
            return true;
        }
        return false;
    }

    checkOrgKey(currentUser: DecodeAccessToken, orgKey) {
        if (currentUser['custom:OrgKey'] == orgKey) {
            return true;
        }
        return false;
    }
}
