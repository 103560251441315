
export const fhlbConstants = {
  // HttpError codes
  invalidContactError: 'ContactNotFoundError',

  // Access tokens
  mfaAccessToken: 'mfa_access_token',
  accessToken: 'ls.access_token',
  renewAccessToken: 'renew_access_token',
  accessTokenExpiresIn: 'access_token_expires_in',
  accessTokenExpiresUtc: 'access_token_expires_utc',
  accessTokenIssuedUtc: 'access_token_issued_utc',
  expired: 'Expired',

  // Localstorage keys
  absLoginUrl: 'ls.absLoginUrl',
  returnUrl: 'ls.returnUrl',
  logoutUrl: 'ls.logoutUrl',
  gaTrackingObj: 'gaTracker',
  gaTagManagerId: 'gaTagManagerId',
  eMpfIdentityProvider: 'ls.eMpfIdentityProvider',
  identityProvider: 'ls.identityProvider',
  lossiAuthTicket: 'ls.lossiAuthTicket',

  // errorCategory keys
  application: 'Application',
  permissionDenied: 'PermissionDenied',
  code: 'code',
  unauthorizedRoute: '/error/unauthorized',
  grantType: 'authorization_code',
  expiration: 'exp',
  authTime: 'auth_time',
  tokenUse: 'token_use',
  id: 'id',
  issuer: 'iss',
  isAuthenticated: 'isAuthenticated',
  auth: 'auth',
  entryContainerComponentName : 'EntryContainerComponent',
  HomesContainerComponentName : 'HomesContainerComponent',
  ResourcesComponent:'ResourcesComponent',
  PricingMainComponent:'PricingMainComponent',
  TreasuryMainComponent:'TreasuryMainComponent',
  ExhibitsComponent:'ExhibitsComponent',
  ReportsMainComponent:'ReportsMainComponent',
  TransactionComponent:'TransactionComponent',
  DeliveryCommitmentComponent:'DeliveryCommitmentComponent',
  DocumentsContainerComponent:'DocumentsContainerComponent',

  // eMPF behaviors
  createPfiOrg: "Create PFI Org",
  createMC : "Create MC",
  createDC : "Create DC",
  makeFundingRequest : "Make Funding Request",
  submitBatch: "Submit Batch",
  bePostClosingContact: "Be Post Closing Contact",
  presentLoan: "Present Loan",
  onResolution: "On Resolution",
  actasQA: "Act as QA",
  accessToEmpf: "Access eMPF",
  sendReportingtoMasterServicer: "Send Reporting to Master Servicer",
  requestCollateral: "Request Collateral",
  createJrMC: "Create Jr. MC",
  createSrMC: "Create Sr. MC",
  downloadFiles: "Download Files",
  receiveEmails: "Receive Emails",
  uploadFiles: "Upload Files",
  approvePfiOriginator: "Approve PFI/Originator",
  approveMC: "Approve MC",
  receiveFiles: "Receive Files",
  accountManager: "Account Manager",
  updatePricing: "Update Pricing",
  activeDistributionforMPFPlus: "Active Distribution for MPFPlus",
  activeDistributionforEnhancedMI: "Active Distribution for Enhanced MI",
  optoutAuthorizer: "Opt out Authorizer",
  requestServicingTransfer: "Request Servicing Transfer",
  pricingEngineVendorRelationshipContact: "Pricing Engine Vendor Relationship Contact",
  directSecurity: "Direct Security",
  servicingContact: "Servicing Contact",
  empfSecurityAdministrator: "eMPF Security Administrator",
  xtraMbsInvestorReporting: "Xtra MBS Investor Reporting",


  // LOS AD Roles, values of the below fields are the keys in appsettings.json file.
  MpfAdmin: "Fhlbc_Mpf_Los_Security_Administrator",
  MpfSuperuser: "Fhlbc_Mpf_Los_Security_Superuser",
  MpfInquiry: "Fhlbc_Mpf_Los_Security_Inquiry",
  MpfInquiryNoPII: "Fhlbc_Mpf_Los_Security_Inquiry_NOPII",

  // eMPF AD Roles
  EmpfPfiMpf: "EMPF_PFI_MPF",
  EmpfHlbMpf: "EMPF_HLB_MPF",
  EmpfFhlbankuserMpf: "EMPF_FHLBANKUSER_MPF",
  EmpfScuMpf: "EMPF_SCU_MPF",
  GivenName: "given_name",
  EmpfExtendedTimeoutMPFRole: ["Empf_ExtendedTimeout_MPF","Empf_ExtendedTimeout_MPF_q"],

  //eMPF File Server Document Names
  UserAuthorizationHelpDoc: "User Authorization Help Document.pdf",
  InvestorDetails: "Investor Code Details.pdf",
  MPFXtraMBSLLPAGridDetails: "MPFXtraMBSLLPAGrid.pdf",
  FNMALoanNumberDetails: "FNMA Loan Number Details.pdf",
  AMI: "FHFA80PercentAMIAmountsByCounty.xls",

  // login constants
  defaultPageRedirection: 'None',

  // mfa constants
  mfaChallengeLockout: 'ChallengeLockout',
  mfaEnrollment: 'Enrollment',
  mfaChallenge: 'Challenge',
  mfaMachineAuthenticated: 'MachineAuthenticated',

  // localstorage keys
  clientMachineValue: 'clientMachineValue',
  unexpectedErrorMessage: 'An unexpected error has occurred. For further assistance, please contact the MPF Service Center at 877-345-2673.',
  unexpectedCognitoErrorMessage: 'An unexpected error has occurred while getting cognito token. Please close browser and try again, if issue persist then, please contact the MPF Service Center at 877-345-2673.',
  ExtendedTimeOutMpfRoleAccessTokenExpireUtc: 'access_token_expires_utc',
  isExtendedTimeOutMpfRole:"isExtendedTimeOutMpfRole"
};

