import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../../configuration/app-config.service';
import { BatchCategory } from 'src/origination/batch-process/models/BatchCategory';

@Injectable ({
    providedIn: 'root'
})

export class documentDownloadService
{        
    private REPORTAPI_PATH = `${AppConfigService.Settings.Mpf.ReportAPIEndpoint}`;

    private API_SERVICE_EXT_PATH = `${this.REPORTAPI_PATH}/api/report`;
    private options: object = { headers: new HttpHeaders(
        { 
            'Content-Type': 'application/json' 
        }), withCredentials: true };

    constructor(private http: HttpClient) { }

    downloadFile(documentName : string): Observable<any>
    {
        return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadDocument'.toLowerCase()}`,
            { params:new HttpParams().set('filename', documentName), 
            observe: 'response', responseType:'arraybuffer' })
            .pipe(map(response => response));
    }

    downloadAMIFile(documentName : string): Observable<any>
    {
        return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadAMIDocument'.toLowerCase()}`,
            { params:new HttpParams().set('filename', documentName), 
            observe: 'response', responseType:'arraybuffer' })
            .pipe(map(response => response));
    }

    //TODO- Need to modify once API is ready.
    downloadGenerateFinalFile(McID:number,SupplementalMIID :string ) :Observable<any>
    {
        return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'reports/downloadMPFPlusFinalReport'.toLowerCase()}`,
            { params:new HttpParams().set('McId',McID.toString()).set('SupplementalMIID',SupplementalMIID)
            })
            .pipe(map(response => response));
    }
    
    //download MPF Plus Notice file for MC
    downloadMPFPlusNoticeFile(mcID: number):  Observable<any> {
    {
        return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadMpfPlusReport'.toLowerCase()}`,
        { params:new HttpParams().set('MCNumber', mcID.toString()),
         observe: 'response', responseType:'arraybuffer' }).pipe(map(response => response));
    }
}

    downloadBatchReport(batchID: number, batchType : BatchCategory, isZipFile: boolean ): Promise<any> {
        const params = new HttpParams().set('batchID', batchID.toString())
            .set('batchType', batchType.toString())
            .set('isZipFile', isZipFile.toString());
        return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadbatchreportfile'.toLowerCase()}`, { params, observe: 'response', responseType: 'arraybuffer', }).toPromise()

    }
}
