import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgControl } from '@angular/forms';
import { isNullOrUndefined } from 'is-what';

@Directive({
    selector: '[toNegativeCurrency]'
})
export class ToNegativeCurrencyDirective implements OnInit {
    private ngControl: NgControl;
    private htmlEl: HTMLElement;
    @Input('toCurrencyMax') maxAmount: string;
    @Input('nonNegativeFlag') nonNegative: string;
    @Input('genericDataFlag') generic: string;
    @Input('isRequiredFlag') isRequiredFlag: string;
    constructor(public el: ElementRef, public renderer: Renderer2, private currencyPipe: CurrencyPipe, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
        this.htmlEl = el.nativeElement;
    }

    ngOnInit() {
        setTimeout(() => {
            this.format(this.el.nativeElement.value);
        }, 3000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }


    format(val: string) {
        let replacedVal = val.replace(/[$ , )]/g, '');
        replacedVal=replacedVal.replace(/[(]/g,'-');
        const numberFormat = parseFloat(replacedVal);
        let usd = isNaN(numberFormat) ? val : this.currencyPipe.transform(numberFormat, 'USD', 'symbol', '1.2-2');
        let allowNegative = true;
        if (isNullOrUndefined(this.maxAmount) || this.maxAmount.trim() === '') {
            this.maxAmount = '1000000000';
        }
        if (!isNullOrUndefined(this.nonNegative) && this.nonNegative === 'true'
            && !isNullOrUndefined(val) && val.trim() !== '' && numberFormat <= 0) {
            allowNegative = false;
        }

        if (numberFormat > +this.maxAmount || isNaN(+replacedVal) || !allowNegative) {
            if(this.generic === 'true'){
                if (numberFormat > +this.maxAmount) {
                    this.renderer.setProperty(this.el.nativeElement, 'value', '');
                    this.ngControl.viewToModelUpdate(null);                    
       
                    if (this.isRequiredFlag === 'true') {
                        this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                        this.ngControl.control.setErrors({ required: true }); 
                    }
                
                }
                else if(val ==='0' || val === ''){
                    this.renderer.setProperty(this.el.nativeElement, 'value', this.currencyPipe.transform(0, 'USD', 'symbol', '1.2-2'));
                    this.ngControl.control.setErrors({ pattern : true });
                }
                else {
                  this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                 this.ngControl.control.setErrors({ pattern: true });
                  this.renderer.setProperty(this.el.nativeElement, 'value', val);
                  if (!this.htmlEl.classList.contains('is-invalid')) {
                    this.htmlEl.classList.add('is-invalid');
                  }
                }
            }
            else{
                if(numberFormat > +this.maxAmount){
                    this.renderer.setProperty(this.el.nativeElement, 'value', '');                    
                    this.ngControl.viewToModelUpdate(null);        
                    if (this.isRequiredFlag === 'true') {
                        this.ngControl.control.setErrors({ required: true });
                    }
                }
                else
                {
                    this.ngControl.control.setErrors({ pattern: true });    
                    this.renderer.setProperty(this.el.nativeElement, 'value', val);
                    this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
               
                    if (!this.htmlEl.classList.contains('is-invalid')) {
                        this.htmlEl.classList.add('is-invalid');
                    }
                }
               
            }
        } else {
            if(usd.charAt(0) === '-')
            {
                usd='(' + usd.substring(1, usd.length) + ')' 
            }
            this.renderer.setProperty(this.el.nativeElement, 'value', usd);
            isNaN(numberFormat) ? this.ngControl.viewToModelUpdate(null) : this.ngControl.viewToModelUpdate(numberFormat);
            this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
            if (this.htmlEl.classList.contains('is-invalid')) {
                this.htmlEl.classList.remove('is-invalid');
            }
        }
    }

}
