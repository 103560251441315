import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from '@app-shared/constants/http-status-codes';
import { ModelFailures, ProcessMessagesFailures } from '@origination-shared/services/organization/models';
import { isNullOrUndefined } from 'is-what';
import { Injectable } from "@angular/core";

@Injectable()
export class HttpUtilHelper {
    public static ConvertQueryString(reqParams: any, propertiesLowercase: boolean = true): string {

        //Bug-106061 : created cloned object to stop original object getting modified.
        let params = JSON.parse(JSON.stringify(reqParams));

        if (isNullOrUndefined(params)) {
            return null;
        }
        return Object.keys(params).map(key => {
            if (params[key] == undefined || params[key] == null) {
                if (typeof (params[key]) !== "boolean")
                    return null;
            }

            if (params[key] instanceof Array) {
                let allitemqs: string[] = []
                for (let index = 0; index < params[key].length; index++) {
                    const i = params[key][index];
                    let itemqs = i instanceof Object ? this.ConvertQueryString(i) : i
                    if (itemqs != null || itemqs != undefined) {
                        allitemqs.push(propertiesLowercase ? `${key.toLowerCase()}=${itemqs}` : `${key}=${itemqs}`)
                    }
                }

                return allitemqs.join('&');
            }
            params[key] = encodeURIComponent(params[key]);
            return propertiesLowercase ? `${key.toLowerCase()}=${params[key]}` : `${key}=${params[key]}`;
        }).filter(x => x != null).join('&');
    }

    public static ConvertComplexObjectQueryString(params: Object, withDot: boolean = true): string {
        let allItems: string[] = [];

        Object.keys(params).map(key => {
            if (params[key] instanceof Object) {
                let obj = params[key];
                Object.keys(obj).map((objKey, index) => {
                    let item = obj[objKey];
                    if (withDot) {
                        allItems.push(`${key.toLowerCase()}.${objKey.toLowerCase()}=${item}`);
                    } else {
                        allItems.push(`${objKey.toLowerCase()}=${item}`)
                    }
                });
            }
        });

        return allItems.join('&');
    }

    public static HandleServiceHttpErrors(errorResponse: HttpErrorResponse): string[] {
        let errorMessages: string[] = [];

        if (errorResponse.status == HttpStatusCode.BAD_REQUEST) {
            let propertyErrors = errorResponse.error as ModelFailures;
            propertyErrors.errors.forEach(ex => {
                ex.errors.forEach(p => errorMessages.push(`${p.processMessage.description}`));
            });
        } else if (errorResponse.status == HttpStatusCode.UNPROCESSABLE_ENTITY || errorResponse.status == HttpStatusCode.INTERNAL_SERVER_ERROR) {
            let propertyErrors = errorResponse.error as ProcessMessagesFailures;
            propertyErrors.exceptions.forEach(ex => {
                errorMessages.push(`${ex.processMessage.description}`);
            });
        } else {
            errorMessages.push(`${errorResponse.status} ${errorResponse.statusText} - ${errorResponse.url}`);
        }
        
        errorMessages=errorMessages.filter((v,i) => errorMessages.indexOf(v) == i);
        
        return errorMessages;
    }

    public static ConvertMultiComplexObjectQueryString(params: Object, withDot: boolean = true): string {
        let allItems: string[] = [];

        Object.keys(params).map(key => {
            if (params[key] instanceof Object) {
                let obj: Object = params[key];
                Object.keys(obj).map((objKey, index) => {
                    let item = obj[objKey];
                    Object.keys(item).map((objkey1, index) => {
                        let item1 = item[objkey1];
                        if (withDot) {
                            allItems.push(`${key.toLowerCase()}.${objKey.toLowerCase()}.${objkey1.toLowerCase()}=${item1}`);
                        } else {
                            allItems.push(`${objkey1.toLowerCase()}=${item1}`)
                        }

                    })
                });
            }
        });

        return allItems.join('&');
    }

}

