import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { pricingHelper } from '../helpers/pricing-helper';
import { UtilHelper } from '../helpers/util-helper';


@Directive({
    selector: '[scheduleSelectionValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ScheduleSelectionValidatorDirective, multi: true }]
})
export class ScheduleSelectionValidatorDirective implements OnInit, OnChanges {

    constructor(private el: ElementRef, @Inject(DOCUMENT) private document: any) {
    }
    @Input() scheduleSelectionOptions;
    @Input() selectValidatorTrigger;
    @Input() category;

    scheduleValidationClass = 'pricing-panel-body-column-validation';

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.selectValidatorTrigger) {
            if (this.selectValidatorTrigger.event.defaultPrevented) {
                return;
            }
            this.validate(this.selectValidatorTrigger.event, this.selectValidatorTrigger);
        }
    }

    validate(event, args) {
        {
            // Merges configuration set in directive with default one
            const configOptions = {};
            const mergedConfig = Object.assign({}, configOptions, (this.scheduleSelectionOptions));

            // retrieve the criteria property
            const criteria = mergedConfig['criteria'];
            const category = mergedConfig['category'];
            const scheduleContainerId = mergedConfig['scheduleContainerId'];
            const jsonObj = args;
            const targetFormName = jsonObj['targetFormName'];

            // perform validation
            const results = pricingHelper.validateSchedulesInPricingCriteria(criteria);
            if (!results.status && results.errorType === category) {
                if (results.errors.length > 0) {
                    if (results.stopPropagation) {
                        event.preventDefault();
                        this.removePricingValidationCss(event, scheduleContainerId);
                    }
                    this.el.nativeElement.classList.add(this.scheduleValidationClass);
                } else {
                    this.el.nativeElement.classList.remove(this.scheduleValidationClass);
                }
            } else {
                this.el.nativeElement.classList.remove(this.scheduleValidationClass);
            }
        }
    }
    removePricingValidationCss(event: any, scheduleContainerId: any) {
        {
            // remove the CSS class from pricing main template
            const mainTmpl = this.document.getElementById(scheduleContainerId);
            if (UtilHelper.isDefinedOrNotNull(mainTmpl)) {
                const matchingCssEls = mainTmpl.querySelectorAll('.' + this.scheduleValidationClass);
                if (UtilHelper.isDefinedOrNotNull(matchingCssEls)) {
                    const ngMatchingCssEls = matchingCssEls;
                    ngMatchingCssEls.forEach((e) => {
                        const ngEl = e;
                        ngEl.classList.remove(this.scheduleValidationClass);
                    });
                }
            }
        }
    }
}
