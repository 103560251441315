import { Pipe, PipeTransform } from '@angular/core';
import { UtilHelper } from '@app-shared/services';

@Pipe({
    name: 'dcreportfilter'
})
export class DCRepoertFilter implements PipeTransform {

    transform(value: any[], args: any): any { 
        
        if (UtilHelper.isDefinedOrNotNull(args.subProductName) && UtilHelper.isDefinedOrNotNull(args.dcRate)) {
            var a = value.filter(item => (
                (item.servicingModel.toString().toLowerCase()===args.servicingModel.toString().toLowerCase()) &&
                (item.subProductName.toString().toLowerCase()===args.subProductName.toString().toLowerCase()) &&
                (item.dcRate.toFixed(3).toString().toLowerCase()===args.dcRate.toString().toLowerCase())));
            return a;
        } else if (UtilHelper.isDefinedOrNotNull(args.subProductName) && UtilHelper.isDefinedOrNotNull(args.pfiOwner)) {
            var a = value.filter(item => (
                (item.servicingModel.toString().toLowerCase()===args.servicingModel.toString().toLowerCase())  &&
                (item.subProductName.toString().toLowerCase()===args.subProductName.toString().toLowerCase())  &&
                (item.pfiOwner.toString().toLowerCase()===args.pfiOwner.toString().toLowerCase())));
            return a;
        } else {
            var a = value.filter(item => (
                (item.servicingModel.toString().toLowerCase()===args.servicingModel.toString().toLowerCase()) &&
                (item.deliveryDate && item.deliveryDate.toString().toLowerCase()===args.deliveryDate && args.deliveryDate.toString().toLowerCase())));
            return a;
        }
    }
}

