import { Component } from '@angular/core';
import { AppState } from 'src/store/reducers';
import { Store } from '@ngrx/store';
import { ModalExtendSessionAction, ModalLogOutAction } from 'src/store/actions/auth.actions';

@Component({
    selector: 'refresh-notification-container',
    templateUrl: `./refresh-notification.container.component.html`
})

export class RefreshNotificationContainerComponent {
    topBorderClass = 'top-border-green';

    constructor(private store: Store<AppState>) {
    }

    extendSession() {
        this.store.dispatch(new ModalExtendSessionAction())
    }

    logout() {
        this.store.dispatch(new ModalLogOutAction());
    }
}
