import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'negativeCurrencyToParens'
})
export class NegativeCurrencyToParensPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return (value && value.charAt(0)) === '-' ?
            '(' + value.substring(1, value.length) + ')' :
            value;
    }
}