export enum SearchType {
    /// <summary>
    /// Org
    /// </summary>
    Org = "Org",

    /// <summary>
    /// MC
    /// </summary>
    MC = "MC",

    /// <summary>
    /// DC
    /// </summary>
    DC = "DC"
}