import {
    ActionReducerMap,
} from '@ngrx/store';
import * as fromSearch from '../reducers/interactive-search.reducers';
import * as fromGeo from '../reducers/geo-process.reducers';
import * as fromRoot from '../../../store/reducers';

export interface InteractiveSearchState {
    interactiveSearch: fromSearch.State;
}

export interface GeoProcessState {
    geoProcess: fromGeo.State;
}

export interface State extends fromRoot.AppState, fromGeo.State {
    interactiveSearch: InteractiveSearchState;
    geoProcess: GeoProcessState
}

export const interactiveSearchReducers: ActionReducerMap<InteractiveSearchState> = {
    interactiveSearch: fromSearch.reducer
};

export const geoProcessReducers: ActionReducerMap<GeoProcessState> = {
    geoProcess: fromGeo.reducer
};