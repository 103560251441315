import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAssociateDeliveryCommitment } from '../../../models/associate-delivery-commitment.model';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';


@Component({
    selector: 'app-viewdc-list',
    templateUrl: './view-dc-list.component.html',
    styleUrls: ['./view-dc-list.component.sass']
})
export class ViewDCListComponent implements OnInit {
    @Input() dcList: IAssociateDeliveryCommitment[];
    @Input() isInquiryUser: boolean;
    @Input() mcID: number;
    @Input() pageNo: number;
    @Input() pageSize: number;
    @Output() goToMCViewClick: EventEmitter<number> = new EventEmitter<number>();
    @Output() goToExtendClick: EventEmitter<{ dcNum: number, deliveryStatus: string,deliveryDate: string, scheduleCode:string }> = new EventEmitter<{ dcNum: number, deliveryStatus: string,deliveryDate: string,scheduleCode:string }>();
    @Output() goToReduceClick: EventEmitter<{ dcNum: number, deliveryStatus: string,deliveryDate: string, scheduleCode:string }> = new EventEmitter<{ dcNum: number, deliveryStatus: string,deliveryDate: string,scheduleCode:string }>();
    @Output() goToPFIViewClick: EventEmitter<number> = new EventEmitter<number>();
    @Output() goToDCViewClick: EventEmitter<number> = new EventEmitter<number>();
    @Output() goToLoansViewClick: EventEmitter<{ dcNo: number }> = new EventEmitter<{ dcNo: number }>();
    @Output() goToEditDcClick: EventEmitter<{ dCId: string, dcKey: number }> = new EventEmitter<{ dCId: string, dcKey: number }>();
    @Output() pageSizeChangeClick: EventEmitter<{ pageSize: number }> = new EventEmitter<{ pageSize: number }>();
    @Output() pageNumberChangeClick: EventEmitter<{ pageNumber: number }> = new EventEmitter<{ pageNumber: number }>();
    //pageSize = 25;
    page = 1;
    @Input() totalItems: number;
    constructor(public router: Router) {
    }
    sortingName: string;
    isDesc: boolean;


    ngOnInit() {
        this.page = this.pageNo !== null ? this.pageNo : this.page;
        this.pageSize = this.pageSize != null ? this.pageSize : 25;
        if (isNullOrUndefined(this.mcID)) {
            this.sortingName = 'dcNum';
            this.isDesc = true;
        }
    }

    goToMCView(mcNum: number) {

        this.goToMCViewClick.emit(mcNum);
    }

    goToExtend(dcNum: number, deliveryStatus: string, deliveryDate: string, scheduleCode: string) {
        this.goToExtendClick.emit({ dcNum, deliveryStatus,deliveryDate, scheduleCode });
    }
    goToReduce(dcNum: number, deliveryStatus: string, deliveryDate: string, scheduleCode: string) {
        this.goToReduceClick.emit({ dcNum, deliveryStatus,deliveryDate,scheduleCode });
    }

    goToDC(dcNum: number) {
        this.goToDCViewClick.emit(dcNum);
    }

    goToPFI(pfiNum: number) {
        this.goToPFIViewClick.emit(pfiNum);
    }

    goToLoans(dcNo: number) {
        this.goToLoansViewClick.emit({ dcNo: dcNo });
    }
    goToEdit(dCId: string, dcKey: number) {
        this.goToEditDcClick.emit({ dCId, dcKey });
    }
    manageDcStatus(dcitems: IAssociateDeliveryCommitment) {
        dcitems.clickstate = !dcitems.clickstate;
    }

    blurPopover(dcitems: IAssociateDeliveryCommitment) {
        setTimeout(() => {
            dcitems.clickstate = false;
        }, 200);
        
    }

    pageSizeChange(pageSize: number) {
        this.pageSizeChangeClick.emit({ pageSize });
    }

    pageChanged(pageNumber: number) {
        this.pageNumberChangeClick.emit({ pageNumber })
    }




}
