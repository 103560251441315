<div class="entry-pre-load"
    [class.pre-selection-load]="accountLoginModel && accountLoginModel.isFHLBankUser">
    <div id="main-canvas" class="font-size-3 container-fluid entry-canvas entry-picker hlb-picker"
        *ngIf="loginFlow == LoginFlowType.FHLBANK_USER_LOGIN_FLOW">
        <section id="entry-page-hlb-picker-section">
            <entry-landing [accountLoginModel]="accountLoginModel" [hlbList]="hlbList" (LoginUser)="loginFhlBankUser($event)">
            </entry-landing>
        </section>
    </div>
</div>

<div class="entry-pre-load" [class.pre-selection-load]="profile">
    <div id="main-canvas" class="font-size-3 container-fluid entry-canvas entry-picker profile-picker"
        *ngIf="loginFlow == LoginFlowType.USER_PROFILE_LOGIN_FLOW">
        <section id="entry-page-profile-picker-section">
            <user-profile [profile] = "profile" [userProfileList]="userProfileList"
            (LoginProfileUser)="loginProfileUser($event)">
            </user-profile>
        </section>
    </div>
</div>