import { Component, OnInit, Input, ViewChild, SimpleChanges, } from '@angular/core';
import { IAudit } from '@app-shared/models/audit.model';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'reason-for-update',
  templateUrl: './reason-for-update.component.html',
  styleUrls: ['./reason-for-update.component.sass']

})

export class ReasonForUpdateComponent implements OnInit {
  @Input() audit: IAudit;
  @Input() formSubmitted: boolean;
  @Input() isCollapsed: boolean = true;
  @ViewChild('reasonForUpdate', { static: false }) reasonForUpdate: NgForm;
  reasonForUpdatePattern = /^[\s\S]{1,255}$/;

  constructor() {

  }


  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {

  }

}

