import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '@app-shared/services';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import {
  UpdateOrganizationApiRequest, GetOrganizationApiResponse,
  PricingEngineVendorApiResponse, GetAllBehaviorsApiResponse, GetOrganizationApiRequest,
  GetPricingEngineVendorsApiRequest, CodeValueApiResponse, SendEmailToContactMailingListApiRequest,
  NotifyEmailApiRequest, GetServicingModelsApiResponse, GetRolesApiRequest, GetRolesApiResponse,
  GetRoleApiRequest, GetRoleApiResponse, GetBehaviorEnumApiResponse, GetTransactionTypesApiRequest,
  GetTransactionTypesApiResponse, GetPfiListByOgClassificationApiRequest,
  IsValidOrganizationApiRequest, GetMPFBanksApiResponse,
  GetMPFBankByNameApiRequest, GetMPFBankByIDApiRequest, CheckMPFProducePortfolioPricingRoleApiRequest,
  OrganizationRequestCriteriaApiRequest, GetOrganizationsApiResponse, GetPfisOwnedByMPFBankApiRequest,
  GetPricingEngineVendorsRelationshipsApiResponse,
  GetPagedOrgResultsApiRequest, GetPagedOrgApiResponse,
  GetBehaviorsApiRequest,
  GetBehaviorsApiResponse,
  GetAllBehaviorsApiRequest,
  CreateOrganizationApiRequest,
  GetSalesforceAccountApiResponse,
  GetSalesforceAccountForPFIChicagoApiRequest
} from './models';
import { SaveOrgVendorRelationshipApiRequest } from './models/SaveOrgVendorRelationshipApiRequest';
import { CreateUpdateOrganizationApiResponse } from './models/CreateUpdateOrganizationApiResponse';

@Injectable()
export class OrganizationService {
  
  private API_PATH =  `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH = `${this.API_PATH}/api/organization`
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/organization`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  createOrganization(req: CreateOrganizationApiRequest): Observable<CreateUpdateOrganizationApiResponse> {
    return this.http.post<CreateUpdateOrganizationApiResponse>(`${this.API_SERVICE_PATH}/${"CreateOrganization".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  updateOrganization(req: UpdateOrganizationApiRequest): Observable<CreateUpdateOrganizationApiResponse> {
    return this.http.put<CreateUpdateOrganizationApiResponse>(`${this.API_SERVICE_PATH}/${"UpdateOrganization".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  getFromEmailIDInfo(): Observable<CodeValueApiResponse> {
    return this.http.get<CodeValueApiResponse>(`${this.API_SERVICE_PATH}/${"GetFromEmailIDInfo".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getLDAP_OU(): Observable<CodeValueApiResponse> {
    return this.http.get<CodeValueApiResponse>(`${this.API_SERVICE_PATH}/${"GetLDAP-OU".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getLDAPInfo(): Observable<CodeValueApiResponse> {
    return this.http.get<CodeValueApiResponse>(`${this.API_SERVICE_PATH}/${"GetLDAPInfo".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getUserType_Custodian(): Observable<CodeValueApiResponse> {
    return this.http.get<CodeValueApiResponse>(`${this.API_SERVICE_PATH}/${"GetUserType-Custodian".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getUserType_HLB(): Observable<CodeValueApiResponse> {
    return this.http.get<CodeValueApiResponse>(`${this.API_SERVICE_PATH}/${"GetUserType-HLB".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getUserType_PFI(): Observable<CodeValueApiResponse> {
    return this.http.get<CodeValueApiResponse>(`${this.API_SERVICE_PATH}/${"GetUserType-PFI".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  sendEmailToContactMailingList(req: SendEmailToContactMailingListApiRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_SERVICE_PATH}/${"SendEmailToContactMailingList".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  notifyEmail(req: NotifyEmailApiRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_SERVICE_PATH}/${"NotifyEmail".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  getServicingModels(): Observable<GetServicingModelsApiResponse> {
    return this.http.get<GetServicingModelsApiResponse>(`${this.API_SERVICE_PATH}/${"GetServicingModels".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getRoles(req: GetRolesApiRequest): Observable<GetRolesApiResponse> {
    return this.http.get<GetRolesApiResponse>(`${this.API_SERVICE_PATH}/${"GetRoles".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getRole(req: GetRoleApiRequest): Observable<GetRoleApiResponse> {
    return this.http.get<GetRoleApiResponse>(`${this.API_SERVICE_PATH}/${"GetRole".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getBehaviorEnum(): Observable<GetBehaviorEnumApiResponse> {
    return this.http.get<GetBehaviorEnumApiResponse>(`${this.API_SERVICE_PATH}/${"GetBehaviorEnum".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getTransactionTypes(req: GetTransactionTypesApiRequest): Observable<GetTransactionTypesApiResponse> {
    return this.http.get<GetTransactionTypesApiResponse>(`${this.API_SERVICE_PATH}/${"GetTransactionTypes".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getAllHLBCode(): Observable<CodeValueApiResponse> {
    return this.http.get<CodeValueApiResponse>(`${this.API_SERVICE_PATH}/${"GetAllHLBCode".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getPfiListByOgClassification(req: GetPfiListByOgClassificationApiRequest): Observable<GetOrganizationsApiResponse> {
    return this.http.get<GetOrganizationsApiResponse>(`${this.API_SERVICE_PATH}/${"GetPfiListByOgClassification".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  isValidOrganization(req: IsValidOrganizationApiRequest): Observable<boolean> {
    return this.http.get<boolean>(`${this.API_SERVICE_PATH}/${"IsValidOrganization".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getOrganization(req: GetOrganizationApiRequest): Observable<GetOrganizationApiResponse> {
    return this.http.get<GetOrganizationApiResponse>(`${this.API_SERVICE_PATH}/${"GetOrganization".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getMpfBanks(): Observable<GetMPFBanksApiResponse> {
    return this.http.get<GetMPFBanksApiResponse>(`${this.API_SERVICE_PATH}/${"GetMpfBanks".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getMpfBankProvider(): Observable<GetOrganizationApiResponse> {
    return this.http.get<GetOrganizationApiResponse>(`${this.API_SERVICE_PATH}/${"GetMpfBankProvider".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getMpfBankByName(req: GetMPFBankByNameApiRequest): Observable<GetOrganizationApiResponse> {
    return this.http.get<GetOrganizationApiResponse>(`${this.API_SERVICE_PATH}/${"GetMpfBankByName".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getMpfBankByID(req: GetMPFBankByIDApiRequest): Observable<GetOrganizationApiResponse> {
    return this.http.get<GetOrganizationApiResponse>(`${this.API_SERVICE_PATH}/${"GetMpfBankByID".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  checkMPFProducePortfolioPricingRole(req: CheckMPFProducePortfolioPricingRoleApiRequest): Observable<boolean> {
    return this.http.get<boolean>(`${this.API_SERVICE_PATH}/${"CheckMPFProducePortfolioPricingRole".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  /*
  User Story 255192: Ang v15 upgrade -> Due to an unintended JavaScript comparison in HttpUtilHelper.ConvertQueryString() where "0 == []" would evaluate as TRUE, 
  any input parameters getting passed to these HTTP GET APIs with a value of 0 or 0.0 would then always get ignored and not translated into the resulting API
  query string. To preserve the original API query string that is getting passed in as part of the API call, any explicit 0's in the API calls are removed so that
  they do not appear in the resulting query string either.
  */
  getOrganizations(req: OrganizationRequestCriteriaApiRequest): Observable<GetOrganizationsApiResponse> {
    return this.http.get<GetOrganizationsApiResponse>(`${this.API_SERVICE_PATH}/${"GetOrganizations".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getPFIsOwnedByMPFBank(req: GetPfisOwnedByMPFBankApiRequest): Observable<GetOrganizationsApiResponse> {
    return this.http.get<GetOrganizationsApiResponse>(`${this.API_SERVICE_PATH}/${"GetPFIsOwnedByMPFBank".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getPricingEngineVendors(req: GetPricingEngineVendorsApiRequest): Observable<PricingEngineVendorApiResponse> {
    return this.http.get<PricingEngineVendorApiResponse>(`${this.API_SERVICE_PATH}/${"GetPricingEngineVendors".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getPricingEngineVendorsRelationships(): Observable<GetPricingEngineVendorsRelationshipsApiResponse> {
    return this.http.get<GetPricingEngineVendorsRelationshipsApiResponse>(`${this.API_SERVICE_PATH}/${"GetPricingEngineVendorsRelationships".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  /* User Story 255192: Ang v15 upgrade -> See explanation above under the same User Story ID */
  getPagedPfiList(req: GetPagedOrgResultsApiRequest): Observable<GetOrganizationsApiResponse> {
    return this.http.get<GetOrganizationsApiResponse>(`${this.API_SERVICE_PATH}/${"GetPagedPfiList".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  /* User Story 255192: Ang v15 upgrade -> See explanation above under the same User Story ID */
  getPagedOrgList(req: GetPagedOrgResultsApiRequest): Observable<GetPagedOrgApiResponse> {
    return this.http.get<GetPagedOrgApiResponse>(`${this.API_SERVICE_PATH}/${"GetPagedOrgList".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getBehaviors(req: GetBehaviorsApiRequest): Observable<GetBehaviorsApiResponse> {
    return this.http.get<GetBehaviorsApiResponse>(`${this.API_SERVICE_PATH}/${"GetBehaviors".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getAllBehaviors(req: GetAllBehaviorsApiRequest): Observable<GetAllBehaviorsApiResponse> {
    return this.http.get<GetAllBehaviorsApiResponse>(`${this.API_SERVICE_PATH}/${"GetAllBehaviors".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getAllPricingEngineVendors(req: GetPricingEngineVendorsApiRequest): Observable<PricingEngineVendorApiResponse> {
    return this.http.get<PricingEngineVendorApiResponse>(`${this.API_SERVICE_PATH}/${"GetAllPricingEngineVendors".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  saveOrgVendorRelationship(req: SaveOrgVendorRelationshipApiRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_SERVICE_PATH}/${"SaveOrgVendorRelationship".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  getSalesforceAccountForPFIChicago(req: GetSalesforceAccountForPFIChicagoApiRequest): Observable<GetSalesforceAccountApiResponse> {
    return this.http.get<GetSalesforceAccountApiResponse>(`${this.API_SERVICE_PATH}/${"GetSalesforceAccountForPFIChicago".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }
}

