import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tool-tip-display',
  templateUrl: './tool-tip-display.component.html',
  styleUrls: ['./tool-tip-display.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ToolTipDisplayComponent implements OnInit {
  showTooltipHelp: boolean = false;
  constructor() { }
  @Input() toolTipText: string;
  ngOnInit() {
  }
  clickMe(toolTipText)
  {
this.toolTipText=toolTipText;
  }
}
