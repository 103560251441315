import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'currencyFormatter'
})
export class currencyFormatter implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value == null)
            return null;
        return value.charAt(0) === '-' ? '(' + value.substring(1, value.length) + ')' : value;
    }
}