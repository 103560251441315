<form class="defaultText" #alertform="ngForm" name="alertform">
    <fhlb-modal-2 [title]="title" [topBorder]="topBorderClass" class="modal-hr" id="alert-modal">
        <div>
            <div class="col-sm-12 defaultText alertTextTitle text-center ps-2" [innerHtml]="messageTitle">
                
            </div>
            <div class="col-sm-12 defaultText alertText text-center">
                {{message}}
            </div>
            <div class="row justify-content-center" id="alignCenterBtn">
                <div class="col">
                    <button class="btn btn-dark-blue btn-primary col-12" id="okBtnclick" type="submit"
                        (click)="ok()"><b>
                            {{confirmBtnText}}</b>
                    </button>
                </div>
                <div class="col">
                    <button type="button" id="cancelBtnclick"
                        class="btn btn-light-blue btn-primary cancel-button-float col-12" (click)="cancel()"><b>
                            {{cancelBtnText}}</b>
                    </button>
                </div>
            </div>
        </div>
    </fhlb-modal-2>
</form>
