import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls:['./confirmation-modal.component.sass']
})
export class ConfirmationModalComponent implements OnInit {
    @Input() message: string
    @Output() confirmClicked = new EventEmitter();
    @Output() cancelClicked = new EventEmitter();
    topBorderClass = 'top-border-blue';

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

    okClicked(){
        this.confirmClicked.emit()
        this.activeModal.close();
    }

    cancelClick(){
        this.cancelClicked.emit()
        this.activeModal.close();
    }
}
