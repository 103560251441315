import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
  styleUrls: ['./circular-progress-bar.component.sass']
})
export class CircularProgressBarComponent implements OnInit {
  @Input() progressValue: number;
  @Input() progressText: string;
  constructor() { }

  ngOnInit() {
    this.setProgressBar();
  }

  setProgressBar(){
    const value = this.progressValue;
    if (value > 0) {
      if (value <= 50) {
        document.getElementById('progressRight').style.transform = 'rotate(' + this.percentageToDegrees(value) + 'deg)';
      } else {
        document.getElementById('progressRight').style.transform = 'rotate(180deg)';
        document.getElementById('progressLeft').style.transform = 'rotate(' + this.percentageToDegrees(value - 50) + 'deg)';
      }
    }
  }

  percentageToDegrees(percentage) {
    return percentage / 100 * 360;
  }
}
