import { Data } from "@angular/router";

export interface RouteDataSites extends Data {
    siteName: SiteName
}

export interface RouteDataBreadcrumb extends Data {
    breadcrumb: {
        value: IRouteBreadcrumb,
        overridingBreadcrumb: IRouteBreadcrumb[]
    }
}

export interface RouteDataAuthenication extends Data {
    empfAuth:{
        roles: string[],
        behaviors: string[]
    },
    losAuth: {
        roles: string[]
    }
}

export enum SiteName {
    Empf = 0,
    Origination = 1
}

export interface IRouteBreadcrumb {
    name: string,
    route: string,
    setCookies?: { [key: string]: string }
}