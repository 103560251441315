import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BroadCastService {
    observable: Observable<any>;
    observer: Observer<any>;

    constructor() {
        let temp = Observable.create((observer: Observer<any>) => {
            this.observer = observer;
        });
        this.observable = temp.pipe(share());
    }

    broadcast(event) {
        this.observer.next(event);
    }

    on(eventName, callback) {
        this.observable.subscribe(callback);
    }
}
