import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FhlbModalComponent } from './components/modal/fhlb-modal.component';
import { FhlbSwitchComponent } from './components/toggle-switch/fhlbc-switch.component';
import { ShowErrorDirective } from './directives/show-errors.directive';
import { SelectValidateDirective } from './directives/select-validator.directive';
import { CompareElementJsonDirective } from './directives/compare-element-json.directive';
import { CompareElementDirective } from './directives/compare-element-value.directive';
import { NumberPreFillDirective } from './directives/number-prefill.directive';
import { ConfirmEqualValidatorDirective } from './directives/confirm-equal-validator.directive';
import { GrdFilterPipe } from './pipes/grd-filter.pipe';
import { OrderByPipe } from './pipes/orderby.pipe';
import { OrderByMultiplePipe } from './pipes/orderByMultiple.pipe';
import { LimitToPipe } from './pipes/limitTo.pipe';
import { NgbActiveModal, NgbModalModule, NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NumberDirective } from './directives/numbers-only.directive';
import { ValidNumberDirective } from './directives/valid-number.directive';
import { ToCurrencyDirective } from './directives/to-currency.directive';
import { ToCurrencyTwoDigitsDirective } from './directives/to-currency-twodigits.directive';
import { ToPercentDirective } from './directives/to-percent.directive';
import { ConvertToWholeNumberDirective } from './directives/convert-to-whole-number.directive';
import { AlphaNumericOnlyDirective } from './directives/alphanumeric-only.directive';
import { AlphaNumericWithSpacesDirective } from './directives/alphanumericwithspaces.directive';
import { ReasonForUpdateComponent } from './components/reason-for-update/reason-for-update.component';
import { SuccessMessageModalComponent } from '@app-shared/components/success-message/success-message-modal.component';
import { CancelAlertComponent } from '@app-shared/components/cancel-alert/cancel-alert.component';
import { HttpClientModule } from '@angular/common/http';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from './components/block-ui-template.component';
import { SelectJsonValidateDirective } from './directives/select-json-validator.directive';
import { SuccessTopMessageComponent } from './components/success-top-message/success-top-message.component';
import { ValidateDateDirective } from './directives/valid-date.directive';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { WarningAlertComponent } from './components/warning-alert/warning-alert.component';
import { FilterPipe } from './Pipes/unique.pipe';
import { Filter } from './Pipes/filter.pipe';
import { currencyFormatter } from './Pipes/currency-formatter-pipe';
import { NgInitDirective } from './directives/ngInit.directive';
import { GroupByPipe } from './pipes/group-by.pipe';
import { PropertyFilter } from './pipes/propertyFilter.pipe';
import { LoanPresentmentListFilter } from './pipes/loanPresentmentListFilter.pipe';
import { FindMatches } from './pipes/find-matches';
import { FindSubProductsPipe } from './pipes/find-sub-products';
import { FindDistinctNoteRates } from './pipes/distinct-noteRate.pipe';
import { FindSchedules } from './pipes/find-schedules.pipe';
import { FhlbProgressBarComponent } from './components/fhlb-progress-bar/fhlb-progress-bar.component';
import { FormatAsPercentDirective } from './directives/format-as-percent.directive';
import { AllowDecimalNumbersDirective } from './directives/allow-decimal-numbers.directive';
import { productSelectionValidatorDirective } from './directives/productSelectionValidator';
import { ListItemsIntoColumnsDirective } from './directives/list-items-into-columns-directives';
import { FindMatch } from './pipes/findMatch.pipe';
import { FixedHeaderTopDirective } from './directives/fixed-header-top.directive';
import { SRPSelectionValidatorDirective } from './directives/srpSelectionValidator';
import { DisplayPercentageFilterPipe } from './pipes/display-percentage-filter.pipe';
import { NumberOnlyDirective } from './directives/allow-numbers.directive';
import { RealTimeCurrencyDirective } from './directives/real-time-currency.directive';
import { RealTimePercentDirective } from './directives/real-Time-Percent.directive';
import { ExpandSelectDirective } from './directives/expand-select.directive';
import { ToolTipDisplayComponent } from './components/tool-tip-display/tool-tip-display.component';
import { dateRangeSelectionValidatorDirective } from './directives/dateRangeSelectionValidator';
import { ScheduleSelectionValidatorDirective } from './directives/scheduleSelectionValidator';
import { DCRepoertFilter } from './pipes/dc-report-filter';
import { FilterSchedules } from './pipes/filter-Schedules';
import { decimalRoundOff } from './pipes/decimal-roundoff-pipe';
import { FormatDateDirective } from './directives/format-date.directive';
import { SessionAutoRenewDirective } from './directives/session-auto-renew.directive';
import { InlineSuccessMessageComponent } from './components/inline-success-message/inline-success-message.component';
import { NegativeCurrencyToParensPipe } from './Pipes/negativecurrencytoParens.pipe';
import { ToPercentUptoThreeDecimalsDirective } from './directives/to-percent-upto-three-decimals.directive';
import { DecimalsOnlyDirective } from './directives/decimal-numbers-Only.directive';
import { InlineErrorMessageComponent } from './components/inline-error-message/inline-error-message.component';
import { CompareValidatorDirective } from './directives/compare-validator.directive';
import { ToDecimalDirective } from './directives/to-decimal.directive';
import { AliasValueValidatorDirective } from './directives/alias-value-validator.directive';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { InactiveContactValidate } from './directives/inactive-contact-validator.directive';
import { NegativeDecimalsOnlyDirective } from './directives/negative-decimal-numbers-only.directive';
import { ToPercentUpToNDirective } from './directives/to-percent-upto-n-decimals.directive';
import { EnableOverrideComponent } from './components/toastr-component/enableoverride/enable-override.component';
import { RulesValidationErrorComponent } from './components/rules-validation-error/rules-validation-error.component';
import { ToNegativeCurrencyDirective } from './directives/to-negativecurrency.directive';
import { LoanFundingModalComponent } from './components/loan-funding-modal/loan-funding-modal.component';
import { ReasonForUpdateModalComponent } from './components/reason-for-update-modal/reason-for-update-modal.component';
import { ToNoteRateFormatDirective } from './directives/note-rate-field-validator.directive';
import { ValidatePastDateDirective } from './directives/valid-past-date.directive';
import { EmailModalComponent } from './components/email-modal/email-modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { CircularProgressBarComponent } from './components/circular-progress-bar/circular-progress-bar.component';
import { WebsocketService } from './services/websocket/websocket.service';
import { ConvertToBpsDirective } from './directives/convert-to-bps-value.directive';
import { RefreshNotificationContainerComponent } from './components/session/refresh-notification/refresh-notification.container.component';
import { ExpiredNotificationContainerComponent } from './components/session/expired-notification/expired-notification.container.component';
import { dynamicHeightDirective } from './directives/dynamic-height.directive';
import { SitesWrappingContainerComponent } from './components/sites-wrapping/sites-wrapping.container.component';
import { SitesHeaderComponent } from './components/sites-wrapping/sites-header/sites-header.component';
import { SitesMenuContainerComponent } from './components/sites-wrapping/sites-menu/sites-menu.container.component';
import { SitesFooterContainerComponent } from './components/sites-wrapping/sites-footer/sites-footer.container.component';
import { SafeHtmlPipe } from './Pipes/safeHtml.pipe';
import { SiteErrorPageComponent } from './components/sites-wrapping/site-error-page/site-error-page.component';
import { SelectDefaultValidateDirective } from './directives/select-default-validator.directive';
import { OrderByDatePipe } from './Pipes/orderbydate.pipe';
import { DisplayCurrencyPipe } from './Pipes/display-currency-pipe';
import { ValidateDateRangeDirective } from './directives/dob-validator.directive';
import { RealTimeCurrencyNoDecimalDirective } from './directives/real-time-currency-noDecimal.directive';
import { CommaSeparatedCurrencyDirective } from './directives/comma-separated-currency.directive';
import { AddSpaceToTextPipe } from './Pipes/add-space-to-text.pipe';
import { SitesBannerContainerComponent } from './components/sites-wrapping/sites-banner/sites-banner-container.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModalModule,
    NgbCollapseModule,
    HttpClientModule,
    NgSelectModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
    RouterModule,
    NgbModule
  ],
  exports: [
    FhlbModalComponent,
    FhlbSwitchComponent,
    ShowErrorDirective,
    SelectValidateDirective,
    CompareElementJsonDirective,
    CompareElementDirective,
    NumberPreFillDirective,
    ConfirmEqualValidatorDirective,
    GrdFilterPipe,
    OrderByPipe,
    OrderByDatePipe,
    OrderByMultiplePipe,
    LimitToPipe,
    NumberDirective,
    DecimalsOnlyDirective,
    NegativeDecimalsOnlyDirective,
    ValidNumberDirective,
    ToCurrencyDirective,
    ToCurrencyTwoDigitsDirective,
    ToNegativeCurrencyDirective,
    ToPercentDirective,
    ToPercentUptoThreeDecimalsDirective,
    ToPercentUpToNDirective,
    ConvertToWholeNumberDirective,
    RealTimeCurrencyNoDecimalDirective,
    RealTimeCurrencyDirective,
    NumberOnlyDirective,
    AlphaNumericOnlyDirective,
    AlphaNumericWithSpacesDirective,
    ReasonForUpdateComponent,
    SelectJsonValidateDirective,
    SuccessTopMessageComponent,
    ValidateDateDirective,
    BreadcrumbComponent,
    NgSelectModule,
    WarningAlertComponent,
    FilterPipe,
    Filter,
    currencyFormatter,
    decimalRoundOff,
    PropertyFilter,
    NgInitDirective,
    FhlbProgressBarComponent,
    productSelectionValidatorDirective,
    SRPSelectionValidatorDirective,
    LoanPresentmentListFilter,
    GroupByPipe,
    FindMatches,
    FindSubProductsPipe,
    FindDistinctNoteRates,
    FindSchedules,
    FindMatch,
    FormatAsPercentDirective,
    RealTimePercentDirective,
    AllowDecimalNumbersDirective,
    ListItemsIntoColumnsDirective,
    FixedHeaderTopDirective,
    DisplayPercentageFilterPipe,
    ExpandSelectDirective,
    ToolTipDisplayComponent,
    dateRangeSelectionValidatorDirective,
    ScheduleSelectionValidatorDirective,
    DCRepoertFilter,
    FilterSchedules,
    FormatDateDirective,
    SessionAutoRenewDirective,
    dynamicHeightDirective,
    SafeHtmlPipe,
    SelectDefaultValidateDirective,
    DisplayCurrencyPipe,
    InlineSuccessMessageComponent,
    NegativeCurrencyToParensPipe,
    InlineErrorMessageComponent,
    CompareValidatorDirective,
    ToDecimalDirective,
    AliasValueValidatorDirective,
    AlertModalComponent,
    InactiveContactValidate,
    RulesValidationErrorComponent,
    EnableOverrideComponent,
    RealTimeCurrencyDirective,
    ExpandSelectDirective,
    ToolTipDisplayComponent,
    dateRangeSelectionValidatorDirective,
    ToCurrencyTwoDigitsDirective,
    ConfirmationModalComponent,
    ToNoteRateFormatDirective,
    ValidatePastDateDirective,
    EmailModalComponent,
    CircularProgressBarComponent,
    ScheduleSelectionValidatorDirective,
    DCRepoertFilter,
    FilterSchedules,
    FormatDateDirective,
    SessionAutoRenewDirective,
    dynamicHeightDirective,
    SafeHtmlPipe,
    ConvertToBpsDirective,
    CircularProgressBarComponent,
    ConvertToBpsDirective,
    ValidateDateRangeDirective,
    CommaSeparatedCurrencyDirective,
    AddSpaceToTextPipe,
    CurrencyPipe, 
    DatePipe, 
    DecimalPipe, 
    PercentPipe
  ],
  declarations: [
    SitesHeaderComponent,
    SitesMenuContainerComponent,
    SitesFooterContainerComponent,
    SitesWrappingContainerComponent,
    SiteErrorPageComponent,
    FhlbModalComponent,
    FhlbSwitchComponent,
    ShowErrorDirective,
    SelectValidateDirective,
    CompareElementJsonDirective,
    CompareElementDirective,
    NumberPreFillDirective,
    ConfirmEqualValidatorDirective,
    GrdFilterPipe,
    OrderByPipe,
    OrderByDatePipe,
    OrderByMultiplePipe,
    LimitToPipe,    
    NumberDirective,
    DecimalsOnlyDirective,
    NegativeDecimalsOnlyDirective,
    ValidNumberDirective,
    ToCurrencyDirective,
    ToCurrencyTwoDigitsDirective,
    ToNegativeCurrencyDirective,
    ToPercentDirective,
    ToPercentUptoThreeDecimalsDirective,
    ToPercentUpToNDirective,
    ConvertToWholeNumberDirective,
    RealTimeCurrencyNoDecimalDirective,
    RealTimeCurrencyDirective,
    NumberOnlyDirective,
    AlphaNumericOnlyDirective,
    AlphaNumericWithSpacesDirective,
    ReasonForUpdateComponent,
    SuccessMessageModalComponent,
    CancelAlertComponent,
    SelectJsonValidateDirective,
    SuccessTopMessageComponent,
    ValidateDateDirective,
    BreadcrumbComponent,
    WarningAlertComponent,
    FilterPipe,
    Filter,
    currencyFormatter,
    decimalRoundOff,
    NgInitDirective,
    LoanPresentmentListFilter,
    FormatAsPercentDirective,
    RealTimePercentDirective,
    FindMatch,
    GroupByPipe,
    PropertyFilter,
    FindMatches,
    FindSubProductsPipe,
    FindDistinctNoteRates,
    FindSchedules,
    FhlbProgressBarComponent,
    productSelectionValidatorDirective,
    AllowDecimalNumbersDirective,
    ListItemsIntoColumnsDirective,
    FixedHeaderTopDirective,
    SRPSelectionValidatorDirective,
    DisplayPercentageFilterPipe,
    ExpandSelectDirective,
    ToolTipDisplayComponent,
    dateRangeSelectionValidatorDirective,
    ScheduleSelectionValidatorDirective,
    dynamicHeightDirective,
    DCRepoertFilter,
    FilterSchedules,
    FormatDateDirective,
    SessionAutoRenewDirective,
    InlineSuccessMessageComponent,
    NegativeCurrencyToParensPipe,
    InlineErrorMessageComponent,
    CompareValidatorDirective,
    ToDecimalDirective,
    AliasValueValidatorDirective,
    AlertModalComponent,
    InactiveContactValidate,
    RulesValidationErrorComponent,
    EnableOverrideComponent,
    RealTimeCurrencyDirective,
    ExpandSelectDirective,
    ToolTipDisplayComponent,
    dateRangeSelectionValidatorDirective,
    ConfirmationModalComponent,
    LoanFundingModalComponent,
    ReasonForUpdateModalComponent,
    ToNoteRateFormatDirective,
    ValidatePastDateDirective,
    EmailModalComponent,
    CircularProgressBarComponent,
    ConvertToBpsDirective,
    RefreshNotificationContainerComponent,
    ExpiredNotificationContainerComponent,
    SafeHtmlPipe,
    SelectDefaultValidateDirective,
    DisplayCurrencyPipe,
    ValidateDateRangeDirective,
    CommaSeparatedCurrencyDirective,
    AddSpaceToTextPipe,
    SitesBannerContainerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class SharedModule { }
