import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpUtilHelper } from 'src/shared/helpers/http-util-helper';
import { GetReportsApiRequest } from './models/GetReportsApiRequest';
import { GetReportsApiResponse } from './models/GetReportsApiResponse';
import { GetReportsDataApiRequest } from './models/GetReportsDataApiRequest';
import { GetReportsDataApiResponse } from './models/GetReportsDataApiResponse';
import { DownloadLFReportsApiRequest } from './models/DownloadLFReportsApiRequest';
import { GetAccountingReportsResponseModel } from './models/GetAccountingReportsResponseModel';
import { GetAccountingReportsDownloadRequestModel } from './models/GetAccountingReportsDownloadRequestModel';
import { GetPfiListResponseModel } from './models/GetPfiListResponseModel';
import { GetPfiListRequestModel } from './models/GetPfiListRequestModel';
import { AecActivityReportDownloadRequestModel } from './models/AecActivityReportDownloadRequestModel';
import { DownloadReportsApiRequest } from './models/DownloadReportsApiRequest';
import { GetReportsByReportTypeRequestModel } from './models/GetReportsByReportTypeRequestModel';
import { GetReportsByReportTypeResponseModel } from './models/GetReportsByReportTypeResponseModel';
import { ILoanPresentmentRequestModel } from '../transaction/loan-presentment/models/ILoanPresentmentRequestModel';
import { IPfiBatchReportResponseModel } from '../batch/models/IPfiBatchReportResponseModel';
import { IReportsRequestModel } from './models/ReportsRequestModel';
import { IServicingTransferReportsResponseModel } from './models/IServicingTransferReportsResponseModel';
import { AppConfigService } from '@app-shared/services';
import { IReportsResponseModel } from './models/IReportsResponseModel';
import { GetAecSearchDataRequestModel } from './models/getAecSearchDataRequestModel';

@Injectable({
    providedIn: 'root'
})

export class ReportService {
    private REPORTAPI_PATH = `${AppConfigService.Settings.Mpf.ReportAPIEndpoint}`;

    private API_SERVICE_EXT_PATH = `${this.REPORTAPI_PATH}/api/report`;
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private MICROSERVICE_BATCH_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;


  private BUILT_API_BATCH_PATH = `${this.MICROSERVICE_BATCH_PATH}/api/batch/ext`;
  private API_BATCH_SERVICE_EXT_PATH = `${this.BUILT_API_BATCH_PATH}/batch`;

    constructor(private http: HttpClient) { } 

  getDCReports(req: any): Observable<GetReportsApiResponse> {
        return this.http.get<GetReportsApiResponse>(`${this.API_SERVICE_EXT_PATH}/getreports?reportType=${req}`, this.options)
            .pipe(map(response => response));
    }
    getReports(req: GetReportsApiRequest): Observable<GetReportsApiResponse> {
        return this.http.get<GetReportsApiResponse>(`${this.API_SERVICE_EXT_PATH}/${"getreports".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    getReportsData(req: GetReportsDataApiRequest): Observable<GetReportsDataApiResponse> {
        return this.http.post<GetReportsDataApiResponse>(`${this.API_SERVICE_EXT_PATH}/getreportsdata`, req, this.options)
            .pipe(map(response => response));
    }

    // downloadReport(data: GetReportsApiRequest, config: any): Observable<any> {
    //     return this.http.post(`${this.API_SERVICE_EXT_PATH}/downloadreports`, data, config)
    //         .pipe(map(response => response));
    // }

    downloadLFReports(req: DownloadLFReportsApiRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_EXT_PATH}/${"DownloadLFReports".toLowerCase()}`,
            req, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response));
    }

    // These calls have not been tested, may require refactoring for use.

    getArchiveReports(requestModel: DownloadReportsApiRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_EXT_PATH}/getarchivereports`, requestModel)
            .pipe(map(response => response)
            );
    }

    getAecSearchData(requestModel: GetAecSearchDataRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_EXT_PATH}/getaecsearchdata`, requestModel)
            .pipe(map(response => response)
            );
    }

    downloadReport(requestModel: DownloadReportsApiRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_EXT_PATH}/downloadreports`, requestModel, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    downloadFileData(reportsRequestModel: IReportsRequestModel): Observable<any> {
        const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const body = `startDate=${reportsRequestModel.startDate}&endDate=${reportsRequestModel.endDate}&reportType=${reportsRequestModel.reportType}&servicingReportNumber=${reportsRequestModel.servicingReportNumber}&transferId=${reportsRequestModel.transferId}&fileName=${reportsRequestModel.fileName}`;
        return this.http.post(`${this.API_SERVICE_EXT_PATH}/downloadfile`, body, { headers: header,observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    // getSubmittedBatchesPaged(pfiBatchReportsModel: any, config: any): Observable<any> {
    //     return this.http.post(`${this.API_SERVICE_PATH}/getsubmittedbatchespaged`, pfiBatchReportsModel)
    //         .pipe(map(response => response)
    //         );
    // }

    // getSubmittedBatches(pfiBatchReportsModel: any): Observable<any> {
    //     return this.http.post(`${this.API_SERVICE_PATH}/getsubmittedbatches`, pfiBatchReportsModel)
    //         .pipe(map(response => response)
    //         );
    // }

    // getLatestSubmittedBatchesPaged(pfiBatchReportsModel: any): Observable<any> {
    //     return this.http.post(`${this.API_SERVICE_PATH}/getlatestsubmittedbatchespaged`, pfiBatchReportsModel)
    //         .pipe(map(response => response)
    //         );
    // }

    getOgFormStatus(): Observable<any> {
        return this.http.post(`${this.API_SERVICE_EXT_PATH}/getogformstatus`, this.options)
            .pipe(map(response => response)
            );
    }

    downloadAecActivityReport(requestModel: AecActivityReportDownloadRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_EXT_PATH}/downloadaecactivityreport`, requestModel, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    getPfiListByOgClassification(requestModel: GetPfiListRequestModel): Observable<GetPfiListResponseModel> {
        return this.http.post<GetPfiListResponseModel>(`${this.API_SERVICE_EXT_PATH}/getpfilistbyogclassifiaction`, requestModel)
            .pipe(map(response => response)
            );
    }

    getFormYear(): Observable<any> {
        return this.http.get(`${this.API_SERVICE_EXT_PATH}/getformyear`)
            .pipe(map(response => response)
            );
    }

    getReportsByReportTypes(reportType: GetReportsByReportTypeRequestModel): Observable<GetReportsByReportTypeResponseModel> {
        return this.http.post<GetReportsByReportTypeResponseModel>(`${this.API_SERVICE_EXT_PATH}/getReportsByReportTypes`.toLowerCase(), reportType)
            .pipe(map(response => response));
    }

    getAccountingReports(): Observable<GetAccountingReportsResponseModel> {
        return this.http.get<GetAccountingReportsResponseModel>(`${this.API_SERVICE_EXT_PATH}/getaccountingreports`)
            .pipe(map(response => response)
            );
    }

    downloadAccountingReports(requestModel: GetAccountingReportsDownloadRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_EXT_PATH}/downloadaccountingreports`, requestModel, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    getReportDataByReportType(requestModel: IReportsRequestModel): Observable<IReportsResponseModel> {
        return this.http.post<IReportsResponseModel>(`${this.API_SERVICE_EXT_PATH}/getReportDataByReportType`.toLowerCase(), requestModel)
            .pipe(map(response => response)
            );
    }

    downloadDcReports(requestModel: any): Observable<any> {
        return this.http.post(`${this.API_SERVICE_EXT_PATH}/downloaddcreports`, requestModel, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    getSubmittedBatchLoanRecords(loanPresentmentRequestModel:ILoanPresentmentRequestModel): Observable<IPfiBatchReportResponseModel> {
        return this.http.post<IPfiBatchReportResponseModel>(`${this.API_BATCH_SERVICE_EXT_PATH}/${'getSubmittedBatchLoanRecords'.toLowerCase()}`, loanPresentmentRequestModel)
            .pipe(map(response => response)
            );
    }
    getRecentReports(): Observable<GetReportsApiResponse> {
        return this.http.get<GetReportsApiResponse>(`${this.API_SERVICE_EXT_PATH}/recentlyviewed`, this.options)
            .pipe(map(response => response));
    }
    getServicingDetail(reqData: IReportsRequestModel): Observable<IServicingTransferReportsResponseModel> {
        const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const body = `startDate=${reqData.startDate}&endDate=${reqData.endDate}&reportType=${reqData.reportType}&servicingReportNumber=${reqData.servicingReportNumber}`;
        return this.http.post<IServicingTransferReportsResponseModel>(`${this.API_SERVICE_EXT_PATH}/getservicingdetails`, body,{ headers: header })
            .pipe(map(response => response)
            );
    }

}
