export const pricingConstant = {
    traditional: 'Traditional',
    governmentMbs: 'GovernmentMbs',
    mpfXtra: 'MpfXtra',
    mpfXtraMbs: 'MpfXtraMbs',
    mpx: 'Mpx',
    mpxDisplayName: 'MPF MPX' ,
    mpfXtraDisplayName: 'MPF Xtra',
    mpfXtraMBSDisplayName: 'MPF Xtra MBS',
    traditionalDisplayName: 'MPF Traditional',
    governmentMbsDisplayName: 'MPF Government MBS',
    allProducts: 'All Products',
    allSubProducts: 'All Sub Products',
    selectAll: 'selectAll',
    deliveryType: 'deliveryType',
    remittanceType: 'remittanceType',
    product: 'product',
    subProduct: 'subProduct',
    schedule: 'schedule',
    mandatory: 'Mandatory',
    bestEfforts: 'BestEfforts',
    remittanceScheduled: 'SS',
    remittanceActual: 'AA',
    selectAllPricingSource: 'selectAllPricingSource',
    pricingTypeArchived: 'archived',
    pricingTypeCurrent: 'current',
    pricingTypeActionView: 'view',
    pricingTypeActionDownload: 'download',
    loanType: 'loanType',
    servicer: 'servicer'

}