import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpUtilHelper } from "@app-shared/helpers/http-util-helper";
import { AppConfigService } from "@app-shared/services";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { GetBatchApiRequest } from "./models/GetBatchApiRequest";
import { GetBatchApiResponse } from "./models/GetBatchApiResponse";
import { IAbortBatchByBatchIDApiRequest } from "./models/AbortBatchByBatchIDApiRequest";
import { IAbortBatchByBatchIDApiResponse } from "./models/AbortBatchByBatchIDApiResponse";
import { GetBatchesBySearchCriteriaPagedApiRequest } from "./models/GetBatchesBySearchCriteriaPagedApiRequest";
import { GetBatchesBySearchCriteriaPagedApiResponse } from "./models/GetBatchesBySearchCriteriaPagedApiResponse";
import { GetBatchForBatchMonitorApiRequest } from "./models/GetBatchForBatchMonitorApiRequest";
import { GetBatchForBatchMonitorApiResponse } from "./models/GetBatchForBatchMonitorApiResponse";
import { SendBatchNotificationApiRequest } from "./models/SendBatchNotificationApiRequest";
import { ICreateBatchAPIRequest } from "./models/CreateBatchAPIRequest";
import { ICreateBatchAPIResponse } from "./models/CreateBatchAPIResponse";
import { ISubmitBatchRequestModel } from "@app-shared/services/audit/models/SubmitBatchRequestModel";
import { IUploadBatchFileAPIResponse } from "./models/UploadBatchFileAPIResponse";
import { ReprocessBatchApiRequest } from "./models/ReprocessBatchApiRequest";
import { UpdateBatchLoansForReprocessingApiRequest } from "./models/UpdateBatchLoansForReprocessingApiRequest";
import { DownloadBatchFileToSharedPathApiRequest } from "./models/DownloadBatchFileToSharedPathApiRequest";
import { DownloadBatchFileToSharedPathApiResponse } from "./models/DownloadBatchFileToSharedPathApiResponse";
import { IProcessBatchAPIRequest } from "./models/ProcessBatchAPIRequest";
import { IProcessBatchAPIResponse } from "./models/ProcessBatchAPIResponse";
import { GetSchedulePricingTokensApiRequest } from "./models/GetSchedulePricingTokensApiRequest";
import { GetSchedulePricingTokensApiResponse } from "./models/GetSchedulePricingTokensApiResponse";
import { ReprocessBatchApiResponse } from "./models/ReprocessBatchApiResponse";

@Injectable()
export class BatchService {
  private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH = `${this.API_PATH}/api/batch`
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/batch`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }
  private reqstr: string;

  constructor(private http: HttpClient) { }

  GetBatchesBySearchCriteriaPaged(req: GetBatchesBySearchCriteriaPagedApiRequest): Observable<GetBatchesBySearchCriteriaPagedApiResponse> {
    return this.http.get<GetBatchesBySearchCriteriaPagedApiResponse>(`${this.API_SERVICE_PATH}/${"GetBatchesBySearchCriteriaPaged".toLowerCase()}?${HttpUtilHelper.ConvertMultiComplexObjectQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  //GetBatchForBatchMonitor
  GetBatchForBatchMonitor(req: GetBatchForBatchMonitorApiRequest): Observable<GetBatchForBatchMonitorApiResponse> {
    return this.http.get<GetBatchForBatchMonitorApiResponse>(`${this.API_SERVICE_PATH}/${"GetBatchForBatchMonitor".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }


  GetBatch(req: GetBatchApiRequest): Observable<GetBatchApiResponse> {
    return this.http.get<GetBatchApiResponse>(`${this.API_SERVICE_PATH}/${"GetBatch".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  AbortBatchByBatchID(req: IAbortBatchByBatchIDApiRequest): Observable<IAbortBatchByBatchIDApiResponse> {
    return this.http.get<IAbortBatchByBatchIDApiResponse>(`${this.API_SERVICE_PATH}/${"AbortBatchByBatchID".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req, true)}`, this.options)
      .pipe(map(response => response));
  }

  SendBatchNotification(req: SendBatchNotificationApiRequest) {
    return this.http.post(`${this.API_SERVICE_PATH}/${"SendBatchNotification".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }
  ReprocessBatch(connectionId: string,req: ReprocessBatchApiRequest) {
    return this.http.post<ReprocessBatchApiResponse>(`${this.API_SERVICE_PATH}/${"ReprocessBatch".toLowerCase()}`, req, this.websocketOptions(connectionId))
      .pipe(map(response => response));

  }

  CreateBatch(connectionId: string, req: ICreateBatchAPIRequest): Observable<ICreateBatchAPIResponse> {
    return this.http.post<ICreateBatchAPIResponse>(`${this.API_SERVICE_PATH}/${"CreateBatch".toLowerCase()}`, req, this.websocketOptions(connectionId))
      .pipe(map(response => response));
  }

  uploadBatchFile(batchFiles: any, additionalFiles: any): Observable<IUploadBatchFileAPIResponse> {
    const formData = new FormData();
    //add all of the assigned files
    if (batchFiles && batchFiles.length > 0) {
      let fileToUpload = <File>batchFiles[0];
      formData.append('BatchFile', fileToUpload, fileToUpload.name);
    }

    if (additionalFiles && additionalFiles.length > 0) {
      let addfileToUpload = <File>additionalFiles[0];
      formData.append('AdditionalFile', addfileToUpload, addfileToUpload.name);
    }

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    return this.http.post<IUploadBatchFileAPIResponse>(`${this.API_SERVICE_PATH}/${"UploadBatchFiles".toLowerCase()}`, formData, { headers })
      .pipe(map(response => response));
  }


  UpdateBatchLoansForReprocessing(req: UpdateBatchLoansForReprocessingApiRequest) {
    return this.http.post(`${this.API_SERVICE_PATH}/${"UpdateBatchLoansForReprocessing".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  DownloadBatchFileToSharedPath(connectionId: string, req: DownloadBatchFileToSharedPathApiRequest): Observable<DownloadBatchFileToSharedPathApiResponse> {
    return this.http.get<DownloadBatchFileToSharedPathApiResponse>(`${this.API_SERVICE_PATH}/${"DownloadBatchFileToSharedPath".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.websocketOptions(connectionId))
      .pipe(map(response => response));
  }

  ProcessBatch(connectionId: string, req: IProcessBatchAPIRequest): Observable<IProcessBatchAPIResponse> {
    return this.http.post<IProcessBatchAPIResponse>(`${this.API_SERVICE_PATH}/${"ProcessBatch".toLowerCase()}`, req, this.websocketOptions(connectionId))
      .pipe(map(response => response));
  }

  GetSchedulePricingTokens(req: GetSchedulePricingTokensApiRequest): Observable<GetSchedulePricingTokensApiResponse> {
    return this.http.get<GetSchedulePricingTokensApiResponse>(`${this.API_SERVICE_PATH}/${"GetSchedulePricingTokens".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req, true)}`, this.options)
      .pipe(map(response => response));
  }


}
