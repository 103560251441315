import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '@app-shared/services';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import {
  GetLookupsByCategoryIdApiRequest,
  GetLookupApiResponse,
  GetLookupsByLookupCategoryNameApiRequest
} from '@origination-shared/services/organization/models/index';

@Injectable()
export class LookupService {

  private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH = `${this.API_PATH}/api/organization`;
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/lookup`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  private lookupKey_state = { id: 14, name: 'States' };
  private lookupKey_hmdaReporterStatus = { id: 87, name: 'HMDAReporterStatus' };
  private lookupKey_pfiType = { id: 41, name: 'PFITypes' };
  private lookupKey_og1Classification = { id: 61, name: 'PfiStatuses' };
  private lookupKey_pfiStatus = { id: 10, name: 'PFIStatus' };
  private lookupKey_mergerReason = { id: 83, name: 'MergerReason' };
  private lookupKey_mergerType = { id: 84, name: 'MergerType' };
  private lookupKey_successorType = { id: 85, name: 'SuccessorType' };
  private lookupKey_mergerStatus = { id: 86, name: 'MergerStatus' };
  private lookupKey_intCalcMethod = { id: 12, name: null };
  private lookupKey_investmentOption = { id: 57, name: 'InvestmentOption' };
  private lookupKey_remittanceType = { id: 29, name: 'RemittanceType' };
  private lookupKey_loanType = { id: 22, name: 'LoanType' };
  private lookupKey_dcType = { id: 54, name: 'DeliveryCommitmenttype' };
  private lookupKey_mcstatus = { id: 3, name: 'MCSTatus' };
  private lookupKey_ceForm = { id: 4, name: 'CeFormOptions' };
  private lookupKey_ceReportType = { id: 8, name: 'CeReportTypeOptions' };
  private lookupKey_executionType = { id: 54, name: 'executionType' };
  private lookupKey_xtraServicingType = { id: 70, name: 'XtraServicingType' };
  private lookupKey_underwritingMethod = { id: 71, name: 'UnderwritingMethod' };
  private lookupKey_noteRateIncrement = { id: 72, name: 'NoteRateIncrement' };
  private lookupKey_servicingModel = { id: 73, name: 'ServicingModel' };
  private lookupKey_dcStatus = { id: 16, name: 'dcStatus' };
  private lookupKey_dcTransactionCode = { id: 17, name: 'dcTransactionCode' };
  private lookupKey_loanPurposeCategory = { id: 21, name: 'loanPurposeCategory' };
  private lookupKey_occupancyCategory = { id: 19, name: 'occupancyCategory' };
  private lookupKey_attachmenttypeCategory = { id: 92, name: 'attachmenttypeCategory' };
  private lookupKey_propertyCategory = { id: 23, name: 'loanPlancategory' };
  private lookupKey_mhNumberOfUnits = { id: 48, name: 'mhNumberOfUnits' };
  private lookupKey_mhPriorOccupancyStatus = { id: 49, name: 'mhPriorOccupancyStatus' };
  private lookupKey_lprLoanPurpose = { id: 21, name: 'lprLoanPurpose' };
  private lookupKey_lprLoanType = { id: 20, name: 'lprLoanType' };

  private lookupKey_lprLoanOriginationSource = { id: 47, name: 'lprLoanOriginationSource' };
  private lookupKey_avmCategory = { id: 28, name: "AVM_CATEGORY" }

  private lookupKey_aplCategoryCode = { id: 46, name: 'aplCategoryCode' };
  private lookupKey_lprLoanDocType = { id: 34, name: 'lprLoanDocType' };
  private lookupKey_lprLoanAppraisalType = { id: 27, name: 'lprLoanAppraisalType' };
  private lookupKey_lprAUScode = { id: 37, name: 'lprAUScode' };
  private lookupKey_EthinicityCode = { id: 30, name: 'Ethnicity' };
  private lookupKey_TerritoryCategory = { id: 45, name: 'TerritoryCategory' };
  private lookupKey_BEoccupancyCategory= { id: 69, name: 'BEoccupancyCategory' };
  private lookupKey_eVaultVendors= { id: 93, name: 'eVaultVendors' };

  constructor(private http: HttpClient) { }

  public getLookupsByCategoryId(req: GetLookupsByCategoryIdApiRequest): Observable<GetLookupApiResponse> {
    return this.http.get<GetLookupApiResponse>(`${this.API_SERVICE_PATH}/${"GetLookupsByCategoryId".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  public getLookupsByLookupCategoryName(req: GetLookupsByLookupCategoryNameApiRequest): Observable<GetLookupApiResponse> {
    return this.http.get<GetLookupApiResponse>(`${this.API_SERVICE_PATH}/${"GetLookupsByLookupCategoryName".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  public getStates(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_state.id });
  }

  public getPfiStatuses(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_pfiStatus.id });
  }

  public getHmdaReporterStatuses(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_hmdaReporterStatus.id });
  }

  public getOg1Classifications(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_og1Classification.id });
  }

  public getPfiTypes(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_pfiType.id });
  }

  public getInterestCalculationMethods(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_intCalcMethod.id });
  }

  public getMergerReason(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_mergerReason.id });
  }
  public getMergerStatus(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_mergerStatus.id });
  }
  public getSuccessorType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_successorType.id });
  }
  public getMergerType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_mergerType.id });
  }
  public getCeFormOptions(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_ceForm.id });
  }
  public getCeReportTypeOptions(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_ceReportType.id });
  }
  geteVaultVendorsOptions(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_eVaultVendors.id });
  }

  getInvestmentOptions(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_investmentOption.id });
  }
  getRemittanceTypes(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_remittanceType.id });
  }
  getLoanTypes(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_loanType.id });
  }
  getDeliveryCommitmentTypes(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_dcType.id });
  }
  getMcStatuses(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_mcstatus.id });
  }
  getExecutionType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_executionType.id });
  }
  getUnderwritingMethodType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_underwritingMethod.id });
  }
  getServicingModelType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_servicingModel.id });
  }
  getXtraServicingType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_xtraServicingType.id });
  }
  getNoteRateIncrementType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_noteRateIncrement.id });
  }
  getDCStatus(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_dcStatus.id });
  }
  getLookupsByLookupCategoryNameType(req: string): Observable<GetLookupApiResponse> {
    return this.getLookupsByLookupCategoryName({ categoryName: req });
  }
  getDcTransacionCode(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_dcTransactionCode.id });
  }
  getLoanPurposeCategory(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_loanPurposeCategory.id });
  }
  getOccupancyCategory(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_occupancyCategory.id });
  }
  getBEOccupancyCategory(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_BEoccupancyCategory.id });
  }
  getattachmenttypeCategory(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_attachmenttypeCategory.id });
  }
  getMhNumberOfUnits(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_mhNumberOfUnits.id });
  }
  getMhPriorOccupancyStatus(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_mhPriorOccupancyStatus.id });
  }

  getLookupsProperty(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_propertyCategory.id });
  }

  getAPLCategory(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_aplCategoryCode.id });
  }

  getLoanPurposeName(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_lprLoanPurpose.id });
  }
  getLprLoanType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_lprLoanType.id });
  }
  getLoanOriginationSource(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_lprLoanOriginationSource.id });
  }
  getLoanDocType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_lprLoanDocType.id });
  }
  getLoanAppraisalType(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_lprLoanAppraisalType.id });
  }
  getLoanAUScode(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_lprAUScode.id });
  }
  getAVM(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_avmCategory.id });
  }

  getTerritoryCategory(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_TerritoryCategory.id });
  }
  getEthinicity(): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: this.lookupKey_EthinicityCode.id });
  }
  getFICOScoreSource(categoryKey: number): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: categoryKey });
  }
  getBorrowerResidencyType(categoryKey: number): Observable<GetLookupApiResponse> {
    return this.getLookupsByCategoryId({ categoryId: categoryKey });
  }
 
}
