import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displaypercentage'
})
export class DisplayPercentageFilterPipe {
    constructor(private _decimalPipe: DecimalPipe) { }
    transform(value: string | number, args: string | number): string {
        if (value === null) {
            return null;
        }
        let valueString = value.toString();
        let argsString = args.toString();
        return this._decimalPipe.transform(valueString, '1.' + argsString + '-' + argsString) + '%';
    }
}