export enum BehaviourType
{
  /// <summary>
  /// The unspecified
  /// </summary>

  Unspecified = 0,
  /// <summary>
  /// The create pfi org
  /// </summary>
  CreatePFIOrg = 1,
  /// <summary>
  /// The create mc
  /// </summary>
  CreateMC,
  /// <summary>
  /// The create dc
  /// </summary>
  CreateDC,
  /// <summary>
  /// The make funding request
  /// </summary>
  MakeFundingRequest,
  /// <summary>
  /// The submit batch
  /// </summary>
  SubmitBatch,
  /// <summary>
  /// The be post closing contact
  /// </summary>
  BePostClosingContact,
  /// <summary>
  /// The present loan
  /// </summary>
  PresentLoan,
  /// <summary>
  /// The on resolution
  /// </summary>
  OnResolution,
  /// <summary>
  /// The act as qa
  /// </summary>
  ActAsQA,
  /// <summary>
  /// The accesse MPF
  /// </summary>

  AccesseMPF,
  /// <summary>
  /// The send reporting to master servicer
  /// </summary>

  SendReportingToMasterServicer,
  /// <summary>
  /// The request collateral
  /// </summary>

  RequestCollateral,
  /// <summary>
  /// The create jr mc
  /// </summary>

  CreateJrMC,
  /// <summary>
  /// The create sr mc
  /// </summary>

  CreateSrMC,
  /// <summary>
  /// The download files
  /// </summary>

  DownloadFiles,
  /// <summary>
  /// The receive emails
  /// </summary>

  ReceiveEmails,
  /// <summary>
  /// The upload files
  /// </summary>

  UploadFiles,
  /// <summary>
  /// The approve pfi originator
  /// </summary>

  ApprovePFIOriginator,
  /// <summary>
  /// The approve mc
  /// </summary>

  ApproveMC,
  /// <summary>
  /// The receive files
  /// </summary>

  ReceiveFiles,
  /// <summary>
  /// The account manager
  /// </summary>

  AccountManager,
  /// <summary>
  /// The update pricing
  /// </summary>

  UpdatePricing,
  /// <summary>
  /// The active distribution for MPF plus
  /// </summary>

  ActiveDistributionForMPFPlus,
  /// <summary>
  /// The active distribution for enhanced mi
  /// </summary>

  ActiveDistributionForEnhancedMI,
  /// <summary>
  /// The opt out authorizer
  /// </summary>

  OptOutAuthorizer,
  /// <summary>
  /// The request servicing transfer
  /// </summary>

  RequestServicingTransfer,
  /// <summary>
  /// The pricing engine vendor relationship contact
  /// </summary>

  PricingEngineVendorRelationshipContact,
  /// <summary>
  /// The servicing contact
  /// </summary>

  ServicingContacts

  
}