
<!--Begin Progress Bar-->
<div class="row">
    <div class="col-12">
        <div  class="font-bold font-size-2 text-start bottom-margin-5px">
            <span >{{displayProgressionMessage}}</span>
        </div>


        <div class="position-relative row">
            <div class="position-absolute col-12">
                <span >
                    <ngb-progressbar type="info" height="30px" [value]="currentpercentage" [striped]="true" [animated]="true"><i>{{currentpercentage}}</i></ngb-progressbar>
                </span>
            </div>
          
        </div>


    </div>
</div>
<!--End Progress Bar-->

