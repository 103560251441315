import { CommonModule } from "@angular/common";
import { NO_ERRORS_SCHEMA } from "@angular/core";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BlockTemplateComponent } from "@app-shared/components/block-ui-template.component";
import { SharedModule } from "@app-shared/shared.module";
import { BlockUIModule } from "ng-block-ui";
import { AecReportContainerComponent } from "./components/aec/aec-report.container.component";
import { AecReportPageComponent } from "./components/aec/aec-report.page.component";
import { ActiveDCModalComponent } from "./components/dc/active-dc-modal/active-dc-modal.component";
import { EmailMessagePopUpComponent } from "./components/lf/email-message-pop-up/email-message-pop-up.component";
import { UserEmailContactsContainerComponent } from "./components/lf/user-email-contacts/user-email-contacts.container.component";
import { UserEmailContactsComponent } from "./components/lf/user-email-contacts/user-email-contacts.page.component";
import { ViewPfiListComponent } from "./components/view-pfi-list/view-pfi-list.component";
import { RouterModule, Routes } from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule,
        BlockUIModule.forRoot({
            template: BlockTemplateComponent
          })
    ],
    exports: [
        ViewPfiListComponent,
        AecReportContainerComponent,
        AecReportPageComponent
        

    ],
    declarations: [
        ViewPfiListComponent,
        ActiveDCModalComponent,
        UserEmailContactsComponent,
        EmailMessagePopUpComponent,
        UserEmailContactsContainerComponent,
        AecReportContainerComponent,
        AecReportPageComponent
        
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class EmpfSharedModule { }
