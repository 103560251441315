import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { UtilHelper } from 'src/shared/services';
import { isNullOrUndefined } from 'is-what';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[orgRepWarrantValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: OrgRepWarrantValidateDirective, multi: true }]
})

export class OrgRepWarrantValidateDirective implements Validator {
    @Input('orgRepWarrantValidate') defaultValue: string;
    constructor() { }

    validate(control: AbstractControl): { [key: string]: boolean } | null {
        control.setErrors(null);
        if (UtilHelper.isDefinedOrNotNull(control.value) && !isNullOrUndefined(this.defaultValue)) {
            if (this.defaultValue === control.value) {
                control.setErrors({ invalid: true });
                return { invalidoption: true };
            }
        }

        return null;
    }
}
