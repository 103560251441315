
  <div class="card mt-3 defaultText">
    <div class="row">
      <div class="col-12">
        <div class="col-12 card-header p-1">
          <div class="row">
            <div class="col-11 ps-4">
              <b>Reason For Update</b>
            </div>
            <button class="col-1 pe-4 btn text-end defaultText"
            
             id="collapseBtn"
                    (click)="isCollapsed= !isCollapsed" [ngSwitch]="isCollapsed">
              <i *ngSwitchCase="false" id="plus" class="fa fa-plus fa-lg"></i>
              <i *ngSwitchCase="true" id="minus" class="fa fa-minus fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="collapse row" id="collapseComment" [ngbCollapse]="!isCollapsed">
      <div class="col-12 card-body">
        <form name="reasonForUpdate" id="reasonForUpdate" #reasonForUpdate="ngForm"
              [class.was-validated]="formSubmitted">
          <div class="form-group row g-0 justify-content-center mb-0">
            <div class="col-3 text-end">
              <label for="comment" class="col-form-label fieldLabel">Reason for Update:</label>
            </div>
            <div class="col-4 ps-3">
              <textarea type="text" class="form-control " id="comment" name="comment"
                        [(ngModel)]="audit.reasonForUpdate" required #comment="ngModel"  [pattern]="reasonForUpdatePattern" show-errors ></textarea>
              <div class="help-block row" *ngIf="comment.invalid && (comment.touched || formSubmitted)">
                <p class="col-sm-12" *ngIf="comment.errors.required">
                  Reason for Update is required
                </p>
                <p class="col-sm-12" *ngIf="comment.errors.pattern">
                  Must be &lt; 255
                </p>
              </div>
              <div>
                <p class="card-bottom-text">Please click save again to save your updates. If you cancel, your updates will be lost.</p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
