import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { UtilHelper } from '@app-shared/services';
import { Store } from '@ngrx/store';
import { RefreshTokensAction } from 'src/store/actions/auth.actions';
import { AppState } from 'src/store/reducers';
import { isNullOrUndefined } from 'is-what';

@Directive({
    selector: '[session-auto-renew]'
})
export class SessionAutoRenewDirective implements OnInit {
    currentTickInSeconds: number;
    expireNotificationInSeconds: number;
    updateOccured: boolean = false;
    intervalObject: any
    constructor(public el: ElementRef, private appStore: Store<AppState>) {
    }

    ngOnInit(): void {
        this.intervalObject = setInterval(() => {
            if(!isNullOrUndefined(this.updateOccured) && this.updateOccured){
                this.appStore.dispatch(new RefreshTokensAction({
                    updateEmpfSessionTime: true,
                    navigateToHome: null,
                    loginFlow: null
                }));
                this.updateOccured = false;
            }
        }, 60000);
    }

    @HostListener('click', ['$event']) onInput(e) {
        if (e !== '') {
            this.eventHandler(e);
        }
    }

    @HostListener('keydown', ['$event']) onInputChange(e) {
        if (e !== '') {
            this.eventHandler(e);
        }
    }

    eventHandler(e) {
        if (UtilHelper.isDefinedOrNotNull(e.target)) {

            var hasFocus = e.target.tagName.toUpperCase() === 'INPUT' || e.target.tagName.toUpperCase() === 'SPAN' || e.target.tagName.toUpperCase() === 'A';

            if ((e.type === 'click' && hasFocus) || (e.type === 'keydown' && (e.keyCode === 13 || e.keyCode === 9))) {
                this.updateOccured = true;
            }
        }
    }
}
