import { AfterViewChecked } from '@angular/core';
import { Directive, ElementRef, Input } from '@angular/core'; import { isNullOrUndefined } from 'is-what';
;
@Directive({
    selector: '[dynamicHeight]'
})



export class dynamicHeightDirective implements AfterViewChecked {
    @Input('fromDiv') fromDiv: string;
    @Input('toDiv') toDiv: string;
    @Input('render') startRender: boolean;

    constructor() {

    }

    ngAfterViewChecked() {
        if(this.startRender){
        this.matchHeight();
        }
    }

    matchHeight() {
        let fromDivHeight;
        let toDivHeight;

        if (!isNullOrUndefined(this.fromDiv) && !isNullOrUndefined(this.toDiv)) {
            if (document.getElementById(this.fromDiv)) {
                fromDivHeight = document.getElementById(this.fromDiv).offsetHeight;
            }

            if (document.getElementById(this.toDiv)) {
                toDivHeight = document.getElementById(this.toDiv).offsetHeight;
            }

            if (fromDivHeight > toDivHeight) {
                document.getElementById(this.toDiv).style.height = fromDivHeight + 'px';
            } else {
                document.getElementById(this.fromDiv).style.height = toDivHeight + 'px';
            }
        }
    }

    
}
