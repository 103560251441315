export enum PFIType{
    Unspecified = 0,
    CommercialBank = 1,
    StateSavingsBank,
    FederalSavingsBank,
    Thrift,
    Other,
    CreditUnion,
    InsuranceCompany,
    HousingAssociate
}