<form #userProfileForm="ngForm" class="fhlbc-standard-box full-height" [class.was-validated]="formSubmitted">
    <div class="fhlbc-standard-box">
        <div class="col-12 ps-0 pe-0 loginHeader">
            <hr class="modal-hr modal-hr-border-top-color-3" />
            <div id="login-header" class="text-center fhlbc-standard-box">
                <img class="login-logo" src="assets/MPF_medium_blue_vector_72ppi.png" />
            </div>
        </div>
    </div>
    <div class="fhlbc-standard-box" style="min-height: 135px;">
        <div style="margin:10%;">
            <div class="form-group mb-3">
                <select id="userProfile" name="userProfile" [(ngModel)]="profileScopeID" 
                (change)="userProfileChanged()" selectValidate
                    selectDefault="{{defaultDropDownValue}}"
                    class="form-select dropdown-toggle-select-element tableText" 
                    required #userProfile="ngModel"
                    show-errors>
                    <option value="0">Select User Profile</option>
                    <option class="feedback-form-input" *ngFor="let usrProfile of userProfileList"
                    value={{usrProfile.ProfileScopeID}}>
                        {{usrProfile.OnScreenLabel}}
                    </option>
                </select>
                <div class="help-block row" *ngIf="userProfile.invalid && (userProfile.touched || formSubmitted)">
                    <p class="col-sm-12">Field Required</p>
                </div>
            </div>
        </div>
    </div>
    <div style="margin:10%;">
      <input type="submit" (click)="submit()" class="col-12 btn btn-success login-button login-button-font"
             value="LOG IN" />
    </div>
</form>
