<div>
    <div class=" card-header">
        <div class="row ">
            <label class="defaultText col-8  btn text-start font-bold ">System Alert</label>
            <button type="button" class="col-2 pe-0 btn text-end">
                <span id="minus" class="fa fa-minus fa-lg "
                    [ngClass]="{ 'fa-minus': accordionStatus=== true, 'fa-plus': accordionStatus=== false }"
                    data-bs-toggle="collapse" data-bs-target="#warning-alert"
                    (click)="accordionStatus = !accordionStatus"></span>
            </button>
            <button type="button" class="col-2  btn  btnccross" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true" class="fa fa-times fa-lg "></span>
            </button>
        </div>
    </div>
</div>

<div class="bordererror show" id="warning-alert"  *ngIf= "accordionStatus">
    <br />
    <div class="row justify-content-center ">
        <div class="col-11 col-form-label message text-center defaultText" [innerHtml]="message">
        </div>

    </div>
    <br />
    <div class="col-12">
        <div class="form-group mb-3 row g-0 justify-content-center">
            <div class="col-4 justify-content-center">
                <button id="continue" type="submit" class="btn btncolor btn-primary col-md-10 defaultText"
                    (click)="ok()">
                    <b>
                        OK
                    </b>
                </button>
            </div>
            <div class="col-4 justify-content-center" *ngIf="showCancel">
                <button id="continue" type="submit"
                    class="btn btn-dark-grey btn-primary col-11" (click)="cancel()">
                    <b>
                        CANCEL
                    </b>
                </button>
            </div>

        </div>
    </div>
</div>