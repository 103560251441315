import { Component } from '@angular/core';

@Component({
  selector: 'site-error-page',
  templateUrl: './site-error-page.component.html',
  styleUrls: ['./site-error-page.component.sass']

})

export class SiteErrorPageComponent {

}