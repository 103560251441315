import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grdFilter'
})
export class GrdFilterPipe implements PipeTransform {
  transform(items: any[], searchText: { [key: string]: any }, defaultFilter: boolean = true): any {
    if (!searchText){
      return items;
    }

    if (!Array.isArray(items)){
      return items;
    }

    if (searchText && Array.isArray(items)) {
      let filterKeys = Object.keys(searchText);

      if (defaultFilter) {
        return items.filter(item =>
            filterKeys.reduce((x, keyName) =>
                (x && new RegExp(searchText[keyName], 'gi').test(item[keyName])) || searchText[keyName] == "", true));    
      }
      else {
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            return (/[.*+\-?^${}()|[\]\\]/g.test(searchText[keyName]))?new RegExp(searchText[keyName].replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'gi',).test(item[keyName]):new RegExp(searchText[keyName], 'gi').test(item[keyName]) || searchText[keyName] == "";
          });
        });
      }
    }
  }
}