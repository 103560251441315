import { Directive, Input, Self } from '@angular/core';
import { Validator, ValidatorFn, UntypedFormControl, AbstractControl, NgControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[validPastDate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ValidatePastDateDirective, multi: true }]
})
export class ValidatePastDateDirective implements Validator {

    private validatorFunc: ValidatorFn;

    constructor() {

        this.validatorFunc = dateValidator();
    }

    validate(control: UntypedFormControl) {
        return this.validatorFunc(control);
    }
}

export function dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const date = new Date(control.value);

        const todaysDate = new Date();

        if (date > todaysDate) {
            control.setErrors({ invalid: true });
            return { futureDate: true };
        }
        return null;

    };
}
