import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { fhlbConstants } from '@app-shared/constants/fhlb-constants';
import { AppConfigService } from '@app-shared/services';
import { UtilHelper } from '@app-shared/helpers/util-helper';
import { Store } from '@ngrx/store';
import { AppState } from 'src/store/reducers';
import { getAccessTokenFromStore, getExpiryDateFromStore, getHLBFromStore, getIsFhlbBankUserFromStore } from 'src/store/selectors';
import { map, take } from 'rxjs/operators';
import { RefreshTokensAction } from 'src/store/actions/auth.actions';
import { EncryptedFhlBankUserModel } from '@empf-shared/services/treasury/models/EncryptedFhlBankUserModel';
import { isNullOrUndefined } from 'is-what';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private store: Store<AppState>) { }

    sleep(ms: number) {
        let currentTime = new Date();
        let expireTime = new Date();
        expireTime.setMilliseconds(expireTime.getMilliseconds() + ms);
        console.log("Sleeping until " + expireTime + ". Current time: " + currentTime);
        do {
            currentTime = new Date();
        }
        while (expireTime > currentTime);
        console.log("Sleep done: " + currentTime);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let awsEncodedIdToken: string;
        let hlbInfoToken: EncryptedFhlBankUserModel;
        let isFhlbBankcUser: boolean;
        let extApi: boolean;
        let b2cEncodedAccessToken: string;
        let b2cTokenExpireDateTime: Date;
        this.store.select(getAccessTokenFromStore).pipe(take(1), map(x => b2cEncodedAccessToken = x)).subscribe();
        this.store.select(getExpiryDateFromStore).pipe(take(1), map(x => b2cTokenExpireDateTime = x)).subscribe();
        this.store.select(getHLBFromStore).pipe(take(1), map(x => hlbInfoToken = x)).subscribe();
        this.store.select(getIsFhlbBankUserFromStore).pipe(take(1), map(x => isFhlbBankcUser = x)).subscribe();


        //Check for URL path
        if (request.url.includes("/ext/") || request.url.includes("/report/")) {
            extApi = true;
        }

        //AWS API Gateway, Web Socket, or Report API
        if (UtilHelper.isDefinedOrNotNull(AppConfigService.Settings)) {
            if(request.url.includes(AppConfigService.Settings.Mpf.ApiEndpoint) 
                || request.url.includes(AppConfigService.Settings.Mpf.WebSocket) 
                || request.url.includes(AppConfigService.Settings.Mpf.ReportAPIEndpoint)) {

                //Check B2C Access token expiration time / Refresh tokens if exp claim is in the past
                let dateNow = new Date();
                if (dateNow > b2cTokenExpireDateTime)
                {
                    this.store.dispatch(new RefreshTokensAction({ updateEmpfSessionTime: false, navigateToHome: null, loginFlow: null }));
                    this.sleep(1000);
                    this.store.select(getAccessTokenFromStore).pipe(take(1), map(x => b2cEncodedAccessToken = x)).subscribe();
                }
                request = request.clone({
                    setHeaders: {
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        Authorization: `Bearer ${b2cEncodedAccessToken}`
                    }
                });

                if (extApi && isFhlbBankcUser && hlbInfoToken) {
                    request = request.clone({
                        setHeaders: {
                            mpfbankkey: hlbInfoToken.mpfBankKey,
                            mpfbankname: hlbInfoToken.mpfBankName
                        }
                    });
                }
                //console.log("Modified HTTP Request: ", request);
            } 
        }
        return next.handle(request);
    }
}

