import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[real-time-currency-noDecimal]'
})
export class RealTimeCurrencyNoDecimalDirective implements OnInit {
    private ngControl: NgControl;

    constructor(public el: ElementRef, public renderer: Renderer2, private currencyPipe: CurrencyPipe, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
    }

    ngOnInit() {
        setTimeout(() => {
            this.format(this.el.nativeElement.value);
        }, 1000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
            this.format(e);
    }

    format(val: string) {
        if(val!=''){
            const replacedVal = val.replace(/[$ ,]/g, '');
            const numberFormat = parseFloat(replacedVal);
            const usd = isNaN(numberFormat) ? val : this.currencyPipe.transform(numberFormat, 'USD', 'symbol', '1.0-0');
    
            if (isNaN(+replacedVal)) {
                this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                this.ngControl.control.setErrors({ pattern: true });
    
                this.renderer.setProperty(this.el.nativeElement, 'value', val);
            } else {
                this.renderer.setProperty(this.el.nativeElement, 'value', usd);
                isNaN(numberFormat) ?  this.ngControl.viewToModelUpdate(0) : this.ngControl.viewToModelUpdate(numberFormat);
                this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
            }
        }
    }

}
