import { Component, Input, OnInit } from '@angular/core';
import { BlockTemplateComponent } from '@app-shared/components/block-ui-template.component';
import { LoanFundingService } from '@empf-shared/services/loan-funding.service';
import { IExistingLoanFundingModel } from '@empf-shared/services/loan-funding/models/ExistingLoanFundingModel';
import { INewLoanFundingModel } from '@empf-shared/services/loan-funding/models/NewLoanFundingModel';
import { IContact } from '@empf-shared/services/loan-funding/models/ScheduleModel';
import { EmailFeatureType, ISendEmailToRequestModel } from '@empf-shared/services/loan-funding/models/SendEmailToRequestModel';
import { DeliveryCommitmentService } from '@empf-shared/services/transaction/delivery-commitment-service';
import { IDeliveryCommitmentMainModel } from '@empf-shared/services/transaction/delivery-commitment/IDeliveryCommitmentMainModel';
import { LoanPresentmentService } from '@empf-shared/services/transaction/loan-presentment-service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CustomToastService } from 'src/shared/services';
@Component({
  selector: 'app-user-email-contacts-container',
  templateUrl: './user-email-contacts.container.component.html'
})
export class UserEmailContactsContainerComponent implements OnInit {
  @Input() cmsModel: any;
  blockTemplate = BlockTemplateComponent;
  @BlockUI() blockUI: NgBlockUI;
  @Input() title: string;
  @Input() componentName: string;
  @Input() contacts: IContact[];
  @Input() sendNewDcEmail: boolean;
  @Input() dcMainModel: IDeliveryCommitmentMainModel;
  validationErrors: Array<string>;
  taskSuccess = false;
  isEmailSuccess = false;
  showEmailPopUpModal: boolean = null;
  sendEmailToRequestModel: ISendEmailToRequestModel;
  @Input() existingLoanFundingModel: IExistingLoanFundingModel;
  @Input() newLoanFundingModel: INewLoanFundingModel;
  @BlockUI('contactMailingList') blockUIContactMailingList: NgBlockUI; 

  constructor(private toastr: CustomToastService, private loanFundingService: LoanFundingService,
    private dcService: DeliveryCommitmentService, private loanPresentmentService: LoanPresentmentService) { }

  ngOnInit() {
    this.validationErrors = [];
  }
  sendEmail(selectedEmailList: Array<string>) {
    this.taskSuccess = false;
    this.toastr.remove();
    this.validationErrors = [];
    if (selectedEmailList.length > 0) {
      if (this.componentName == 'Loan Presentment Retrieve Reports') {
        this.sendEmailToRequestModel = {
          toAddresses: selectedEmailList,
          featureType: EmailFeatureType.LPRCreateConfirmation
        }
        this.blockUIContactMailingList.start();
        this.loanPresentmentService.sendEmail(this.sendEmailToRequestModel).subscribe(() => {
          this.taskSuccess = true;
          this.isEmailSuccess = true;
          this.showEmailPopUpModal = !this.showEmailPopUpModal;
          this.blockUIContactMailingList.stop();
        }, (errorResponse) => {
          this.blockUIContactMailingList.stop();
          console.log(errorResponse);
          this.isEmailSuccess = false;
          this.taskSuccess = true;
          this.cmsModel.FailuerEmail = 'Your email could not be sent at this time. Please try again later.';
          this.showEmailPopUpModal = !this.showEmailPopUpModal;
        });
      }
      else {
        if (this.componentName == 'Loan Funding Summary') {
          this.sendEmailToRequestModel = {
            toAddresses: selectedEmailList,
            featureType: EmailFeatureType.LFCreateConfirmation,
            properties: [
              {
                description: 'PfiLoanNumber',
                value: this.existingLoanFundingModel.loanFundingRequestModel.loanFundingSummaryModel.loanFunding.pfiLoanNumber
              }]
          };
        }
        else if (this.componentName == 'Loan Funding Confirmation') {
          this.sendEmailToRequestModel = {
            toAddresses: selectedEmailList,
            featureType: EmailFeatureType.LFCreateConfirmation,
            properties: [
              {
                description: 'PfiLoanNumber',
                value: this.newLoanFundingModel.loanFundingRequestModel.pfiLoanNumber
              }]
          };
        }
        this.blockUIContactMailingList.start();
        this.loanFundingService.sendEmail(this.sendEmailToRequestModel).subscribe(() => {
          this.taskSuccess = true;
          this.isEmailSuccess = true;
          this.showEmailPopUpModal = !this.showEmailPopUpModal;
          this.blockUIContactMailingList.stop();
        }, (errorResponse) => {
          this.blockUIContactMailingList.stop();
          console.log(errorResponse);
          this.isEmailSuccess = false;
          this.taskSuccess = true;
          this.cmsModel.FailuerEmail = 'Your email could not be sent at this time. Please try again later.';
          this.showEmailPopUpModal = !this.showEmailPopUpModal;
        });
      }
    } else {
      this.validationErrors.push('Please select contact/contacts to send email');
      setTimeout(() => {
        this.toastr.show(this.validationErrors);        
      }, 400);
      this.taskSuccess = true;
      return;
    }
  }

  sendDCEmail(selectedEmailList: Array<string>) {
    this.taskSuccess = false;

    if (selectedEmailList.length > 0) {
      this.sendEmailToRequestModel = {
        toAddresses: selectedEmailList,
        isXtraDC: this.dcMainModel.isMpfXtra,
      };
      if (this.sendNewDcEmail) {
        this.sendEmailToRequestModel.featureType = EmailFeatureType.DCCreateConfirmation;
        this.sendEmailToRequestModel.properties = [
          {
            description: 'DCNumber',
            value: this.dcMainModel.dcDetails.dcNumber.toString()
          }];

      } else {
        this.sendEmailToRequestModel.featureType = EmailFeatureType.DCRequestedMpfInformation;
        this.sendEmailToRequestModel.properties = [
          {
            description: 'ReportName',
            value: this.dcMainModel.generatedReportName
          }];
      }
      this.dcService.sendDCEmail(this.sendEmailToRequestModel).subscribe(() => {
        this.taskSuccess = true;
        this.isEmailSuccess = true;
        this.showEmailPopUpModal = !this.showEmailPopUpModal;
      }, (errorResponse) => {
        this.isEmailSuccess = false;
        if (this.sendNewDcEmail) {
          this.sendNewDcEmail = false;
        }
        this.taskSuccess = true;
        this.cmsModel.FailuerEmail = 'Your email could not be sent at this time. Please try again later.';
        this.showEmailPopUpModal = !this.showEmailPopUpModal;
      });
    } else {
      this.blockUI.stop();
      this.validationErrors.push('Please select contact/contacts to send email');
      setTimeout(() => {
        this.toastr.show(this.validationErrors);        
      }, 400);
      this.taskSuccess = true;
      return;
    }
    this.blockUI.stop();
  }
}
