import { Component, OnInit, Input, ViewChild, SimpleChanges, Output, EventEmitter, } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'enable-override-toast-component',
  templateUrl: './enable-override.component.html',
  styleUrls: ['./enable-override.component.sass']

})

export class EnableOverrideComponent implements OnInit {
  @Input() isCollapsed: boolean = true;
  @Output() onOverrideChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('enableoverride', { static: false }) enableoverride: NgForm;
  @Output() enableoverrideButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {

  }


  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {

  }
  EnableoverrideButton(){
    this.enableoverrideButtonEvent.emit(true);
  }


  close(){
    this.onOverrideChangeEvent.emit(true);
  }

}

