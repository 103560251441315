import { Output } from '@angular/core';
import { EventEmitter, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'inline-error-message',
  templateUrl: './inline-error-message.component.html',
  styleUrls: ['./inline-error-message.component.sass']
})
export class InlineErrorMessageComponent implements OnInit {

  @Input() show: boolean;
  @Input() message: string;
  @Input() title: string;
  @Output() closeClick: EventEmitter<string> = new EventEmitter<string>(); 
  accordionStatus = true;

  constructor() { }

  ngOnInit() {
  }

  close()
  {
    this.closeClick.emit();
  }

}
