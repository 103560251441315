import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfigService } from '../configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  private REPORTAPI_PATH = `${AppConfigService.Settings.Mpf.ReportAPIEndpoint}`;
  private API_SERVICE_EXT_PATH = `${this.REPORTAPI_PATH}/api/report`;

  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true };

  private MICROSERVICE_DeliveryCommitment_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_DeliveryCommitment_PATH = `${this.MICROSERVICE_DeliveryCommitment_PATH}/api/deliverycommitment`; 
  private API_DeliveryCommitment_SERVICE_EXT_PATH = `${this.BUILT_API_DeliveryCommitment_PATH}/deliverycommitment`;

  constructor(private http: HttpClient) { }

  downloadOrgAuditLog(OrgId: string): Promise<any> {
    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadOrgAuditReport'.toLowerCase()}`, { params: new HttpParams().set('OrgId', OrgId), observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }
  //TODO-Need to modify once API is  ready
  downloadMcAuditLog(McID: number): Promise<any> {
    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadMcAuditReport'.toLowerCase()}`, { params: new HttpParams().set('McID', McID.toString()), observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }
  //TODO-Need to modify once API is  ready
  downloadDcAuditLog(DcID: number): Promise<any> {
    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadDcAuditReport'.toLowerCase()}`, { params: new HttpParams().set('DcID', DcID.toString()), observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }
  //TODO-Need to modify once API is  ready
  downloadprintDcReport(DcID: number): Promise<any> {
    return this.http.get(`${this.API_DeliveryCommitment_SERVICE_EXT_PATH}/${'printDcAuditReport'.toLowerCase()}`, { params: new HttpParams().set('DcID', DcID.toString()), observe: 'response', responseType: 'arraybuffer' }).toPromise()

  }

  //CEEstimator Report
  downloadCEEstimatorReport(ceEstimatorId: number): Promise<any> {
    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadCEEstimatorReport'.toLowerCase()}`, { params: new HttpParams().set('ceEstimatorId',ceEstimatorId.toString()), observe: 'response', responseType: 'arraybuffer' }).toPromise()

  }
  downloadMpfSystemParametersAuditLog(): Promise<any> {
    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'DownloadMPFSystemParametersAuditReport'.toLowerCase()}`, { observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }

  downloadRulesetsAuditLog(RulesetId: string): Promise<any> {

    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadRulesetAuditReport'.toLowerCase()}`, { params: new HttpParams().set('RulesetId', RulesetId), observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }

  downloadSpecialFeatureCodeAuditLog(): Promise<any> {
    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadSpecialFeatureCodeAuditReport'.toLowerCase()}`, { observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }

  downloadEditAusCodeAuditLog(): Promise<any> {
    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadEditAusCodeAuditReport'.toLowerCase()}`, { observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }

  downloadDcExtensionReport(DCId: string, DCNumber: string, ConfirmationType: string): Promise<any> {
    const params = new HttpParams().set('DCId', DCId)
      .set('DCNumber', DCNumber)
      .set('ConfirmationType', ConfirmationType);

    return this.http.get(`${this.API_DeliveryCommitment_SERVICE_EXT_PATH}/${'downloadDcExtensionReport'.toLowerCase()}`, { params, observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }


  downloadDcReductionReport(req: {
    DCId?: string;
    DCNumber?: string;
    ConfirmationType?: string;
  }): Promise<any> {
    const params = new HttpParams().set('DCId', req.DCId)
      .set('DCNumber', req.DCNumber)
      .set('ConfirmationType', req.ConfirmationType);

    return this.http.get(`${this.API_DeliveryCommitment_SERVICE_EXT_PATH}/${'downloadDcReductionReport'.toLowerCase()}`, { params, observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }

  downloadLPRAuditReport(pfiNumber: string, pfiLoanNumber: string) {
    const params = new HttpParams().set('pfiNumber', pfiNumber)
      .set('pfiLoanNumber', pfiLoanNumber);

    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadLPRAuditReport'.toLowerCase()}`, { params, observe: 'response', responseType: 'arraybuffer' }).toPromise();

  }

  printLPConfirmationReport(pfiNumber: string, pfiLoanNumber: string) {
    const params = new HttpParams().set('pfiNumber', pfiNumber)
      .set('pfiLoanNumber', pfiLoanNumber);

    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'printLPConfirmationReport'.toLowerCase()}`, { params, observe: 'response', responseType: 'arraybuffer' }).toPromise();

  }

  printLFConfirmationReport(pfiNumber: string, pfiLoanNumber: string,fundingTransactionNumber:string,confirmationType:string) : Promise<any> {
    const params = new HttpParams().set('pfiNumber', pfiNumber)
      .set('pfiLoanNumber', pfiLoanNumber)
      .set('fundingTransactionNumber', fundingTransactionNumber)
      .set('confirmationType', confirmationType);

    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'printLFConfirmationReport'.toLowerCase()}`, { params, observe: 'response', responseType: 'arraybuffer' }).toPromise();

  }

  downloadBatchViewAuditReport(batchId?: string): Promise<any> {
    const params = new HttpParams().set('batchId', batchId);     

    return this.http.get(`${this.API_SERVICE_EXT_PATH}/${'downloadBatchViewAuditReport'.toLowerCase()}`, { params, observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }

  generateDCMailReport(DCId: string, DCNumber: string): Promise<any> {
    const params = new HttpParams().set('DCId', DCId)
    .set('DCNumber', DCNumber);

    return this.http.get(`${this.API_DeliveryCommitment_SERVICE_EXT_PATH}/${'generateDCMailReport'.toLowerCase()}`, { params, observe: 'response', responseType: 'arraybuffer' }).toPromise()
  }

}
