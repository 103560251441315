import { WebsocketService } from "@app-shared/services/websocket/websocket.service";
import { Subject } from "rxjs";
import { map, take, tap } from 'rxjs/operators';
import { GenericWebsocketPingResponse } from "@app-shared/services/websocket/models/GenericWebsocketPingResponse";
import { isNullOrUndefined } from 'is-what';
import { GeographicInfoService } from "../geographic-info-service";
import { CallGeographicCentrusEODProcessApiResponse } from "../models/CallGeographicCentrusEODProcessApiResponse";
import { CallGeographicCentrusEODProcessRequest } from "../models/inner/CallGeographicCentrusEODProcessRequest";
import { Store } from "@ngrx/store";
import { AppState } from "src/store/reducers";

export class RunGeographicProcessWebSocket {
    private messages: Subject<CallGeographicCentrusEODProcessApiResponse>;
    public connectionDetails = new Subject<GenericWebsocketPingResponse>();
    public connection: GenericWebsocketPingResponse = null;
    private wsService: WebsocketService;

    constructor(private geographicInfoService: GeographicInfoService) {
        this.wsService = new WebsocketService();
    }

    connect(store: Store<AppState>) {
        this.messages = <Subject<CallGeographicCentrusEODProcessApiResponse>>this.wsService.connect(store).pipe(map(
            (response: MessageEvent) => {
                let jsonStringData = response.data;
                
                let rep = <GenericWebsocketPingResponse>JSON.parse(jsonStringData)

                if (!isNullOrUndefined(rep) && !isNullOrUndefined(rep.connectionId) && !isNullOrUndefined(rep.statusCode)) {
                    this.connection = <GenericWebsocketPingResponse>JSON.parse(jsonStringData);
                    
                    this.connectionDetails.next(this.connection);
                    return null
                }

                return <CallGeographicCentrusEODProcessApiResponse>JSON.parse(jsonStringData);
            }
        ));

        this.messages.pipe(tap(x => { })).subscribe()
    }

    sendRestRequest(req: CallGeographicCentrusEODProcessRequest, connection: GenericWebsocketPingResponse) {
        if(isNullOrUndefined(connection) || isNullOrUndefined(connection.connectionId))
            throw new ReferenceError("Websocket connection is not connected and in ready/open state")
            
        this.geographicInfoService.callGeographicCentrusEODProcess(connection.connectionId, req).pipe(take(1)).subscribe()
    }

    getMessages(): Subject<CallGeographicCentrusEODProcessApiResponse> {
        return this.messages;
    }

    close() {
        this.messages.complete();
        this.wsService.close();
    }
}
