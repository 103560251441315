import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import { AppConfigService } from '@app-shared/services';
import { GetLoansApiRequest } from './models/GetLoansApiRequest';
import { GetLoanbyCriteriaApiRequest } from './models/GetLoanbyCriteriaApiRequest';
import { GetLoanbyCriteriaApiResponse } from './models/GetLoanbyCriteriaApiResponse';
import { GetLoansApiResponse } from './models/GetLoansApiResponse';
import { GetPagedLoanListApiResponse } from './models/GetPagedLoanListApiResponse';
import { IGetPagedLoanRequestCriteria } from './models/IGetPagedLoanRequestCriteria';
import { GetLoanFundingInfoByLoanNumberApiRequest } from './models/GetLoanFundingInfoByLoanNumberRequest';
import { GetLoanFundingInfoByLoanNumberApiResponse, GetLoanFundingInfoByPFILoanNumberApiResponse } from './models/GetLoanFundingInfoByPFILoanNumberResponse';
import { IGetLoanApiRequest } from './models/GetLoanApiRequest';
import { IGetLoanApiResponse } from './models/GetLoanApiResponse';
import { IGetLoanFundingApiRequest } from './models/GetLoanFundingApiRequest';
import { IGetLoanFundingApiResponse } from './models/GetLoanFundingApiResponse';
import { IsLoanExistsApiRequest } from './models/IsLoanExistsApiRequest';
import { IsLoanExistsApiResponse } from './models/IsLoanExistsApiResponse';
import { UpdatePFILoanNumberApiRequest } from './models/UpdatePfiLoanNumberReq';
import { GetLoanFundingInfoByPFILoanNumberApiRequest } from './models/GetLoanFundingInfoByPFILoanNumberRequest';
import { GetCalculatedInterimInterestApiRequest } from './models/GetCalculatedInterimInterestApiRequest';
import { ValidateFieldsAndSendApiRequest } from './models/ValidateFieldsAndSendApiRequest';
import { value } from '../lookup/models/AUSCodesResponse';
import { ValidateFieldsAndSendApiResponse } from './models/ValidateFieldsAndSendApiResponse';
import { CalcAccruedInterestDaysApiRequest } from './models/CalcAccruedInterestDaysApiRequest';
import { CalcAccruedInterestDaysApiResponse } from './models/CalcAccruedInterestDaysApiResponse';
import { GetFundedFlagApiRequest } from './models/GetFundedFlagApiRequest';
import { GetFundedFlagApiResponse } from './models/GetFundedFlagApiResponse';
import { GetLoansByBatchIdPagedApiRequest } from '../batch/models/GetLoansByBatchIdPagedApiRequest';
import { GetLoansByBatchIdPagedApiResponse } from '../batch/models/GetLoansByBatchIdPagedApiResponse';
import { GetLLPAForFundingDateChangeApiResponse } from './models/GetLLPAForFundingDateChangeApiResponse';
import { GetLLPAForFundingDateChangeApiRequest } from './models/GetLLPAForFundingDateChangeApiRequest';
import { UpdateContactEmailAddressApiRequest } from './models/UpdateContactEmailAddressApiRequest';
import { ProcessLoanApiResponse } from './models/ProcessLoanApiResponse';
import { ProcessLoanApiRequest } from './models/ProcessLoanApiRequest';
import { GetTransactionCodeApiResponse } from './models/GetTransactionCodeApiResponse';
import { IGetTransactionCodeApiRequest } from './models/GetTransactionCodeApiRequest';
import { IGetCurrentInvestmentStatusofGNMAMBSLoanApiRequest } from './models/GetCurrentInvestmentStatusofGNMAMBSLoanApiRequest';
import { IGetCurrentInvestmentStatusofGNMAMBSLoanApiResponse } from './models/GetCurrentInvestmentStatusofGNMAMBSLoanApiResponse';
import { GetLoanFundingDateApiRequest} from './models/GetLoanFundingDateApiRequest';
import { GetLoanFundingDateApiResponse} from './models/GetLoanFundingDateApiResponse'; 
import { GetFnmaXtraMBSErrorsRequest } from './models/GetFnmaXtraMBSErrorsRequest';
import { GetFnmaXtraMBSErrorsResponse} from './models/GetFnmaXtraMBSErrorsResponse';
import { GetFNMALoanInfoApiRequest } from './models/GetFNMALoanInfoApiRequest';


@Injectable()
export class LoansService {
  private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH = `${this.API_PATH}/api/loan`;
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/loan`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private reqstr: string;
  constructor(private http: HttpClient) { }

  GetPagedLoanList(req: IGetPagedLoanRequestCriteria): Observable<GetPagedLoanListApiResponse> {
    return this.http.get<GetPagedLoanListApiResponse>(`${this.API_SERVICE_PATH}/${"GetPagedLoanList".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }
  getLoanbyCriteria(req: GetLoanbyCriteriaApiRequest): Observable<GetLoanbyCriteriaApiResponse> {
    return this.http.get<GetLoanbyCriteriaApiResponse>(`${this.API_SERVICE_PATH}/${"GetLoanbyCriteria".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetLoans(req: GetLoansApiRequest): Observable<GetLoansApiResponse> {
    return this.http.get<GetLoansApiResponse>(`${this.API_SERVICE_PATH}/${"GetLoans".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }
  GetLoan(req: IGetLoanApiRequest): Observable<IGetLoanApiResponse> {
    return this.http.get<IGetLoanApiResponse>(`${this.API_SERVICE_PATH}/${"GetLoan".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetLoanFunding(req: IGetLoanFundingApiRequest): Observable<IGetLoanFundingApiResponse> {
    return this.http.get<IGetLoanFundingApiResponse>(`${this.API_SERVICE_PATH}/${"GetLoanFunding".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  isLoanExists(req: IsLoanExistsApiRequest): Observable<IsLoanExistsApiResponse> {
    return this.http.get<IsLoanExistsApiResponse>(`${this.API_SERVICE_PATH}/${"IsLoanExists".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  updatePfiLoanNumber(req: UpdatePFILoanNumberApiRequest) {
    return this.http.post(`${this.API_SERVICE_PATH}/${"UpdatePFILoanNumber".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  //GetLoanFundingInfoByLoanNumber
  GetLoanFundingInfoByLoanNumber(req: GetLoanFundingInfoByLoanNumberApiRequest): Observable<GetLoanFundingInfoByLoanNumberApiResponse> {
    return this.http.get<GetLoanFundingInfoByLoanNumberApiResponse>(`${this.API_SERVICE_PATH}/${"GetLoanFundingInfoByLoanNumber".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  //GetLoanFundingInfoByPFILoanNumber
  GetLoanFundingInfoByPFILoanNumber(req: GetLoanFundingInfoByPFILoanNumberApiRequest): Observable<GetLoanFundingInfoByPFILoanNumberApiResponse> {
    return this.http.get<GetLoanFundingInfoByPFILoanNumberApiResponse>(`${this.API_SERVICE_PATH}/${"GetLoanFundingInfoByPFILoanNumber".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetTransactionCode(req: IGetTransactionCodeApiRequest): Observable<GetTransactionCodeApiResponse> {
    return this.http.get<GetTransactionCodeApiResponse>(`${this.API_SERVICE_PATH}/${"GetTransactionCode".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }
  //GetCalculatedInterimInterest
  GetCalculatedInterimInterest(req: GetCalculatedInterimInterestApiRequest): Observable<GetLoanFundingInfoByLoanNumberApiResponse> {
    return this.http.get<GetLoanFundingInfoByLoanNumberApiResponse>(`${this.API_SERVICE_PATH}/${"GetCalculatedInterimInterest".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  //ValidateFieldsAndSend
  ValidateFieldsAndSend(req: ValidateFieldsAndSendApiRequest): Observable<ValidateFieldsAndSendApiResponse> {
    return this.http.get<ValidateFieldsAndSendApiResponse>(`${this.API_SERVICE_PATH}/${"ValidateFieldsAndSend".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  //GetCalculatedInterimInterest
  CalcAccruedInterestDays(req: CalcAccruedInterestDaysApiRequest): Observable<CalcAccruedInterestDaysApiResponse> {
    return this.http.get<CalcAccruedInterestDaysApiResponse>(`${this.API_SERVICE_PATH}/${"CalcAccruedInterestDays".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetFundedFlag(req: GetFundedFlagApiRequest): Observable<GetFundedFlagApiResponse> {
    return this.http.get<GetFundedFlagApiResponse>(`${this.API_SERVICE_PATH}/${"GetFundedFlag".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetFnmaXtraMBSErrors(req: GetFnmaXtraMBSErrorsRequest): Observable<GetFnmaXtraMBSErrorsResponse> {
    return this.http.get<GetFnmaXtraMBSErrorsResponse>(`${this.API_SERVICE_PATH}/${"GetFnmaXtraMBSErrors".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
        .pipe(map(response => response));
}


  GetLoansByBatchIdPaged(req: GetLoansByBatchIdPagedApiRequest): Observable<GetLoansByBatchIdPagedApiResponse> {
    return this.http.get<GetLoansByBatchIdPagedApiResponse>(`${this.API_SERVICE_PATH}/${"GetLoansByBatchIdPaged".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
      .pipe(map(response => response));
    }
    GetLLPAForFundingDateChange(req: GetLLPAForFundingDateChangeApiRequest) {
        return this.http.post<GetLLPAForFundingDateChangeApiResponse>(`${this.API_SERVICE_PATH}/${'GetLLPAForFundingDateChange'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }

  ProcessLoan(req: ProcessLoanApiRequest): Observable<ProcessLoanApiResponse> {
    return this.http.post<ProcessLoanApiResponse>(`${this.API_SERVICE_PATH}/${"ProcessLoan".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
    }
    updateContacteMailAddress(req: UpdateContactEmailAddressApiRequest) {
        return this.http.post(`${this.API_SERVICE_PATH}/${"updatecontactemailaddress".toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }

    GetCurrentInvestmentStatusofGNMAMBSLoan(req: IGetCurrentInvestmentStatusofGNMAMBSLoanApiRequest): Observable<IGetCurrentInvestmentStatusofGNMAMBSLoanApiResponse> {
      return this.http.get<IGetCurrentInvestmentStatusofGNMAMBSLoanApiResponse>(`${this.API_SERVICE_PATH}/${"GetCurrentInvestmentStatusofGNMAMBSLoan".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
        .pipe(map(response => response));
    } 
    GetLoanFundingDate(req: GetLoanFundingDateApiRequest): Observable<GetLoanFundingDateApiResponse> {
      return this.http.get<GetLoanFundingDateApiResponse>(`${this.API_SERVICE_PATH}/${"GetLoanFundingDate".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
        .pipe(map(response => response));
    }
   
    DownloadFNMALoanInfo(req: GetFNMALoanInfoApiRequest): Promise<any> {
      return this.http.post(`${this.API_SERVICE_PATH}/${"DownloadFNMALoanInfo".toLowerCase()}`, req,
       { observe: 'response', responseType: 'arraybuffer' }).toPromise()
          
    }

    
  
}
