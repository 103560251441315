<div class="row">
  <div class="col-12">
    <div class="col-12 card-header">
      <div class="row">
        <div class="col-12  fontcolor">
          <label class="col-10  btn text-start fontcolor font-bold">System Alert</label>
          <button type="button" class="col-1 pe-4 btn text-end">
            <span id="minus" class="fa fa-minus fa-lg fontcolor" 
            [ngClass]="{ 'fa-minus': accordionStatus=== true, 'fa-plus': accordionStatus=== false }"
           data-bs-toggle="collapse" (click)="accordionStatus = !accordionStatus"></span>
          </button>
          <button type="button" class="col-1 pe-4 btn text-end btnccross" aria-label="Close"
                  (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true" class="fa fa-times fa-lg fontcolor"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="bordererror show" id="cancel-alert"  *ngIf= "accordionStatus">
  <div class="row">
    <label class="col ps-4 col-form-label message text-start">There are unsaved changes on this screen. Any changes made will be lost.</label>
  </div>

  <div class="row">
    <label class="col ps-4 col-form-label message text-start">
      Are you sure you want to cancel and leave the screen?
    </label>
  </div>
  <br />
<div class="row">
  <div class="col-12">
    <div class="form-group mb-3 row g-0 justify-content-left ps-3">
      <div class="col-4 justify-content-left">
        <button id="continue" type="submit" class="btn btncolor btn-primary fontcolor col-md-10"
                (click)="close()">
          <b>
            YES 
          </b>
        </button>
      </div>
      <div class="col-8 justify-content-left">
        <button type="submit" class="btn btn-light-blue  btn-primary" (click)="returnToScreen()">
          <b>
            RETURN TO SCREEN
          </b>
        </button>
      </div>
    </div>
  </div>
</div>
</div>
