import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'displayCurrency'
})
export class DisplayCurrencyPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {
       
    }
    transform(value: any, args?: any): any {
        if (value == null) {
            return null;
        } else if (value < 0) {
            let number=this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
            return '(' + number.toString().replace('-', '') + ')';
             
        }
        else {
            return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
        }
    }
}