import { PlatformLocation } from '@angular/common';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'warning-alert',
    templateUrl: './warning-alert.component.html',
    styleUrls: ['./warning-alert.component.sass']
  
  })
  
  export class WarningAlertComponent implements OnInit {
  
  message: string;
  showCancel: boolean = false;
  accordionStatus = true;
 

  constructor(private router :Router, public activeModal: NgbActiveModal,private location: PlatformLocation) { }

  ngOnInit() {
  }

  ok() {

    this.activeModal.close('ok');
    }

  cancel(){
    this.activeModal.close('CANCEL');
  }
 
}

