import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import { AppConfigService } from '@app-shared/services';
import { IGetSchedulesForDCApiRequest, IGetSchedulesForXmbsBEDCRepriceApiRequest } from './models/GetSchedulesForDCApiRequest';
import { IGetSchedulesForDCApiResponse } from './models/GetSchedulesForDCApiResponse';
import { GetTsfAdjustmentFactorsResponse } from './models/GetTsfAdjustmentFactorsResponse.Model';
import { CreateScheduleApiRequest } from './models/CreateScheduleApiRequest';
import { CreateScheduleApiResponse } from './models/CreateScheduleApiResponse';
import { GetScheduleStatusApiRequest } from './models/GetScheduleStatusApiRequest';
import { IGetScheduleStatusApiResponse } from './models/GetScheduleStatusApiResponse';

@Injectable()
export class ScheduleService {
  private API_PATH =  `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH = `${this.API_PATH}/api/schedule`;
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/schedule`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private reqstr: string;
  constructor(private http: HttpClient) { }

  GetSchedulesForDC(req: IGetSchedulesForDCApiRequest): Observable<IGetSchedulesForDCApiResponse> {
    return this.http.get<IGetSchedulesForDCApiResponse>(`${this.API_SERVICE_PATH}/${"GetSchedulesForDC".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetScheduleForXmbsBEDCReprice(req: IGetSchedulesForXmbsBEDCRepriceApiRequest): Observable<IGetSchedulesForDCApiResponse> {
    return this.http.get<IGetSchedulesForDCApiResponse>(`${this.API_SERVICE_PATH}/${"GetScheduleForXmbsBEDCReprice".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetTsfAdjustmentFactors(): Observable<GetTsfAdjustmentFactorsResponse> {
    return this.http.get<GetTsfAdjustmentFactorsResponse>(`${this.API_SERVICE_PATH}/${"GetTsfAdjustmentFactors".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  createSchedule(req: CreateScheduleApiRequest): Observable<CreateScheduleApiResponse> {
    return this.http.post<CreateScheduleApiResponse>(`${this.API_SERVICE_PATH}/${"CreateSchedule".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  getScheduleStatus(req: GetScheduleStatusApiRequest): Observable<IGetScheduleStatusApiResponse> {
    return this.http.get<IGetScheduleStatusApiResponse>(`${this.API_SERVICE_PATH}/${"getScheduleStatus".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

}
