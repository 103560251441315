import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[toDecimal]'
})
export class ToDecimalDirective implements OnInit {
    private ngControl: NgControl;
    @Input('toDecimal') decimalplace: string;   
    inputvalue: string;
    

    constructor(public el: ElementRef, public renderer: Renderer2, private decimalPipe: DecimalPipe, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
    }

    ngOnInit() {
        setTimeout(() => {
        this.format(this.el.nativeElement.value);
        }, 3000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    format(val: string) {
        const replacedVal = val.replace(/[$ ,]/g, '');
        const numberFormat = parseFloat(replacedVal);
       
        const decimalval = isNaN(numberFormat) ? val : this.decimalPipe.transform(numberFormat, this.decimalplace);

        if (isNaN(+replacedVal)) {
            this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
            this.ngControl.control.setErrors({ pattern: true });

            this.renderer.setProperty(this.el.nativeElement, 'value', val);
        } else {
            this.renderer.setProperty(this.el.nativeElement, 'value', decimalval);
            
        }
    }

}
