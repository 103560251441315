import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[negativeDecimalsOnly]'
})
export class NegativeDecimalsOnlyDirective {
  private el: NgControl;
  @Input('negativeDecimalsOnly') mandatory: any;
  constructor(private ngControl: NgControl) {
    this.el = ngControl;
  }

  @HostListener('blur', ['$event.target.value']) onInputChange(e: string) {
    if (this.mandatory) {
      if (e !== "") {
        e = e.replace(/[^0-9.]/g, '');
        e = "-" + e;
        this.el.control.patchValue(e);
      }
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Use NgControl patchValue to prevent the issue on validation
    let val = value.replace(/[^0-9.-]/g, '');
    this.el.control.patchValue(val);
  }

}