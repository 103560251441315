import { CommonModule } from "@angular/common";
import { NO_ERRORS_SCHEMA } from "@angular/core";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app-shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreatemergerModalComponent } from "./components/createmerger-modal/createmerger-modal-component";
import { InteractiveHeaderComponent } from "./components/interactive-header/interactive-header.component";
import { MersMinValueValidateDirective } from './directives/mers-min-validator.directive';
import { OrgRepWarrantValidateDirective } from "./directives/orgRep-warrant-validator.directive";
import { UniversalLoanIdentifierValueValidate } from './directives/universal-loan-identifier-validator.directive';
import { ViewDCListComponent } from './components/search/view-dc/components/view-dc-list/view-dc-list.component';
import { ViewMclistComponent } from './components/search/search-mc-details/search-mc-details.component';
import { PFIMergerService } from './services/organization/pfimerger-service'
import { OrganizationService } from './services/organization/organization-service'
import { OrgProductService } from './services/organization/org-product-service'
import { LookupService } from './services/organization/lookup-service'
import { ContactService } from './services/organization/contact-service'
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AdministrationService } from "./services/administration/administration-service";
import { DailyProcessService } from "./services/daily-process/daily-process-service";
import { DeliveryCommitmentService } from "./services/delivery-commitment/delivery-commitment-service";
import { LoanExportService } from "./services/loan-export/loan-export-service";
import { LoansService } from "./services/loans/loans-service";
import { LookupLosService } from "./services/lookup/lookuplos-service";
import { MasterCommitmentService } from "./services/master-commitment/master-commitment-service";
import { RulesService } from "./services/rules-rulesets/rules-service";
import { ScheduleService } from "./services/schedule/schedule.service";
import { PfiLoanListComponent } from "./components/search/search-lpr-loan-list/search-lpr-loan-list.component";
import { BatchService } from "./services/batch/batch.service";
import { CEEstimatorService } from "./services/ce-estimators/ce-estimator-service";
import { GeographicInfoService } from "./services/geographic-info/geographic-info-service";
import { UploadService } from "./services/upload/upload.service";
import { AuthEffects } from "src/store/effects/auth.effects";
import { AuthInterceptor } from "@app-shared/interceptors/auth-interceptor";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgbModule
    ],
    exports: [
        InteractiveHeaderComponent,
        CreatemergerModalComponent,
        OrgRepWarrantValidateDirective,
        ViewDCListComponent,
        ViewMclistComponent,
        PfiLoanListComponent
    ],
    declarations: [
        InteractiveHeaderComponent,
        CreatemergerModalComponent,
        MersMinValueValidateDirective,
        UniversalLoanIdentifierValueValidate,
        OrgRepWarrantValidateDirective,
        ViewDCListComponent,
        ViewMclistComponent,
        PfiLoanListComponent
    ],
    providers: [
        PFIMergerService,
        OrganizationService,
        OrgProductService,
        LookupService,
        ContactService,
        AdministrationService,
        DailyProcessService,
        DeliveryCommitmentService,
        LoanExportService,
        LoansService,
        LookupLosService,
        MasterCommitmentService,
        RulesService,
        CEEstimatorService,
        ScheduleService,
        BatchService,
        UploadService,
        GeographicInfoService,
        AuthEffects,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class OriginationSharedModule { }
