import { Pipe, PipeTransform } from '@angular/core';
import { NumberOnlyDirective } from '@app-shared/directives/allow-numbers.directive';

@Pipe({
  name: 'addSpaceToText'
})
export class AddSpaceToTextPipe implements PipeTransform {
  transform(value: string, args: number) : string { 
  
    let limit = args;

    if (value != '') {     
      
      return value.substring(0,limit) +' '+value.substring(limit);
    
  }
}
}