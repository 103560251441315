import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '@app-shared/services';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import {
  GetChildOrgApiRequest,
  GetChildOrgApiResponse,
  GetOrgDetailApiRequest,
  GetOrgDetailApiResponse,
  SaveMergerApiRequest,
  SaveMergerApiResponse,
  UpdateMergerApiRequest,
  GetMergerforPfiApiRequest,
  GetMergerforPfiApiReponse,
  GetChildOrgFromMergerKeyApiRequest,
  GetChildOrgFromMergerKeyApiResponse,
  GetMergerDetailsApiRequest,
  GetMergerDetailsApiResponse,
  GetLatestMergerKeyApiRequest,
  GetLatestMergerKeyApiResponse,
  RollbackMergerApiRequest,
  GetPfIforMergerApiRequest,
  GetPFIforMergerApiResponse,
  GetSearchMergerApiRequest,
  GetSearchMergerApiReponse,
} from './models';
import { UpdateMergerDetailsApiResponse } from './models/UpdateMergerDetailsApiResponse';

@Injectable()
export class PFIMergerService {
  private API_PATH =`${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH = `${this.API_PATH}/api/organization`;
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/pfimerger`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }


  getChildOrg(req: GetChildOrgApiRequest): Observable<GetChildOrgApiResponse> {
    return this.http.get<GetChildOrgApiResponse>(`${this.API_SERVICE_PATH}/${"GetChildOrg".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getOrgDetail(req: GetOrgDetailApiRequest): Observable<GetOrgDetailApiResponse> {
    return this.http.get<GetOrgDetailApiResponse>(`${this.API_SERVICE_PATH}/${"GetOrgDetail".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  saveMerger(req: SaveMergerApiRequest): Observable<SaveMergerApiResponse> {
    return this.http.post<SaveMergerApiResponse>(`${this.API_SERVICE_PATH}/${"SaveMerger".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  updateMergerDetails(req: UpdateMergerApiRequest): Observable<UpdateMergerDetailsApiResponse> {
    return this.http.put<UpdateMergerDetailsApiResponse>(`${this.API_SERVICE_PATH}/${"UpdateMergerDetails".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  getMergerforPfi(req: GetMergerforPfiApiRequest): Observable<GetMergerforPfiApiReponse> {
    return this.http.get<GetMergerforPfiApiReponse>(`${this.API_SERVICE_PATH}/${"GetMergerforPfi".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }
  GetSearchMerger(req: GetSearchMergerApiRequest): Observable<GetSearchMergerApiReponse> {
    return this.http.get<GetSearchMergerApiReponse>(`${this.API_SERVICE_PATH}/${"GetSearchMerger".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getChildOrgFromMergerKey(req: GetChildOrgFromMergerKeyApiRequest): Observable<GetChildOrgFromMergerKeyApiResponse> {
    return this.http.get<GetChildOrgFromMergerKeyApiResponse>(`${this.API_SERVICE_PATH}/${"GetChildOrgFromMergerKey".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getMergerDetails(req: GetMergerDetailsApiRequest): Observable<GetMergerDetailsApiResponse> {
    return this.http.get<GetMergerDetailsApiResponse>(`${this.API_SERVICE_PATH}/${"GetMergerDetails".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getLatestMergerKey(req: GetLatestMergerKeyApiRequest): Observable<GetLatestMergerKeyApiResponse> {
    return this.http.get<GetLatestMergerKeyApiResponse>(`${this.API_SERVICE_PATH}/${"GetLatestMergerKey".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  rollbackMerger(req: RollbackMergerApiRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_SERVICE_PATH}/${"RollbackMerger".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  getPFIforMerger(req: GetPfIforMergerApiRequest): Observable<GetPFIforMergerApiResponse> {
    return this.http.get<GetPFIforMergerApiResponse>(`${this.API_SERVICE_PATH}/${"GetPFIforMerger".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }
}
