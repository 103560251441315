import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';  
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-set-page-titles',
  templateUrl: './set-page-titles.component.html'
})
export class SetPageTitlesComponent implements OnInit {

  constructor(public router: Router, private titleService: Title,  
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.router.events.pipe(  
      filter(event => event instanceof NavigationEnd),  
    ).subscribe(() => {  
      const rt = this.getChild(this.activatedRoute);  
      rt.data.subscribe(data => {
         let dashStr = ' - '; 
         if(!data.title){
           data.title = '';
           dashStr = '';
         }
        this.titleService.setTitle(data.title + dashStr + "EMPF Application");
        
        });  
      
    }); 
  }

  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  } 

}
