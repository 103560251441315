import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild, Input } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[toPercentUptoThreeDecimal]'
})
export class ToPercentUptoThreeDecimalsDirective implements OnInit {
    private ngControl: NgControl;
    @Input('positiveVariance') positiveVariance: string;
    @Input('negativeVariance') negativeVariance: string;
    percentagePattern = /^\d{1,6}(?:[.,]\d{1,5})?$/;
    percentagePatternNegative = /^-?\d{1,6}(?:[.,]\d{1,5})?$/;

    constructor(public el: ElementRef, public renderer: Renderer2, private percentPipe: PercentPipe, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
    }

    ngOnInit() {
        setTimeout(() => {
            this.format(this.el.nativeElement.value);
        }, 2000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        if (e !== "") {
            this.format(e);
        }
    }

    @HostListener('focus', ['$event.target.value']) onInputFocus(e: string) {
        if (e !== "") {            
            this.formatOnFocus(e);            
        }
    }

    format(val: string) {
        if (val.toString().length > 0) {
        if (this.positiveVariance == 'true') {
            if (!this.percentagePattern.test(val)) {
                this.ngControl.control.setErrors({ invalidPattern: true });
                return false;
            }
        }
        if (this.negativeVariance == 'true') {
            if (!this.percentagePatternNegative.test(val)) {
                this.ngControl.control.setErrors({ invalidPattern: true });
                return false;
            }
        }
    }
        this.ngControl.control.setErrors(null);
        const replacedVal = val.replace(/[% ,]/g, '');
        let numberFormat = Number(replacedVal);
        let finalVal = this.preFormat(numberFormat)

        finalVal = finalVal / 100;
        const percentFormatVal = isNaN(finalVal) ? val : this.percentPipe.transform(finalVal, '1.3');

        if (numberFormat > 1000000000 || isNaN(+replacedVal)) {
            this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
            this.ngControl.control.setErrors({ pattern: true });
            this.renderer.setProperty(this.el.nativeElement, 'value', finalVal);
        } else {
            this.renderer.setProperty(this.el.nativeElement, 'value', percentFormatVal);
            isNaN(numberFormat) ? this.ngControl.viewToModelUpdate(0) : this.ngControl.viewToModelUpdate(numberFormat);
            this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
        }    

    }


    preFormat(value) {
        var finalValue;
        if (value.toString().length > 0) {
            finalValue = value;
            if (finalValue < 1.00 && Math.abs(finalValue) < 1.00) {
                finalValue = parseFloat(finalValue) * 100;
            }
            if (finalValue.toString().indexOf(".") > 3) {
                finalValue = parseFloat(value.toString());
            }
            if (parseFloat(finalValue) < 0) {
                finalValue = finalValue.toFixed(3)
            } else {
                finalValue = (parseFloat(finalValue)).toFixed(3)
            }
        }
        return finalValue;
    }


    formatOnFocus(value) {
        var finalValue;       
        var textBox = value.replace(/[% ,]/g, '');;
        if (textBox.length > 0) {
            finalValue = parseFloat(textBox) / 100;
            value = finalValue.toFixed(5);
            this.renderer.setProperty(this.el.nativeElement, 'value', value);
        }
    }
}
