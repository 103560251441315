import { All, SearchActionTypes, SearchHeaderOptions } from '../actions/interactive-search.actions';

export interface State {
  key?: SearchHeaderOptions;
  criteria?: string;
  clicked?: boolean;
}

export const initialState: State = {
  key: null,
  criteria: null,
  clicked: null
};

export function reducer(state = initialState, action: All): State {
  switch (action.type) {
    case SearchActionTypes.CLEAR_SEARCH: {
      console.log("Clear search state")
      console.log(initialState)
      return initialState
    }
    case SearchActionTypes.SET_SEARCH: {
      let result = <{ key: SearchHeaderOptions, criteria: string }>action.payload

      return {
        ...state,
        key: result.key,
        criteria: result.criteria,
        clicked: true,
      }
    }
    default: {
      return state;
    }
  }
}
