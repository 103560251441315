<div class="container-fluid interactive-header-padding" *ngIf="showInteractiveHeader">
  <div class="row fhlb-padding background-slate">
    <div class="pt-3 col-padding" 
    [ngClass]="{ 'col-sm-5': isCreateAllowed, 'col-sm-6 offset-3': !isCreateAllowed }">
      <div class="input-group mb-3">
        <select id="hlbType" name="hlbType" tabindex="3" [(ngModel)]="searchKey" (change) = "searchChange()"
                class="form-select col-sm-4 dropdown-toggle-select-element ps-1 pe-3 dropdown-border-radius tableText interactive-dropdown">
          <option id="searchOption{{i}}" *ngFor="let searchOption of searchOptions; let i = index" [disabled]="searchOption.key === 4"
                  [ngValue]="searchOption.key">
                  <span *ngIf="ifSearchSubMenu(searchOption.key)">&nbsp;&nbsp;&nbsp;&nbsp;</span> {{searchOption.value}}
          </option>
        </select>
        <input id="pfiSearchInput" [(ngModel)]="searchInput" type="text" class="form-control col-sm font-14"
               tabindex="2" placeholder="{{inputPlaceholder}}" (keydown.enter)="interactiveSearch()">
        <button class="btn btn-warning ps-3 pe-3 font-14 pt-1 pb-1" type="button"
                (click)="interactiveSearch()" id="interactiveSearch">
          <span class="fa fa-search fa-lg"></span>
        </button>

      </div>
    </div>
    <div class="col-sm-2 divider-align" *ngIf="isCreateAllowed">
      <div class="ms-3 me-3">
        <span class="font-white font-medium">|</span>
      </div>
    </div>
    <div class="col-sm-5 pt-3 col-padding" *ngIf="isCreateAllowed">
      <div class="input-group mb-3">
        <select id="hlbType" name="hlbType" tabindex="4"
                class="form-select col-sm tableText dropdown-toggle-select-element ps-1 pe-3 interactive-dropdown"
                [(ngModel)]="selectedCreateOption">
          <option id="createOption{{i}}" *ngFor="let createOption of createOptions; let i = index"
                  [ngValue]="createOption.value" [disabled]="createOption.key === 5">
            <span *ngIf="ifSubMenu(createOption.key)">&nbsp;&nbsp;&nbsp;&nbsp;</span>{{createOption.value}}
          </option>
        </select>
        <button class="btn background-light-blue ps-3 pe-3 pt-1 pb-1" type="button"
                (click)="createEvent()" id="createButton">
          <b class="font-light-blue font-14">CREATE</b>
        </button>

      </div>
    </div>
  </div>
</div>
