import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EmpfRoutingHelper } from "@empf-shared/navigation/empf.navigation";

@Component({
  selector: 'sites-footer-container',
  templateUrl: './sites-footer.container.component.html'
})
export class SitesFooterContainerComponent implements OnInit {
  links: {name: string, route: string}[] = []
  currentYear: number
  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear()
    this.links.push({name: 'ABOUT US', route: EmpfRoutingHelper.FooterAboutUsLandingPath()})
    this.links.push({name: 'COPYRIGHT', route: EmpfRoutingHelper.FooterCopyrightLandingPath()})
    this.links.push({name: 'PRIVACY POLICY', route: EmpfRoutingHelper.FooterPrivacyPolicyLandingPath()})
    this.links.push({name: 'TERMS & CONDITIONS', route: EmpfRoutingHelper.FooterTermsAndConditiontLandingPath()})
    this.links.push({name: 'FAQS', route: EmpfRoutingHelper.FaqsLandingPath()})
    this.links.push({name: 'FEEDBACK FORM', route: EmpfRoutingHelper.FooterFeedbackFormLandingPath()})
  }
}