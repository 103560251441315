import { Component, OnInit } from "@angular/core";
import * as moment from 'moment-timezone';

@Component({
  selector: 'origination',
  templateUrl: './origination.container.component.html',
  styleUrls: ['./origination.container.component.sass']
})
export class OriginationContainerComponent implements OnInit {
  showInteractiveHeader: boolean = true;
  showBreadCrumb: boolean = true;
  todayTime: string = moment().format('MM/DD/YYYY HH:mm z');

  ngOnInit() {
    setInterval(() => {
      this.todayTime = moment().format('MM/DD/YYYY HH:mm z')
    }, 1000);
  }
}