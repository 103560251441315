import { Action } from '@ngrx/store';

export enum SearchActionTypes {
  SET_SEARCH = '[Search] Set Search',
  CLEAR_SEARCH = '[Search] Clear Search'
}

export enum SearchHeaderOptions {
  BatchProcess = 1,
  DeliveryCommitment = 2,
  MasterCommitment = 3,
  Merger = 4,
  Loans = 5,
  MPFLoan = 6,
  PFILoan = 7,
  Organization = 8,
}

export class SetSearchAction implements Action {
  readonly type = SearchActionTypes.SET_SEARCH;
  constructor(public payload: { key: SearchHeaderOptions, criteria: string }) {}
}

export class ClearSearchAction implements Action {
  readonly type = SearchActionTypes.CLEAR_SEARCH;
  constructor() {}
}

export type All =
  | SetSearchAction
  | ClearSearchAction;
