import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
    name: 'findSubProducts'
})
export class FindSubProductsPipe implements PipeTransform {

    transform(items: any, pricingCriteriaSelection, pricingSourceName, pricingSourceSelectionTracker): any {
        {
            let filtered = [];

            let returnAllProducts = false;
            let hasProductChanges = false;
            let enableAllSubProducts = false;

            let returnAllDeliverytype = false;
            let hasDeliveryTypeChanges = false;

            let clonedMasterSubProducts = [];

            if (pricingCriteriaSelection.preferredPricingSources.length > 0 && pricingSourceName === "Mpx" && false) {


                if (pricingCriteriaSelection.preferredPricingSources[0].deliveryTypes.length > 0 && pricingCriteriaSelection.preferredPricingSources[0].preferredProducts.length > 0) {

                    //check if the delivery type and product selection got changed since last action
                        if ((pricingSourceSelectionTracker.pricingSources && pricingSourceSelectionTracker.pricingSources != null) && pricingSourceSelectionTracker.pricingSources.length > 0) {
                            pricingSourceSelectionTracker.pricingSources.forEach( (pricingSourceTracker) =>{
                                if (pricingSourceTracker.pricingSourceName === pricingSourceName) {

                                    if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources == null) || 
                                    ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) &&
                                        pricingCriteriaSelection.preferredPricingSources.length > 0)) {

                                        enableAllSubProducts = false;
                                        let pricingSourceFound = false;
                                        pricingCriteriaSelection.preferredPricingSources.forEach(  (preferredPricingSource)=> {
                                            if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                                                if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                                                    let areProductEq = _.isEqual(preferredPricingSource.preferredProducts, pricingSourceTracker.products);
                                                    let aredeliveryTypesEq = _.isEqual(preferredPricingSource.deliveryTypes, pricingSourceTracker.deliveryTypes);
                                                    if (areProductEq && aredeliveryTypesEq) {
                                                        hasProductChanges = false;
                                                        hasDeliveryTypeChanges = false;
                                                        clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                                    } else {
                                                        hasProductChanges = true;
                                                        hasDeliveryTypeChanges = true;
                                                    }

                                                    pricingSourceFound = true;
                                                    return;
                                                }
                                            }
                                        });

                                        if (!pricingSourceFound && this.checkToReturnAllProducts(pricingCriteriaSelection, pricingSourceName, items, pricingSourceSelectionTracker)) {
                                            returnAllProducts = true;
                                            returnAllDeliverytype = true;
                                        }
                                        else if (!pricingSourceFound) {
                                            clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                        }
                                    } else {
                                        if (pricingSourceTracker.enableAllSubProducts) {
                                            pricingSourceSelectionTracker.pricingSources = [];
                                            returnAllProducts = true;
                                            returnAllDeliverytype = true;
                                        } else {
                                            enableAllSubProducts = true;
                                            hasProductChanges = true;
                                            hasDeliveryTypeChanges = true;
                                        }
                                    }
                                }
                            });
                        }
                        else {

                            enableAllSubProducts = true;
                            hasProductChanges = true;
                            hasDeliveryTypeChanges = true;
                        }

                        if (returnAllProducts && returnAllDeliverytype) {
                            filtered = items;

                            return filtered;
                        }
                        if (!hasProductChanges && !hasDeliveryTypeChanges) {
                            filtered = clonedMasterSubProducts;

                            return filtered;
                        }

                        let selectAllSubProducts = null;
                        if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources == null) ||
                            ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) && pricingCriteriaSelection.preferredPricingSources.length === 0)) {

                            if ((items && items != null) && items.length > 0) {
                                filtered = items;
                            }

                            this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                            return filtered;
                        } else {
                            pricingCriteriaSelection.preferredPricingSources.forEach(  (preferredPricingSource)=> {
                                if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                                    if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                                        if (((preferredPricingSource.deliveryTypes && preferredPricingSource.deliveryTypes != null)
                                                && preferredPricingSource.deliveryTypes.length === 0)
                                                && ((preferredPricingSource.preferredProducts && preferredPricingSource.preferredProducts != null)
                                                && preferredPricingSource.preferredProducts.length === 0)) {
                                            selectAllSubProducts = true;
                                        } else {
                                            selectAllSubProducts = false;
                                        }
                                        return;
                                    }
                                }
                            });
                        }

                        if ((selectAllSubProducts == undefined || selectAllSubProducts == null) || selectAllSubProducts) {
                            if ((items && items != null) && items.length > 0) {
                                filtered = items;
                            }

                            this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                            return filtered;
                        }

                        selectAllSubProducts = true;
                        var currentSelectedProducts = [];
                        var currentSelectedDeliveryTypes = [];
                        //filter subproduct based on pricing criteria selections
                        pricingCriteriaSelection.preferredPricingSources.forEach(  (preferredPricingSource)=> {
                            if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                                if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                                    if (((preferredPricingSource.deliveryTypes && preferredPricingSource.deliveryTypes != null)
                                            && preferredPricingSource.deliveryTypes.length > 0)
                                            && ((preferredPricingSource.preferredProducts && preferredPricingSource.preferredProducts != null)
                                            && preferredPricingSource.preferredProducts.length > 0)) {

                                        currentSelectedProducts = preferredPricingSource.preferredProducts;
                                        currentSelectedDeliveryTypes = preferredPricingSource.deliveryTypes;
                                        selectAllSubProducts = false;
                                        items.forEach( (subProduct)=> {
                                            preferredPricingSource.deliveryTypes.forEach( (deliveryType)=> {
                                                preferredPricingSource.preferredProducts.forEach( (preferredProduct)=> {
                                                    if (preferredProduct.productCode === subProduct.parentProductCode &&
                                                        deliveryType === subProduct.deliveryType) {
                                                        filtered.push(subProduct);
                                                        return;
                                                    }
                                                });
                                            });
                                        });

                                        if (filtered.length > 0) {
                                            return;
                                        }
                                    }
                                }
                            }
                        });

                        if (selectAllSubProducts) {
                            filtered = items;
                        }

                        this.updatePricingSourceSelectionTracker(pricingSourceName, currentSelectedProducts, currentSelectedDeliveryTypes, filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                        return filtered;
                    }

                else if (pricingCriteriaSelection.preferredPricingSources[0].deliveryTypes.length > 0) { //&& pricingCriteriaSelection.preferredPricingSources[0].preferredProducts.length === 0

                    //check if the delivery type selection got changed since last action
                    if ((pricingSourceSelectionTracker.pricingSources && pricingSourceSelectionTracker.pricingSources != null) && pricingSourceSelectionTracker.pricingSources.length > 0) {
                        pricingSourceSelectionTracker.pricingSources.forEach( (pricingSourceTracker) =>{
                            if (pricingSourceTracker.pricingSourceName === pricingSourceName) {

                                if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources == null) || 
                                ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) &&
                                    pricingCriteriaSelection.preferredPricingSources.length > 0)) {

                                    enableAllSubProducts = false;
                                    let pricingSourceFound = false;
                                    pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource) =>{
                                        if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                                            if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                                                let areProductEq = _.isEqual(preferredPricingSource.preferredProducts, pricingSourceTracker.products);
                                                let areDeliveryTypeEq = _.isEqual(preferredPricingSource.deliveryTypes, pricingSourceTracker.deliveryTypes);
                                                if (areProductEq && areDeliveryTypeEq) {
                                                    hasDeliveryTypeChanges = false;
                                                    clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                                } else {
                                                    hasDeliveryTypeChanges = true;
                                                }

                                                pricingSourceFound = true;
                                                return;
                                            }
                                        }
                                    });

                                    if (!pricingSourceFound && this.checkToReturnAllProducts(pricingCriteriaSelection, pricingSourceName, items, pricingSourceSelectionTracker)) {
                                        returnAllDeliverytype = true;
                                    }
                                    else if (!pricingSourceFound) {
                                        clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                    }
                                } else {
                                    if (pricingSourceTracker.enableAllSubProducts) {
                                        pricingSourceSelectionTracker.pricingSources = [];
                                        returnAllDeliverytype = true;
                                    } else {
                                        enableAllSubProducts = true;
                                        hasDeliveryTypeChanges = true;
                                    }
                                }
                            }
                        });
                    }
                    else {

                        enableAllSubProducts = true;
                        hasDeliveryTypeChanges = true;
                    }

                    if (returnAllDeliverytype) {
                        filtered = items;

                        return filtered;
                    }
                    if (!hasDeliveryTypeChanges) {
                        filtered = clonedMasterSubProducts;

                        return filtered;
                    }

                    let selectAllSubProducts = null;
                    if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources == null) ||
                        ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) && pricingCriteriaSelection.preferredPricingSources.length === 0)) {

                        if ((items && items != null) && items.length > 0) {
                            filtered = items;
                        }

                        this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                        return filtered;
                    } else {
                        pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource)=> {
                            if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                                if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {
                                    if ((preferredPricingSource.deliveryTypes && preferredPricingSource.deliveryTypes != null) && preferredPricingSource.deliveryTypes.length === 0) {
                                        selectAllSubProducts = true;
                                    } else {
                                        selectAllSubProducts = false;
                                    }
                                    return;
                                }
                            }
                        });
                    }

                    if ((selectAllSubProducts == undefined || selectAllSubProducts == null) || selectAllSubProducts) {
                        if ((items && items != null) && items.length > 0) {
                            filtered = items;
                        }

                        this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                        return filtered;
                    }

                    selectAllSubProducts = true;
                    //let currentSelectedProducts = [];
                    let currentSelectedDeliveryTypes = [];
                    //filter subproduct based on pricing criteria selections
                    pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource)=> {
                        if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                            if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                                if ((preferredPricingSource.deliveryTypes && preferredPricingSource.deliveryTypes != null) &&
                                    preferredPricingSource.deliveryTypes.length > 0) {

                                    //currentSelectedProducts = preferredPricingSource.preferredProducts;
                                    currentSelectedDeliveryTypes = preferredPricingSource.deliveryTypes
                                    selectAllSubProducts = false;
                                    items.forEach( (subProduct) =>{
                                        preferredPricingSource.deliveryTypes.forEach( (deliveryType)=> {
                                            if (deliveryType === subProduct.deliveryType) {
                                                filtered.push(subProduct);
                                                return;
                                            }
                                        });
                                    });

                                    if (filtered.length > 0) {
                                        return;
                                    }
                                }
                            }
                        }
                    });

                    if (selectAllSubProducts) {
                        filtered = items;
                    }

                    this.updatePricingSourceSelectionTracker(pricingSourceName, currentSelectedProducts, currentSelectedDeliveryTypes, filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                    return filtered;
                }

                else if (pricingCriteriaSelection.preferredPricingSources[0].preferredProducts.length > 0) {
                    //check if the product selection got changed since last action
                    if ((pricingSourceSelectionTracker.pricingSources && pricingSourceSelectionTracker.pricingSources != null) && pricingSourceSelectionTracker.pricingSources.length > 0) {
                        pricingSourceSelectionTracker.pricingSources.forEach( (pricingSourceTracker)=> {
                            if (pricingSourceTracker.pricingSourceName === pricingSourceName) {

                                if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources == null) || ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) &&
                                    pricingCriteriaSelection.preferredPricingSources.length > 0)) {

                                    enableAllSubProducts = false;
                                    let pricingSourceFound = false;
                                    pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource) =>{
                                        if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                                            if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                                                let areProductEq = _.isEqual(preferredPricingSource.preferredProducts, pricingSourceTracker.products);
                                                let areDeliveryTypeEq = _.isEqual(preferredPricingSource.deliveryTypes, pricingSourceTracker.deliveryTypes);
                                                if (areProductEq && areDeliveryTypeEq) {
                                                    hasProductChanges = false;
                                                    clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                                } else {
                                                    hasProductChanges = true;
                                                }

                                                pricingSourceFound = true;
                                                return;
                                            }
                                        }
                                    });

                                    if (!pricingSourceFound && this.checkToReturnAllProducts(pricingCriteriaSelection, pricingSourceName, items, pricingSourceSelectionTracker)) {
                                        returnAllProducts = true;
                                    }
                                    else if (!pricingSourceFound) {
                                        clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                    }
                                } else {
                                    if (pricingSourceTracker.enableAllSubProducts) {
                                        pricingSourceSelectionTracker.pricingSources = [];
                                        returnAllProducts = true;
                                    } else {
                                        enableAllSubProducts = true;
                                        hasProductChanges = true;
                                    }
                                }
                            }
                        });
                    }
                    else {

                        enableAllSubProducts = true;
                        hasProductChanges = true;
                    }

                    if (returnAllProducts) {
                        filtered = items;

                        return filtered;
                    }
                    if (!hasProductChanges) {
                        filtered = clonedMasterSubProducts;

                        return filtered;
                    }

                    let selectAllSubProducts = null;
                    if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources == null) ||
                        ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) && pricingCriteriaSelection.preferredPricingSources.length === 0)) {

                        if ((items && items != null ) && items.length > 0) {
                            filtered = items;
                        }

                        this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                        return filtered;
                    } else {
                        pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource) =>{
                            if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                                if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {
                                    if ((preferredPricingSource.preferredProducts && preferredPricingSource.preferredProducts != null) &&
                                        preferredPricingSource.preferredProducts.length === 0) {
                                        selectAllSubProducts = true;
                                    } else {
                                        selectAllSubProducts = false;
                                    }
                                    return;
                                }
                            }
                        });
                    }

                    if ((selectAllSubProducts == undefined || selectAllSubProducts == null) || selectAllSubProducts) {
                        if ((items && items != null) && items.length > 0) {
                            filtered = items;
                        }

                        this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                        return filtered;
                    }

                    selectAllSubProducts = true;
                    var currentSelectedProducts = [];
                    //let currentSelectedDeliveryTypes = [];
                    //filter subproduct based on pricing criteria selections
                    pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource)=> {
                        if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                            if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                                if ((preferredPricingSource.preferredProducts && preferredPricingSource.preferredProducts != null) &&
                                    preferredPricingSource.preferredProducts.length > 0) {

                                    currentSelectedProducts = preferredPricingSource.preferredProducts;
                                    //currentSelectedDeliveryTypes = preferredPricingSource.deliveryTypes;
                                    selectAllSubProducts = false;
                                    items.forEach( (subProduct) =>{
                                        preferredPricingSource.preferredProducts.forEach( (preferredProduct)=> {
                                            if (preferredProduct.productCode === subProduct.parentProductCode) {
                                                filtered.push(subProduct);
                                                return;
                                            }
                                        });
                                    });

                                    if (filtered.length > 0) {
                                        return;
                                    }
                                }
                            }
                        }
                    });

                    if (selectAllSubProducts) {
                        filtered = items;
                    }

                    this.updatePricingSourceSelectionTracker(pricingSourceName, currentSelectedProducts, currentSelectedDeliveryTypes, filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                    return filtered;
                }
            }
            else {
                //check if the product selection got changed since last action
                if ((pricingSourceSelectionTracker.pricingSources && pricingSourceSelectionTracker.pricingSources != null) && pricingSourceSelectionTracker.pricingSources.length > 0) {
                    pricingSourceSelectionTracker.pricingSources.forEach( (pricingSourceTracker)=> {
                        if (pricingSourceTracker.pricingSourceName === pricingSourceName) {

                            if ((pricingCriteriaSelection.preferredPricingSources == undefined ||pricingCriteriaSelection.preferredPricingSources == null ) || ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) &&
                                pricingCriteriaSelection.preferredPricingSources.length > 0)) {

                                enableAllSubProducts = false;
                                let pricingSourceFound = false;
                                pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource)=> {
                                    if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                                        if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                                            let areProductEq = _.isEqual(preferredPricingSource.preferredProducts, pricingSourceTracker.products);
                                            if (areProductEq) {
                                                hasProductChanges = false;
                                                clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                            } else {
                                                hasProductChanges = true;
                                            }

                                            pricingSourceFound = true;
                                            return;
                                        }
                                    }
                                });

                                if (!pricingSourceFound && this.checkToReturnAllProducts(pricingCriteriaSelection, pricingSourceName, items, pricingSourceSelectionTracker)) {
                                    returnAllProducts = true;
                                }
                                else if (!pricingSourceFound) {
                                    clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                }
                            } else {
                                if (pricingSourceTracker.enableAllSubProducts) {
                                    pricingSourceSelectionTracker.pricingSources = [];
                                    returnAllProducts = true;
                                } else {
                                    enableAllSubProducts = true;
                                    hasProductChanges = true;
                                }
                            }
                        }
                    });
                }
                else {

                    enableAllSubProducts = true;
                    hasProductChanges = true;
                }

                if (returnAllProducts) {
                    filtered = items;

                    return filtered;
                }
                if (!hasProductChanges) {
                    filtered =[]
                  //  filtered = clonedMasterSubProducts;
                // filtered = clonedMasterSubProducts;
   pricingCriteriaSelection.preferredPricingSources.forEach((preferredPricingSource) =>{
    if ((preferredPricingSource.pricingSourceDetail.value != undefined )) {
        if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

            if (( preferredPricingSource.preferredProducts != undefined) &&
                preferredPricingSource.preferredProducts.length > 0) {
                currentSelectedProducts = preferredPricingSource.preferredProducts;

                items.forEach((subProduct)=> {                  
                    if(preferredPricingSource.deliveryTypes !== undefined && preferredPricingSource.deliveryTypes.length > 0){
                   preferredPricingSource.preferredProducts.forEach((preferredProduct)=> {
                        if (preferredProduct.productCode === subProduct.parentProductCode &&
                           (preferredPricingSource.deliveryTypes.indexOf(subProduct.deliveryType) > -1)) {
                            filtered.push(subProduct);
                            return;
                        }
                    });
                    }
                    else{
                        preferredPricingSource.preferredProducts.forEach((preferredProduct)=> {
                            if (preferredProduct.productCode === subProduct.parentProductCode ) {
                                filtered.push(subProduct);
                                return;
                            }
                        });
                    }
                });

                if (filtered.length > 0) {
                    return;
                }
             }
            


            //filtering on the basis of Delivery type and then Products
           else if (preferredPricingSource.deliveryTypes !== undefined 
                && preferredPricingSource.deliveryTypes.length > 0) {
                    
            items.forEach((subProduct)=> {
                if(preferredPricingSource.preferredProducts !== undefined && 
                    preferredPricingSource.preferredProducts.length > 0){
                preferredPricingSource.preferredProducts.forEach((preferredProduct)=> {
                    if (preferredProduct.productCode === subProduct.parentProductCode &&
                       (preferredPricingSource.deliveryTypes.indexOf(subProduct.deliveryType) > -1)) {
                        filtered.push(subProduct);
                        return;
                    }
                });
                }
                else{
                    if(preferredPricingSource.deliveryTypes.indexOf(subProduct.deliveryType) > -1){
                        filtered.push(subProduct);
                            return;
                    }
                   
                }
            });

            if (filtered.length > 0) {
                return;
            }
        }else{
            filtered = items;
        }
        }
    }
});
                    return filtered;
                }

                let selectAllSubProducts = null;
                if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources == null) ||
                    ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) && pricingCriteriaSelection.preferredPricingSources.length === 0)) {

                    if ((items && items != null) && items.length > 0) {
                        filtered = items;
                    }

                    this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                    return filtered;
                } else {
                    pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource) =>{
                        if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value!= null)) {
                            if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {
                                if ((preferredPricingSource.preferredProducts && preferredPricingSource.preferredProducts!= null ) &&
                                    preferredPricingSource.preferredProducts.length === 0) {
                                    selectAllSubProducts = true;
                                } else {
                                    selectAllSubProducts = false;
                                }
                                return;
                            }
                        }
                    });
                }

                if ((selectAllSubProducts == undefined || selectAllSubProducts == null) || selectAllSubProducts) {
                    if ((items && items != null) && items.length > 0) {
                        filtered = items;
                    }

                    this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                    return filtered;
                }

                selectAllSubProducts = true;
                var currentSelectedProducts = [];
                //filter subproduct based on pricing criteria selections
                pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource) =>{
                    if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                        if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                            if ((preferredPricingSource.preferredProducts) &&
                                preferredPricingSource.preferredProducts.length > 0) {

                                currentSelectedProducts = preferredPricingSource.preferredProducts;
                                selectAllSubProducts = false;
                                items.forEach((subProduct)=> {
                                    preferredPricingSource.preferredProducts.forEach( (preferredProduct) =>{
                                        if (preferredProduct.productCode === subProduct.parentProductCode) {
                                            filtered.push(subProduct);
                                            return;
                                        }
                                    });
                                });

                                if (filtered.length > 0) {
                                    return;
                                }
                            }
                        }
                    }
                });

                if (selectAllSubProducts) {
                    filtered = items;
                }

                this.updatePricingSourceSelectionTracker(pricingSourceName, currentSelectedProducts, [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                return filtered;
            }

            if (pricingCriteriaSelection.preferredPricingSources[0].deliveryTypes.length > 0) { //&& pricingCriteriaSelection.preferredPricingSources[0].preferredProducts.length === 0

                //check if the delivery type selection got changed since last action
                if ((pricingSourceSelectionTracker.pricingSources && pricingSourceSelectionTracker.pricingSources != null) && pricingSourceSelectionTracker.pricingSources.length > 0) {
                    pricingSourceSelectionTracker.pricingSources.forEach( (pricingSourceTracker) =>{
                        if (pricingSourceTracker.pricingSourceName === pricingSourceName) {

                            if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources == null) || 
                            ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) &&
                                pricingCriteriaSelection.preferredPricingSources.length > 0)) {

                                enableAllSubProducts = false;
                                let pricingSourceFound = false;
                                pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource) =>{
                                    if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                                        if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                                            let areProductEq = _.isEqual(preferredPricingSource.preferredProducts, pricingSourceTracker.products);
                                            let areDeliveryTypeEq = _.isEqual(preferredPricingSource.deliveryTypes, pricingSourceTracker.deliveryTypes);
                                            if (areProductEq && areDeliveryTypeEq) {
                                                hasDeliveryTypeChanges = false;
                                                clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                            } else {
                                                hasDeliveryTypeChanges = true;
                                            }

                                            pricingSourceFound = true;
                                            return;
                                        }
                                    }
                                });

                                if (!pricingSourceFound && this.checkToReturnAllProducts(pricingCriteriaSelection, pricingSourceName, items, pricingSourceSelectionTracker)) {
                                    returnAllDeliverytype = true;
                                }
                                else if (!pricingSourceFound) {
                                    clonedMasterSubProducts = pricingSourceTracker.clonedMasterSubProducts;
                                }
                            } else {
                                if (pricingSourceTracker.enableAllSubProducts) {
                                    pricingSourceSelectionTracker.pricingSources = [];
                                    returnAllDeliverytype = true;
                                } else {
                                    enableAllSubProducts = true;
                                    hasDeliveryTypeChanges = true;
                                }
                            }
                        }
                    });
                }
                else {

                    enableAllSubProducts = true;
                    hasDeliveryTypeChanges = true;
                }

                if (returnAllDeliverytype) {
                    filtered = items;

                    return filtered;
                }
                if (!hasDeliveryTypeChanges) {
                    filtered = clonedMasterSubProducts;

                    return filtered;
                }

                let selectAllSubProducts = null;
                if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources == null) ||
                    ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources != null) && pricingCriteriaSelection.preferredPricingSources.length === 0)) {

                    if ((items && items != null) && items.length > 0) {
                        filtered = items;
                    }

                    this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                    return filtered;
                } else {
                    pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource)=> {
                        if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                            if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {
                                if ((preferredPricingSource.deliveryTypes && preferredPricingSource.deliveryTypes != null) && preferredPricingSource.deliveryTypes.length === 0) {
                                    selectAllSubProducts = true;
                                } else {
                                    selectAllSubProducts = false;
                                }
                                return;
                            }
                        }
                    });
                }

                if ((selectAllSubProducts == undefined || selectAllSubProducts == null) || selectAllSubProducts) {
                    if ((items && items != null) && items.length > 0) {
                        filtered = items;
                    }

                    this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                    return filtered;
                }

                selectAllSubProducts = true;
                //let currentSelectedProducts = [];
                let currentSelectedDeliveryTypes = [];
                //filter subproduct based on pricing criteria selections
                pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource)=> {
                    if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                        if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {

                            if ((preferredPricingSource.deliveryTypes && preferredPricingSource.deliveryTypes != null) &&
                                preferredPricingSource.deliveryTypes.length > 0) {

                                //currentSelectedProducts = preferredPricingSource.preferredProducts;
                                currentSelectedDeliveryTypes = preferredPricingSource.deliveryTypes
                                selectAllSubProducts = false;
                                items.forEach( (subProduct) =>{
                                    preferredPricingSource.deliveryTypes.forEach( (deliveryType)=> {
                                        if (deliveryType === subProduct.deliveryType) {
                                            filtered.push(subProduct);
                                            return;
                                        }
                                    });
                                });

                                if (filtered.length > 0) {
                                    return;
                                }
                            }
                        }
                    }
                });

                if (selectAllSubProducts) {
                    filtered = items;
                }

                this.updatePricingSourceSelectionTracker(pricingSourceName, currentSelectedProducts, currentSelectedDeliveryTypes, filtered, enableAllSubProducts, pricingSourceSelectionTracker);

                return filtered;
            }

        } 
    }
    checkToReturnAllProducts(pricingCriteriaSelection, pricingSourceName, items, pricingSourceSelectionTracker){
        {

            if ((pricingCriteriaSelection.preferredPricingSources == undefined || pricingCriteriaSelection.preferredPricingSources ==null) ||
                ((pricingCriteriaSelection.preferredPricingSources && pricingCriteriaSelection.preferredPricingSources !=null) &&
                pricingCriteriaSelection.preferredPricingSources.length > 0)) {

                let filtered = [];
                let pricingSourceFound = false;
                pricingCriteriaSelection.preferredPricingSources.forEach( (preferredPricingSource) =>{
                    if ((preferredPricingSource.pricingSourceDetail.value && preferredPricingSource.pricingSourceDetail.value != null)) {
                        if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName) {
                            pricingSourceFound = true;
                            return;
                        }
                    }
                });

                if (!pricingSourceFound) {
                    if ((items && items != null) && items.length > 0) {
                        filtered = items;
                    }

                    this.updatePricingSourceSelectionTracker(pricingSourceName, [], [], filtered, true, pricingSourceSelectionTracker);

                    return true;
                }
            }

            return false;
        }

    }

    updatePricingSourceSelectionTracker(pricingSourceName, currentSelectedProducts, currentSelectedDeliveryTypes, subProductsToClone, enableAllSubProducts, pricingSourceSelectionTracker){
        {

            //clone to persist the current subproduct state
            let clonedsubProducts = [];
            let clonedCurrentSelectedProducts = [];
            let clonedCurrentSelectedDeliveryTypes = [];
            clonedsubProducts = subProductsToClone;
            clonedCurrentSelectedProducts = currentSelectedProducts;
            clonedCurrentSelectedDeliveryTypes = currentSelectedDeliveryTypes


            if ((pricingSourceSelectionTracker.pricingSources == undefined || pricingSourceSelectionTracker.pricingSources == null) ||
                pricingSourceSelectionTracker.pricingSources.length === 0) {
                pricingSourceSelectionTracker.pricingSources.push({
                    products: clonedCurrentSelectedProducts,
                    pricingSourceName: pricingSourceName,
                    clonedMasterSubProducts: clonedsubProducts,
                    deliveryTypes: clonedCurrentSelectedDeliveryTypes,
                    enableAllSubProducts: enableAllSubProducts
                });
            } else {
                let exists = false;
                pricingSourceSelectionTracker.pricingSources.forEach( (pricingSource) =>{
                    if (pricingSource.pricingSourceName === pricingSourceName) {
                        pricingSource.products = clonedCurrentSelectedProducts;
                        pricingSource.clonedMasterSubProducts = clonedsubProducts;
                        pricingSource.deliveryTypes = clonedCurrentSelectedDeliveryTypes;
                        pricingSource.enableAllSubProducts = enableAllSubProducts;
                        exists = true;
                    }
                });

                if (!exists) {
                    pricingSourceSelectionTracker.pricingSources.push({
                        products: clonedCurrentSelectedProducts,
                        pricingSourceName: pricingSourceName,
                        clonedMasterSubProducts: clonedsubProducts,
                        deliveryTypes: clonedCurrentSelectedDeliveryTypes,
                        enableAllSubProducts: enableAllSubProducts
                    });
                }
            }
        }

    }
}