import { Component, ElementRef, Injectable } from '@angular/core';
import * as xlsx from 'xlsx';

@Injectable({
  providedIn: 'root'
})

export class PrintService {

  printSection(elementID, paddingLeft: string = null) {
    var messageSection = document.getElementById(elementID);
    messageSection.style.display = 'block';
    var clone = messageSection.cloneNode(true);
    var $printSection = document.getElementById('printSection');

    if (!$printSection) {
      $printSection = document.createElement('div');
      $printSection.id = 'printSection';
      document.body.appendChild($printSection);
    }

    $printSection.innerHTML = '';
    $printSection.appendChild(clone);
    $printSection.style.width = '100%';
    if (paddingLeft) {
      $printSection.style.paddingLeft = paddingLeft;
    }
    window.print();
  }

  printPropertySection(elementID, paddingLeft: string = null) {
    let messageSection = document.getElementById(elementID);
    let parentNode = document.getElementById(elementID).parentNode;

    var $printSection = document.getElementById('printSection');

    if (!$printSection) {
      $printSection = document.createElement('div');
      $printSection.id = 'printSection';
      document.body.appendChild($printSection);
    }

    $printSection.innerHTML = '';
    $printSection.appendChild(messageSection);
    $printSection.style.width = '100%';

    window.print();

    parentNode.appendChild(messageSection);
  }

  printToExcel(element, name) {
    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>{section}</body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };

    name = name + '.xls';

    var ctx = { worksheet: name, section: element };
    //Support has been dropped for older browsers
    //var browser = window.navigator.appVersion;
    //if ((browser.indexOf('Trident') !== -1 && browser.indexOf('rv:11') !== -1) ||
    //    (browser.indexOf('MSIE 10') !== -1)) {
    //    var builder = new MSBlobBuilder();
    //    builder.append(uri + format(template, ctx));
    //    var blob = builder.getBlob('data:application/vnd.ms-excel');
    //    window.navigator.msSaveOrOpenBlob(blob, name);
    //} else {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri + base64(format(template, ctx));
    link.click();
    //}
  }
}
