import { Directive, ElementRef, OnInit, Self, HostListener, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { AbstractControl, NgControl, Validator, Validators } from '@angular/forms';

@Directive({
    selector: '[preFillNumber]'
})

export class NumberPreFillDirective {

    @Input('preFillNumber') padlen: string;
    @Input('allowZero') allowZero: boolean = false;
    inputvalue: string;
    @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();

    //@Output() inputSliderValueChange: EventEmitter<string> = new EventEmitter<string>();
    private ngControl: NgControl;
    constructor(private el: ElementRef, public renderer: Renderer2, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
    }

    prefillzero() {

        var num = this.el.nativeElement.value;
        let paddedValue;
        var regEx = new RegExp("^\\d{" + this.padlen + "}$");

        if (num !== '') {
            this.inputvalue = num;
            let paddingChar = '0';
            if (!isNaN(Number(num.toString()))) {

                if (Math.sign(num) == -1) {
                    this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
                }
                else {
                    paddedValue = num.padStart(+this.padlen, paddingChar);
                    this.renderer.setProperty(this.el.nativeElement, 'value', paddedValue);
                    this.inputvalue = paddedValue;
                }

            }

            if (parseInt(num) === 0 && !this.allowZero || !this.inputvalue.match(regEx)) {
                this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                this.ngControl.control.setErrors({ pattern: true });

            }
            else if (parseInt(num) > 0 && this.inputvalue.match(regEx)) {
                this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
            }
            this.ngControl.viewToModelUpdate(paddedValue);
        }

    }
   
    @HostListener('blur') onblur() {
        this.prefillzero();
    }
}
