<div class="row mt-3">
  <div class="col-9 size" *ngIf="mcList && !isCriteriaSearch">
    Showing {{totalItems==0? 0 : ((pageNo-1) * pageSize+1)}}
    to {{totalItems > (pageNo * pageSize) ? pageNo * pageSize : totalItems}}
    of {{totalItems}} items </div>
  <div class="col-9" *ngIf="!mcList">
    Showing No items
  </div>
  <div class="col-3 text-end size" *ngIf="mcList && !isCriteriaSearch">
    Show entries &nbsp;
    <select id="selectentry" class="showentry" [(ngModel)]="pageSize"
      (ngModelChange)="pageSizeChange(pageSize)">
      <option [ngValue]="5">5</option>
      <option [ngValue]="10">10</option>
      <option [ngValue]="25">25</option>
      <option [ngValue]="50">50</option>
      <option [ngValue]="100">100</option>
    </select>
  </div>
</div>
<div class="row mt-2">
  <div class="col-sm-12">
    <table class="table table-striped">
      <thead id="th" class="font-bold">
        <th>MC No.</th>
        <th>PFI No.</th>
        <th class="text-wrap">MC Amount</th>
        <th>Committed Amount</th>
        <th>Uncommitted Amount</th>
        <th>Remittance Type</th>
        <th>MC Type</th>
        <th>MA Status </th>
        <th>Program</th>
        <th>Sub Program</th>
        <th></th>
      </thead>
      <tbody id="tbodycount">
        <tr id="mcrow" *ngFor="let mc of mcList | slice: 0 : pageSize">
          <td class="font-bold">
            <a tabindex="0" class="font-light-blue" id="mCId{{mc.mCId}}" (click)="goToView(mc.mCId)">{{mc.mCId}}</a>
          </td>
          <td class="font-bold">
            <a tabindex="0" class="font-light-blue" id="pfi{{mc.pfiNum}}" (click)="goToPfi(mc.pfiNum)">{{mc.pfiNum}}</a>
          </td>
          <td> {{mc.mcamount | currency}} </td>
          <td> {{mc.committedAmount | currency}} </td>
          <td> {{mc.uncommittedAmount | currency | negativeCurrencyToParens}} </td>
          <td> {{mc.mCRemittanceType}} </td>
          <td> {{mc.mCType}} </td>
          <td> {{mc.mCStatus}} </td>
          <td> {{mc.mCProgram}} </td>
          <td> {{mc.subProgram}} </td>
          <td class="font-bold column-width">
            <ng-template #popTemplate>
              <a class="color-grey" (click)="goToDCs(mc.mCId)">View DCs </a><br />
              <a class="color-grey" (click)="goToLoans(mc.pfiNum, mc.mCId)">View Loans </a><br />
              <a class="color-grey" *ngIf="showAddEditControls" (click)="goToEdit(mc.mCId)"> Edit </a>
            </ng-template>
            <a class="color-grey" tabindex="0" id="popTemplate" [ngbPopover]="popTemplate" #p1="ngbPopover" [autoClose]="true"
              placement="bottom-right" (click)="mc.clickstate = !mc.clickstate" (blur)="blurPopover(mc)"
              [ngSwitch]="mc.clickstate" mdbRippleRadius>
              <i *ngSwitchCase="true" class="fa fa-ellipsis-h" aria-hidden="true"></i>
              <i *ngSwitchCase="false" class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row justify-content-center align-items-center font-size-2 pagination" *ngIf="totalItems"
      [hidden]="(mcList.length !=0 && totalItems > 0 )? mcList.length == totalItems % (pageNo*pageSize) ? true : false : true">
      <ngb-pagination id="pagination" [collectionSize]="totalItems" [maxSize]="10" [(page)]="page" [pageSize]="pageSize"
        (pageChange)="pageChanged($event)" aria-label="Default pagination">
        <ng-template id="previous" ngbPaginationPrevious class="font-size-2">
          &lt;Prev</ng-template>
            <ng-template id="Next" ngbPaginationNext class="font-size-2">Next&gt;</ng-template>
      </ngb-pagination>
    </div>
    <div *ngIf="mcList.length== 0" class="defaultText text-center ">
      No results could be found. Please try your search again.
    </div>
  </div>
</div>