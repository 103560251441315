import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/store/reducers';
import { IRouteBreadcrumb, RouteDataBreadcrumb } from '@app-shared/models/routingdata';
import { isNullOrUndefined } from 'is-what';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IRouteBreadcrumb[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
      this.setCookiesFrom(this.breadcrumbs);
    })
  }

  buildBreadCrumb(route: ActivatedRoute, breadcrumbs: IRouteBreadcrumb[] = []): IRouteBreadcrumb[] {
    let breadcrumbData = route.routeConfig && route.routeConfig.data ? <RouteDataBreadcrumb>route.routeConfig.data : null;

    if (!isNullOrUndefined(breadcrumbData) && !isNullOrUndefined(breadcrumbData.breadcrumb)) {
      if (!isNullOrUndefined(breadcrumbData.breadcrumb.overridingBreadcrumb)) {
        if (breadcrumbData.breadcrumb.overridingBreadcrumb.length > 0) {
          if (route.firstChild)
            return this.buildBreadCrumb(route.firstChild, breadcrumbData.breadcrumb.overridingBreadcrumb);

          return breadcrumbData.breadcrumb.overridingBreadcrumb
        } else {
          return [];
        }
      }

      let newBreadcrumbs = !isNullOrUndefined(breadcrumbData.breadcrumb.value) ? [...breadcrumbs, breadcrumbData.breadcrumb.value] : [...breadcrumbs];
      if (route.firstChild) {
        return this.buildBreadCrumb(route.firstChild, newBreadcrumbs);
      }

      return newBreadcrumbs;
    } else {
      if (route.firstChild)
        return this.buildBreadCrumb(route.firstChild, breadcrumbs);
    }

    return breadcrumbs;
  }

  breadcrumbHasRoute(route: string): boolean {
    return !isNullOrUndefined(route)
  }

  setCookiesFrom(breadcrumbs: IRouteBreadcrumb[]) {
    breadcrumbs.forEach(item => {
      if (!isNullOrUndefined(item) && !isNullOrUndefined(item.setCookies))
        for (let key in item.setCookies) {
          localStorage.setItem(key, item.setCookies[key]);
        }
    })
  }
}
