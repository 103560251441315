import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IContact } from '@empf-shared/services/loan-funding/models/ScheduleModel';
import { EmailMessagePopUpComponent } from '../email-message-pop-up/email-message-pop-up.component';

@Component({
  selector: 'app-user-email-contacts',
  templateUrl: './user-email-contacts.page.component.html',
  styleUrls: ['./user-email-contacts.page.component.sass']
})
export class UserEmailContactsComponent implements OnInit {
  @Input() cmsModel: any;
  topBorderClass = `top-border-blue`;
  @Input() title: string;
  @Input() contacts: IContact[];
  taskSuccess = false;
  selectedEmailList: Array<string> = [];
  public showSort: boolean = false;
  public showEmailSort: boolean = false;
  public sortingName: string;
  public isDesc = false;
  public isNameSortBtnDesc = false;
  public isEmailAddressCodeSortBtnDesc = false;
  public isNameSortBtnAsc = false;
  public isEmailAddressCodeSortBtnAsc = false;
  @Output() sendEmailEmitter = new EventEmitter<Array<string>>();
  @Input() isEmailSuccess:boolean=false
  private _showEmailPopUpModal: boolean = null;
  get showEmailPopUpModal(): boolean {
    return this._showEmailPopUpModal;
  }
  @Input() set showEmailPopUpModal(value: boolean) {
    this._showEmailPopUpModal = value;
    if (this._showEmailPopUpModal != null) {
      this.showEmailPopUpModalView();
    }
  }
  constructor(public activeModal: NgbActiveModal,private modalService: NgbModal) { }

  ngOnInit() {
  }
  showEmailPopUpModalView(){
    this.activeModal.close();
    const modalRef = this.modalService.open(EmailMessagePopUpComponent, {
      size: 'sm', backdrop: 'static', windowClass: 'centered-modal-emailpopup'
    });
    modalRef.componentInstance.isEmailSuccess = this.isEmailSuccess;
    modalRef.componentInstance.cmsModel = this.cmsModel;

  }
  sort(name: string): void {
    if (name && this.sortingName !== name) {
      this.isDesc = false;
    } else {
      this.isDesc = !this.isDesc;
    }
    this.sortingName = name;
  }
  recordSelected(item) {
    this.taskSuccess = false;
    var index = this.selectedEmailList.indexOf(item.emailAddress);
    if (item.isSelectedEmail) {
      if (index === -1) {
        this.selectedEmailList.push(item.emailAddress);
      }
    }
    else {
      if (index > -1) {
        this.selectedEmailList.splice(index, 1);
      }
    }
  }
  sendEmail() {
    this.sendEmailEmitter.emit(this.selectedEmailList);
  }
}
