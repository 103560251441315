import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OriginationRoutingHelper } from 'src/origination/shared/navigation/origination.navigation';


@Component({
  selector: 'success-message',
  templateUrl: './success-message-modal.component.html',
  styleUrls: ['./success-message-modal.component.sass']

})

export class SuccessMessageModalComponent implements OnInit {
  message: string;
  additionalInformation: string;
  title: string;
  topBorderClass: string;
  returnToHome: boolean = false;
  hideCloseButton = false;

  constructor(private router :Router, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.title = '';
    this.topBorderClass = `top-border-green`;
  }

  returnToHomeClick(){
    this.router.navigate([OriginationRoutingHelper.Home()]);
    this.activeModal.dismiss();
  }
}

