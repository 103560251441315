import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild, Input } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[toPercentUptoN]'
})
export class ToPercentUpToNDirective implements OnInit {
    private ngControl: NgControl;
    @Input('toPercentUptoN') upToDigits: string;
    @Input('allowNegative') allowNegative: boolean=false;
    htmlEl: HTMLElement;

    constructor(public el: ElementRef, public renderer: Renderer2, private percentPipe: PercentPipe, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
        this.htmlEl = el.nativeElement;
    }

    ngOnInit() {
        setTimeout(() => {
            if (this.el.nativeElement.value !== '') {
                this.format(this.el.nativeElement.value);
            }
        }, 1000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        if (e !== '') {
            this.format(e);
        }
    }

    format(val: string) {
      const replacedVal = val.replace(/[% ,]/g, '');
      let numberFormat = Number(replacedVal);

      numberFormat = this.allowNegative ? numberFormat / 100 : numberFormat > 0 ? numberFormat / 100 : numberFormat;
      let percentFormatValue;
        if (this.upToDigits) {
            const percentFormatVal = isNaN(numberFormat) ? val : this.percentPipe.transform(numberFormat, this.upToDigits);
            percentFormatValue = percentFormatVal;
        }
        else {
            const percentFormatVal = isNaN(numberFormat) ? val : this.percentPipe.transform(numberFormat, '1.2-5');
            percentFormatValue = percentFormatVal;
        }
      
       if(isNaN(+replacedVal))
       {
        this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
        this.ngControl.control.setErrors({ pattern: true });
        this.renderer.setProperty(this.el.nativeElement, 'value', val);
       }
        else if (numberFormat > 100000000) {
            this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
            this.renderer.setProperty(this.el.nativeElement, 'value', '');
            
        } else {
          
            this.renderer.setProperty(this.el.nativeElement, 'value', percentFormatValue.replace(/[,]/g, ''));
            isNaN(numberFormat) ? this.ngControl.viewToModelUpdate(0) : this.ngControl.viewToModelUpdate(numberFormat);
            this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
        }
    }

}
