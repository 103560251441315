import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'orderByDate' })
export class OrderByDatePipe implements PipeTransform {

    transform(records: Array<any>, args?: any): any {
        if (records) {
            return records.sort((a, b) => new Date(a.entryTime).getTime() - new Date(b.entryTime).getTime());
        }
    }
}
