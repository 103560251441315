import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgControl } from '@angular/forms';
import { isNullOrUndefined } from 'is-what';

@Directive({
    selector: '[toCurrencytwodigits]'
})
export class ToCurrencyTwoDigitsDirective implements OnInit {
    private ngControl: NgControl;
    private htmlEl: HTMLElement;
    @Input('toCurrencytwodigits') maxAmount: string;
    @Input('nonNegative') nonNegative: string;
    @Input('genericData') generic: string;
    constructor(public el: ElementRef, public renderer: Renderer2, private currencyPipe: CurrencyPipe, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
        this.htmlEl = el.nativeElement;
    }

    ngOnInit() {
        setTimeout(() => {
            this.format(this.el.nativeElement.value);
        }, 3000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    format(val: string) {
        const replacedVal = val.replace(/[$ ,]/g, '');
        const numberFormat = parseFloat(replacedVal);
        const usd = isNaN(numberFormat) ? val : this.currencyPipe.transform(numberFormat, 'USD', 'symbol', '1.2-2');
        let allowNegative = true;
        if (numberFormat % 1 != 0)
        {
          if(this.getDecimalPlaces(numberFormat)>2)
          {
            this.renderer.setProperty(this.el.nativeElement, 'value', '');
             this.ngControl.control.setErrors({ pattern : true });
            return false;
            
            
          }
        }
        if (isNullOrUndefined(this.maxAmount) || this.maxAmount.trim() === '') {
            this.maxAmount = '1000000000';
        }
        if (!isNullOrUndefined(this.nonNegative) && this.nonNegative === 'true'
            && !isNullOrUndefined(val) && val.trim() !== '' && numberFormat <= 0) {
            allowNegative = false;
        }

        if (numberFormat > +this.maxAmount || isNaN(+replacedVal) || !allowNegative) {
            if(this.generic === 'true'){
                if(numberFormat > +this.maxAmount){
                    this.renderer.setProperty(this.el.nativeElement, 'value', this.currencyPipe.transform(0, 'USD', 'symbol', '1.2-2'));
                    this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                    this.ngControl.control.setErrors({ pattern: true });
                    if (!this.htmlEl.classList.contains('is-invalid')) {
                        this.htmlEl.classList.add('is-invalid');
                    }
                }
                else if(val ==='0' || val === ''){
                    this.renderer.setProperty(this.el.nativeElement, 'value', this.currencyPipe.transform(0, 'USD', 'symbol', '1.2-2'));
                    this.ngControl.control.setErrors({ pattern : true });
                }
                else {
                  this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                  this.ngControl.control.setErrors({ pattern: true });
                  this.renderer.setProperty(this.el.nativeElement, 'value', val);
                  if (!this.htmlEl.classList.contains('is-invalid')) {
                    this.htmlEl.classList.add('is-invalid');
                  }
                }
            }
            else{
                this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                this.ngControl.control.setErrors({ pattern: true });    
                this.renderer.setProperty(this.el.nativeElement, 'value', val);
                if (!this.htmlEl.classList.contains('is-invalid')) {
                    this.htmlEl.classList.add('is-invalid');
                }
            }
        } else {
            this.renderer.setProperty(this.el.nativeElement, 'value', usd);
            isNaN(numberFormat) ? this.ngControl.viewToModelUpdate(0) : this.ngControl.viewToModelUpdate(numberFormat);
            this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
            if (!this.htmlEl.classList.contains('is-invalid')) {
                this.htmlEl.classList.remove('is-invalid');
               
            }
        }
    }

    getDecimalPlaces(num) {
        var match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!match) { return 0; }
        return Math.max(
             0,
             // Number of digits right of decimal point.
             (match[1] ? match[1].length : 0)
             // Adjust for scientific notation.
             - (match[2] ? +match[2] : 0));
      }

}
