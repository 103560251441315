import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGetDeliveryCommitmentReportApiResponse } from './delivery-commitment/IGetDeliveryCommitmentReportApiResponse.ts';
import { IDeliveryCommitmentRequestModel } from './delivery-commitment/IDeliveryCommitmentRequestModel';
import { IDeliveryCommitmentResponseModel } from './delivery-commitment/IDeliveryCommitmentResponseModel';
import { ILockDCRequestModel } from './delivery-commitment/ILockDCRequestModel';
import { ILockDCResponseModel } from './delivery-commitment/ILockDCResponseModel';
import { IGetDeliveryCommitmentListRequestModel } from './delivery-commitment/IGetDeliveryCommitmentListRequestModel';
import { IValidateDcRequestModel } from './delivery-commitment/IValidateDcRequestModel';
import { IValidateDcResponseModel } from './delivery-commitment/IValidateDcResponseModel';
import { IDeliveryCommitmentScheduleRequestModel } from './delivery-commitment/IDeliveryCommitmentScheduleRequestModel';
import { IGetDeliveryCommitmentSchedulesResponseModel } from './delivery-commitment/IGetDeliveryCommitmentSchedulesResponseModel';
import { IValidateBusinessHoursForDCCreationResponseModel } from './delivery-commitment/IValidateBusinessHoursForDCCreationResponseModel';
import { IGetActiveSpecialScheduleForDCResponseModel } from './delivery-commitment/IGetActiveSpecialScheduleForDCResponseModel';
import { IGetDeliveryCommitmentResponseModel } from './delivery-commitment/GetDeliveryCommitmentResponseModel';
import { IGetDeliveryCommitmentRequestModel } from './delivery-commitment/GetDeliveryCommitmentRequestModel';
import { IGetHolidaysRequestModel } from './delivery-commitment/IGetHolidaysRequestModel';
import { IGetHolidaysResponseModel } from './delivery-commitment/IGetHolidaysResponseModel';
import { IValidateMcResponseModel } from './delivery-commitment/IValidateMcResponseModel';
import { IValidateMcRequestModel } from './delivery-commitment/IValidateMcRequestModel';
import { IGetBestEffortsDCLookupResponseModel } from './delivery-commitment/IGetBestEffortsDCLookupResponseModel';
import {IGetBestEffortsDCLookupRequestModel} from './delivery-commitment/IGetBestEffortsDCLookupRequestModel';
import { IGetNoteRateRequestModel } from './delivery-commitment/IGetNoteRateRequestModel';
import { IGetNoteRateResponseModel } from './delivery-commitment/IGetNoteRateResponseModel';
import { IGetCommitmentTermResponseModel } from './delivery-commitment/IGetCommitmentTermResponseModel';
import { IGetCommitmentTermRequestModel } from './delivery-commitment/IGetCommitmentTermRequestModel';
import { IGetDcScheduleForCommitmentTermRequestModel } from './delivery-commitment/IGetDcScheduleForCommitmentTermRequestModel';
import { IGetDcScheduleForCommitmentTermResponseModel } from './delivery-commitment/IGetDcScheduleForCommitmentTermResponseModel';
import { IValidateCreateDcRequestModel } from './delivery-commitment/IvalidateCreateDcRequestModel';
import { IValidateCreateDCResponseModel } from './delivery-commitment/IValidateCreateDCResponseModel';
import { IGetPricingGridResponseModel } from './delivery-commitment/IGetPricingGridResponseModel';
import { IGetPricingGridRequestModel } from './delivery-commitment/IGetPricingGridRequestModel';
import { IValidateBestEffortsDCResponseModel } from './delivery-commitment/IValidateBestEffortsDCResponseModel';
import { IValidateBestEffortsDCRequestModel } from './delivery-commitment/IValidateBestEffortsDCRequestModel';
import { IGetStatesResponseModel } from './delivery-commitment/IGetStatesResponseModel';
import { IValidateDeliveryCommitmentRequestModel } from './delivery-commitment/IValidateDeliveryCommitmentRequestModel';
import { HttpUtilHelper } from 'src/shared/helpers/http-util-helper';
import { IGetAuthorizedByResponseModel } from './delivery-commitment/IGetAuthorizedByResponseModel';
import { IGetAuthorizedByRequestModel } from './delivery-commitment/IGetAuthorizedByRequestModel';
import { IGetNoteRatesAndAgentFeesResponseModel } from './delivery-commitment/IGetNoteRatesAndAgentFeesResponseModel';
import { IGetNoteRatesAndAgentFeesRequestModel } from './delivery-commitment/IGetNoteRatesAndAgentFeesRequestModel';
import { IGetReductionsRequestModel } from './delivery-commitment/IGetReductionsRequestModel';
import { IGetReductionsResponseModel } from './delivery-commitment/IGetReductionsResponseModel';
import { IGetExtensionsResponseModel } from './delivery-commitment/IGetExtensionsResponseModel';
import { IGetExtensionsRequestModel } from './delivery-commitment/IGetExtensionsRequestModel';
import { IGetDCLoanFundingRequestModel } from './delivery-commitment/IGetDCLoanFundingRequestModel';
import { IGetDCLoanFundingResponseModel } from './delivery-commitment/IGetDCLoanFundingResponseModel';
import { ICreateXtraDeliveryCommitmentRequestModel } from './delivery-commitment/ICreateXtraDeliveryCommitmentRequestModel';
import { ICreateXtraDeliveryCommitmentResponseModel } from './delivery-commitment/ICreateXtraDeliveryCommitmentResponseModel';
import { ISendEmailToRequestModel } from '../loan-funding/models/SendEmailToRequestModel';
import { ISendEmailToResponseModel } from '../loan-funding/models/SendEmailToResponseModel';
import { ICreateDeliveryCommitmentRequestModel } from './delivery-commitment/ICreateDeliveryCommitmentRequestModel';
import { ICreateDeliveryCommitmentResponseModel } from './delivery-commitment/ICreateDeliveryCommitmentResponseModel';
import { IDownloadDCRequestModel } from './delivery-commitment/IDownloadDCRequestModel';
import { ICalculateExtensionFeeRequestModel } from './delivery-commitment/ICalculateExtensionFeeRequestModel';
import { ICalculateExtensionFeeResponcetModel } from './delivery-commitment/ICalculateExtensionFeeResponcetModel';
import { ICalculatePairOffFeeRequestModel } from './delivery-commitment/ICalculatePairOffFeeRequestModel';
import { ICalculatePairOffFeeResponseModel } from './delivery-commitment/ICalculatePairOffFeeResponseModel';
import { ISubmitDcExtensionRequestModel } from './delivery-commitment/ISubmitDcExtensionRequestModel';
import { ISubmitDcExtensionResponseModel } from './delivery-commitment/ISubmitDcExtensionResponseModel';
import { ISubmitDcReductionRequestModel } from './delivery-commitment/ISubmitDcReductionRequestModel';
import { ISubmitDcReductionResponseModel } from './delivery-commitment/ISubmitDcReductionResponseModel';
import { IValidateDcBusinessDateRequest } from './delivery-commitment/IValidateDcBusinessDateRequest';
import { IGetDeliveryCommitmentSummaryResponseModel } from './delivery-commitment/IGetDeliveryCommitmentSummaryResponseModel';
import { AppConfigService } from '@app-shared/services';
import { IGetDCConfigurationResponse } from './delivery-commitment/IGetDCConfigurationResponse';
import { IGetDCTimerExpirationTimeRequestModel } from './delivery-commitment/IGetDCTimerExpirationTimeRequestModel';
import { IGetDCTimerExpirationTimeResponseModel } from './delivery-commitment/IGetDCTimerExpirationTimeResponseModel';

@Injectable({
    providedIn: 'root'
})

export class DeliveryCommitmentService {
     //Microservice API Base URL
     private MICROSERVICE_PATH =  `${AppConfigService.Settings.Mpf.ApiEndpoint}`;

     private LOOKUP_MICROSERVICE_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
     private ORG_MICROSERVICE_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
     private LOAN_MICROSERVICE_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;

    private BUILT_API_PATH = `${this.MICROSERVICE_PATH}/api/deliverycommitment/ext`
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/deliverycommitment`;

    private LOOKUP_BUILT_API_PATH = `${this.LOOKUP_MICROSERVICE_PATH}/api/lookup/ext`;
    private API_LOOKUP_SERVICE_PATH = `${this.LOOKUP_BUILT_API_PATH}/lookup`;

    private ORG_BUILT_API_PATH = `${this.ORG_MICROSERVICE_PATH}/api/organization/ext`;
    private API_ORG_SERVICE_EXT_PATH = `${this.ORG_BUILT_API_PATH}/organization`;

    private LOAN_BUILT_API_PATH = `${this.LOAN_MICROSERVICE_PATH}/api/loan/ext`;
    private API_LF_SERVICE_EXT_PATH = `${this.LOAN_BUILT_API_PATH}/loanfunding`;

    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }
    constructor(private http: HttpClient) {
    }


    getDeliveryCommitmentDetails(dcRequestModel: IDeliveryCommitmentRequestModel): Observable<IDeliveryCommitmentResponseModel> {
       return this.http.post<IDeliveryCommitmentResponseModel>(`${this.API_SERVICE_PATH}/getdeliverycommitmentdetails`, dcRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    getDCReports(): Observable<IGetDeliveryCommitmentReportApiResponse> {
        return this.http.get<IGetDeliveryCommitmentReportApiResponse>(`${this.API_SERVICE_PATH}/dcreports`, this.options)
            .pipe(map(response => response)
            );
    }

    lockDC(lockModeRequestmodel: ILockDCRequestModel): Observable<ILockDCResponseModel> {
        return this.http.post<ILockDCResponseModel>(`${this.API_SERVICE_PATH}/lockdc`, lockModeRequestmodel, this.options)
            .pipe(map(response => response)
            );
    }

    validateBestEffortsDCWebSocket(connectionId: string, validateBestEffortsDCRequestModel: IValidateBestEffortsDCRequestModel): Observable<IValidateBestEffortsDCResponseModel> {
        return this.http.post<IValidateBestEffortsDCResponseModel>(`${this.API_SERVICE_PATH}/validatebesteffortsdc`, validateBestEffortsDCRequestModel, this.websocketOptions(connectionId))
            .pipe(map(response => response)
            );
    }

    validateMC(validateMCRequestModel: IValidateMcRequestModel): Observable<IValidateMcResponseModel> {
        return this.http.get<IValidateMcResponseModel>
            (`${this.API_SERVICE_PATH}/${'validateMc'.toLowerCase()}?${HttpUtilHelper.ConvertQueryString(validateMCRequestModel)}`, this.options)
            .pipe(map(response => response)
            );
    }

    validateBusinessHoursForDCCreation(): Observable<IValidateBusinessHoursForDCCreationResponseModel> {
        return this.http.post<IValidateBusinessHoursForDCCreationResponseModel>(`${this.API_SERVICE_PATH}/validatebusinesshoursfordccreation`, this.options)
            .pipe(map(response => response)
            );
    }

    getActiveSpecialSchedulesForDC(): Observable<IGetActiveSpecialScheduleForDCResponseModel> {
        return this.http.post<IGetActiveSpecialScheduleForDCResponseModel>(`${this.API_SERVICE_PATH}/getactivespecialschedulesfordc`, this.options)
            .pipe(map(response => response)
            );
    }

    sendDCEmail(requestModel: ISendEmailToRequestModel): Observable<ISendEmailToResponseModel> {
        return this.http.post<ISendEmailToResponseModel>(`${this.API_SERVICE_PATH}/senddcemail`, requestModel)
            .pipe(map(response => response)
            );
    }

    createDeliveryCommitmentWebSocket(connectionId: string, requestModel: ICreateDeliveryCommitmentRequestModel): Observable<ICreateDeliveryCommitmentResponseModel> {
        return this.http.post<ICreateDeliveryCommitmentResponseModel>(`${this.API_SERVICE_PATH}/createdeliverycommitment`, requestModel, this.websocketOptions(connectionId))
            .pipe(map(response => response)
            );
    }

    getCommitmentTerm(getCommitmentTermRequestModel: IGetCommitmentTermRequestModel): Observable<IGetCommitmentTermResponseModel> {
        return this.http.post<IGetCommitmentTermResponseModel>(`${this.API_SERVICE_PATH}/getcommitmentterm`, getCommitmentTermRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    getScheduleForDC(getDcScheduleForCommitmentTermRequestModel: IGetDcScheduleForCommitmentTermRequestModel): Observable<IGetDcScheduleForCommitmentTermResponseModel> {
        return this.http.post<IGetDcScheduleForCommitmentTermResponseModel>(`${this.API_SERVICE_PATH}/getschedulefordc`, getDcScheduleForCommitmentTermRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    getBestEffortsDCLookup(request:IGetBestEffortsDCLookupRequestModel): Observable<IGetBestEffortsDCLookupResponseModel> {
        return this.http.post<IGetBestEffortsDCLookupResponseModel>(`${this.API_SERVICE_PATH}/getbesteffortsdclookup`,request, this.options)
            .pipe(map(response => response)
            );
    }

    getPricingGridValues(getPricingGridRequestModel: IGetPricingGridRequestModel): Observable<IGetPricingGridResponseModel> {
        return this.http.post<IGetPricingGridResponseModel>(`${this.API_SERVICE_PATH}/getpricinggridvalues`, getPricingGridRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    createXtraDeliveryCommitmentWebSocket(connectionId: string, requestModel: ICreateXtraDeliveryCommitmentRequestModel): Observable<ICreateXtraDeliveryCommitmentResponseModel> {
        return this.http.post<ICreateXtraDeliveryCommitmentResponseModel>(`${this.API_SERVICE_PATH}/creatextradeliverycommitment`, requestModel, this.websocketOptions(connectionId))
            .pipe(map(response => response)
            );
    }

    getDeliveryCommitments(): Observable<IGetDeliveryCommitmentSummaryResponseModel> {
        return this.http.get<IGetDeliveryCommitmentSummaryResponseModel>(`${this.API_SERVICE_PATH}/getdeliverycommitments`, this.options)
            .pipe(map(response => response)
            );
    }

     calculatePairOffFee(requestModel: ICalculatePairOffFeeRequestModel): Observable<ICalculatePairOffFeeResponseModel> {
         return this.http.post<ICalculatePairOffFeeResponseModel>(`${this.API_SERVICE_PATH}/calculatepairofffee`, requestModel)
             .pipe(map(response => response)
             );
     }

    calculateExtensionFee(requestModel: ICalculateExtensionFeeRequestModel): Observable<ICalculateExtensionFeeResponcetModel> {
        return this.http.post<ICalculateExtensionFeeResponcetModel>(`${this.API_SERVICE_PATH}/calculateextensionfee`, requestModel)
            .pipe(map(response => response)
            );
    }

    submitDCExtend(requestModel: ISubmitDcExtensionRequestModel): Observable<ISubmitDcExtensionResponseModel> {
        return this.http.post<ISubmitDcExtensionResponseModel>(`${this.API_SERVICE_PATH}/submitdcextend`, requestModel)
            .pipe(map(response => response)
            );
    }

     submitDCReduce(requestModel: ISubmitDcReductionRequestModel): Observable<ISubmitDcReductionResponseModel> {
         return this.http.post<ISubmitDcReductionResponseModel>(`${this.API_SERVICE_PATH}/submitdcreduce`, requestModel)
             .pipe(map(response => response)
             );
     }

    getNoteRates(getNoteRateRequestModel: IGetNoteRateRequestModel): Observable<IGetNoteRateResponseModel> {
        return this.http.post<IGetNoteRateResponseModel>(`${this.API_SERVICE_PATH}/getnoterates`, getNoteRateRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    validateCreateDCWebSocket(connectionId: string, validateCreateDcRequestModel: IValidateCreateDcRequestModel): Observable<IValidateCreateDCResponseModel> {
        return this.http.post<IValidateCreateDCResponseModel>(`${this.API_SERVICE_PATH}/validatecreatedc`, validateCreateDcRequestModel, this.websocketOptions(connectionId))
            .pipe(map(response => response)
            );
    }

    downloadDCReport(downloadDCRequestModel: IDownloadDCRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/downloaddcreport`, downloadDCRequestModel, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    CalculateMinimumExpirationDate(): Observable<IGetDCConfigurationResponse> {
        const t= this.http.post<IGetDCConfigurationResponse>(`${this.API_SERVICE_PATH}/minimumexpirationdate`, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response) 
            );
            return t;
    }

    getDCListWebSocket(connectionId: string, dcListRequestModel: IGetDeliveryCommitmentListRequestModel): Observable<IDeliveryCommitmentResponseModel> {
        return this.http.post<IDeliveryCommitmentResponseModel>(`${this.API_SERVICE_PATH}/getdclist`, dcListRequestModel, this.websocketOptions(connectionId))
            .pipe(map(response => response)
            );
    }

    validateDC(validateDCRequestModel: IValidateDcRequestModel): Observable<IValidateDcResponseModel> {
        return this.http.post<IValidateDcResponseModel>(`${this.API_SERVICE_PATH}/validatedc`, validateDCRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    getDcSchedules(dcScheduleRequest: IDeliveryCommitmentScheduleRequestModel): Observable<IGetDeliveryCommitmentSchedulesResponseModel> {
        return this.http.post<IGetDeliveryCommitmentSchedulesResponseModel>(`${this.API_SERVICE_PATH}/getschedules`, dcScheduleRequest, this.options)
            .pipe(map(response => response)
            );
    }

    validateDCNewDeliveryDate(requestModel: IValidateDcBusinessDateRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/validatedcnewdeliverydate`, requestModel)
            .pipe(map(response => response)
            );
    }

    validateDaysExtend(requestModel: IValidateDcBusinessDateRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/validatedaysextend`, requestModel)
            .pipe(map(response => response)
            );
    }

    getNoteRatesAndAgentFees(getNoteRatesAndAgentFeesRequestModel: IGetNoteRatesAndAgentFeesRequestModel): Observable<IGetNoteRatesAndAgentFeesResponseModel> {
        return this.http.post<IGetNoteRatesAndAgentFeesResponseModel>(`${this.API_SERVICE_PATH}/getnoteratesandagentfees`, getNoteRatesAndAgentFeesRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    getReductions(getReductionsRequestModel: IGetReductionsRequestModel): Observable<IGetReductionsResponseModel> {
        return this.http.post<IGetReductionsResponseModel>(`${this.API_SERVICE_PATH}/getreductions`, getReductionsRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    getExtensions(getExtensionsRequestModel: IGetExtensionsRequestModel): Observable<IGetExtensionsResponseModel> {
        return this.http.post<IGetExtensionsResponseModel>(`${this.API_SERVICE_PATH}/getextensions`, getExtensionsRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    generateDCReports(requestModel: any): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/generatedcreports`, requestModel, this.options)
            .pipe(map(response => response)
            );
    }

  generateLfReports(generateLfReportsRequestModel: any): Observable<any> {
    return this.http.post(`${this.API_LF_SERVICE_EXT_PATH}/generatelfreports`, generateLfReportsRequestModel, this.options)
      .pipe(map(response => response) 
      );
  }

    generateNewDCConfirmation(requestModel: any): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/generatenewdcconfirmation`, requestModel, this.options)
            .pipe(map(response => response)
            );
    }

    getHolidays(getHolidaysRequestModel: IGetHolidaysRequestModel): Observable<IGetHolidaysResponseModel> {
        return this.http.post<IGetHolidaysResponseModel>(`${this.API_SERVICE_PATH}/getholidays`, getHolidaysRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    
    validateDeliveryCommitment(validateDeliveryCommitmentRequestModel: IValidateDeliveryCommitmentRequestModel): Observable<IValidateDcResponseModel> {
        return this.http.post<IValidateDcResponseModel>(`${this.API_SERVICE_PATH}/validatedeliverycommitment`, validateDeliveryCommitmentRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

    getDeliveryCommitmentById(getDeliveryCommitmentRequestModel: IGetDeliveryCommitmentRequestModel): Observable<IGetDeliveryCommitmentResponseModel> {
        return this.http.post<IGetDeliveryCommitmentResponseModel>(`${this.API_SERVICE_PATH}/getdeliverycommitmentbyid`, getDeliveryCommitmentRequestModel, this.options)
            .pipe(map(response => response)
            );
    }

  getStates(): Observable<IGetStatesResponseModel> {
    return this.http.get<IGetStatesResponseModel>(`${this.API_LOOKUP_SERVICE_PATH}/${'getstates'}`, this.options)
      .pipe(map(response => response)
      );

  }

  getAuthorizedByName(getAuthorizedByRequestModel: IGetAuthorizedByRequestModel): Observable<IGetAuthorizedByResponseModel> {
    return this.http.post<IGetAuthorizedByResponseModel>(`${this.API_ORG_SERVICE_EXT_PATH}/getauthorizedbyname`, getAuthorizedByRequestModel, this.options)
      .pipe(map(response => response)
      );
  }
  
    getDCLoanFunding(getDCLoanFundingRequestModel: IGetDCLoanFundingRequestModel): Observable<IGetDCLoanFundingResponseModel> {
        return this.http.post<IGetDCLoanFundingResponseModel>(`${this.API_LF_SERVICE_EXT_PATH}/getdcloanfunding`, getDCLoanFundingRequestModel, this.options)
            .pipe(map(response => response) 
            );
    }

    getDCTimerExpirationTime(dcTimerExpRequest: IGetDCTimerExpirationTimeRequestModel): Observable<IGetDCTimerExpirationTimeResponseModel> {
        return this.http.get<IGetDCTimerExpirationTimeResponseModel>
            (`${this.API_SERVICE_PATH}/${'getdctimerexpirationtime'.toLowerCase()}?${HttpUtilHelper.ConvertQueryString(dcTimerExpRequest)}`, this.options)
            .pipe(map(response => response)
            );
    }
}

