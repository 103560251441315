import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@app-shared/services';
import { GetAecSearchDataRequestModel } from '@empf-shared/services/reports/models/getAecSearchDataRequestModel';
import { IReportsResponseModel } from '@empf-shared/services/reports/models/IReportsResponseModel';
import { SortHelper } from 'src/shared/helpers/sort-helper';
@Component({
  selector: 'app-aec-report-page',
  templateUrl: './aec-report.page.component.html',
  styleUrls: ['./aec-report.page.component.sass'],
})
export class AecReportPageComponent implements OnInit {
  @Input() yearList = this.getDropDownDefaultValues();
  @Input() ogStatus = this.getDropDownDefaultValues();
  @Input() searchList: IReportsResponseModel;
  @Output() searchDataEvent = new EventEmitter<GetAecSearchDataRequestModel>();

  @Input() pfiList = this.getPfiGenericDropDownDefaultValues();
  @Input() isPFIUser: boolean;
  constructor(private router: Router,) { }

  sort = {
    column: 'pfiID',
    column2: 'ogFormType',
    column3: 'acceptRejectDate',
    descending: false
};
  ngOnInit() {
  }

  
  getPfiGenericDropDownDefaultValues() {
    return {
      availableOptions: [{ id: 0, name: 'SELECT' }],
      selectedOption: { id: 0, name: 'SELECT' }
    };
  }
  getDropDownDefaultValues() {
    return {
      availableOptions: [{ id: 0, name: '' }],
      selectedOption: { id: 0, name: '' }
    };
  }

  searchData() {
    let request = {} as GetAecSearchDataRequestModel;
    request.ogStatus = this.ogStatus.selectedOption.name;
    request.ogYear = this.yearList.selectedOption.id;
    request.pfiId = this.pfiList.selectedOption.id.toString();
    this.searchDataEvent.emit(request);
  }

  sortColumn(column) {
    const sort = this.sort;
    return SortHelper.sortColumn(column, sort);
}

changeSorting(column, column2, column3) {
    const sort = this.sort;
    SortHelper.changeMultiSorting(column, column2,column3, sort);
    
}

}
