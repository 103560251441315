import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDeliveryCommitment } from '@empf-shared/services/transaction/delivery-commitment/IDeliveryCommitment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SortHelper } from 'src/shared/helpers/sort-helper';
import { ICmsContent } from 'src/shared/models/content.model';

@Component({
    selector: 'app-active-dc-modal',
    templateUrl: './active-dc-modal.component.html',
    styleUrls: ['./active-dc-modal.component.sass']
})
export class ActiveDCModalComponent implements OnInit {
    title: string;
    topBorderClass = `top-border-blue`;
    public sortingName: string;
    public isDesc = false;

    sort = {
        column: 'dcNumber',
        descending: true
    };

    @Input() cmsModel: ICmsContent;
    @Input() hasRecords: boolean;
    @Input() noRecordError: string;
    @Input() dcList: IDeliveryCommitment[];
    @Output() validateDCClick: EventEmitter<number> = new EventEmitter<number>();

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() { }

    sortColumn(column) {
        const sort = this.sort;
        return SortHelper.sortColumn(column, sort);
    }

    changeSorting(column) {
        const sort = this.sort;
        SortHelper.changeSorting(column, sort);
    }

    selectedDc(dcNumber: number) {
        this.validateDCClick.emit(dcNumber);
        this.activeModal.close(dcNumber);
    }
}
