import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAccountLogin } from 'src/entry/components/models/account-login.model';
import { AppConfigService } from '../configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private MICROSERVICE_PATH_ORG =  `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH_ORG = `${this.MICROSERVICE_PATH_ORG}/api/organization/ext`;
  private ORG_API_SERVICE_EXT_PATH = `${this.BUILT_API_PATH_ORG}/organization`;


  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true };

  constructor(private http: HttpClient) { }

  getHLBForFHLBank(): Observable<any> {
      return this.http.get(`${this.ORG_API_SERVICE_EXT_PATH}/${'getHLBforbank'.toLowerCase()}`)
      .pipe(map(response => response)
      );
  }

}
