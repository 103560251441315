import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessagesService } from '@empf-shared/services/header/messages-service';

@Component({
  selector: 'sites-banner-container',
  templateUrl: './sites-banner-container.component.html',
  styleUrls: ['./sites-banner-container.component.sass']
})


export class SitesBannerContainerComponent implements OnInit {
    displayBannerMessage: boolean = false
    bannerMessageContentHTML: string = ''    
    bannerMessage: string = ''
  constructor(private router: Router, private messagesService: MessagesService) {
  }

  ngOnInit(): void {
    
    this.messagesService.GetLatestBannerMessageForUser().subscribe(response => {
      if (response !== null) {
        if(response.messageContent!=null)
        {
          this.bannerMessage=response.messageContent;
          this.bannerMessageContentHTML = '<div class="row"><div class="col-md-2 banner-message-content">IMPORTANT: </div><div class="col-md-10 banner-message-content-desc padding-top-5px padding-bottom-5px">' + this.bannerMessage + '</div></div>';
          this.displayBannerMessage = true;
        }
        else
          this.displayBannerMessage=false;    
    }
  
  })  
  }       
}
    




