<div>
    <div class="row">
      <div class="col-xl-3 col-lg-6">
        <div>
          <div class="progress-main mx-auto">
            <span class="progress-left">
                          <span id="progressLeft" class="progress-bar border-primary"></span>
            </span>
            <span class="progress-right"> 
                          <span id="progressRight" class="progress-bar border-primary"></span>
            </span>
            <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
              <div class="h2 fw-bold">{{progressText}}</div>
            </div>
          </div>
      </div>
    </div>
</div>
  