import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpiredNotificationContainerComponent } from '@app-shared/components/session/expired-notification/expired-notification.container.component';
import { RefreshNotificationContainerComponent } from '@app-shared/components/session/refresh-notification/refresh-notification.container.component';
import { fhlbConstants } from '@app-shared/constants/fhlb-constants';
import { AppConfigService, CustomToastService } from '@app-shared/services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, interval, timer } from 'rxjs';
import { catchError, filter, map, take, withLatestFrom } from 'rxjs/operators';
import { isNullOrUndefined } from 'is-what';
import { AuthActionTypes, LogOutAcknownledgeAction, LogOutAction, LogOutSuccessAction, RefreshTokensAction, RefreshTokensFailAction, RefreshTokensSuccessAction, SetRefreshStatusAction, UserProfileSelectedAction } from '../actions/auth.actions';
import { AppState } from '../reducers';
import { getRefreshStatusFromStore, getSelectedProfileScopeId, getLogInStatusFromStore, getExpiryDateFromStore, getEmpfAppShowRefreshModalDateTimeFromStore, getEmpfAppExpTimeStamp } from '../selectors';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import { LoginFlow } from '@app-shared/constants/login-flow';

@Injectable()
export class AuthEffects {
    constructor(
        private actions: Actions,
        private store$: Store<AppState>,
        private modalService: NgbModal,
        private toastr: CustomToastService,
        private cookieService: CookieService,
        private httpClient: HttpClient,
        private authService: MsalService,
        private router: Router
    ) {
    }

    private readonly logOutIntervalCheckSeconds = AppConfigService.Settings.Empf.LogOutIntervalCheckSeconds;
    private readonly refreshTokenIntervalCheckSeconds = AppConfigService.Settings.Empf.RefreshTokenIntervalCheckSeconds; 
    private readonly backgroundRefreshTokensMinutes = AppConfigService.Settings.Empf.BackgroundRefreshTokensMinutes;   

    private refreshModal: NgbModalRef = undefined;
    private expireModal: NgbModalRef;
    private profileScopeIDfromStore: string;


    showLogOffExpiredModalFromTimer$ = createEffect(() => interval(1000 * this.logOutIntervalCheckSeconds).pipe(
        withLatestFrom(this.store$.select(getEmpfAppExpTimeStamp)),
        filter(([time, expiryDateTime]) => !isNullOrUndefined(expiryDateTime)),
        map(([time, expiryDateTime]) => {
            let dateNowMoment = moment();
            let expireMoment = moment(expiryDateTime);

            if (dateNowMoment >= expireMoment) {
                this.store$.dispatch(new LogOutAction({ acknowledge: true }));
            }
        })
    ), { dispatch: false })


    showRefreshModalFromTimer$ = createEffect(() => timer(1000 * this.refreshTokenIntervalCheckSeconds, 1000 * this.refreshTokenIntervalCheckSeconds)
        .pipe(
            withLatestFrom(
                this.store$.select(getLogInStatusFromStore),
                this.store$.select(getExpiryDateFromStore),
                this.store$.select(getRefreshStatusFromStore),
                this.store$.select(getEmpfAppShowRefreshModalDateTimeFromStore)
            ),
            filter(([time, loggedIn, expiryDate, refreshStatus, refreshTime]) =>
                !isNullOrUndefined(expiryDate) && !isNullOrUndefined(loggedIn)
                && !isNullOrUndefined(refreshStatus) && !isNullOrUndefined(refreshTime)
                && loggedIn
                && (!refreshStatus || (refreshStatus && isNullOrUndefined(this.refreshModal)))),
            map(([time, loggedIn, expiryDate, refreshStatus, refreshTime]) => {
                let dateNowMoment = moment();
                let expiryMoment = moment(refreshTime);

                if (dateNowMoment > expiryMoment && !refreshStatus && isNullOrUndefined(this.refreshModal)) {
                    this.refreshModal = this.modalService.open(RefreshNotificationContainerComponent, {
                        size: 'md', backdrop: 'static',
                        windowClass: 'centered-modal', keyboard: false
                    });
                    return new SetRefreshStatusAction(true)
                }

                return new SetRefreshStatusAction(false)
            })
        ));


    modalExtendButtonClicked$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.MODAL_EXTEND_SESSION),
        map(() => {
            if (!isNullOrUndefined(this.refreshModal)) {
                this.refreshModal.close();
                this.refreshModal = null;
            }
            return new RefreshTokensAction({
                updateEmpfSessionTime: true,
                navigateToHome: null,
                loginFlow: null
            });
        })
    ));


    modalLogOutButtonClicked$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.MODAL_LOG_OUT),
        map(() => {
            if (!isNullOrUndefined(this.refreshModal)) {
                this.refreshModal.close();
                this.refreshModal = null;
            }
            return new LogOutAction({ acknowledge: false });
        })
    ));


    resetRefreshTimerOnTokenRefreshSuccess$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.REFRESH_TOKENS_SUCCESS),
        map((x: RefreshTokensSuccessAction) => {
            if (x.payload.updateEmpfSessionTime) {
                this.store$.dispatch(new SetRefreshStatusAction(false));
            }
        })
    ), { dispatch: false });


    resetRefreshTimerOnTokenRefreshFailure$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.REFRESH_TOKENS_FAIL),
        map((x: RefreshTokensFailAction) => {
            if (x.payload.updateEmpfSessionTime) {
                this.store$.dispatch(new SetRefreshStatusAction(false));
            }
        })
    ), { dispatch: false });


    logoutClear$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.LOG_OUT),
        map((action: LogOutAction) => {
            if (action.payload.acknowledge) {
                return new LogOutAcknownledgeAction()
            }

            return new LogOutSuccessAction();
        })
    ));


    logoutClearAcknownledge$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.LOG_OUT_ACKNOWLEDGE),
        map((x) => {
            this.modalService.dismissAll();
            this.expireModal = this.modalService.open(ExpiredNotificationContainerComponent, {
                size: 'md', backdrop: 'static',
                windowClass: 'centered-modal', keyboard: false
            });
        })
    ), { dispatch: false });


    logoutSuccess$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.LOG_OUT_SUCCESS),
        map((logoutSuccessAction: LogOutSuccessAction) => {
         this.authService.logoutRedirect();      
        })
    ), { dispatch: false });


    userProfileSelected$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.USER_PROFILE_SELECTED),
        map((action: UserProfileSelectedAction) => {
            return new RefreshTokensAction({
                updateEmpfSessionTime: true,
                navigateToHome: action.payload.navigateToHome,
                loginFlow: action.payload.loginFlow
            });
        })
    ));

    resetRefreshToken$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.REFRESH_TOKENS),
        map((action: RefreshTokensAction) => {
            this.acquireToken(action.payload.updateEmpfSessionTime, action.payload.navigateToHome, action.payload.loginFlow);
        }),
        catchError((error) => {
            console.log(error)
            this.store$.dispatch(new RefreshTokensFailAction({
                updateEmpfSessionTime: false
            }));

            return EMPTY
        })

    ), { dispatch: false });


    refreshTokensSuccess$ = createEffect(() => this.actions.pipe(
        ofType(AuthActionTypes.REFRESH_TOKENS_SUCCESS),
        map((action: RefreshTokensSuccessAction) => {
            if (!isNullOrUndefined(action.payload.navigateToHome) && action.payload.loginFlow == LoginFlow.USER_PROFILE_LOGIN_FLOW) {
                console.log("Navigate to home", action.payload.navigateToHome);
                this.router.navigate([action.payload.navigateToHome]);
            }
        })
    ), { dispatch: false });


    refreshB2CToken$ = createEffect(() => interval(1000 * 60 * this.backgroundRefreshTokensMinutes).pipe(
        withLatestFrom(this.store$.select(getLogInStatusFromStore)),
        filter(([time, loggedIn]) =>
            !isNullOrUndefined(loggedIn) && loggedIn
        ),
        map(() => {
            this.store$.dispatch(new RefreshTokensAction({
                updateEmpfSessionTime: false,
                navigateToHome: null,
                loginFlow: null
            }));

        })
    ), { dispatch: false });


    acquireToken(updateEmpfSessTime: boolean, navToHome: string, loginFlowPayload: LoginFlow): void {
        this.store$.select(getSelectedProfileScopeId).pipe(take(1),
            map(x => {
                this.profileScopeIDfromStore = x
                console.log("Inside acquireToken: ", this.profileScopeIDfromStore);
            })

        ).subscribe();
        if (!isNullOrUndefined(this.profileScopeIDfromStore))
        {
            let request: SilentRequest = {
                forceRefresh: true,
                scopes: [AppConfigService.Settings.apiConfig.uri + this.profileScopeIDfromStore], // Scopes for which you want to acquire token
            };
            this.authService.acquireTokenSilent(request)
                .subscribe(
                    (response) => {
                        this.store$.dispatch(new RefreshTokensSuccessAction({
                            encodedAzureB2CIDToken: response.idToken,
                            encodedAzureB2CAccessToken: response.accessToken,
                            updateEmpfSessionTime: updateEmpfSessTime,
                            navigateToHome: navToHome,
                            loginFlow: loginFlowPayload
                        }));
                    },
                    (error) => {
                        console.error('Error acquiring token:', error);
                        this.store$.dispatch(new RefreshTokensFailAction({ updateEmpfSessionTime: false }));
                    }
                );
        }
    }
}

