import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
// tslint:disable-next-line: directive-selector
    selector: '[compareElementValue]',
    providers: [{provide: NG_VALIDATORS, useExisting: CompareElementDirective, multi: true}]
})

export class CompareElementDirective implements Validator {
   @Input('compareElementValue') compareValues: string;

   validate(control: AbstractControl): {[key: string]: boolean} | null {
       let controlValue = control.value;
       let compareValuesArray = this.compareValues.split(',');
       if (compareValuesArray.indexOf(controlValue) > -1 && controlValue !== '') {
           control.setErrors({invalid: true});
           return { unique: true };
       }

       return null;
   }
}
