import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomToastService } from '@app-shared/services';
import { IKeyValuePair } from '@app-shared/models/key-value-pair.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as FromSearchStore from '../../../store/reducers/interactive-search.reducers'
import * as FromSearchActions from '../../../store/actions/interactive-search.actions'
import { OriginationRoutingHelper } from '@origination-shared/navigation/origination.navigation';
import { SearchType } from '@app-shared/models/types/SearchType.enum';
import { AppConfigService } from '@app-shared/services';
import { getIsFhlbcUserFromStore, getIsRoleFoundFromStore } from 'src/store/selectors';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/store/reducers';
import { CreatemergerModalComponent } from '../createmerger-modal/createmerger-modal-component';

@Component({
  selector: 'interactive-header',
  templateUrl: './interactive-header.component.html',
  styleUrls: ['./interactive-header.component.sass']
})
export class InteractiveHeaderComponent implements OnInit {
  searchInput: string;
  searchKey: number;
  createOptions: IKeyValuePair[];
  searchOptions: {key: number, value: string, placeholder: string}[];
  selectedCreateOption: string;
  displayCreateOption: string;
  showInteractiveHeader: boolean;
  isCreateAllowed: boolean;
  @BlockUI() blockUI: NgBlockUI;
  isCollapsed: boolean;
  isLosSecurityInquiryFound: boolean;
  showAddEditControls: boolean;
  inputPlaceholder: string;
  isRolesInStore: boolean = false;
  isFhlbcUser: boolean = false;
  
  constructor(private router: Router, public toastr: CustomToastService, private modalService: NgbModal,
      private searchStore: Store<FromSearchStore.State>, private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.store.select(getIsRoleFoundFromStore([AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry])).pipe(take(1), map(x => this.isLosSecurityInquiryFound = x)).subscribe()

    this.store.select(getIsRoleFoundFromStore([AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry, AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry_NOPII])).pipe(
      take(1),
      map(x => this.isRolesInStore = x)
    ).subscribe();

    this.store.select(getIsFhlbcUserFromStore).pipe(take(1), map(x => this.isFhlbcUser = x)).subscribe();


    this.interactiveHeaderShow();
    this.DcCheck();

    this.searchOptions = [
      {
        key: 1,
        value: 'Delivery Commitment',
        placeholder: 'Search DC Number'
      },
      {
        key: 2,
        value: 'Master Commitment',
        placeholder: 'Search MC Number'
      },
      {
        key: 3,
        value: 'Merger',
        placeholder: 'Search PFI Name or Number'
      },
      {
        key: 4,
        value: 'Loans',
        placeholder: 'Search by loan id'
      },
      {
        key: 5,
        value: 'MPF Loan',
        placeholder: 'Search by MPF Loan No'
      },
      {
        key: 6,
        value: 'PFI Loan',
        placeholder: 'Search PFI Number'
      },
      {
        key: 7,
        value: 'Organization',
        placeholder: 'Search PFI Name or Number'
      }
    ];

    this.searchKey = 7;
    this.createOptions = [
      {
        key: 9,
        value: 'Batch'
      },
      {
        key: 2,
        value: 'Delivery Commitment'
      },
      {
        key: 3,
        value: 'Loan Presentment'
      },
      {
        key: 4,
        value: 'Loan Funding'
      },
      {
        key: 5,
        value: 'Master Commitments'
      },
      {
        key: 6,
        value: 'Servicing Retained'
      },
      {
        key: 7,
        value: 'Concurrent Sale of Servicing'
      },
      {
        key: 8,
        value: 'Servicing Released Bifurcated/Co-Issue'
      },      
      {
        key: 10,
        value: 'Merger'
      },
      {
        key: 11,
        value: 'Organization'
      },
      {
        key: 12,
        value: 'Process CE Estimator File'
      }
      
    ];
    this.selectedCreateOption = 'Organization';
    this.searchChange()
  }

  searchChange() {
    this.inputPlaceholder = this.searchOptions.find(x => x.key == this.searchKey).placeholder
  }


  interactiveSearch() {
    this.toastr.remove();
    switch (this.searchKey) {
      case 1: {
        // DC
        this.blockUI.start();
        this.router.navigate([OriginationRoutingHelper.SearchDc()],
          { queryParams: { searchInput: this.searchInput }, queryParamsHandling: null });
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;
      }
      case 2: {
        this.blockUI.start();       
           this.router.navigate([OriginationRoutingHelper.SearchMc()],
         { queryParams: { searchType:SearchType.MC.toString(), searchInput: this.searchInput }, queryParamsHandling: null });
         setTimeout(() => {
           this.blockUI.stop();
         }, 1000);
       break;
      }
      case 3: {
        this.blockUI.start();
            this.router.navigate([OriginationRoutingHelper.SearchMerger()],
          { queryParams: { searchInput: this.searchInput }, queryParamsHandling: null });
        break;
      }
      case 5: {
        this.blockUI.start();
        if (this.searchInput == null || this.searchInput == '') {
          this.toastr.showMessage('Please Enter an MPF Loan Number to proceed.');
          this.blockUI.stop();
        }
        else {
        this.router.navigate([OriginationRoutingHelper.SearchMpfLoanpresentment()],
          { queryParams: { loanType: "MPF", searchInput: this.searchInput }, queryParamsHandling: null });
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
      }
        break;
      }
      case 6: {
        this.blockUI.start();
        if (this.searchInput == null || this.searchInput == '') {
          this.toastr.showMessage('Please enter an Organization/PFI No. to proceed.');
          this.blockUI.stop();
        }
        else {
          this.router.navigate([OriginationRoutingHelper.SearchPfiLoanpresentment()],
            { queryParams: { loanType: "PFI", searchInput: this.searchInput }, queryParamsHandling: null });
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
        }
        break;
      }
      case 7: {
        this.blockUI.start();
         this.searchStore.dispatch(new FromSearchActions.SetSearchAction({ key: FromSearchActions.SearchHeaderOptions.Organization, criteria: this.searchInput }))
            this.router.navigate([OriginationRoutingHelper.SearchOrganization()],
          { queryParams: { searchType:SearchType.Org.toString(), searchInput: this.searchInput }, queryParamsHandling: null });
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
        break;
      }
      default: {
        // statements;
        break;
      }

    }
  }

  interactiveHeaderShow() {
    this.showInteractiveHeader = this.isFhlbcUser;

    if(this.showInteractiveHeader && !this.isRolesInStore)
      this.isCreateAllowed = true;
  }

  createEvent() {
    this.toastr.remove();
    switch (this.selectedCreateOption) {
      case 'Organization': {
        this.blockUI.start();
            this.router.navigate([OriginationRoutingHelper.CreateOrganization()]);
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;
      }
      case 'Merger': {
        this.openMergerModal();
        break;
      }
      case 'Servicing Retained': {
        this.blockUI.start();
        this.router.navigate([OriginationRoutingHelper.CreateMc()], { queryParams: { servicingModel: 'SRT' }, queryParamsHandling: null });
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;
      }
      case 'Concurrent Sale of Servicing': {
        this.blockUI.start();
        this.router.navigate([OriginationRoutingHelper.CreateMc()], { queryParams: { servicingModel: 'CSS' }, queryParamsHandling: null });
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;
      }
      case 'Servicing Released Bifurcated/Co-Issue': {
        this.blockUI.start();
        this.router.navigate([OriginationRoutingHelper.CreateMc()], { queryParams: { servicingModel: 'BSR' }, queryParamsHandling: null });
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;
      }
      case 'Delivery Commitment': {
        this.blockUI.start();
        this.router.navigate([OriginationRoutingHelper.CreateDc()]);
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;

      }   
      case 'Loan Presentment': {
        this.blockUI.start();
        this.router.navigate([OriginationRoutingHelper.CreateLoanpresentment()]);
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;

      }
      case 'Loan Funding': {
        this.blockUI.start();
        this.router.navigate([OriginationRoutingHelper.CreateLoanFunding()]);
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;

      }
      case 'Process CE Estimator File': {
        this.blockUI.start();
        this.router.navigate([OriginationRoutingHelper.ProcessCEEstimatorFile()]);
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;

      }
      case 'Batch': {
        this.blockUI.start();
        this.router.navigate([OriginationRoutingHelper.CreateBatch()]);
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        break;

      }
      default: {
        // statements;
        break;
      }

    }
  }

  ifSubMenu(key) {
    if (key === 6 || key === 7 || key === 8) {
      return true;
    } else {
      return false;
    }
  }

  ifSearchSubMenu(key) {
    if (key === 5 || key === 6) {
      return true;
    } else {
      return false;
    }
  }

  openMergerModal() {
    const modalRef = this.modalService.open(CreatemergerModalComponent, { size: 'xl', backdrop: 'static' });
    modalRef.componentInstance.title = 'Mergers & Acquisitions';
  }

  searchMerger() {
    this.router.navigate([OriginationRoutingHelper.SearchMerger()], { queryParams: { searchInput: this.searchInput }, queryParamsHandling: null });
  }

  DcCheck() {
    this.isCollapsed = false;
    if (this.isLosSecurityInquiryFound) {
      this.showAddEditControls = false;
    } else {
      this.showAddEditControls = true;
    }
  }
}
