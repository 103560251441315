import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fhlb-progress-bar',
  templateUrl: './fhlb-progress-bar.component.html',
  styleUrls: ['./fhlb-progress-bar.component.sass']
})
export class FhlbProgressBarComponent implements OnInit {
  @Input() currentpercentage:number;
  @Input() displayProgressionMessage:string;


  constructor() { }

  ngOnInit() {
  }

}
