import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgControl } from '@angular/forms';
import { UtilHelper } from '../helpers/util-helper';



@Directive({
    selector: '[real-time-currency]'
})
export class RealTimeCurrencyDirective implements OnInit {
    private ngControl: NgControl;
    @Input('isNotEmptyText') isNotEmptyText: boolean =false;


    constructor(public el: ElementRef, public renderer: Renderer2, private currencyPipe: CurrencyPipe, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
    }

    ngOnInit() {
        setTimeout(() => {
            this.format(this.el.nativeElement.value);
        }, 1000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    filterParseFunc(val) {
        if(val == 'NaN') { //If NaN then setting empty as per legacy logic
            if(!this.isNotEmptyText){
            this.renderer.setProperty(this.el.nativeElement, 'value', '');
            this.ngControl.viewToModelUpdate('');
            }
        }else if (UtilHelper.isDefinedOrNotNull(val) && val !== '') {
            // val=Number(val);
            const value = this.currencyPipe.transform(parseFloat(val).toFixed(2), 'USD', 'symbol', '1.2-2');
            this.renderer.setProperty(this.el.nativeElement, 'value', value);
            isNaN(val) ? this.ngControl.viewToModelUpdate(0) : this.ngControl.viewToModelUpdate((parseFloat(val).toFixed(2)).toString());
            this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
        } else {
            return undefined;
        }
    }



    format(val: string) {
        if (UtilHelper.isDefinedOrNotNull(val) && val !== '') {
            const replacedVal = val.replace(/[$ ,]/g, '');
            let numberFormat = parseFloat(replacedVal);
            const maxLength = 15;
            let usd;
            const numbFormat = numberFormat.toLocaleString('fullwide', { useGrouping: false });
            if (numbFormat.match(/[^.]+/g)) {
                usd = numbFormat;
            }
            else {
                usd = numbFormat + '.00';
            }

            if (UtilHelper.isDefinedOrNotNull(numbFormat)) {
                const splitDecimal = usd.split('.');
                if (splitDecimal[0].length &&
                    splitDecimal[0].length > maxLength - 3) {
                    if (splitDecimal.length > 1) {
                        usd = splitDecimal[0].substring(0, maxLength - 3) + '.' + splitDecimal[1];
                    } else {
                        usd = splitDecimal[0].substring(0, maxLength - 3) + '.00';
                    }
                    numberFormat = this.filterParseFunc(usd);
                    this.el.nativeElement.val(numberFormat);
                    return usd;
                } else {
                    numberFormat = this.filterParseFunc(usd);
                }
            } else {
                numberFormat = this.filterParseFunc(usd);
            }
        } else {
            return undefined;
        }
    }
}
