<fhlb-modal-2 [title]="title" [topBorder]="topBorderClass">
  <div class="modal-modalheight">
    <div class="row g-0 justify-content-center mb-3">
      <div class="col-5 text-end">
        <label for="roles" class="col-form-label fieldLabel">
          Enter Successor Organization ID or Name:&nbsp;
          &nbsp;
        </label>
      </div>
      <div class="combobox col-7 ps-3">
        <div class="row">
          <div class="col-md-8 pe-4">
        <div class="input-group ">
          <input type="search" [(ngModel)]="inputItem" (ngModelChange)="getFilteredList()"
                 class="form-control py-2 border-end-0 border combobox-input" (blur)="toggleListDisplay(0)" (focus)="toggleListDisplay(1)"
                 [ngClass]="{'error': showError}" (keydown.enter)="enterClick()">
          <button class="btn border-start-0 border" type="button">
            <i class="fa fa-search font-light-blue"></i>
          </button>
        </div>
        <div class="input-group col-md-10">
          <div class="combobox-options" *ngIf="!listHidden">
            <list-item *ngFor="let item of pfiList;let i = index" (click)="selectItem(i)"
                       [ngClass]="{'selected': i===selectedIndex}">{{item}}</list-item>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col"></div>
  </div>
  <div class="row">
    <div class="col"></div>
  </div>
  <div class="row">
    <div class="col"></div>
  </div>
  <div class="row">
    <div class="col"></div>
  </div>
  <div class="row justify-content-center">
    <div class="col-3">
      <button class="btn btn-dark-blue btn-primary col-md-10 col-xs-6" type="button" id="createButton"
              (click)="goToCreate()">
        <b>CREATE</b>
      </button>
    </div>
  </div>

</fhlb-modal-2>
