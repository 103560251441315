
export enum SuccessorType {
    /// <summary>
    /// 
    /// </summary>
    PFI = 1,
    /// <summary>
    /// /
    /// </summary>
    NonPFI = 2,
    /// <summary>
    /// 
    /// </summary>
    NonMember = 3,
    /// <summary>
    /// /
    /// </summary>
    NonMPFBankMember = 4,
    /// <summary>
    /// /
    /// </summary>
    Receiver = 5

}
