import { Component, OnInit, Input,ViewChild, } from '@angular/core';
import { IAudit } from '@app-shared/models/audit.model';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'success-top-message',
  templateUrl: './success-top-message.component.html',
  styleUrls: ['./success-top-message.component.sass']

})

export class SuccessTopMessageComponent implements OnInit {
  @Input() show: boolean;
  isCollapsed = true;
  @Input() message: string;
  
  constructor() {
    this.show = false;
  }


  ngOnInit() {
  }
}

