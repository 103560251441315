import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GetPfiListByOrgRequestModel } from '@empf-shared/services/pricing/models/getPfiListByOrgRequestModel';
import { PricingService } from '@empf-shared/services/pricing-service';
import { GetPfiResponseModel } from '@empf-shared/services/pricing/models/GetPfiResponseModel';
import { ReportService } from '@empf-shared/services/reports/report.service';
import { of, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AppConfigService, CustomToastService } from '@app-shared/services';
import { GetAecSearchDataRequestModel } from '@empf-shared/services/reports/models/getAecSearchDataRequestModel';
import { IReportsResponseModel } from '@empf-shared/services/reports/models/IReportsResponseModel';
import { getIsRoleFoundFromStore } from 'src/store/selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/store/reducers';

@Component({
  selector: 'app-ace-report-container',
  templateUrl: './aec-report.container.component.html'
})
export class AecReportContainerComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  validationErrors: Array<string>;
  pfiList = this.getPfiGenericDropDownDefaultValues();
  searchList: IReportsResponseModel;

  getPfiListByOrgRequestModel: GetPfiListByOrgRequestModel;
  pfiListResponse: GetPfiResponseModel;
  yearList = this.getDropDownDefaultValues();
  ogStatus = this.getOgStatusValue();
  isPFIUser: boolean;

  constructor(private pricingService: PricingService, private reportService: ReportService
    , private toastr: CustomToastService, private store: Store<AppState>,) {
    this.validationErrors = {} as Array<string>;

  }
  ngOnInit() {
    this.toastr.remove();
    this.init();
    this.getPFIUserType();
  }
  getPfiGenericDropDownDefaultValues() {
    return {
      availableOptions: [{ id: 0, name: 'All PFIs' }],
      selectedOption: { id: 0, name: 'All PFIs' }
    };
  }
  getDropDownDefaultValues() {
    return {
      availableOptions: [],
      selectedOption: {}
    };
  }
  getOgStatusValue() {
    return {
      availableOptions: this.getOgStatusTypes(),
      selectedOption: this.setDefaultChoice()
    };
  }
  getOgStatusTypes() {
    return [{ id: 0, name: 'Submitted' },
    { id: 1, name: 'Accepted' },
    { id: 2, name: 'Rejected' },
    { id: 3, name: 'Certification Complete' },
    { id: 4, name: 'All' }];
  }
  setDefaultChoice() {
    return { id: 0, name: 'Submitted' };
  }

  init() {
    this.blockUI.start();

    this.getPfiListByOrgRequestModel = new GetPfiListByOrgRequestModel();
    forkJoin([
      this.pricingService.getPfiListByOrg(this.getPfiListByOrgRequestModel),
      this.reportService.getFormYear()
    ]).pipe(take(1),
      map(([pfiListResponse, yearListResponse]) => {
        //this.pfiListResponse = pfiListResponse;

        pfiListResponse.pfiList.forEach((pfi) => {
          var pfiObj = {
            id: Number(pfi.id),
            name: pfi.id + ' - ' + pfi.name
          }
          this.pfiList.availableOptions.push(pfiObj);
        });

        if (yearListResponse != null) {
          yearListResponse.reports.forEach((report) => {
            var yearObj = {
              id: report.ogYear,
              name: report.ogYear
            }
            this.yearList.availableOptions.push(yearObj);
          });

          if (!this.isPFIUser) {
            var addAllObj = {
              id: 0,
              name: 'All'
            }
            this.yearList.availableOptions.push(addAllObj);
          }

          var selectedYearObj = {
            id: this.yearList.availableOptions[0].id,
            name: this.yearList.availableOptions[0].name
          }
          this.yearList.selectedOption = selectedYearObj;
        }
        this.blockUI.stop();
      }),
      catchError(errorResponse => {
        this.blockUI.stop();
        setTimeout(() => {
          this.toastr.show(errorResponse.error.errorMessages);
        }, 400);
        return of(null);
      })
    ).subscribe()

  }
  getGenericDropDownDefaultValues() {
    return {
      availableOptions: [{ key: 0, value: 'All ' }],
      selectedOption: { key: 0, value: 'All ' }
    };
  }

  searchDataEvent(request: GetAecSearchDataRequestModel) {
    var requestModel = {} as GetAecSearchDataRequestModel;
    requestModel = {
      ogYear: request.ogYear,
      ogStatus: request.ogStatus,
      pfiId: request.pfiId
    };

    this.toastr.remove();
    this.blockUI.start();
    this.reportService.getAecSearchData(requestModel).subscribe((response) => {
      if (response.hasRecords) {
        this.searchList = response;
      }
      else if (!response.hasRecords) {
        this.searchList = response;
        this.blockUI.stop();
        setTimeout(() => {
          this.toastr.show([response.noRecords]);
        }, 400);
      }
      this.blockUI.stop();
    }, errorResponse => {
      var decodedString = String.fromCharCode.apply(null, new Uint8Array(errorResponse.error));
      var error = JSON.parse(decodedString);
      this.validationErrors.push(error.errorMessages);
      setTimeout(() => {
        this.toastr.show(this.validationErrors);
      }, 400);
      this.blockUI.stop();
    })

  }

  getPFIUserType() {
    this.store.select(getIsRoleFoundFromStore([AppConfigService.Settings.Empf.EMPF_PFI_MPF])).pipe(
      take(1),
      map(x => this.isPFIUser = x)
    ).subscribe();
  }

}
