import { isNullOrUndefined } from 'is-what';

export const OriginationRoutingHelper = {
    UnauthorizedRoute: () => '/error/unauthorized',
    Home: () => "/origination/home",
    ViewOrganization: (orgId: string) => `/origination/organization/view/${orgId}`,
    ViewMerger: (mergerKey: string) => `/origination/organization/merger/view/${mergerKey}`,
    EditMerger: (mergerKey: string) => `/origination/organization/merger/edit/${mergerKey}`,
    CreateMerger: (orgId: string) => `/origination/organization/merger/create/${orgId}`,
    EditOrganization: (orgId: string) => `/origination/organization/edit/${orgId}`,
    CreateOrganization: () => `/origination/organization/create`,
    SearchOrganizationMcDc: (orgId: string, mCId: string) => `/origination/organization/search/${orgId}/associated-mastercommitments/${mCId}/associated-deliverycommitments`,
    SearchOrganizationMcLoans: (orgId: string, mCId: string) => `/origination/organization/search/${orgId}/associated-mastercommitments/${mCId}/associated-loans`,
    SearchOrganizationMc: (orgId: string) => `/origination/organization/search/${orgId}/associated-mastercommitments`,
    SearchOrganization: (searchInput: string = null) =>
        !isNullOrUndefined(searchInput)
            ? `/origination/organization/search?searchInput=${searchInput}`
            : `/origination/organization/search`,
    SearchOrganizationDcLoan: (orgId: number, dcNo: number) => `/origination/organization/search/${orgId}/associated-deliverycommitments/${dcNo}/associated-loans`,
    SearchOrganizationMcDcLoan: (orgId: string, mCId: string, dcNo: number) => `/origination/organization/search/${orgId}/associated-mastercommitments/${mCId}/associated-deliverycommitments/${dcNo}/associated-loans`,
    AssociatedDeliveryCommitments: (orgId: number) => `/origination/organization/search/${orgId}/associated-deliverycommitments`,
    AssociatedMasterCommitments: (orgId: number) => `/origination/organization/search/${orgId}/associated-mastercommitments`,
    AssociatedLoans: (orgId: number) => `/origination/organization/search/${orgId}/associated-loans`,
    ViewMc: (mc: string) => `/origination/mastercommitment/view/${mc}`,
    ViewLoanpresentment: (pfiLoanNum: string, pfiNum: string) => `/origination/loanpresentment/view/${pfiLoanNum}/${pfiNum}`,
    ViewLoanFunding: (mPFLoanNumber: number) => `/origination/loanfunding/view/${mPFLoanNumber}`,
    ViewLoanFundingByMPFLoanNNoAndLFNo: (mPFLoanNumber: number, loanFundingNumber:number) => `/origination/loanfunding/view/${mPFLoanNumber}/${loanFundingNumber}`,
    ViewPfiLoanFunding: (pfiLoanNumber: number) => `/origination/loanfunding/viewloan/${pfiLoanNumber}`,
    LoanpresentmentChangePfiLoanNumber: () => `/origination/loanpresentment/changepfiloannumber`,
    LoanFundingRequestReverse: () => `/origination/loanfunding/requestreverse`,
    EditLoanpresentment: (pfiLoanNum: string, pfiNum: string) => `/origination/loanpresentment/edit/${pfiLoanNum}/${pfiNum}`,
    ViewDc: (dc: string) => `/origination/deliverycommitment/view/${dc}`,
    EditMc: (mc: string) => `/origination/mastercommitment/edit/${mc}`,
    EditDc: (dc: string) => `/origination/deliverycommitment/edit/${dc}`,
    SearchMc: () => `/origination/mastercommitment/search`,
    CreateMc: () => `/origination/mastercommitment/create`,
    SearchDc: () => `/origination/deliverycommitment/search`,
    SearchDcAssociatedLoans: (dc: string) => `/origination/deliverycommitment/search/${dc}/associated-loans`,
    CreateDc: () => `/origination/deliverycommitment/create`,
    CreateLoanpresentment: () => `/origination/loanpresentment/create`,
    CreateLoanFunding: () => `/origination/loanfunding/create`,
    SearchMerger: () => `/origination/organization/merger/search`,
    SearchMpfLoanpresentment: () => `/origination/loanpresentment/MPF/search`,
    SearchPfiLoanpresentment: () => `/origination/loanpresentment/PFI/search`,
    SearchMcAssociatedDeliveryCommitments: (mCId: string) => `/origination/mastercommitment/search/${mCId}/associated-deliverycommitments`,
    SearchMcAssociatedDcLoans: (mCId: string, dcNo: number) => `/origination/mastercommitment/search/${mCId}/associated-deliverycommitments/${dcNo}/associated-loans`,
    SearchMcAssociatedLoans: (mCId: string) => `/origination/mastercommitment/search/${mCId}/associated-loans`,
    ExtendDc: (dc: string) => `/origination/deliverycommitment/extend/${dc}`,
    ReduceDc: (dc: string) => `/origination/deliverycommitment/reduce/${dc}`,
    AdminManageSubproducts: () => "/origination/administration/subproducts",
    AdminMpfsystemparameters: () => "/origination/administration/mpfsystemparameters",
    AdminMpfpricingparameters: () => "/origination/administration/mpfpricingparameters",
    AdminSpecialfeaturescodes: () => "/origination/administration/specialfeaturecodes",
    AdminManageauscodes: () => "/origination/administration/auscodes",
    AdminManagepermanentbuydownprices: () => "/origination/administration/managepermanentbuydownprices",
    AdminManageempfbannermessage: () => "/origination/administration/manageempfbannermessage", //manageempfbannermessage
    AdminEditSubproduct: (subProductKey: string) => `/origination/administration/subproducts/edit/${subProductKey}`,
    AdminEditAuscodes: (ausCode: string) => `/origination/administration/auscodes/edit/${ausCode}`,
    AdminEditspecialfeaturecodes: (code: string) => `/origination/administration/specialfeaturecodes/edit/${code}`,
    RulesCreate: () => `/origination/rulesandrulesets/create`,
    RulesSearch: () => "/origination/rulesandrulesets/search",
    RulesCategoryAlias: () => "/origination/rulesandrulesets/categoryalias",
    RulesView: (ruleId: string) => `/origination/rulesandrulesets/rules/view/${ruleId}`,
    RulesEdit: (ruleId: string) => `/origination/rulesandrulesets/rules/edit/${ruleId}`,
    RulesetsView: (rulesetId: string) => `/origination/rulesandrulesets/rulesets/view/${rulesetId}`,
    RulesetsEdit: (rulesetId: string) => `/origination/rulesandrulesets/rulesets/edit/${rulesetId}`,
    RulesExportRuleset: () => "/origination/rulesandrulesets/rulesets/export",
    RulesImportRuleset: () => "/origination/rulesandrulesets/rulesets/import",
    ProcessCEEstimatorFile: () => `/origination/ceestimator/create`,
    MonitorBatch: () => `/origination/batchprocess/monitor`,   
    SearchCEEstimator: (ceEstimatorID: number = null)=>
    !isNullOrUndefined(ceEstimatorID)
        ? `/origination/ceestimator/search?ceEstimatorID=${ceEstimatorID}`
        : `/origination/ceestimator/search`,
    SearchBatch: (batchID: number = null,isEmailSent: boolean = null,isError: boolean = null,batchStatus:number = null) =>
    !isNullOrUndefined(batchID)
        ? `/origination/batchprocess/search?batchID=${batchID}&isEmailSent=${isEmailSent}&isError=${isError}&batchStatus=${batchStatus}`
        : `/origination/batchprocess/search`,

    CreateBatch: () => `/origination/batchprocess/create`,
    SubmitBatch: (batchID: string) => `/origination/batchprocess/submit/${batchID}`,
    ManageGeographicInformation: () => `/origination/geographic-information/manage`
}
