import { All, AuthActionTypes } from '../actions/auth.actions';
import jwt_decode from "jwt-decode";
import { isNullOrUndefined } from 'is-what';
import { DecodeAccessToken, Identities } from './models';
import { SiteName } from '@app-shared/models/routingdata';
import { EncryptedFhlBankUserModel } from '@empf-shared/services/treasury/models/EncryptedFhlBankUserModel';
import { DecodeAzureB2CIDToken } from './models/DecodeAzureB2CIDToken';
import { DecodeAzureB2CAccessToken } from './models/DecodeAzureB2CAccessToken';
import { Behavior } from './models/Behavior';
import { fhlbConstants } from '@app-shared/constants/fhlb-constants';
import { AppConfigService } from '@app-shared/services';
import { LoginFlow } from '@app-shared/constants/login-flow';

export interface State {
  site?: SiteName,
  loginFlow?: LoginFlow,
  isRefreshOccurring: boolean | null
  encoded: {
    azureB2CIDToken: string;
    azureB2cAccessToken: string;
  }
  decoded: {
    idToken: DecodeAccessToken;
    azureB2CIDToken: DecodeAzureB2CIDToken;
    azureB2cAccessToken: DecodeAzureB2CAccessToken;
  }
  azureB2cTokenExpireDateTime?: Date;
  empfApplicationExpireDateTime?: Date;
  empfApplicationShowRefreshModalDateTime?: Date;
  hlbBankInfo: EncryptedFhlBankUserModel;
  selectedProfileScopeID: string;
}

export const initialState: State = {
  site: null,
  loginFlow: LoginFlow.NO_FLOW,
  isRefreshOccurring: null,
  encoded: {
    azureB2CIDToken: null,
    azureB2cAccessToken: null,
  },
  decoded: {
    idToken: null,
    azureB2CIDToken: null,
    azureB2cAccessToken: null,
  },
  azureB2cTokenExpireDateTime: null,
  empfApplicationExpireDateTime: null,
  empfApplicationShowRefreshModalDateTime: null,
  hlbBankInfo: null,
  selectedProfileScopeID: null
};

export function reducer(state = initialState, action: All): State {

  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS: {
      let newState: State = {
        site: state.site,
        loginFlow: state.loginFlow,
        isRefreshOccurring: false,
        encoded: {
          azureB2CIDToken: null,
          azureB2cAccessToken: null
        },
        decoded: {
          idToken: null,
          azureB2CIDToken: action.payload.azureB2CIDToken,
          azureB2cAccessToken: null
        },
        azureB2cTokenExpireDateTime: new Date(action.payload.azureB2CIDToken.exp * 1000),
        empfApplicationExpireDateTime: null,
        empfApplicationShowRefreshModalDateTime: null,
        hlbBankInfo: state.hlbBankInfo,
        selectedProfileScopeID: null
      }
      return newState;
    }
    case AuthActionTypes.REFRESH_TOKENS_SUCCESS: {
      let decodeAzureB2CAccessToken = decodeAzureB2CAccessJwtToken(action.payload.encodedAzureB2CAccessToken)
      let decodeAzureB2CIDToken = decodeAzureB2CIDJwtToken(action.payload.encodedAzureB2CIDToken);
      
      let identity: Identities[] = [{
        userId: state.decoded.idToken.identities[0].userId,
        providerName: ''
      }]


      let decodedToken: DecodeAccessToken = {
        'cognito:groups': null,
        'cognito:username': null,
        'custom:UserGroup': state.decoded.idToken['custom:UserGroup'],
        'custom:OrgKey': state.decoded.idToken['custom:OrgKey'],
        'custom:Behavior': state.decoded.idToken['custom:Behavior'],
        email: decodeAzureB2CIDToken.email,
        exp: decodeAzureB2CIDToken.exp,
        given_name: decodeAzureB2CIDToken.given_name,
        iat: decodeAzureB2CIDToken.iat,
        identities: identity
      }
      let newEmpfAppExpireDateTime= calculateExpireTimeStamp(decodedToken['custom:UserGroup'],decodedToken.iat);
      let newEmpfAppShowRefreshModalDateTime = calculateShowRefreshModalTimeStamp(decodedToken['custom:UserGroup'],newEmpfAppExpireDateTime);
      let newState: State = {
        site: state.site,
        loginFlow: (action.payload.loginFlow) ? action.payload.loginFlow : state.loginFlow,
        isRefreshOccurring: state.isRefreshOccurring,
        encoded: {
          azureB2CIDToken: action.payload.encodedAzureB2CIDToken,
          azureB2cAccessToken: action.payload.encodedAzureB2CAccessToken
        },
        decoded: {
          idToken: decodedToken,
          azureB2CIDToken: decodeAzureB2CIDToken,
          azureB2cAccessToken: decodeAzureB2CAccessToken
        },
        azureB2cTokenExpireDateTime:  new Date(decodeAzureB2CIDToken.exp * 1000),              
        empfApplicationExpireDateTime: (action.payload.updateEmpfSessionTime) ? newEmpfAppExpireDateTime
         : state.empfApplicationExpireDateTime,
        empfApplicationShowRefreshModalDateTime:(action.payload.updateEmpfSessionTime) ? newEmpfAppShowRefreshModalDateTime
        : state.empfApplicationShowRefreshModalDateTime  ,
        hlbBankInfo: state.hlbBankInfo,
        selectedProfileScopeID: state.selectedProfileScopeID
      }      
      return newState;
    }
    case AuthActionTypes.SET_REFRESH_STATUS: {
      return { ...state, isRefreshOccurring: action.payload};
    }

    case AuthActionTypes.REFRESH_TOKENS_FAIL: {
      return { ...state };
    }
    case AuthActionTypes.SET_SITE: {
      return { ...state, site: action.payload };
    }

    case AuthActionTypes.LOG_OUT: {
      return { ...initialState };
    }
    case AuthActionTypes.LOG_OUT_ACKNOWLEDGE: {
      return { ...state };
    }
    case AuthActionTypes.HLB_BANK_LOGIN: {
      return { ...state, hlbBankInfo: action.payload };
    }
    case AuthActionTypes.USER_PROFILE_SELECTED: {
      console.log("selected scope id :", action.payload.selectedProfile);

      let identity: Identities[] = [{
        userId: action.payload.userProfile.NtUserName,
        providerName: ''
      }];

      let decodedToken: DecodeAccessToken = {
        'cognito:groups': null,
        'cognito:username': null,
        'custom:UserGroup': action.payload.userProfile.Roles,
        'custom:OrgKey': action.payload.userProfile.OrgKey.toString(),
        'custom:Behavior': mapBehaviorToDescriptions(action.payload.userProfile.Behaviors),
        email: null,
        exp: 0,
        given_name: null,
        iat: 0,
        identities: identity
      };
      let newState: State = {
        site: state.site,
        loginFlow: state.loginFlow,
        isRefreshOccurring: false,
        encoded: {
          azureB2CIDToken: null,
          azureB2cAccessToken: null
        },
        decoded: {
          idToken: decodedToken,
          azureB2CIDToken: state.decoded.azureB2CIDToken,
          azureB2cAccessToken: null
        },
        azureB2cTokenExpireDateTime: state.azureB2cTokenExpireDateTime,
        empfApplicationExpireDateTime: null,
        empfApplicationShowRefreshModalDateTime: null,
        hlbBankInfo: state.hlbBankInfo,
        selectedProfileScopeID: action.payload.selectedProfile
      };
      console.log("selected Profile reducer values: ", newState);
      return newState;

    }
    case AuthActionTypes.REFRESH_TOKENS:{
      return {...state};
    }
    default: {
      return state;
    }
  }
}

function mapBehaviorToDescriptions(behaviors: number[]): string[] {
  if (behaviors) { return behaviors.map(behavior => Behavior[behavior]); }
  else return null;

}

function checkForExtendedTimeoutRole(roles: string[]): boolean
{
  let isExtendedTimeoutMpfRole: boolean = false;
  let validExtendedRoles: string[] = fhlbConstants.EmpfExtendedTimeoutMPFRole.map((function(roleString) { return roleString.toUpperCase(); } ));
  roles.forEach((role) =>
    {
      if (validExtendedRoles.includes(role.toUpperCase()))
      {
        isExtendedTimeoutMpfRole = true;
      }
    }); 
    
  return isExtendedTimeoutMpfRole;
}

function calculateExpireTimeStamp(roles :string[], issuedAt: number) :Date
{
  console.log("calculateExpireTimeStamp Roles:", roles);
  let isExtendedTimeOutMpfRole: boolean = checkForExtendedTimeoutRole(roles);
  const issuedAtDate = new Date(issuedAt * 1000);
  console.log("calculateExpireTimeStamp IssuedAt:", issuedAtDate);
  const newDate = new Date(issuedAtDate.getTime()); 

  if (isExtendedTimeOutMpfRole)
  {      
    newDate.setMinutes(newDate.getMinutes() + AppConfigService.Settings.Empf.ExtendedTimeoutRoleEmpfSessionExpirationInMins);
    return newDate;
  }
  else {
    newDate.setMinutes(newDate.getMinutes() + AppConfigService.Settings.Empf.EmpfSessionExpirationInMins);
    return newDate;
  }
}

function calculateShowRefreshModalTimeStamp(roles :string[], empfExp: Date) :Date
{ 
  console.log("calculateShowRefreshModalTimeStamp empfExp", empfExp);
  let isExtendedTimeOutMpfRole: boolean = checkForExtendedTimeoutRole(roles);
  const newDate = new Date(empfExp.getTime());  

  if (isExtendedTimeOutMpfRole)
  {      
    newDate.setMinutes(newDate.getMinutes() - AppConfigService.Settings.Empf.ExtendedTimeoutRoleShowRefreshBeforeExpirationInMins);
    return newDate;
  }
  else {
    newDate.setMinutes(newDate.getMinutes() - AppConfigService.Settings.Empf.EmpfSessionShowRefreshBeforeExpirationInMins);
    return newDate;
  }
}

function decodeAzureB2CAccessJwtToken(token: string): DecodeAzureB2CAccessToken {
  if (isNullOrUndefined(token))
    return null;

  let ret: DecodeAzureB2CAccessToken
  const tkn = jwt_decode(token);

  ret = <DecodeAzureB2CAccessToken>tkn;
  return ret;
}

function decodeAzureB2CIDJwtToken(token: string): DecodeAzureB2CIDToken {
  if (isNullOrUndefined(token))
    return null;

  let ret: DecodeAzureB2CIDToken
  const tkn = jwt_decode(token);

  ret = <DecodeAzureB2CIDToken>tkn;
  return ret;
}


