import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { pricingHelper } from '@app-shared/helpers/pricing-helper';
import { DateHelper } from '../helpers/date-helper';
import { UtilHelper } from '../helpers/util-helper';
import { CustomToastService } from '../services';


@Directive({
    selector: '[dateRangeSelectionValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: dateRangeSelectionValidatorDirective, multi: true }]
})
export class dateRangeSelectionValidatorDirective implements OnInit, OnChanges {
    @Input() dateRangeSelectionOptions;
    @Input() selectDateValidatorTrigger;

    constructor(private el: ElementRef, @Inject(DOCUMENT) private document: any) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.selectDateValidatorTrigger) {
            if (this.selectDateValidatorTrigger.event.defaultPrevented) {
                return;
            }
            this.validate(this.selectDateValidatorTrigger.event, this.selectDateValidatorTrigger);
        }
    }

    pricingValidationClass = 'pricing-panel-body-column-validation';
    validate(event, args) {
        {
            // Merges configuration set in directive with default one
            var configOptions = {};
            var mergedConfig = Object.assign({}, configOptions, (this.dateRangeSelectionOptions));

            //retrieve the criteria property
            var fromDateTime = mergedConfig['fromDateTime'];
            var toDateTime = mergedConfig['toDateTime'];
            var pricingContainerId = mergedConfig['pricingContainerId'];
            var numberOfDaysAllowed = mergedConfig['numberOfDaysAllowed'];

            var jsonObj = args;
            var targetFormName = jsonObj['targetFormName'];

            //perform validation
            const results = pricingHelper.validateDateRange(fromDateTime, toDateTime, numberOfDaysAllowed);
            
            if (!results.status) {
                if (results.errors.length > 0) {
                    if (results.stopPropagation) {
                        event.preventDefault();

                        // remove all the matching CSS classes from pricing main template before apply new class
                        this.removePricingValidationCss(event, pricingContainerId);
                    }
                    this.el.nativeElement.classList.add(this.pricingValidationClass);
                } else {
                    this.el.nativeElement.classList.remove(this.pricingValidationClass);
                }
            } else {
                this.el.nativeElement.classList.remove(this.pricingValidationClass);
            }
        }
    }
    removePricingValidationCss(event: any, pricingContainerId: any) {
        {
            //remove the CSS class from pricing main template
            var mainTmpl = this.document.getElementById(pricingContainerId);
            if (UtilHelper.isDefinedOrNotNull(mainTmpl)) {
                var matchingCssEls = mainTmpl.querySelectorAll('.' + this.pricingValidationClass);
                if (UtilHelper.isDefinedOrNotNull(matchingCssEls)) {
                    var ngMatchingCssEls = matchingCssEls;
                    ngMatchingCssEls.forEach((e) => {
                        var ngEl = e;

                        ngEl.classList.remove(this.pricingValidationClass);
                    });
                }
            }
        }
    }

}