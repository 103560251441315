<fhlb-modal-2 [topBorder]="topBorderClass" [showHeader]="false">
    <div class="row text-center p-3">
        <div class="col-sm-12 defaultText">
            You are about to be logged out. Click 'Extend' to keep your session.
        </div>
    </div>
    <div class="row p-3 justify-content-center">
        <div class="col-5 pe-3">
            <button type="submit" class="btn btn-success col w-100" (click)="extendSession()"><b>EXTEND</b></button>
        </div>
        <div class="col-5 pe-3">
            <button type="submit" class="btn btn-danger col w-100" (click)="logout()"><b>LOGOUT</b></button>
        </div>
    </div>
</fhlb-modal-2>