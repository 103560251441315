import { isNullOrUndefined } from 'is-what';
import { Injectable } from '@angular/core';

@Injectable()
export class UtilHelper {
    public static isUndefinedOrNull(obj): boolean {
        return obj === undefined || obj === null;
    }

    public static isDefinedOrNotNull(obj): boolean {
        return obj !== undefined && obj !== null;
    }

    public static format(actual, strArguments) {
        let content = actual;
        for (let i = 0; i < strArguments.length; i++) {
            const replacement = '{' + i + '}';
            content = content.replace(replacement, strArguments[i]);
        }
        return content;
    }

    public static toJsonLocalDate(date: Date) {
        let local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    }

    public static addLeadingZeros(custodialAccount: number, padLength: number) {
      
        let paddedValue;
        if (custodialAccount > 0) {
            let num: string = custodialAccount.toString();
            let pedLen = padLength;
            let paddingChar = '0';
            paddedValue = num.padStart(pedLen, paddingChar);

        }
        return paddedValue;

    }
    public static addTrailingZeros(custodialAccount: number, padLength: number) {
      
        let paddedValue;
        if (custodialAccount > 0) {
            let num: string = custodialAccount.toString();
            let pedLen = padLength;
            let paddingChar = '0';
            paddedValue = num.padEnd(pedLen, paddingChar);

        }
        return paddedValue;

    }

    public static isNullOrEmptyString(val: string) {
        return isNullOrUndefined(val) || val === '';
    }

    public static copyObject(jsonObj) {
        return JSON.parse(JSON.stringify(jsonObj));
    }

    public static toFileSize(bytessize) {
        let actualsize = bytessize;
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (actualsize === 0)
            actualsize = '0 Byte';
        let i = Math.floor(Math.log(actualsize) / Math.log(1024));
        actualsize = Math.round(actualsize / Math.pow(1024, i)) + ' ' + sizes[i];

        return actualsize;
    }

    public static printSection(elementId) {
        const messageSection = document.getElementById(elementId);
        const clone = messageSection.cloneNode(true);

        let $printSection = document.getElementById('printSection');

        if (!$printSection) {
            $printSection = document.createElement('div');
            $printSection.id = 'printSection';
            document.body.appendChild($printSection);
        }

        $printSection.innerHTML = '';
        $printSection.appendChild(clone);

        $printSection.style.width = '100%';

        window.print();
    }
}
