import { HttpErrorResponse } from '@angular/common/http';
import { ModelFailures, ProcessMessagesFailures, ValidationErrors } from '@origination-shared/services/organization/models';
import { HttpStatusCode } from '@app-shared/constants/http-status-codes';
import { isNullOrUndefined } from 'is-what';
import { Exception } from '@app-shared/services/shared-models/api-generic-exception';

export class LosServiceHttpUtilHelper {
    public static HandleServiceHttpErrors(errorResponse: HttpErrorResponse): string[] {
        let errorMessages: string[] = [];

        if (errorResponse.status == HttpStatusCode.BAD_REQUEST) {
            let propertyErrors = errorResponse.error as ModelFailures;

            propertyErrors.errors.forEach(ex => {
                ex.errors.forEach(p => errorMessages.push(`${p.processMessage.description}`));
            });
        } else if (errorResponse.status == HttpStatusCode.UNPROCESSABLE_ENTITY || errorResponse.status == HttpStatusCode.INTERNAL_SERVER_ERROR) {
            if (errorResponse.error.hasOwnProperty('validations')) {
                let propertysErrors = errorResponse.error as ValidationErrors;
                
                propertysErrors.validations.forEach(ex => {
                    errorMessages.push(`${ex.processMessage.description}`);
                });
            }
            else if(errorResponse.error.hasOwnProperty('exceptions')){
                let propertyErrors = errorResponse.error as ProcessMessagesFailures;
                propertyErrors.exceptions.forEach(ex => {
                    errorMessages.push(`${ex.processMessage.description}`);
                });
            }
            else if(errorResponse.error.message && errorResponse.error.message.toString().toLowerCase()==="validationexception"){
                let validationEx = errorResponse.error as Exception;
                if(!isNullOrUndefined(validationEx)){
                    if(validationEx.detail)
                    errorMessages.push(`${validationEx.detail.description} - Message: ${validationEx.detail.message}`);
                }
            }
            else
            {
                let apiEx = errorResponse.error as Exception;
                if(!isNullOrUndefined(apiEx)){
                    let showSource = !isNullOrUndefined(apiEx.source);
                    errorMessages.push(`$${errorResponse.status} - Message: ${apiEx.message}${ showSource ? '; Source:' + apiEx.source : '' }`);
                }
            }
        } else {
            if(isNullOrUndefined(errorResponse.status) || isNullOrUndefined(errorResponse.url)){
                console.log("Unknown handling of HTTP errors has occurred.")
                console.log(errorResponse)
                errorMessages.push("Unknown handling of HTTP response has occurred. Please check console for information");
            } else {
                errorMessages.push(`${errorResponse.status} ${errorResponse.statusText} - ${errorResponse.url}`);
            }
        }

        errorMessages = errorMessages.filter((v, i) => errorMessages.indexOf(v) == i);

        return errorMessages;
    }
}

