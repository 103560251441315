import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICmsContent } from 'src/shared/models/content.model';

@Component({
  selector: 'app-email-message-pop-up',
  templateUrl: './email-message-pop-up.component.html',
  styleUrls: ['./email-message-pop-up.component.sass']
})
export class EmailMessagePopUpComponent implements OnInit {
  topBorderClass = `top-border-green`;
  @Input() isEmailSuccess: boolean;
  @Input() cmsModel: ICmsContent;
  constructor(public activeModal: NgbModal) { }

  ngOnInit() {
  }

  okClick() {
    this.activeModal.dismissAll();
  }
}
