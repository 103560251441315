import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {

    if (value) {      
      if(value.indexOf('-')!==-1)
      {
        this.DELIMITER = '-';        
      }     
      const date = value.split(this.DELIMITER);
      this.DELIMITER = '/';
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10)
      };
    }

    return null;
  }

  format(date: NgbDateStruct | null): string {
    let month: string;
    let day: string;
    month = date ? date.month < 10 ? '0' + date.month : date.month.toLocaleString() : '';
    day = date ? date.day < 10 ? '0' + date.day : date.day.toLocaleString() : '';

    var newdate = date ? new Date(month + this.DELIMITER + day + this.DELIMITER + date.year) : null;
    if (newdate !== null) {
      if (date && date.year.toString().length > 1) {
        date.year = newdate.getFullYear();
      }
    }
    return date ? month + this.DELIMITER + day + this.DELIMITER + date.year : '';
  }
}
