export enum OrganizationDepthIndicator{
    Unspecified,
    Organization,
    ServicingModel,
    Role,
    TransactionTypeAccount,
    Contact,
    ContactauthorizedBehavior,
    OrganizationWithContacts,
    ShallowPFI,
    All
}
