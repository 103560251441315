import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppConfigService } from '@app-shared/services';
import { Store } from '@ngrx/store';
import { isNullOrUndefined } from 'is-what';
import { AppState } from 'src/store/reducers';
import { UserProfile, UserProfilePicker } from 'src/store/reducers/models/UserProfile';



@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})

export class UserProfileComponent implements OnInit {  
  @Input() profile: UserProfile ;
  @Input() userProfileList: UserProfilePicker[];

  @Output() LoginProfileUser: EventEmitter<UserProfile> = new EventEmitter<UserProfile>();
  
  @ViewChild('userProfileForm', { static: true }) form: NgForm;

  public profileScopeID: string = '0';
  formSubmitted: boolean;
  defaultDropDownValue = '0';
  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.userProfileList.sort((a,b) => 
      {
        let sort = a.OrgKey - b.OrgKey; 
        if (sort == 0)
        {
          return a.ContactKey - b.ContactKey;
        }
        else
        {
          return sort;
        }
      });
    this.userProfileList.forEach((profile) => {
      profile.OnScreenLabel = profile.NtUserName;
      if (!isNullOrUndefined(profile.OrgName))
      {
        profile.OnScreenLabel += ' (' + profile.OrgName + ')';
      }

      let upperCaseGroups = (profile.Roles) ? profile.Roles.map(function(roleString) { return roleString.toUpperCase(); }) : [];
      if (upperCaseGroups.includes(AppConfigService.Settings.Empf.EMPF_SCU_MPF.toUpperCase()))
      {
        profile.OnScreenLabel = profile.NtUserName + ' (SCU Login)';
      }
      else if (upperCaseGroups.includes(AppConfigService.Settings.Empf.EMPF_FHLBANKUSER_MPF.toUpperCase()))
      {
        profile.OnScreenLabel = profile.NtUserName + ' (FHLBank Login)';
      }
    });
  }

  userProfileChanged() {
    this.profile.ProfileScopeID = this.profileScopeID;
    this.profile.NtUserName = this.userProfileList.find(x=>x.ProfileScopeID == this.profileScopeID).NtUserName;
    this.profile.Behaviors = this.userProfileList.find(x=>x.ProfileScopeID == this.profileScopeID).Behaviors;
    this.profile.OrgKey = this.userProfileList.find(x=>x.ProfileScopeID == this.profileScopeID).OrgKey;
    this.profile.Roles = this.userProfileList.find(x=>x.ProfileScopeID == this.profileScopeID).Roles;
    this.profile.MpfBankKey = this.userProfileList.find(x=>x.ProfileScopeID == this.profileScopeID).MpfBankKey;
  }

  submit() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      return;
    } else {
      console.log("Profile Values :", this.profile);
      this.LoginProfileUser.emit(this.profile);
    }
  }

 }
