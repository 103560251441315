import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[comma-separated-currency]'
})
export class CommaSeparatedCurrencyDirective implements OnInit {
    private ngControl: NgControl;

    constructor(public el: ElementRef, public renderer: Renderer2, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
    }

    ngOnInit() {
        setTimeout(() => {
            this.format(this.el.nativeElement.value);
        }, 1000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    @HostListener('focus', ['$event.target.value']) onfocus(e: string) {
        this.remove(e);
    }

    format(val: string) {
        if (val != '') {
            const replacedVal = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            this.renderer.setProperty(this.el.nativeElement, 'value', replacedVal);
        }
    }

    remove(val: string) {
        if (val != '') {
            val = val.replace(/\,/g, '');
            this.renderer.setProperty(this.el.nativeElement, 'value', val);
        }
    }


}
