import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fhlb-modal-2',
  templateUrl: `./fhlb-modal.component.html`,
  styleUrls: [`./fhlb-modal.component.sass`]
})

export class FhlbModalComponent implements OnInit {
  @Input() title: string;
  @Input() topBorder: string;
  @Input() showHeader = true;
  @Input() hideCloseButton = false;

  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig) {
  }

  ngOnInit(): void {

  }
}
