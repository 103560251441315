<div class="fhlb-blue-1 top-header padding-bottom-20px row">
    <div class="col-md-4">
        <img src="assets/MPF_medium_white_vector_72ppi.png" class="mpf-dashboard-logo" />
    </div>
    <div class="col-lg-8">
        <ul id="top-header" class="nav nav-pills pull-right">

                <li *ngIf="isEmpfSecurityAdmin"><a (click)="navToManageUsers(userOrgKey);" class="nav-link font-white" tabindex="0">MANAGE USERS</a></li>
                <li *ngIf="!isInternalUser"><a class="nav-link font-white" (click)="navToProfile();"  tabindex="0">PROFILE</a></li>
                <li *ngIf="!isInternalUser" id="messages">
                    <ng-template #messagesContent>
                        <div (mouseleave)="msgPop.close()">
                            <div class="row padding-10px background-color-dark-blue-1">
                                <span class="col-8 ps-0 font-size-1 font-open-sans font-color-white margin-0px font-bold"  tabindex="0">
                                    <span>
                                        Unread Messages
                                    </span>
                                    <span class="messages-popover-unread-count padding-left-20px"  tabindex="0">
                                        <strong>{{ unreadMessages }}</strong>
                                    </span>
                                </span>
                                <span class="col-4 pe-0 font-size-1 font-open-sans font-color-white margin-0px float-end underline-on-hover" 
                                (click)="navToMessages();"  tabindex="0">
                                    <span>Read All</span>
                                    <span class="fa fa-chevron-right padding-left-10px" aria-hidden="true"></span>
                                </span>
                            </div>
                            <div class="row" id="messagePopoverList">
                                <div class="messages-popover-list-item" id="messagePopoverListItem"  tabindex="0" *ngFor="let message of messages | propertyFilter:{ isRead:false } | propertyFilter: { isDismissed:false } | slice:0:5
                                                                                                            let odd = odd;
                                                                                                            let even = even;
                                                                                                            let i = index"
                                     [ngClass]="{'odd': odd, 'even' : even}" (click)="navToMessage(message.messageId);">
                                    <div class="font-open-sans font-size-1 font-color-gray messages-popover-title message-item-on-hover">{{ message.title }}</div>
                                    <div class="font-open-sans-bold font-size-0 font-color-dark-gray message-item-on-hover">{{ message.startDateTime }}</div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div [ngbPopover]="messagesContent" #msgPop="ngbPopover" placement="bottom" [autoClose]="'outside'"
                    triggers="mouseenter focusin" popoverClass="popoverMsgBG">
                        <a class="nav-link font-white padding-bottom-30pxi" (click)="navToMessages();" tabindex="0" >MESSAGES</a>
                    </div>
                </li>
                <li class="unread-messages-superscript text-center font-size-1"  tabindex="0" *ngIf="!isInternalUser && unreadMessages > 0">
                    <div>{{ unreadMessages }}</div>
                </li>
                <li *ngIf="!isInternalUser"><a (click)="navToContact();" class="nav-link font-white" tabindex="0">CONTACT US</a></li>
            
            <li id="last-login-id">
                <ng-template #lastLoginContent>
                    <div id="lastLoginText">
                        <p class="font-size-1 font-open-sans-bold font-color-dark-blue-2 margin-0px">LAST LOGIN</p>
                        <p class="font-size-1 font-open-sans font-color-dark-blue-2 margin-0px">{{lastLoginDate}}</p>
                        <p class="font-size-1 font-open-sans font-color-dark-blue-2 margin-0px">{{lastLoginTime}} CT</p>
                    </div>    
                </ng-template>
                <div id="help-tip-common" [ngbPopover]="lastLoginContent" placement="bottom" [autoClose]="false"
                triggers="mouseenter:mouseleave" popoverClass="popoverLastLoginBG">
                    <a class="nav-link font-white" href="javascript:void(0);" >{{userId}}</a>
                </div>
                        
            </li>

            <li><a class="nav-link logout-button" (click)=logout();  tabindex="0">LOG OUT</a></li>
        </ul>
        <div class="row" style="margin-top: 90px;">
            <div class="col-sm-5 offset-7">
            <button *ngIf="isServiceNowButtonVariable" id="service-now-button" (click)=servicenow();
                class="min-width-290px button-background-color border-none outline-none button-text-color 
                padding-top-10 padding-bottom-10 border-radius-8" style="float: right; font-family:'Tahoma'; font-size:13.5px;" >
                    <strong>MPF CUSTOMER SERVICE PORTAL</strong>
            </button>
            </div>
        </div>
    </div>
</div>

