<div id="footer-nav" class="hidden-print">
    <ul class="nav nav-justified fhlb-blue-2 nav-fill">
        <li *ngFor="let menuItem of links" class="nav-item"><a class="nav-link"
                [routerLink]="menuItem.route"><span>{{menuItem.name}}</span></a></li>
    </ul>
</div>
<div>
    <div id="footer-nav-2" class="fhlb-blue-2 font-size-2 text-center hidden-print">
        <p>&copy; 2000-{{currentYear}}.  Federal Home Loan Bank of Chicago. <br> All Rights Reserved.</p>
    </div>
</div>