<div *ngIf="yearList.availableOptions.length > 0">
<app-aec-report-page
[pfiList]="pfiList"
[yearList]="yearList"
[ogStatus]="ogStatus"
[searchList] ="searchList"
(searchDataEvent)="searchDataEvent($event)"
[isPFIUser]="isPFIUser"
>

</app-aec-report-page>
</div>