import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '@app-shared/services';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import { GetMasterCommitmentByIdRequest } from './models/GetMasterCommitmentByIdRequest';
import { GetMasterCommitmentByIdResponse } from './models/GetMasterCommitmentByIdResponse';
import { GetProgramsResponse } from './models/GetProgramsResponse';
import { GetDcCollectionByCriteriaPagedRequest } from '../delivery-commitment/models/GetDcCollectionByCriteriaPagedRequest';
import { GetDcCollectionByCriteriaPagedResponse } from '../delivery-commitment/models/GetDcCollectionByCriteriaPagedResponse';
import { GetCreditEnhancersAndLoanServicersRequest } from './models/GetCreditEnhancersAndLoanServicersRequest';
import { GetCreditEnhancersAndLoanServicersResponse } from './models/GetCreditEnhancersAndLoanServicersResponse';
import { GetInvestmentAuthorityResponse } from './models/GetInvestmentAuthorityResponse';
import { GetMpfCeCollateralManagerResponse } from './models/GetMpfCeCollateralManagerResponse';
import { GetSubServiceOrgResponse } from './models/GetSubServiceOrgResponse';
import { GetSpecialFeaturesCodeResponse } from './models/GetSpecialFeaturesCodeResponse';
import { GetPagedMasterCommitmentListResponse } from './models/GetPagedMasterCommitmentListResponse';
import { GetPagedMasterCommitmentListRequest } from './models/GetPagedMasterCommitmentListRequest';
import { GetValidateServiceResponse } from './models/GetValidateServiceResponse';
import { GetCreditRatingAgenciesResponse } from './models/GetCreditRatingAgenciesResponse';
import { CreateMasterCommitmentApiRequest } from './models/CreateMasterCommitmentApiRequest';
import { SendNoticesApiRequest } from './models/SendNoticesApiRequest';
import { SendNoticesApiResponse } from './models/SendNoticesApiResponse';
import { UpdateMasterCommitmentApiRequest } from './models/UpdateMasterCommitmentApiRequest';
import { GetRatingForSelectedPFIRequest } from './models/GetRatingForSelectedPFIRequest';
import { GetAllOrgRepAndWarrantResponse } from './models/GetAllOrgRepAndWarrantResponse';
import { GetAccountingProcessCodeForMCResponse } from './models/GetAccountingProcessCodeForMCResponse';
import { GetMasterCommitmentByKeyRequest } from './models/GetMasterCommitmentByKeyRequest';
import { GetMasterCommitmentByKeyResponse } from './models/GetMasterCommitmentByKeyResponse';
import { GetRatingForSelectedPFIApiResponse } from './models/GetRatingForSelectedPFIApiResponse';
import { GetMasterCommitmentByCriteriaApiRequest } from './models/GetMasterCommitmentByCriteriaApiRequest';
import { MCModelType } from '../organization/models/Types/McModelType';
import { ServicingModelType } from '../organization/models/Types';
import { GetDidAccountForLoanServicerApiRequest } from './models/GetDidAccountForLoanServicerApiRequest';
import { GetDidAccountForLoanServicerApiResponse } from './models/GetDidAccountForLoanServicerApiResponse';
import { GetMcDefaultSRPAdjustmentApiRequest } from './models/GetMcDefaultSRPAdjustmentApiRequest';
import { GetMcDefaultSRPAdjustmentApiResponse } from './models/GetMcDefaultSRPAdjustmentApiResponse';
import { CalculateMcPoolCeApiRequest } from './models/CalculateMcPoolCeApiRequest';
import { CalculateMcPoolCeApiResponse } from './models/CalculateMcPoolCeApiResponse';

@Injectable()
export class MasterCommitmentService {
    private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.API_PATH}/api/mastercommitment`
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/mastercommitment`;
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }

    constructor(private http: HttpClient) {
    }



    getMasterCommitmentById(req: GetMasterCommitmentByIdRequest): Observable<GetMasterCommitmentByIdResponse> {
        return this.http.get<GetMasterCommitmentByIdResponse>(`${this.API_SERVICE_PATH}/${"getMasterCommitmentById".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    getPrograms(): Observable<GetProgramsResponse> {
        return this.http.get<GetProgramsResponse>(`${this.API_SERVICE_PATH}/${"getPrograms".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    getDcCollectionByCriteriaPaged(req: GetDcCollectionByCriteriaPagedRequest): Observable<GetDcCollectionByCriteriaPagedResponse> {
        return this.http.get<GetDcCollectionByCriteriaPagedResponse>(`${this.API_SERVICE_PATH}/${"getDcCollectionByCriteriaPaged".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    getLoanServicerAndCreditEnhancers(req: GetCreditEnhancersAndLoanServicersRequest): Observable<GetCreditEnhancersAndLoanServicersResponse> {
        return this.http.get<GetCreditEnhancersAndLoanServicersResponse>(`${this.API_SERVICE_PATH}/${"GetLoanServicerAndCreditEnhancers".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    getInvestmentAuthority(): Observable<GetInvestmentAuthorityResponse> {
        return this.http.get<GetInvestmentAuthorityResponse>(`${this.API_SERVICE_PATH}/${"GetAllInvestmentAuthority".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    getMPFBankCECollateralManager(): Observable<GetMpfCeCollateralManagerResponse> {
        return this.http.get<GetMpfCeCollateralManagerResponse>(`${this.API_SERVICE_PATH}/${"GetMPFBankCECollateralManager".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }


    getAllSubServicerOrgs(): Observable<GetSubServiceOrgResponse> {
        return this.http.get<GetSubServiceOrgResponse>(`${this.API_SERVICE_PATH}/${"getAllSubServicerOrgs".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    getSpecialFeaturesCode(): Observable<GetSpecialFeaturesCodeResponse> {
        return this.http.get<GetSpecialFeaturesCodeResponse>(`${this.API_SERVICE_PATH}/${"getSpecialFeatureCodes".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    getValidateServiceResponse(): Observable<GetValidateServiceResponse> {

        return this.http.get<GetValidateServiceResponse>(`${this.API_SERVICE_PATH}/${"GetAllValidationServicesForAllProgramKeys".toLowerCase()}`, this.options)
            .pipe(map(response => response));

    }

    /*
    User Story 255192: Ang v15 upgrade -> Due to an unintended JavaScript comparison in HttpUtilHelper.ConvertQueryString() where "0 == []" would evaluate as TRUE, 
    any input parameters getting passed to these HTTP GET APIs with a value of 0 or 0.0 would then always get ignored and not translated into the resulting API
    query string. To preserve the original API query string that is getting passed in as part of the API call, any explicit 0's in the API calls are removed so that
    they do not appear in the resulting query string either.
    */
    getPagedMasterCommitmentList(req: GetPagedMasterCommitmentListRequest): Observable<GetPagedMasterCommitmentListResponse> {
        return this.http.get<GetPagedMasterCommitmentListResponse>(`${this.API_SERVICE_PATH}/${"getPagedMasterCommitmentList".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    getAllCreditRatingAgencies(): Observable<GetCreditRatingAgenciesResponse> {
        return this.http.get<GetCreditRatingAgenciesResponse>(`${this.API_SERVICE_PATH}/${"getAllCreditRatingAgencies".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }
    createMasterCommitment(req: CreateMasterCommitmentApiRequest): Observable<GetMasterCommitmentByIdResponse> {
        return this.http.post<GetMasterCommitmentByIdResponse>(`${this.API_SERVICE_PATH}/${'create'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }


    sendNotices(req: SendNoticesApiRequest): Observable<SendNoticesApiResponse> {
        return this.http.post<SendNoticesApiResponse>(`${this.API_SERVICE_PATH}/${"sendnotices".toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }
    UpdateMasterCommitment(req: UpdateMasterCommitmentApiRequest): Observable<GetMasterCommitmentByIdResponse> {
        return this.http.put<GetMasterCommitmentByIdResponse>(`${this.API_SERVICE_PATH}/${'UpdateMCAuditInfo'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));

    }

    getCalculatePoolCeResponse(connectionId: string, req: CalculateMcPoolCeApiRequest): Observable<CalculateMcPoolCeApiResponse> {
        return this.http.get<CalculateMcPoolCeApiResponse>(`${this.API_SERVICE_PATH}/${"CalculateMCPoolCE".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.websocketOptions(connectionId))
            .pipe(map(response => response));
    }

    getRatingForSelectedPFI(req: GetRatingForSelectedPFIRequest): Observable<GetRatingForSelectedPFIApiResponse> {
        return this.http.get<GetRatingForSelectedPFIApiResponse>(`${this.API_SERVICE_PATH}/${"getRatingForSelectedPFI".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    getAllOrgRepAndWarrant(): Observable<GetAllOrgRepAndWarrantResponse> {
        return this.http.get<GetAllOrgRepAndWarrantResponse>(`${this.API_SERVICE_PATH}/${"getAllOrgRepAndWarrant".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    checkAccountingProcessCodeForMasterCommitment(prgKey: any, strInvestmentOption: any): Observable<any> {
        return this.http.get<GetAccountingProcessCodeForMCResponse>(`${this.API_SERVICE_PATH}/${"CheckAccountingProcessCodeForMC".toLowerCase()}?${HttpUtilHelper.ConvertQueryString({ ProgramKey: prgKey, InvestmentOption: strInvestmentOption })}`, this.options)
            .pipe(map(response => response));
    }

    getMasterCommitmentByKey(req: GetMasterCommitmentByKeyRequest): Observable<GetMasterCommitmentByKeyResponse> {
        return this.http.get<GetMasterCommitmentByKeyResponse>(`${this.API_SERVICE_PATH}/${"getMasterCommitmentByKey".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    /* User Story 255192: Ang v15 upgrade -> See explanation above under the same User Story ID */
    getMasterCommitmentByCriteria(req: GetMasterCommitmentByCriteriaApiRequest): Observable<GetPagedMasterCommitmentListResponse> {
        return this.http.get<GetPagedMasterCommitmentListResponse>(`${this.API_SERVICE_PATH}/${"getMasterCommitmentByCriteria".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    bulkMCUpdate(connectionId: string, updatereason:string): Observable<any> {
        return this.http.put<any>(`${this.API_SERVICE_PATH}/${'BulkMCUpdate'.toLowerCase()}`,{reason:updatereason}, this.websocketOptions(connectionId))
            .pipe(map(response => response));
    }
    
    getServicingModelKey(servicingModel: number) {

        switch (servicingModel) {
            case MCModelType.ServicingRetained:
                return ServicingModelType.ServicingRetained;
            case MCModelType.Concurrent:
                return ServicingModelType.ConcurrentSaleServicing;
            case MCModelType.SharedFunding:
                return ServicingModelType.SharedFunding;
            case MCModelType.BifurcatedServicingReleased:
                return ServicingModelType.BifurcatedServicingReleased;

        }
    }

    getDidAccountForLoanServicer(req: GetDidAccountForLoanServicerApiRequest): Observable<GetDidAccountForLoanServicerApiResponse> {
        return this.http.get<GetDidAccountForLoanServicerApiResponse>(`${this.API_SERVICE_PATH}/${"getDidAccountForLoanServicer".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    getMcDefaultSRPAdjustment(req: GetMcDefaultSRPAdjustmentApiRequest): Observable<GetMcDefaultSRPAdjustmentApiResponse> {
        return this.http.get<GetMcDefaultSRPAdjustmentApiResponse>(`${this.API_SERVICE_PATH}/${"getMcDefaultSRPAdjustment".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

}

