import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, OnInit, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ConvertToWholeNumber]'
})
export class ConvertToWholeNumberDirective implements OnInit {
  private ngControl: NgControl;

  constructor(public el: ElementRef, public renderer: Renderer2, private currencyPipe: CurrencyPipe, @Self() ngControl: NgControl) {
      this.ngControl = ngControl;
  }

  ngOnInit() {
      setTimeout(() => {
      this.format(this.el.nativeElement.value);
      }, 3000);
  }

  @HostListener('blur', ['$event.target.value']) onInput(e: string) {
      this.format(e);
  }

  format(val: string) {
      const replacedVal = val.replace(/[$ ,]/g, '');
      const numberFormat = parseFloat(replacedVal);
      const usd = isNaN(numberFormat) ? val : this.currencyPipe.transform(numberFormat, '', '', '1.0-0');

      if (numberFormat > 1000000000 || isNaN(+replacedVal)) {
          this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
          this.ngControl.control.setErrors({ pattern: true });

          this.renderer.setProperty(this.el.nativeElement, 'value', val);
      } else {
          this.renderer.setProperty(this.el.nativeElement, 'value', usd);
          isNaN(numberFormat) ?  this.ngControl.viewToModelUpdate(0):this.ngControl.viewToModelUpdate(numberFormat);
          this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
      }
  }

}
