import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpUtilHelper } from "@app-shared/helpers/http-util-helper";
import { S3BucketUploadAPIRequestModel } from "@app-shared/models/s3Bucket-upload-request.model";
import { AppConfigService } from "@app-shared/services";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { GetBatchUploadURLApiRequest } from "./models/GetBatchUploadURLApiRequest";

@Injectable({
  providedIn: 'root'
})
export class UploadService {
 
  private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;

  private BUILT_API_PATH = `${this.API_PATH}/api/batch`
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/batch`;
  private DOC_API_PATH = `${this.API_PATH}/api/document/ext`
  private API_SERVICE_DOC_EXT_PATH = `${this.DOC_API_PATH}/document`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private reqstr: string;
  constructor(private http: HttpClient) { }



  GetBatchUploadURL(req: GetBatchUploadURLApiRequest): Observable<string> {
    return this.http.get<string>(`${this.API_SERVICE_PATH}/${"BatchFileUpload".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetS3BucketFileUploadURL(req: S3BucketUploadAPIRequestModel): Observable<string> {
    return this.http.get<string>(`${this.API_SERVICE_PATH}/${"BatchFileUpload".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetS3DocumentPresignedURL(req: S3BucketUploadAPIRequestModel): Observable<any> {
    return this.http.get<string>(`${this.API_SERVICE_DOC_EXT_PATH}/${"GetPresignedUrl".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  UploadDocumentToS3(presignedUrl: string, file: File): Observable<any> {
    // Convert the file to a blob
    const blob = new Blob([file], { type: file.type });
    // Use the HttpClient to send a PUT request to the presigned URL
    return this.http.put(presignedUrl, blob);

  }

  async uploadBatchFile(uploadURL: string, batchFiles: any) {
    //Check if Batch is valid or not
    if (batchFiles && batchFiles.length > 0) {
      let fileToUpload = <File>batchFiles[0];
      // Get File Extension and Identify the Mime type
      const fileExtension = fileToUpload.name.split('.').pop().toString();
      const fileMimeType = this.getFileMIMEType(fileExtension.toLowerCase());
      // Pass the file to the blob.
      let blobData = new Blob([fileToUpload], { type: fileMimeType });
      // Reset The Headers and Reset The Authorization in Auth-Interceptor File
      const headers = new HttpHeaders();
      headers.set('Content-Type', undefined);
      //PUT method to upload binary data directly to the signed URL
      const result = await fetch(uploadURL, {
        method: 'PUT',
        body: blobData
      })
      if (result)
        return result;
    }
  }

  uploadJsonToS3(presignedUrl: string, data: any): Observable<any> {
    // Convert the TypeScript object to a Blob
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    // Use the HttpClient to send a PUT request to the presigned URL
    return this.http.put(presignedUrl, blob);

  }

  getFileMIMEType(fileextension: string) {
    switch (fileextension) {
      case "csv": return "text/csv";
      case "txt": return "text/plain";
      case "doc": return "application/msword";
      case "jpg": return "image/jpeg";
      case "gif": return "image/gif";
      case "png": return "image/png";
      case "xml": return "application/xml";
      // Add more extensions accordingly...
      default:
        return "application/xml";
    }
  }


}
