import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetProductRemittancesTypeByPFIApiResponse,
  GetRemittancesTypeApiRequest,
  GetRemittancesTypeByPfiApiResponse,
  GetRemittanceTypeByProductCodeAndPFIApiRequest,
  GetRemittanceTypeByProductCodeAndPFIApiResponse,
  GetProductCodesByPFIApiRequest,
  GetProductCodesByPFIApiResponse,
  UpdateProductRemittanceTypeApiRequest,
  InsertIntoProductRemittanceTypeApiRequest
} from './models';
import { AppConfigService } from '@app-shared/services';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';

@Injectable()
export class OrgProductService {
  private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH = `${this.API_PATH}/api/organization`;
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/product`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getRemittanceTypeByPFI(req: GetRemittancesTypeApiRequest): Observable<GetRemittancesTypeByPfiApiResponse> {
    return this.http.get<GetRemittancesTypeByPfiApiResponse>(`${this.API_SERVICE_PATH}/${"GetRemittanceTypeByPFI".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getRemittanceTypeByProductCodeAndPFI(req: GetRemittanceTypeByProductCodeAndPFIApiRequest): Observable<GetRemittanceTypeByProductCodeAndPFIApiResponse> {
    return this.http.get<GetRemittanceTypeByProductCodeAndPFIApiResponse>(`${this.API_SERVICE_PATH}/${"GetRemittanceTypeByProductCodeAndPFI".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getProductRemittanceTypeByPFI(req: GetRemittancesTypeApiRequest): Observable<GetProductRemittancesTypeByPFIApiResponse> {
    return this.http.get<GetProductRemittancesTypeByPFIApiResponse>(`${this.API_SERVICE_PATH}/${"GetProductRemittanceTypeByPfi".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getProductRemittanceTypeForNewPFI(): Observable<GetProductRemittancesTypeByPFIApiResponse> {
    return this.http.get<GetProductRemittancesTypeByPFIApiResponse>(`${this.API_SERVICE_PATH}/${"GetProductRemittanceTypeForNewPFI".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }

  getProductCodesByPFI(req: GetProductCodesByPFIApiRequest): Observable<GetProductCodesByPFIApiResponse> {
    return this.http.get<GetProductCodesByPFIApiResponse>(`${this.API_SERVICE_PATH}/${"GetProductCodesByPFI".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  updateProductRemittanceType(req: UpdateProductRemittanceTypeApiRequest): Observable<boolean> {
    return this.http.put<boolean>(`${this.API_SERVICE_PATH}/${"UpdateProductRemittanceType".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  InsertIntoProductRemittanceType(req: InsertIntoProductRemittanceTypeApiRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_SERVICE_PATH}/${"InsertIntoProductRemittanceType".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }
}
