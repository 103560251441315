export enum Behavior
{
    'Create PFI Org'=	1,
    'Create MC' =	2,
    'Create DC'=	3,
    'Make Funding Request'=	4,
    'Submit Batch'=	5,
    'Be Post Closing Contact'=	6,
    'Present Loan'=	7,
    'On Resolution'=	8,
    'Act as QA'=	9,
    'Access eMPF'=	10,
    'Send Reporting to Master Servicer'=	11,
    'Request Collateral'=	12,
    'Download Files'=	15,
    'Receive Emails'=	16,
    'Upload Files'=	17,
    'Approve PFI/Originator'=	18,
    'Approve MC'= 19,
    'Receive Files'=	20,
    'Account Manager'=	21,
    'Update Pricing'=	22,
    'Active Distribution for MPFPlus'=	23,
    'Opt out Authorizer'=	25,
    'Request Servicing Transfer'=	26,
    'Pricing Engine Vendor Relationship Contact'=	27,
    'Direct Security'=	28,
    'Servicing Contact'=	29,
    'eMPF Security Administrator'=	30,
    'Xtra MBS Investor Reporting'=	31
   
}