export enum MCModelType
  {
    /// <summary>
    /// The servicing retained
    /// </summary>
    ServicingRetained = 1,
    /// <summary>
    /// The concurrent
    /// </summary>
    Concurrent = 2,
    /// <summary>
    /// The junior master commitment
    /// </summary>
    JuniorMasterCommitment = 3,
    /// <summary>
    /// The senior master commitment
    /// </summary>
    SeniorMasterCommitment = 4,
    /// <summary>
    /// The shared funding
    /// </summary>
    SharedFunding = 5,
    /// <summary>
    /// The bifurcated servicing released
    /// </summary>
    BifurcatedServicingReleased = 6
  }