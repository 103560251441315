import { Component, OnInit, Input, EventEmitter } from '@angular/core';


@Component({
  selector: 'inline-success-message',
  templateUrl: './inline-success-message.component.html',
  styleUrls: ['./inline-success-message.component.sass']

})

export class InlineSuccessMessageComponent implements OnInit {

  @Input() show: boolean;
  @Input() message: string;


  constructor() { }

  ngOnInit() {

  }


}