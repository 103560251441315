
export enum MergerReason {
    /// <summary>
    /// 
    /// </summary>
    PFIAcquisition = 1,
    /// <summary>
    /// 
    /// </summary>
    PFIFailure = 2,
    /// <summary>
    /// 
    /// </summary>
    Other = 3
}
