

import { Directive, ElementRef, OnInit, Self, HostListener, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';




@Directive({
    selector: 'input[allowDecimalNumbers]'
})




export class AllowDecimalNumbersDirective implements OnInit {



    @Input() input: any;
    @Input() allowPercentage: string;
    @Input() allowPositiveNegative: string;
    @Input() allowDecimal: string;
    @Input() decimalUpto: number;
    @Input() allowdLength: number;
    @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();



    constructor(private el: ElementRef) { }


    ngOnInit() {
    }


    @HostListener('keydown', ['$event']) onInputChange(event) {
        var map = { 17: false, 67: false, 86: false, 39: false, 37: false, 16: false, 65: false, 36: false, 35: false, 87: false };
        var key = event.keyCode;
        if (event.which in map) {
            map[event.which] = true;
            if (
                (!(map[17] && (map[67] || map[86] || map[65]))) && //check its not (ctrl+c/ctrl+v/ctrl+a)
                (!(map[16] && (map[37] || map[39] || map[36] || map[35]))) && //check its not (shift+ arrow left/arrow right/end/home)
                (!(map[35] || map[36] || map[37] || map[39]) && (map[65] || map[67] || map[87]))//check its not arrow keys (it should be with mapped characters key down either)
            ) {
                event.preventDefault();
            }
        }
        else if (event.shiftKey && key !== 9) {
            event.preventDefault();
        }
        else {
            if (!((key === 8) || (key === 32) || (key === 46) || (key === 9) || (key === 46) || (key === 110) ||
                (key === 190) || (key >= 35 && key <= 40) || (key >= 48 && key <= 57) || (key >= 96 && key <= 105) || (key === 13))) {
                event.preventDefault();
            }
        }
    }




    @HostListener('keyup', ['$event']) keyup(event) {
        var map = { 17: false, 67: false, 86: false, 39: false, 37: false, 16: false, 65: false, 36: false, 35: false };
        if (event.which in map) {
            map[event.keyCode] = false;
        }
    };



}
