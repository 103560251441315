import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { fhlbConstants } from '@app-shared/constants/fhlb-constants';
import { AppConfigService } from '@app-shared/services';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { getDecodedIdTokenFromStore, getSelectedProfileScopeId, getSiteForUserFromStore } from 'src/store/selectors';
import { catchError, map, take, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/store/reducers';
import { Store } from '@ngrx/store';
import { DecodeAccessToken } from 'src/store/reducers/models';
import { isNullOrUndefined } from 'is-what';
import { RouteDataAuthenication, SiteName } from '@app-shared/models/routingdata';
import { of } from 'rxjs/internal/observable/of';
import { OriginationRoutingHelper } from '@origination-shared/navigation/origination.navigation';
import { EmpfRoutingHelper } from '@empf-shared/navigation/empf.navigation';

@Injectable()

export class AuthGuard implements CanActivate {
    @BlockUI() blockUI: NgBlockUI;

    constructor(private router: Router, private store: Store<AppState>, private activatedRoute: ActivatedRoute) { }

    canActivate(route: ActivatedRouteSnapshot) {
        this.blockUI.start();

        let currentUser: DecodeAccessToken
        let isAdRoleFound = false;
        let areBehaviorsOnRoute = false;
        let u = <RouteDataAuthenication>route.data
        let routeGuardLogging = AppConfigService.Settings.App.RouteGuardLogging;

        return this.store.select(getDecodedIdTokenFromStore).pipe(
            withLatestFrom(this.store.select(getSiteForUserFromStore), this.store.select(getSelectedProfileScopeId)),
            take(1),
            map(([userToken, userSitePath, userSelectedProfileScopeId]) => {
                if (routeGuardLogging) console.log("Auth Guard: ProfileScopeID Check", userSelectedProfileScopeId);

                if (isNullOrUndefined(userSelectedProfileScopeId))
                {
                    // If there is no selected ProfileScopeID in Store, send the user to the Entry Component to have them make a choice
                    if (routeGuardLogging) console.log("Auth Guard: User has not selected Profile Scope ID. Sending user to Entry Component. SelectedProfileScopeID: ", userSelectedProfileScopeId);

                    this.blockUI.stop();
                    this.router.navigate(['/']);
                    return false;
                }

                if (routeGuardLogging) console.log("Auth Guard: Decoded Token", userToken);
                if (routeGuardLogging) console.log("Auth Guard: Route Data", u);

                if (!isNullOrUndefined(userToken)) {
                    currentUser = userToken
                    var isAllowed = false;

                    var upperCaseUserGroups = currentUser['custom:UserGroup'].map((function
                         (roleString) { return roleString.toUpperCase(); } ));
                    if (routeGuardLogging) console.log("Auth Guard: Case-insensitive User Groups", upperCaseUserGroups);

                    if (!isNullOrUndefined(u.losAuth))
                        u.losAuth.roles.forEach(routeRole => {
                            //Check if route role is present in user level groups.
                            if (routeGuardLogging) console.log("Auth Guard: Route Role", AppConfigService.Settings.Empf[routeRole].toUpperCase());

                            if (upperCaseUserGroups.indexOf(AppConfigService.Settings.Empf[routeRole].toUpperCase()) != -1) {
                                isAdRoleFound = true;
                                isAllowed = true;
                            }
                        });

                    if (routeGuardLogging) console.log("Auth Guard: LOS Roles checked. Current AuthGuard state: ", isAdRoleFound, isAllowed);

                    if (!isNullOrUndefined(u.empfAuth) && !isAdRoleFound && !isAllowed)
                        u.empfAuth.roles.forEach(routeRole => {
                            //Check if route role is present in user level groups.
                            if (routeGuardLogging) console.log("Auth Guard: Route Role", AppConfigService.Settings.Empf[routeRole].toUpperCase());

                            if (upperCaseUserGroups.indexOf(AppConfigService.Settings.Empf[routeRole].toUpperCase()) != -1) {
                                isAdRoleFound = true;
                                if (u.empfAuth.behaviors) {
                                    areBehaviorsOnRoute = true;
                                    //If user level groups are present then get the route level behaviors. 
                                    u.empfAuth.behaviors.forEach(routeBehavior => {
                                        //At route level behaviors are present and hence it should be present in user's token.
                                        if (routeGuardLogging) console.log("Auth Guard: Route Behavior", routeBehavior);

                                        if (!isNullOrUndefined(currentUser['custom:Behavior']) && currentUser['custom:Behavior'].length > 0) {
                                            if (currentUser['custom:Behavior'].indexOf(routeBehavior) != -1) {
                                                isAllowed = true;
                                            }
                                        }
                                    });
                                } else {
                                    //Behaviors not found at route level and role/group is validated, hence allow user to access route link.
                                    isAllowed = true;
                                }
                            }
                        });

                    if (routeGuardLogging) console.log("Auth Guard: eMPF Roles checked. Current AuthGuard state: ", isAdRoleFound, isAllowed);

                    if (!isAllowed) {
                        this.blockUI.stop();
                        this.navigateUnauthorizedRoute(userSitePath);
                        return false;
                    } else {
                        this.blockUI.stop();
                        return true;
                    }
                }
                else {
                    this.blockUI.stop();
                    this.navigateUnauthorizedRoute(userSitePath);
                }

                return false;
            }),
            catchError((error) => {
                if (routeGuardLogging) console.log("AuthGuard: Error", error);

                this.blockUI.stop();
                this.navigateUnauthorizedRoute(null);
                return of(false);
            })
        )
    }

    navigateUnauthorizedRoute(siteName: SiteName) {
        if (siteName == SiteName.Empf)
            this.router.navigate([EmpfRoutingHelper.Unauthorized()], { skipLocationChange: true });
        else if (siteName == SiteName.Origination)
            this.router.navigate([OriginationRoutingHelper.UnauthorizedRoute()], { skipLocationChange: true });
        else
            this.router.navigate([fhlbConstants.unauthorizedRoute]);
    }
}
