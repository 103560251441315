import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[universalLoanIdentifierValueValidate]'
})
export class UniversalLoanIdentifierValueValidate implements OnInit {
    private ngControl: NgControl;
    private htmlEl: HTMLElement;
    @Input('universalLoanIdentifierValueValidate') mersMinValue: string;
    pattern = /[^a-zA-Z0-9]*/g;
    constructor(public el: ElementRef, public renderer: Renderer2, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
        this.htmlEl = el.nativeElement;
    }

    ngOnInit() {
        setTimeout(() => {
            this.format(this.el.nativeElement.value);
        }, 3000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    format(val: string) {
        if (val) {
            let setErrors = false;
            this.ngControl.control.setErrors({ pattern: false });
            this.ngControl.control.setErrors(null);
            if (!this.htmlEl.classList.contains('is-invalid')) {
                this.htmlEl.classList.remove('is-invalid');
            }
            let output = val.match(this.pattern);
            if (val.length < 23) {
                setErrors = true;
            }
            else if (output === null) {
                setErrors = true;
            }
            else{
                let str = val.slice(-2);
                if(isNaN(+str)){
                    setErrors = true;
                }
            }
            if (setErrors) {
                this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                this.ngControl.control.setErrors({ pattern: true });
                if (!this.htmlEl.classList.contains('is-invalid')) {
                    this.htmlEl.classList.add('is-invalid');
                }
            }
        }
    }
}
