import { All, GeoProcessActionTypes, GeoStatus } from '../actions/geo-process.actions';

export interface State {
  status: GeoStatus;
  errors: string[];
  modal: {
    startDate: Date,
    endDate: Date
  }
}

export const initialState: State = {
  status: GeoStatus.Na,
  errors: [],
  modal: {
    startDate: null,
    endDate: null
  }
};

export function reducer(state = initialState, action: All): State {
  switch (action.type) {
    case GeoProcessActionTypes.RUN_GEO: {
      let result = <{ startDate: Date, endDate: Date }>action.payload
      
      return {
        ...state,
        status: GeoStatus.Processing,
        modal: result,
        errors: []
      }
    }
    case GeoProcessActionTypes.COMPLETE_GEO: {
      let result = <{ status: GeoStatus, errors?: [] }>action.payload
      
      return {
        ...state,
        status: result.status,
        errors: result.errors
      }
    }
    default: {
      return state;
    }
  }
}
