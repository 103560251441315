<fhlb-modal-2 id="EmailMessage"  [topBorder]="topBorderClass" class="modal-width-325px" [showHeader]="false">
    <div class="row">
        <div [hidden]="!isEmailSuccess" class="col-sm-12 text-center padding-top-15px">
            {{cmsModel?.SuccessEmail}}

        </div>
    </div>
    <div [hidden]="isEmailSuccess" class="row text-center">
        <div class="font-color-dark-gray padding-top-15px padding-5px">
            {{cmsModel?.FailuerEmail}}

        </div>
    </div>
    <br />
    <div class="text-center">
        <input type="submit" class="btn btn-default fhlbc-modal-button browse-button-background-modal fw-bold  " value="OK" (click)="okClick()" />
    </div>
    
</fhlb-modal-2>