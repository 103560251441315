import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'decimalRoundOff'
})
export class decimalRoundOff implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value == null)
            return null;
        return Math.floor(value);
    }
}