import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
    ExportLoanDeliveryFileApiRequest, ExportLoanDeliveryFileApiResponse,
    GenerateMonthlyIssuanceFileResponse, ImportAdjustmentFactorsFileApiResponse,
    ImportPurchaseAdviceApiResponse, ImportSRPFilesApiResponse
} from "./models";
import { AppConfigService } from "@app-shared/services";
import { HttpUtilHelper } from "@app-shared/helpers/http-util-helper";
import { ImportPurchaseAdviceWebSocketResponse } from "./websocket/loan-export/models/ImportPurchaseAdviceWebSocketResponse";
import { ExportLoanDeliveryFileWebsocketResponse } from "./websocket/loan-export/models/ExportLoanDeliveryFileWebsocketResponse";

@Injectable()
export class LoanExportService {
   
    private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.API_PATH}/api/loanexport`;
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/loanexport`;
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }
    constructor(private http: HttpClient) { }

    GenerateMonthlyIssuanceFile(): Observable<GenerateMonthlyIssuanceFileResponse> {
        return this.http.get<GenerateMonthlyIssuanceFileResponse>(`${this.API_SERVICE_PATH}/${"GenerateMonthlyIssuanceFile".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    ExportLoanDeliveryFile(req: ExportLoanDeliveryFileApiRequest, connectionId: string): Observable<ExportLoanDeliveryFileWebsocketResponse> {
        return this.http.get<ExportLoanDeliveryFileWebsocketResponse>(`${this.API_SERVICE_PATH}/${"ExportLoanDeliveryFile".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.websocketOptions(connectionId))
            .pipe(map(response => response));
    }

    ImportAdjustmentFactorsFile(): Observable<ImportAdjustmentFactorsFileApiResponse> {
        return this.http.get<ImportAdjustmentFactorsFileApiResponse>(`${this.API_SERVICE_PATH}/${"ImportAdjustmentFactorsFile".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    ImportSRPFile(): Observable<ImportSRPFilesApiResponse> {
        return this.http.get<ImportSRPFilesApiResponse>(`${this.API_SERVICE_PATH}/${"ImportSRPFiles".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    ImportPurchaseAdviceFile(connectionId: string): Observable<ImportPurchaseAdviceWebSocketResponse> {
        return this.http.get<ImportPurchaseAdviceWebSocketResponse>(`${this.API_SERVICE_PATH}/${'ImportPurchaseAdvice'.toLowerCase()}`, this.websocketOptions(connectionId))
            .pipe(map(response => response));
    }
    
}
