<interactive-header *ngIf="showInteractiveHeader">
</interactive-header>
<div class="container-fluid padding-top-20px">
    <div class="row padding-left-right-40px">
        <div class="container-fluid">
            <div class="origination-top-nav">
                <div class="origination-top-breadcrumb">
                    <app-breadcrumb *ngIf="showBreadCrumb"></app-breadcrumb>
                </div>
                <div class="origination-top-date">{{ todayTime }}</div>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>