import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LogOutSuccessAction } from 'src/store/actions/auth.actions';
import { AppState } from 'src/store/reducers';


@Component({
    selector: 'expired-notification-container',
    templateUrl: `./expired-notification.container.component.html`
})

export class ExpiredNotificationContainerComponent implements OnInit {
    topBorderClass = 'top-border-green';

    constructor(private store: Store<AppState>) {
    }

    ngOnInit(): void {
    }

    logout() {
        this.store.dispatch(new LogOutSuccessAction());
    }
}

