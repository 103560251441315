<form class="defaulttext" #dcEmailModalForm="ngForm" name="=dcEmailModalForm">
    <fhlb-modal-2 [title]="title" [topBorder]="topBorderClass" class="modal-hr">
        <div class="container-fluid ">
            <div class="row justify-content-center">
                <p class="col-11 mb-0 size">
                    <i> {{labelMessage}}<br>
                    To email this report to additional contacts, select the contact names.</i>
                </p>
            </div>
            <div class="row mt-3 justify-content-center">
                <div class="col-6 size" *ngIf="contactEmailList">
                    Showing {{contactEmailList.length ==0? 0 : ((pageNo-1) * pageSize+1)}}
                    to {{contactEmailList.length > (pageNo * pageSize) ? pageNo * pageSize : contactEmailList.length}}
                    of {{contactEmailList.length}} items
                </div>
                <div class="col-5 text-end size" *ngIf="contactEmailList">
                    Display &nbsp;
                    <select id="selectEntry" name="selectEntry" class="showentry" [(ngModel)]="pageSize">
                        <option [ngValue]="5">5</option>
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="25">25</option>
                        <option [ngValue]="50">50</option>
                        <option [ngValue]="100">100</option>
                        <option [ngValue]="contactEmailList.length">ALL</option>
                    </select>
                    &nbsp; per page
                </div>
            </div>
            <div class="row mt-2 justify-content-center">
                <div class="col-11">
                    <table class="table table-striped" id="pfiInfo">
                            <thead id="th" class="font-bold d-flex">
                                <th class="col-1" ></th>
                                <th class="select-width col-4">Contact Name</th>
                                <th class="id-width col-7 Wordwrap">Email Address</th>
                            </thead>
                        <tbody id="tbodycount">
                            <tr class="d-flex" id="contactList" *ngFor="let contacts of contactEmailList 
                            |    orderBy: {property: sortingName} 
                                |  slice: (pageNo-1) * pageSize : (pageNo-1) * pageSize + pageSize ; let i=index">
                                <td class="font-bold col-1">
                                    <div>
                                        <input id="contact-{{i}}" name="contact-{{i}}" type="checkbox"
                                            class="form-check-input" tabindex="-1" [(ngModel)]="selectedContacts[(pageNo-1) * pageSize + i]"
                                            >                                           
                                        <label for="contact-{{i}}" class="mb-3"><span></span></label>
                                    </div>
                                </td>
                                <td class="col-4  Wordwrap">{{contacts.name}}</td>
                                <td class="col-7  Wordwrap">{{contacts.emailAddress}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row justify-content-center align-items-center font-size-2 pagination pt-2"
                        [hidden]="contactEmailList.length <= pageSize">
                        <ngb-pagination id="pagination" [collectionSize]="contactEmailList.length" [maxSize]="10" [(page)]="pageNo"
                            [pageSize]="pageSize" aria-label="Default pagination">
                            <ng-template id="previous" ngbPaginationPrevious class="font-size-2">
                                &lt;Prev</ng-template>
                                    <ng-template id="Next" ngbPaginationNext class="font-size-2">Next&gt;</ng-template>
                        </ngb-pagination>
                    </div>
                    <div class="row justify-content-center" *ngIf="contactEmailList.length == 0 || !contactEmailList">
                        <p>There are no items to display.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center pt-2" *ngIf ="showDoNOtSendBtn">
                <div class="col-3">
                  <button class="btn btn1 btn-dark-blue btn-primary col-12" id="sendEmail" type="submit" (click)="sendEmailClick()" ><b>
                      SEND EMAIL </b>
                  </button>
                </div>
                <div class="col-3">
                    <button class="btn btn-light-blue btn-primary col-12" id="closeEmail" type="submit" (click)="closeEmailClick()" ><b>
                        DO NOT SEND </b>
                    </button>
                  </div>
              </div>
            <div class="row justify-content-center pt-2" *ngIf ="showCloseBtn && !showDoNOtSendBtn">
                <div class="col-3">
                  <button class="btn btn1 btn-dark-blue btn-primary col-12" id="sendEmail" type="submit" (click)="sendEmailClick()" ><b>
                      SEND EMAIL </b>
                  </button>
                </div>
                <div class="col-3">
                    <button class="btn btn-dark-blue btn-primary col-12" id="closeEmail" type="submit" (click)="closeEmailClick()" ><b>
                        {{closeBtnText}}  </b>
                    </button>
                  </div>
              </div>
            <div class="row justify-content-center pt-2"  *ngIf ="!showCloseBtn && !showDoNOtSendBtn">
                <div class="col-6">
                  <button class="btn btn1 btn-light-blue btn-primary col-12" id="sendEmail" type="submit" (click)="sendEmailClick()" ><b>
                      SEND EMAIL </b>
                  </button>
                </div>
              </div>
        </div>

    </fhlb-modal-2>
</form>
