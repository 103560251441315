import { Component, OnInit, Input, EventEmitter } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { PFIMergerService } from '@origination-shared/services/organization/pfimerger-service';
import { MergerStatusType } from '@origination-shared/services/organization/models/Types';
import { catchError, take, map } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { mapFromPfiForMergerResponseToList } from '../../../organization/modules/merger/mappers/pfi-merger-create-response.mapper';
import { CustomToastService } from '@app-shared/services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OriginationRoutingHelper } from '@origination-shared/navigation/origination.navigation';
import { LosServiceHttpUtilHelper } from '@origination-shared/helpers/los-service-util-error-helper';

@Component({
  selector: 'createmerger-modal',
  templateUrl: './createmerger-modal-component.html',
  styleUrls: ['./createmerger-modal-component.sass']

})

export class CreatemergerModalComponent implements OnInit {
  title: string;
  topBorderClass: string;
  @BlockUI() blockUI: NgBlockUI;
  list: string[];
  // two way binding for input text
  inputItem = '';
  // enable or disable visiblility of dropdown
  listHidden = true;
  showError = false;
  selectedIndex = -1;
  selectionDone: boolean = false;

  // the list to be shown after filtering
  pfiList: string[] = [];

  constructor(public activeModal: NgbActiveModal, private router: Router, private pfiMergerService: PFIMergerService
    , private toastr: CustomToastService) {
    this.list = [];
  }


  ngOnInit() {
    this.title = 'Mergers & Acquisitions';
    this.topBorderClass = `top-border-blue`;
    this.toastr.remove();
    this.getPFIList();
  }

  getPFIList() {
    this.blockUI.start();
    forkJoin(
      this.pfiMergerService.getPFIforMerger({ MergerStatusType: MergerStatusType["NotMerged"] })
    ).pipe(take(1),
      map(([pfiListresponse
      ]) => {
        this.list = mapFromPfiForMergerResponseToList(pfiListresponse);
        this.pfiList = this.list;
        this.blockUI.stop();
      }),
      catchError(errorResponse => {
        this.blockUI.stop();
        console.log(errorResponse);
        this.toastr.show(LosServiceHttpUtilHelper.HandleServiceHttpErrors(errorResponse));
        return of(null);
      })
    ).subscribe();
  }
  // modifies the filtered list as per input
  getFilteredList() {

    this.listHidden = false;
    // this.selectedIndex = 0;
    if (!this.listHidden && this.inputItem !== undefined && this.inputItem !== ' ') {
      this.pfiList = this.list.filter((item) => item.toLowerCase().includes(this.inputItem.toLowerCase()));
    }
  }

  // select highlighted item when enter is pressed or any item that is clicked
  selectItem(ind) {
    this.inputItem = this.pfiList[ind];
    this.listHidden = true;
    this.selectedIndex = ind;
  }
  // show or hide the dropdown list when input is focused or moves out of focus
  toggleListDisplay(sender: number) {
    if (sender === 1) {
      this.selectionDone = false;
      this.selectedIndex = -1;
      this.listHidden = false;
      this.getFilteredList();
    } else {
      // helps to select item by clicking
      setTimeout(() => {
        this.selectItem(this.selectedIndex);
        this.listHidden = true;
        if (!this.list.includes(this.inputItem)) {
          this.showError = true;
          this.pfiList = this.list;
        } else {
          this.showError = false;
        }
        this.selectionDone = true;
      }, 60000);
    }
  }
  enterClick() {
    this.toastr.remove();
    if (this.inputItem !== undefined && this.inputItem !== ' ') {
      var enteredItemIndex = this.pfiList.findIndex((item) => item.toLowerCase() == this.inputItem.toLowerCase())
      if (enteredItemIndex == -1) {
        setTimeout(() => {
          this.toastr.show(['The Organization entered is ineligible please enter a valid Successor Organization.']);
        }, 400);        
        this.inputItem = undefined;
      }
      else {
        this.selectedIndex = enteredItemIndex;
        this.toggleListDisplay(0);
        this.goToCreate();
      }
    }
  }
  goToCreate() {
    this.toastr.remove();
    setTimeout(() => {
      if (this.inputItem != undefined && this.inputItem != "") {
        this.blockUI.start();
        var selectedOrgId = this.inputItem.split('-')[0];
        this.router.navigate([OriginationRoutingHelper.CreateMerger(selectedOrgId)]);

        setTimeout(() => {
          this.activeModal.dismiss();
          this.blockUI.stop();
        }, 500);
      }
      else {
        setTimeout(() => {
          this.toastr.show(['The Organization entered is ineligible please enter a valid Successor Organization.']);
        }, 400);
        this.blockUI.stop();
      }
    }, !this.selectionDone ? 600 : 0);

  }
}

