
export enum OrganizationType {
    /// <summary>
    ///   The unspecified
    /// </summary>
    Unspecified = 0,

    /// <summary>
    ///   The organization
    /// </summary>
    Organization = 1,

    /// <summary>
    ///   The pfi
    /// </summary>
    PFI
}
