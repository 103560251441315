import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[toNoteRateFormat]'
})
export class ToNoteRateFormatDirective implements OnInit {
    private ngControl: NgControl;
    private htmlEl: HTMLElement;
    @Input('toNoteRateFormat') toFixedLength: string;
    @Input('maxLengthBeforeDecimal') maxLengthBeforeDecimal: string;
    @Input('maxLengthAfterDecimal') maxLengthAfterDecimal: string;
    decimalPattern =/([0-9]*[.])?[0-9]+/
    constructor(
        public el: ElementRef, 
        public renderer: Renderer2, 
        @Self() ngControl: NgControl) 
    {
        this.ngControl = ngControl;
        this.htmlEl = el.nativeElement;
    }

    ngOnInit() {
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        this.format(e);
    }

    format(val: string) {
        let setErrors = false;
        if (val) {
            let output = val.match(this.decimalPattern);
            if (output === null) {
                setErrors = true;
            }
            else{
                if(val.includes('.')){
                    let numericValue = val.split('.')[0];
                    let decimalValue = val.split('.')[1];
                    if(this.maxLengthBeforeDecimal && numericValue.length > +this.maxLengthBeforeDecimal){
                        setErrors = true;
                    }
                    else if(this.maxLengthAfterDecimal && decimalValue.length > +this.maxLengthAfterDecimal){
                        setErrors = true;
                    }
                }
                else if(this.maxLengthBeforeDecimal && val.length > +this.maxLengthBeforeDecimal){
                    setErrors = true;
                }
            }
        } 
        if(setErrors){
            this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                this.ngControl.control.setErrors({ pattern: true });    
                this.renderer.setProperty(this.el.nativeElement, 'value', val);
                if (!this.htmlEl.classList.contains('is-invalid')) {
                    this.htmlEl.classList.add('is-invalid');
                }
        }
        else {
            if(this.toFixedLength && val.length < +this.toFixedLength){
                let num = +val;
                this.renderer.setProperty(this.el.nativeElement, 'value', num.toFixed(+this.toFixedLength));
            }else{
                this.renderer.setProperty(this.el.nativeElement, 'value', val);
            }
            this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
            if (!this.htmlEl.classList.contains('is-invalid')) {
                this.htmlEl.classList.remove('is-invalid');
            }
        }
    }

}
