import { Directive, AfterViewInit, Input, OnChanges } from "@angular/core";
import { Host, Self, Optional } from "@angular/core";

import { NgSelectComponent } from "@ng-select/ng-select";

@Directive({
    selector: "[expandSelect]"
})
export class ExpandSelectDirective implements OnChanges {
    @Input() public expandSelect: any;
    @Input() public expandselectinput: any;

    constructor( @Host() @Self() @Optional() public hostSelectComponent: NgSelectComponent) {   
    }

    ngOnChanges(changes) {
        if (changes.expandselectinput.currentValue) {
            this.hostSelectComponent.open();
        }
    }
}
