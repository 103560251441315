import { Directive, OnInit, ElementRef, HostListener, Renderer2, Self, ViewChild } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[real-time-percent]'
})
export class RealTimePercentDirective implements OnInit {
    private ngControl: NgControl;

    constructor(public el: ElementRef, public renderer: Renderer2, private percentPipe: PercentPipe, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
    }

    ngOnInit() {
        setTimeout(() => {
            this.format(this.el.nativeElement.value);
        }, 2000);
    }

    @HostListener('blur', ['$event.target.value']) onInput(e: string) {
        if (e !== '') {
            this.format(e);
        }
    }

    @HostListener('modalChange', ['$event.target.value']) onModalChangeInput(e: string) {
        if (e !== '') {
            this.format(e);
        }
    }

format(val: string) {
        const m = val.match(/^$|^-?\d{0,3}(?:\.\d{1,14})?(?: ?%)?$/);
        if (m === null || val === '') {
            this.renderer.setProperty(this.el.nativeElement, 'value', '');
        } else {
            this.ngControl.control.setErrors(null);
            const replacedVal = val.replace(/[% ,]/g, '');
            let numberFormat = Number(replacedVal);

            numberFormat = numberFormat / 100;
            const percentFormatVal = isNaN(numberFormat) ? val : this.percentPipe.transform(numberFormat, '1.3');

            if (numberFormat > 1000000000 || isNaN(+replacedVal)) {
                this.renderer.setProperty(this.el.nativeElement, 'invalid', true);
                this.ngControl.control.setErrors({ pattern: true });
                this.renderer.setProperty(this.el.nativeElement, 'value', numberFormat);
            } else {
                this.renderer.setProperty(this.el.nativeElement, 'value', percentFormatVal);
                isNaN(numberFormat) ? this.ngControl.viewToModelUpdate(0) : this.ngControl.viewToModelUpdate(numberFormat);
                this.renderer.setProperty(this.el.nativeElement, 'invalid', false);
            }
        }
    }
}
