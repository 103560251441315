import { Directive, Input, ElementRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { UtilHelper } from '../helpers/util-helper';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[compareElementJsonValue]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CompareElementJsonDirective, multi: true }]
})

export class CompareElementJsonDirective implements Validator {
    private el: HTMLElement;

    constructor(ref: ElementRef) {
        this.el = ref.nativeElement;
    }
    @Input('compareElementJsonValue') compareValues: string;

    validate(control: AbstractControl): { [key: string]: boolean } | null {
        let controlValue = control.value;
        let selectedField = this.el.attributes['selectValidate'].nodeValue;
        let compareValuesArray: Array<any> = JSON.parse(this.compareValues);
        if (UtilHelper.isDefinedOrNotNull(controlValue)) {
            if ((compareValuesArray.filter(value => (value[selectedField]
                === controlValue[selectedField]))).length > 0 && control.valid) {
                control.setErrors({ invalid: true });
                return { unique: true };
            }
        }

        return null;
    }
}
