<form class="defaulttext" #reasonupdateModalForm="ngForm" name="=reasonupdateModalForm">
    <fhlb-modal-2 [title]="title" [topBorder]="topBorderClass" class="modal-hr">
        <div class="container-fluid ">
            <div class="row justify-content-left ps-4 pb-3 fieldLabel">
                Reason For Update:
            </div>
                <div class="col-12 ps-2">
                    <textarea type="text" class="form-control" id="textarea" placeholder="placeholder text"></textarea>
                </div>
            
            <div class="row justify-content-center pt-4">
                <div class="col-5">
                    <button class="btn btn1 btn-dark-blue btn-primary col-12" id="save" type="submit"><b>
                            SAVE </b>
                    </button>
                </div>
                <div class="col-5">
                    <button class="btn btn1 btn-light-blue btn-primary col-12" id="cancel" type="submit" (click)="close()"><b>
                            CANCEL </b>
                    </button>
                </div>
            </div>
        </div>
    </fhlb-modal-2>
</form>
