import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ContactsList } from "@app-shared/models/contactsList.model";

@Component({
    selector: 'email-modal',
    templateUrl: './email-modal.component.html',
    styleUrls: ['./email-modal.component.sass']
})
export class EmailModalComponent implements OnInit {
    topBorderClass = 'top-border-blue';
    pageSize: number = 5;
    pageNo: number;
    selectedContacts: boolean[]=[];
    selectedContactsId: string[]=[];
    sortingName:string = 'name';
    
    @Input() contactEmailList: ContactsList[];
    @Input() title:string;
    @Input() authorizedEmailID:string;
    @Input() labelMessage:string;
    @Output() emitSendEmailClick:EventEmitter<string[]> = new EventEmitter<string[]>();
    @Input() showCloseBtn: boolean;
    @Input() closeBtnText: string;
    @Input() showDoNOtSendBtn: boolean;
    constructor() { }
    ngOnInit() {
        this.pageNo = 1;
        this.pageSize = 5;
    }
  
    sendEmailClick(){
        this.selectedContactsId = [];
        if(this.contactEmailList){
            for(let i=0;i<this.contactEmailList.length;i++){
                if(this.selectedContacts[i] == true){
                    this.selectedContactsId.push(this.contactEmailList[i].emailAddress);
                }
            }
        }
        this.emitSendEmailClick.emit(this.selectedContactsId);
    }

    closeEmailClick(){
        this.emitSendEmailClick.emit(this.selectedContactsId);
    }
}
