import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';
import { BroadCastService } from '@app-shared/services/broadcast.service';
import { pricingHelper } from '../helpers/pricing-helper';
import { UtilHelper } from '../helpers/util-helper';


@Directive({
    selector: '[productSelectionValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: productSelectionValidatorDirective, multi: true }]
})
export class productSelectionValidatorDirective implements OnInit, OnChanges {
    @Input() productSelectionOptions;
    @Input() selectValidatorTrigger;
    @Input() category

    constructor(private el: ElementRef, @Inject(DOCUMENT) private document: any,private broadCastService: BroadCastService) {

    }
    ngOnInit() {

    }
    ngOnChanges() {
        if (this.selectValidatorTrigger) {
            if (this.selectValidatorTrigger.event.defaultPrevented) {
                return;
            }
            this.validate(this.selectValidatorTrigger.event, this.selectValidatorTrigger);
        }

    }

    pricingValidationClass = 'pricing-panel-body-column-validation';
    validate(event, args) {
        {

            // Merges configuration set in directive with default one
            var configOptions = {};
            var mergedConfig = Object.assign({}, configOptions, (this.productSelectionOptions));

            //retrieve the criteria property
            var criteria = mergedConfig['criteria'];
            var category = this.category;
            var pricingContainerId = mergedConfig['pricingContainerId'];
            var pricingSourceName = mergedConfig['pricingSourceName'];

            var jsonObj = args;
            var targetFormName = jsonObj['targetFormName'];
            var byPassPriceSource = jsonObj['byPassPriceSource'];

            //perform validation
            var results = pricingHelper.validatePricingCriteriaBySource(criteria, pricingSourceName, byPassPriceSource);
            if (byPassPriceSource === 'nonXtra') {
                if (!results.status && results.errorType === category) {
                    if (results.errors.length > 0) {

                        if (results.stopPropagation) {
                            event.preventDefault();
                            this.removePricingValidationCss(event, pricingContainerId);
                        }

                        this.el.nativeElement.classList.add(this.pricingValidationClass)

                    } else {
                        this.el.nativeElement.classList.remove(this.pricingValidationClass)

                    }
                } else {
                    this.el.nativeElement.classList.remove(this.pricingValidationClass)

                }

            }
            else if (byPassPriceSource === 'xtra') { //In case of Market base price link
                if (!results.status && results.errorType === category && pricingSourceName != "MpfXtra") {
                    if (results.errors.length > 0) {

                        if (results.stopPropagation) {
                            event.preventDefault();

                            this.removePricingValidationCss(event, pricingContainerId);
                        }

                        this.el.nativeElement.classList.add(this.pricingValidationClass)

                    } else {
                        this.el.nativeElement.classList.remove(this.pricingValidationClass)

                    }
                } else {
                    this.el.nativeElement.classList.remove(this.pricingValidationClass)

                }
            }

        }
        this.broadCastService.broadcast('hideProductSelectionErrorsEvent');
    }
    removePricingValidationCss(event: any, pricingContainerId: any) {
        {
            //remove the CSS class from pricing main template
            var mainTmpl = this.document.getElementById(pricingContainerId);
            if (UtilHelper.isDefinedOrNotNull(mainTmpl)) {
                var matchingCssEls = mainTmpl.querySelectorAll('.' + this.pricingValidationClass);
                if (UtilHelper.isDefinedOrNotNull(matchingCssEls)) {
                    var ngMatchingCssEls = matchingCssEls;
                    ngMatchingCssEls.forEach((e) => {
                        var ngEl = e;

                        ngEl.classList.remove(this.pricingValidationClass);
                    });
                }
            }
        }
    }

}