import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '@app-shared/services';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import { GetTemplateRuleRequest } from './models/GetTemplateRuleRequest';
import { GetTemplateRuleResponse } from './models/GetTemplateRuleResponse';
import { GetRulesetRequest } from './models/GetRulesetRequest';
import { GetRulesetResponse } from './models/GetRulesetResponse';
import { GetCategoriesResponse } from './models/GetCategoriesResponse';
import { GetCategoriesResponseId } from './models/GetCategoriesResponseId';
import { GetCategoryId } from './models/GetCategoryId';
import { GetRulesResultResponse } from './models/GetRulesResultResponse';
import { GetRuleSetResponseForMC } from '../master-commitment/models/GetRuleSetResponseForMC';
//import { CreateCategoryApiRequest } from 'src/rules-and-rulesets/models/CreateCategoryApiRequest ';
//import { UpdateCategoryApiRequest } from 'src/rules-and-rulesets/models/UpdateCategoryApiRequest';
import { UpdateCategoryResponse } from './models/UpdateCategoryResponse';
import { CreateCategoryResponse } from './models/CreateCategoryResponse';
import { GetAliasResponse } from './models/GetAliasResponse';
import { CreateAliasRequest } from './models/CreateAliasRequest.model';
import { CreateAliasResponse } from './models/CreateAliasResponse.model';
import { GetAliasIDRequest } from './models/GetAliasIDRequest';
import { CreateRuleRequest } from './models/CreateRuleRequest.model';
import { CreateRuleResponse } from './models/CreateRuleResponse.model';
import { CreateRuleSetRequest } from './models/CreateRuleSetRequest.model';
import { CreateRulesetResponse } from './models/CreateRuleSetResponse.model';
import { GetExportedFileNamesResponse } from './models/GetExportedFileNamesResponse';
import { ExportRulesetsResponse } from './models/ExportRulesetsResponse';
import { ImportRulesetsResponse } from './models/ImportRulesetsResponse.model';
import { GetRulesApiRequest } from './models/GetRulesApiRequest.model';
import { GetTemplateStatementMetadataApiResponse } from './models/GetTemplateStatementMetadataApiResponse';
import { GetDenialAndAdjustmentRulesResponse } from './models/GetDenialAndAdjustmentRulesResponse';
import { UpdateRuleApiRequest } from './models/UpdateRuleApiRequest';
import { IUpdateRulesetApiResponse } from './models/UpdateRulesetApiResponse.model';
import { IUpdateRulesetApiRequest } from './models/UpdateRulesetApiRequest.model';
import { GetAliasResponseModel } from './models/GetAliasResponse.model';
import { UpdateAliasRequest } from './models/UpdateAliasRequest.model';
import { ImportRulesetsApiRequest } from './models/ImportRulesetsApiRequest';
import { GetCategoryIDsApiResponse } from './models/GetCategoryIDsApiResponse';
import { UpdateRuleApiResponse } from './models/UpdateRuleApiResponse';
import { GetAliasesApiRequest } from './models/GetAliasesApiRequest';
import { GetAliasesApiResponse } from './models/GetAliasesApiResponse';
import { CreateCategoryApiRequest } from './models/CreateCategoryApiRequest';
import { UpdateCategoryApiRequest } from './models/UpdateCategoryApiRequest';
import { ImportRulesetsWebSocketResponse } from './websocket/rules/models/ImportRulesetsWebSocketResponse';


@Injectable()
export class RulesService {

    private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.API_PATH}/api/rule`;
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/rule`;
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }

    constructor(private http: HttpClient) { 
    }


    showCreateBtn = new BehaviorSubject(false);
    showCreateBtnObservable = this.showCreateBtn.asObservable();
    setValueforShowCreateBtn(req: boolean) {
        this.showCreateBtn.next(req);
    }

    getTemplateRule(req: GetTemplateRuleRequest): Observable<GetTemplateRuleResponse> {
        return this.http.get<GetTemplateRuleResponse>(`${this.API_SERVICE_PATH}/${'getTemplateRule'.toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }
    getRuleset(req: GetRulesetRequest): Observable<GetRulesetResponse> {
        return this.http.get<GetRulesetResponse>(`${this.API_SERVICE_PATH}/${'getRuleset'.toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }
    getAddedRuleset(req: GetRulesetRequest): Observable<GetRulesetResponse> {
        return this.http.get<GetRulesetResponse>(`${this.API_SERVICE_PATH}/${'getAddedRuleset'.toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }
    getCategories(): Observable<GetCategoriesResponse> {
        return this.http.get<GetCategoriesResponse>(`${this.API_SERVICE_PATH}/${'getCategories'.toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }
    getCategory(req: GetCategoryId): Observable<GetCategoriesResponseId> {
        return this.http.get<GetCategoriesResponseId>(`${this.API_SERVICE_PATH}/${'getCategory'.toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    getRules(req: GetRulesApiRequest): Observable<GetRulesResultResponse> {
        return this.http.get<GetRulesResultResponse>(`${this.API_SERVICE_PATH}/${'getRules'.toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }
    getAllRuleSets(): Observable<GetRuleSetResponseForMC> {
        return this.http.get<GetRuleSetResponseForMC>(`${this.API_SERVICE_PATH}/${'getAllRuleSets'.toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    createCategory(req: CreateCategoryApiRequest): Observable<CreateCategoryResponse> {
        return this.http.post<CreateCategoryResponse>(`${this.API_SERVICE_PATH}/${'createCategory'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));

    }

    updateCategory(req: UpdateCategoryApiRequest): Observable<UpdateCategoryResponse> {
        return this.http.post<UpdateCategoryResponse>(`${this.API_SERVICE_PATH}/${'updateCategory'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }
    
    GetAliasesDisplayValuePair(): Observable<GetAliasResponse> {
        return this.http.get<GetAliasResponse>(`${this.API_SERVICE_PATH}/${"getaliasesdisplayvaluepair".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    createAlias(req: CreateAliasRequest): Observable<CreateAliasResponse> {
        return this.http.post<CreateAliasResponse>(`${this.API_SERVICE_PATH}/${'createAlias'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));

    }

    getAlias(req: GetAliasIDRequest): Observable<GetAliasResponseModel> {
        return this.http.get<GetAliasResponseModel>(`${this.API_SERVICE_PATH}/${"getAlias".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));

    }
    updateAlias(req: UpdateAliasRequest): Observable<CreateAliasResponse> {
        return this.http.post<CreateAliasResponse>(`${this.API_SERVICE_PATH}/${'updateAlias'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }

    createRule(req: CreateRuleRequest): Observable<CreateRuleResponse> {
        return this.http.post<CreateRuleResponse>(`${this.API_SERVICE_PATH}/${'createRule'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }
    createRuleset(req: CreateRuleSetRequest): Observable<CreateRulesetResponse> {
        return this.http.post<CreateRulesetResponse>(`${this.API_SERVICE_PATH}/${'CreateRuleset'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }

    UpdateRuleset(req: IUpdateRulesetApiRequest): Observable<IUpdateRulesetApiResponse> {
        return this.http.post<IUpdateRulesetApiResponse>(`${this.API_SERVICE_PATH}/${'UpdateRuleset'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }

    getCategoryIDs(): Observable<GetCategoryIDsApiResponse> {
        return this.http.get<GetCategoryIDsApiResponse>(`${this.API_SERVICE_PATH}/${"getCategoryIDs".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    getTemplateStatementMetadata(): Observable<GetTemplateStatementMetadataApiResponse> {
        return this.http.get<GetTemplateStatementMetadataApiResponse>(`${this.API_SERVICE_PATH}/${'getTemplateStatementMetadata'.toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }
    getExportedFileNames(): Observable<GetExportedFileNamesResponse> {
        return this.http.get<GetExportedFileNamesResponse>(`${this.API_SERVICE_PATH}/${'getExportedFileNames'.toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    getDenialAndAdjustmentRules():Observable<GetDenialAndAdjustmentRulesResponse>{
        return this.http.get<GetDenialAndAdjustmentRulesResponse>(`${this.API_SERVICE_PATH}/${"getDenialAndAdjustmentRules".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }


    ExportRulesets(rulesetIds: number[], FileName: string): Observable<ExportRulesetsResponse> {
        return this.http.post<ExportRulesetsResponse>(`${this.API_SERVICE_PATH}/${'ExportRulesets'.toLowerCase()}`, { rulesetIds, FileName }, this.options)
            .pipe(map(response => response));

    }

    ImportRulesets(req: ImportRulesetsApiRequest): Observable<ImportRulesetsResponse> {
        return this.http.post<ImportRulesetsResponse>(`${this.API_SERVICE_PATH}/${'ImportRulesets'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }

    ImportRulesetsWebSocket(connectionId: string, req: ImportRulesetsApiRequest): Observable<ImportRulesetsWebSocketResponse> {
        return this.http.post<ImportRulesetsWebSocketResponse>(`${this.API_SERVICE_PATH}/${'ImportRulesets'.toLowerCase()}`, req,  this.websocketOptions(connectionId))
            .pipe(map(response => response));
    }

    // TemplateRule
    UpdateRule(req: UpdateRuleApiRequest): Observable<UpdateRuleApiResponse> {
        return this.http.post<UpdateRuleApiResponse>(`${this.API_SERVICE_PATH}/${'UpdateRule'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }

    GetAliases(req: GetAliasesApiRequest): Observable<GetAliasesApiResponse> {
        return this.http.get<GetAliasesApiResponse>(`${this.API_SERVICE_PATH}/${"getaliases".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

}
