import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterUnique'
  })
  export class FilterPipe implements PipeTransform {

         transform(items: any[], filter:any): any {
           return items.filter(
            (thing, i, arr) => arr.findIndex(t => (t[filter.property]) === (thing[filter.property])) === i
          );
      }
  }
  
    