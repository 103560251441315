import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CustomToastService } from '@app-shared/services';

@Component({
  selector: 'cancel-alert',
  templateUrl: './cancel-alert.component.html',
  styleUrls: ['./cancel-alert.component.sass']

})

export class CancelAlertComponent implements OnInit {  
  accordionStatus = true;
  constructor(private router: Router, public activeModal: NgbActiveModal, 
    private modalService: NgbModal,private toastr: CustomToastService) {
  }

  ngOnInit() {
    this.toastr.remove();
  }

  close() {
    this.activeModal.close('closeCancelAlert');
  }

  returnToScreen() {
    this.activeModal.close();
  }
}

