import { pricingConstant } from 'src/shared/constants/pricing-constant';
import { UtilHelper } from 'src/shared/helpers/util-helper';
import { FindMatches } from 'src/shared/pipes/find-matches';
import { DateHelper } from './date-helper';

export class pricingHelper {
    public static validationTraditional(preferredPricingSource, errors) {
        {
            var errorType = '';

            //check for subproduct if delivery type and product are selected
            if (preferredPricingSource.preferredProducts.length > 0) {
                var hasSubProducts = true;
                preferredPricingSource.preferredProducts.forEach((preferredProduct) => {
                    if (preferredProduct.preferredSubProducts.length === 0 && preferredProduct.productCode !== 'selectAll') {
                        errors.push('Select a Subproduct for Product ' + preferredProduct.productCode);
                        errorType = pricingConstant.subProduct;
                        hasSubProducts = false;
                    }
                });

                if (hasSubProducts) {
                    //check for delivery type if subproduct are selected
                    if (preferredPricingSource.remittanceTypes.length === 0) {
                        errors.push('Select a Remittance Type for ' + pricingConstant.traditional + ' pricing source.');
                        errorType = pricingConstant.remittanceType;
                    }
                    //check for product if subproduct are selected
                    if (preferredPricingSource.preferredProducts.length === 0) {
                        errors.push('Select a Product from ' + pricingConstant.traditional + ' pricing source.');
                        errorType = pricingConstant.product;
                    }
                }
            }
            //check for remittance type if product only is selected
            else if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length > 0 &&
                preferredPricingSource.remittanceTypes.length === 0) {
                errors.push('Select a Remittance Type for ' + pricingConstant.traditional + ' pricing source.');
                errorType = pricingConstant.remittanceType;
            }
            //check for product if remittance type is selected
            else if (preferredPricingSource.remittanceTypes.length > 0 && (UtilHelper.isUndefinedOrNull(preferredPricingSource.preferredProducts) ||
                UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length === 0)) {
                errors.push('Select a Product from ' + pricingConstant.traditional + ' pricing source.');
                errorType = pricingConstant.product;
            }

            return errorType;
        }

    }

    public static validationMPX(preferredPricingSource, errors) {
        {
            var errorType = '';

            if (preferredPricingSource.deliveryTypes.length > 0 && (UtilHelper.isUndefinedOrNull(preferredPricingSource.preferredProducts) ||
                UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length === 0)) {
                errors.push('Select a Product from ' + pricingConstant.mpxDisplayName + ' pricing source.');
                errorType = pricingConstant.product;
            }
            //check for delivery type if product only is selected
            else if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length > 0 &&
                preferredPricingSource.deliveryTypes.length === 0) {
                errors.push('Select a Delivery Type for ' + pricingConstant.mpxDisplayName + ' pricing source.');
                errorType = pricingConstant.deliveryType;
            }

            //check for subproduct if delivery type and product are selected
            else if (preferredPricingSource.preferredProducts.length > 0) {
                var hasSubProducts = true;
                preferredPricingSource.preferredProducts.forEach((preferredProduct) => {
                    if (preferredProduct.preferredSubProducts.length === 0 && preferredProduct.productCode !== 'selectAll') {
                        errors.push('Select a Subproduct for Product ' + preferredProduct.productCode);
                        errorType = pricingConstant.subProduct;
                        hasSubProducts = false;
                    }
                });

                if (hasSubProducts) {
                    //check for delivery type if subproduct are selected
                    if (preferredPricingSource.remittanceTypes.length === 0) {
                        errors.push('Select a Remittance Type for ' + pricingConstant.mpxDisplayName + ' pricing source.');
                        errorType = pricingConstant.remittanceType;
                    }
                    //check for product if subproduct are selected
                    if (preferredPricingSource.preferredProducts.length === 0) {
                        errors.push('Select a Product from ' + pricingConstant.mpxDisplayName + ' pricing source.');
                        errorType = pricingConstant.product;
                    }
                }
            }
            //check for remittance type if product only is selected
            else if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length > 0 &&
                preferredPricingSource.remittanceTypes.length === 0) {
                errors.push('Select a Remittance Type for ' + pricingConstant.mpxDisplayName + ' pricing source.');
                errorType = pricingConstant.remittanceType;
            }
            //check for product if remittance type is selected
            else if (preferredPricingSource.remittanceTypes.length > 0 && (UtilHelper.isUndefinedOrNull(preferredPricingSource.preferredProducts) ||
                UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length === 0)) {
                errors.push('Select a Product from ' + pricingConstant.mpxDisplayName + ' pricing source.');
                errorType = pricingConstant.product;
            }

            return errorType;
        }

    }
    public static validationSRPTraditional(preferredPricingSource, errors) {
        {
            var errorType = '';

            //check for remittance type if product only is selected
            if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.servicers) && preferredPricingSource.servicers.length > 0 &&
                preferredPricingSource.loanTypes.length === 0) {
                errors.push('Select a Loan Type for ' + pricingConstant.traditionalDisplayName + ' SRP pricing source.');
                errorType = pricingConstant.loanType;
            }
            //check for product if remittance type is selected
            else if (preferredPricingSource.loanTypes.length > 0 && (UtilHelper.isUndefinedOrNull(preferredPricingSource.servicers) ||
                UtilHelper.isDefinedOrNotNull(preferredPricingSource.servicers) && preferredPricingSource.servicers.length === 0)) {
                errors.push('Select a Servicer for ' + pricingConstant.traditionalDisplayName + ' SRP pricing source.');
                errorType = pricingConstant.servicer;
            }

            return errorType;
        }

    }

    public static validationSRPMpfXtra(preferredPricingSource, errors) {
        {
            var errorType = '';

            //check for remittance type if product only is selected
            if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.servicers) && preferredPricingSource.servicers.length > 0 &&
                preferredPricingSource.products.length === 0) {
                errors.push('Select a Product for ' + pricingConstant.mpfXtraDisplayName + ' SRP pricing source.');
                errorType = pricingConstant.product;
            }
            //check for product if remittance type is selected
            else if (preferredPricingSource.products.length > 0 && (UtilHelper.isUndefinedOrNull(preferredPricingSource.servicers) ||
                UtilHelper.isDefinedOrNotNull(preferredPricingSource.servicers) && preferredPricingSource.servicers.length === 0)) {
                errors.push('Select a Servicer for ' + pricingConstant.mpfXtraDisplayName + ' SRP pricing source.');
                errorType = pricingConstant.servicer;
            }

            return errorType;
        }

    }
    public static validationMpfXtra(preferredPricingSource, errors) {
        {
            var errorType = '';

            //check for product if delivery type is selected
            if (preferredPricingSource.deliveryTypes.length > 0 && (UtilHelper.isUndefinedOrNull(preferredPricingSource.preferredProducts) ||
                UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length === 0)) {
                errors.push('Select a Product from ' + pricingConstant.mpfXtraDisplayName + ' pricing source.');
                errorType = pricingConstant.product;
            }
            //check for delivery type if product only is selected
            else if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length > 0 &&
                preferredPricingSource.deliveryTypes.length === 0) {
                errors.push('Select a Delivery Type for ' + pricingConstant.mpfXtraDisplayName + ' pricing source.');
                errorType = pricingConstant.deliveryType;
            }
            //check for subproduct if delivery type and product are selected
            else if (preferredPricingSource.deliveryTypes.length > 0 && preferredPricingSource.preferredProducts.length > 0) {
                var hasSubProducts = true;
                preferredPricingSource.preferredProducts.forEach((preferredProduct) => {
                    if (preferredProduct.preferredSubProducts.length === 0 && preferredProduct.productCode !== 'selectAll') {
                        errors.push('Select a Subproduct for Product ' + preferredProduct.productCode);
                        errorType = pricingConstant.subProduct;
                        hasSubProducts = false;
                    }
                });

                if (hasSubProducts) {
                    //check for delivery type if subproduct are selected
                    if (preferredPricingSource.deliveryTypes.length === 0) {
                        errors.push('Select a Delivery Type for ' + pricingConstant.mpfXtraDisplayName + ' pricing source.');
                        errorType = pricingConstant.deliveryType;
                    }
                    //check for product if subproduct are selected
                    if (preferredPricingSource.preferredProducts.length === 0) {
                        errors.push('Select a Product from ' + pricingConstant.mpfXtraDisplayName + ' pricing source.');
                        errorType = pricingConstant.product;
                    }
                }
            }

            return errorType;
        }

    }
    public static validationMpfXtraMBS(preferredPricingSource, errors) {
        {
            var errorType = '';

            //check for product if delivery type is selected
            if (preferredPricingSource.deliveryTypes.length > 0 && (UtilHelper.isUndefinedOrNull(preferredPricingSource.preferredProducts) ||
                UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length === 0)) {
                errors.push('Select a Product from ' + pricingConstant.mpfXtraMBSDisplayName + ' pricing source.');
                errorType = pricingConstant.product;
            }
            //check for delivery type if product only is selected
            else if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length > 0 &&
                preferredPricingSource.deliveryTypes.length === 0) {
                errors.push('Select a Delivery Type for ' + pricingConstant.mpfXtraMBSDisplayName + ' pricing source.');
                errorType = pricingConstant.deliveryType;
            }
            //check for subproduct if delivery type and product are selected
            else if (preferredPricingSource.deliveryTypes.length > 0 && preferredPricingSource.preferredProducts.length > 0) {
                var hasSubProducts = true;
                preferredPricingSource.preferredProducts.forEach((preferredProduct) => {
                    if (preferredProduct.preferredSubProducts.length === 0 && preferredProduct.productCode !== 'selectAll') {
                        errors.push('Select a Subproduct for Product ' + preferredProduct.productCode);
                        errorType = pricingConstant.subProduct;
                        hasSubProducts = false;
                    }
                });

                if (hasSubProducts) {
                    //check for delivery type if subproduct are selected
                    if (preferredPricingSource.deliveryTypes.length === 0) {
                        errors.push('Select a Delivery Type for ' + pricingConstant.mpfXtraMBSDisplayName + ' pricing source.');
                        errorType = pricingConstant.deliveryType;
                    }
                    //check for product if subproduct are selected
                    if (preferredPricingSource.preferredProducts.length === 0) {
                        errors.push('Select a Product from ' + pricingConstant.mpfXtraMBSDisplayName + ' pricing source.');
                        errorType = pricingConstant.product;
                    }
                }
            }

            return errorType;
        }

    }
    public static validateSchedulesInPricingCriteria(currentPricingCriteria) {
        {
            var results = {
                errors: [],
                errorType: '',
                stopPropagation: true,
                status: true
            };

            var errors = [];
            var errorType = '';

            var anyProductPricingScheduleSelected = false;
            currentPricingCriteria.preferredPricingSources.forEach((preferredPricingSource) => {
                if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
                    //iterate through preferredProducts
                    preferredPricingSource.preferredProducts.forEach((preferredProduct) => {
                        //check if schedule details exists for current product
                        if (UtilHelper.isDefinedOrNotNull(preferredProduct.preferredScheduleDetails) &&
                            preferredProduct.preferredScheduleDetails.length > 0) {
                            anyProductPricingScheduleSelected = true;
                            return;
                        }
                    });

                    if (!anyProductPricingScheduleSelected) {
                        errors.push('Select a Schedule from the available Pricing Schedules');
                        errorType = pricingConstant.schedule;
                        results.stopPropagation = false;
                    } else {
                        results.stopPropagation = true;
                    }
                }
            });

            results.errors = errors;
            results.errorType = errorType;
            results.status = errors.length > 0 ? false : true;

            return results;
        }

    }
    public static removeSelectAllFromPricingCriteria(currentPricingCriteria) {
        {
            currentPricingCriteria.preferredPricingSources.forEach((preferredPricingSource) => {
                if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
                    //iterate through deliveryTypes
                    var deliveryTypeIndex = -1;
                    preferredPricingSource.deliveryTypes.forEach((deliveryType, index) => {
                        if (deliveryType === pricingConstant.selectAll) {
                            deliveryTypeIndex = index;
                            return;
                        }
                    });

                    //remove selectAll from deliveryTypes
                    if (deliveryTypeIndex > -1) {
                        preferredPricingSource.deliveryTypes.splice(deliveryTypeIndex, 1);
                    }

                    //iterate through remittanceTypes
                    var remittanceTypeIndex = -1;
                    preferredPricingSource.remittanceTypes.forEach((remittanceType, index) => {
                        if (remittanceType === pricingConstant.selectAll) {
                            remittanceTypeIndex = index;
                            return;
                        }
                    });

                    //remove selectAll from remittanceTypes
                    if (remittanceTypeIndex > -1) {
                        preferredPricingSource.remittanceTypes.splice(remittanceTypeIndex, 1);
                    }
                }
            });

            return currentPricingCriteria;
        }

    }
    public static getRemittanceDetail(pricingSource, remittanceType, productRemittanceBySources) {
        {
            var getRemittanceDetail = null;

            productRemittanceBySources.forEach((productRemittanceBySource) => {
                if (UtilHelper.isDefinedOrNotNull(productRemittanceBySource.pricingSourceDetail.value) &&
                    productRemittanceBySource.pricingSourceDetail.value === pricingSource) {
                    productRemittanceBySource.productRemittances.forEach((productRemittance) => {
                        if (productRemittance.remittanceType === remittanceType) {
                            getRemittanceDetail = {
                                description: productRemittance.longDescription,
                                value: remittanceType
                            };
                        }
                    });
                }
            });

            return getRemittanceDetail;
        }
    }
    public static checkAnyProductsAuthorizedToView(currentPricingCriteria, masterProducts, productRemittanceBySources) {

        var anyProductsAuthorizedToView = false;
        currentPricingCriteria.preferredPricingSources.forEach((preferredPricingSource) => {
            if (anyProductsAuthorizedToView) {
                return;
            }

            var allowedToView = this.isAnyProductsAuthorizedToView(preferredPricingSource.pricingSourceDetail.value, currentPricingCriteria,
                masterProducts, productRemittanceBySources);
            if (allowedToView) {
                anyProductsAuthorizedToView = true;
                return;
            }
        });

        return anyProductsAuthorizedToView;



    }
    public static isAnyProductsAuthorizedToView(currentPricingSourceName, currentPricingCriteria, masterProducts, productRemittanceBySources) {
        {
            var productsNotRestricted = [];

            //get restricted products from pricing criteria
            var restrictedPricingCriteria = this.getRestrictedPricingCriteria(currentPricingCriteria,
                masterProducts, productRemittanceBySources);

            //iteration - preferredPricingSources
            currentPricingCriteria.preferredPricingSources.forEach((preferredPricingSource) => {
                if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {

                    if (preferredPricingSource.pricingSourceDetail.value === currentPricingSourceName) {
                        //iteration - preferredPricingSource remittanceTypes
                        preferredPricingSource.remittanceTypes.forEach((remittanceType) => {
                            if (remittanceType !== pricingConstant.selectAll) {
                                //iteration - preferredPricingSource deliveryTypes
                                preferredPricingSource.deliveryTypes.forEach((deliveryType) => {
                                    if (deliveryType !== pricingConstant.selectAll) {
                                        //iteration - preferredPricingSource product
                                        preferredPricingSource.preferredProducts.forEach((preferredProduct) => {
                                            if (preferredProduct.productCode !== pricingConstant.selectAll) {

                                                //check if the product falls under restricted category
                                                var isRestrictedProduct = false;
                                                restrictedPricingCriteria.forEach((rpc) => {
                                                    if (rpc.pricingSourceName === currentPricingSourceName) {
                                                        rpc.restrictedProducts.forEach((restrictedProduct) => {
                                                            if (restrictedProduct.productCode === preferredProduct.productCode &&
                                                                restrictedProduct.remittanceTypeDetail.value === remittanceType) {
                                                                isRestrictedProduct = true;
                                                                return;
                                                            }
                                                        });

                                                    }
                                                });
                                                if (!isRestrictedProduct) {
                                                    productsNotRestricted.push({
                                                        pricingSourceName: currentPricingSourceName,
                                                        productCode: preferredProduct.productCode,
                                                        remittanceType: remittanceType
                                                    });
                                                    return;
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });

            if (productsNotRestricted.length === 0 && currentPricingSourceName !== pricingConstant.mpfXtra) {
                return false;
            }

            return true;
        }
    }
    public static getRestrictedPricingCriteria(currentPricingCriteria, masterProducts, productRemittanceBySources) {
        {
            var restrictedPricingCriteriaSelectionTracker = [];

            currentPricingCriteria.preferredPricingSources.forEach((preferredPricingSource) => {
                if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
                    //iterate through deliveryTypes
                    preferredPricingSource.deliveryTypes.forEach((deliveryType) => {
                        if (deliveryType !== pricingConstant.selectAll) {
                            //iterate through remittanceTypes
                            preferredPricingSource.remittanceTypes.forEach((remittanceType) => {
                                if (remittanceType !== pricingConstant.selectAll) {
                                    //iterate through preferredProducts
                                    preferredPricingSource.preferredProducts.forEach((preferredProduct) => {
                                        //iterate through masterProducts
                                        if (masterProducts) {
                                            masterProducts.forEach((masterProduct) => {
                                                //compare master pricing source name with pricing criteria source name
                                                if (UtilHelper.isDefinedOrNotNull(masterProduct.pricingSourceDetail.value) &&
                                                    masterProduct.pricingSourceDetail.value === preferredPricingSource.pricingSourceDetail.value) {
                                                    //match preferred product with master product
                                                    if (preferredProduct.productCode === masterProduct.productCode &&
                                                        preferredProduct.productCode !== pricingConstant.selectAll) {
                                                        //match for remittance type in master product
                                                        var foundRemittance = false;
                                                        masterProduct.remittanceTypeDetails.forEach((remittanceTypeDetail) => {
                                                            if (remittanceTypeDetail.value === remittanceType) {
                                                                foundRemittance = true;
                                                            }
                                                        });
                                                        if (!foundRemittance) {
                                                            var matchedRemittanceTypeDetail = this.getRemittanceDetail(preferredPricingSource.pricingSourceDetail.value, remittanceType, productRemittanceBySources);
                                                            if (UtilHelper.isUndefinedOrNull(matchedRemittanceTypeDetail)) {
                                                                var errorMsg = 'Unable to get remittance type details from remittance source for type ' + remittanceType;

                                                                return;
                                                            }

                                                            var restrictedProduct = {
                                                                productCode: preferredProduct.productCode,
                                                                remittanceTypeDetail: matchedRemittanceTypeDetail,
                                                                deliveryType: deliveryType
                                                            };

                                                            var restrictedPricingSource;
                                                            //add first restricted product into array based on pricing source name
                                                            if (restrictedPricingCriteriaSelectionTracker.length === 0) {
                                                                restrictedPricingSource = {
                                                                    pricingSourceName: preferredPricingSource.pricingSourceDetail.value,
                                                                    pricingSourceDescription: masterProduct.pricingSourceDetail.description,
                                                                    restrictedProducts: [restrictedProduct]
                                                                };

                                                                restrictedPricingCriteriaSelectionTracker.push(restrictedPricingSource);
                                                            } else {
                                                                //add next product into array based on pricing source name
                                                                var foundPricingSource = false;
                                                                restrictedPricingCriteriaSelectionTracker.forEach((restrictedPricingSourceSelection) => {
                                                                    if (restrictedPricingSourceSelection.pricingSourceName === preferredPricingSource.pricingSourceDetail.value) {
                                                                        foundPricingSource = true;
                                                                        restrictedPricingSourceSelection.restrictedProducts.push(restrictedProduct);
                                                                    }
                                                                });

                                                                //add new pricing source to restricted list
                                                                if (!foundPricingSource) {
                                                                    restrictedPricingSource = {
                                                                        pricingSourceName: preferredPricingSource.pricingSourceDetail.value,
                                                                        pricingSourceDescription: masterProduct.pricingSourceDetail.description,
                                                                        restrictedProducts: [restrictedProduct]
                                                                    };
                                                                    restrictedPricingCriteriaSelectionTracker.push(restrictedPricingSource);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });

            return restrictedPricingCriteriaSelectionTracker;
        }
    }
    public static hasPricingSourceProducts(currentPricingSourceName, masterProducts) {
        {
            var hasProducts = false;
            const filterPipe = new FindMatches();
            var products = filterPipe.transform(masterProducts, currentPricingSourceName);;
            if (UtilHelper.isDefinedOrNotNull(products) && products.length > 0) {
                hasProducts = true;
            }

            return hasProducts;
        }

    }
    public static enhanceUserPreferencePricingCriteria(currentPricingCriteria, masterProducts) {
        {
            //update the user preference pricing criteria based on master products
            var requireUserPreferencePricingCriteriaUpdates = false;

            //remove all products in user preference pricing criteria that doesn't exits in masterProducts
            //iteration - preferredPricingSources
            currentPricingCriteria.forEach((preferredPricingSource) => {
                if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
                    //iteration - preferredPricingSource remittanceTypes
                    preferredPricingSource.remittanceTypes.forEach((remittanceType) => {
                        if (remittanceType !== pricingConstant.selectAll) {
                            //iteration - preferredPricingSource deliveryTypes
                            preferredPricingSource.deliveryTypes.forEach((deliveryType) => {
                                if (deliveryType !== pricingConstant.selectAll) {
                                    //iteration - preferredPricingSource product
                                    preferredPricingSource.preferredProducts.forEach((preferredProduct, index) => {
                                        if (preferredProduct.productCode !== pricingConstant.selectAll) {
                                            //check if the product falls under restricted category
                                            var foundProduct = false;
                                            //iterate through masterProducts
                                            if (masterProducts) {
                                                masterProducts.forEach((masterProduct) => {
                                                    //compare master pricing source name with pricing criteria source name
                                                    if (UtilHelper.isDefinedOrNotNull(masterProduct.pricingSourceDetail.value) &&
                                                        masterProduct.pricingSourceDetail.value === preferredPricingSource.pricingSourceDetail.value) {
                                                        //match preferred product with master product
                                                        if (preferredProduct.productCode === masterProduct.productCode &&
                                                            preferredProduct.productCode !== pricingConstant.selectAll) {
                                                            foundProduct = true;
                                                            return;
                                                        }
                                                    }
                                                });
                                            }

                                            if (!foundProduct) {
                                                preferredPricingSource.preferredProducts.splice(index, 1);
                                                requireUserPreferencePricingCriteriaUpdates = true;
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });

            //remove all pricing sources in user preference pricing criteria that doesn't exits in masterProducts
            //iteration - preferredPricingSources
            if (currentPricingCriteria) {
                currentPricingCriteria.forEach((preferredPricingSource, index) => {
                    if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
                        //iterate through masterProducts
                        var foundPricingSource = false;
                        if (masterProducts) {
                            masterProducts.forEach((masterProduct) => {
                                //compare master pricing source name with pricing criteria source name
                                if (UtilHelper.isDefinedOrNotNull(masterProduct.pricingSourceDetail.value) &&
                                    masterProduct.pricingSourceDetail.value === preferredPricingSource.pricingSourceDetail.value) {
                                    foundPricingSource = true;
                                    return;
                                }
                            });
                        }

                        if (!foundPricingSource) {
                            currentPricingCriteria.splice(index, 1);
                            requireUserPreferencePricingCriteriaUpdates = true;
                        }
                    }
                });
            }

            var jsonObj = {
                pricingCriteria: currentPricingCriteria,
                requireUpdation: requireUserPreferencePricingCriteriaUpdates
            };
            return jsonObj;
        }

    }

    public static validatePricingCriteriaBySource(pricingCriteriaSelection, pricingSourceName, byPassPriceSource) {
        {
            var results = {
                errors: [],
                errorType: '',
                stopPropagation: true,
                status: true
            };

            var errors = [];
            var errorType = '';

            if (UtilHelper.isUndefinedOrNull(pricingCriteriaSelection.preferredPricingSources) ||
                UtilHelper.isDefinedOrNotNull(pricingCriteriaSelection.preferredPricingSources) && pricingCriteriaSelection.preferredPricingSources.length === 0) {
                errors.push('Select a Product from at least one pricing source');
                errorType = pricingConstant.product;
                results.stopPropagation = false;
            } else {
                var anyProductSelected = false;
                pricingCriteriaSelection.preferredPricingSources.forEach((preferredPricingSource) => {
                    if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
                        if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName &&
                            preferredPricingSource.pricingSourceDetail.value === pricingConstant.traditional) {

                            errorType = this.validationTraditional(preferredPricingSource, errors);
                        } else if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName &&
                            preferredPricingSource.pricingSourceDetail.value === pricingConstant.mpfXtra && (byPassPriceSource === 'nonXtra')) {

                            errorType = this.validationMpfXtra(preferredPricingSource, errors);
                        } else if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName &&
                            preferredPricingSource.pricingSourceDetail.value === pricingConstant.mpfXtraMbs && (byPassPriceSource === 'nonXtra')) {

                            errorType = this.validationMpfXtraMBS(preferredPricingSource, errors);
                        } else if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName &&
                            preferredPricingSource.pricingSourceDetail.value === pricingConstant.mpx) {

                            errorType = this.validationMPX(preferredPricingSource, errors);
                        }
                        if (byPassPriceSource === 'nonXtra') {
                            if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length > 0) {
                                anyProductSelected = true;
                            }
                        }
                        else if (byPassPriceSource === 'xtra') {
                            if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length > 0 && preferredPricingSource.pricingSourceDetail.value != pricingConstant.mpfXtra) {
                                anyProductSelected = true;
                            }
                        }

                    }
                });

                //check for at least one product selection from pricing source
                if (!anyProductSelected && errors.length === 0) {
                    errors.push('Select a Product from at least one pricing source');
                    errorType = pricingConstant.product;
                    results.stopPropagation = false;
                }
            }

            results.errors = errors;
            results.errorType = errorType;
            results.status = errors.length > 0 ? false : true;

            return results;
        }
    }
    public static validateSRPPricingCriteriaBySource(pricingCriteriaSelection, pricingSourceName) {
        {
            var results = {
                errors: [],
                errorType: '',
                stopPropagation: true,
                status: true
            };

            var errors = [];
            var errorType = '';

            if (UtilHelper.isUndefinedOrNull(pricingCriteriaSelection.preferredPricingSources) ||
                UtilHelper.isDefinedOrNotNull(pricingCriteriaSelection.preferredPricingSources) && pricingCriteriaSelection.preferredPricingSources.length === 0) {
                errors.push('Select a Servicer from at least one pricing source.');
                errorType = pricingConstant.servicer;
                results.stopPropagation = false;
            } else {
                var anyServicerSelected = false;
                pricingCriteriaSelection.preferredPricingSources.forEach((preferredPricingSource) => {
                    if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
                        if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName &&
                            preferredPricingSource.pricingSourceDetail.value === pricingConstant.traditional) {

                            errorType = this.validationSRPTraditional(preferredPricingSource, errors);
                        } else if (preferredPricingSource.pricingSourceDetail.value === pricingSourceName &&
                            preferredPricingSource.pricingSourceDetail.value === pricingConstant.mpfXtra) {

                            errorType = this.validationSRPMpfXtra(preferredPricingSource, errors);
                        }

                        if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.servicers) && preferredPricingSource.servicers.length > 0) {
                            anyServicerSelected = true;
                        }
                    }
                });

                //check for at least one product selection from pricing source
                if (!anyServicerSelected && errors.length === 0) {
                    errors.push('Select a Servicer from at least one pricing source.');
                    errorType = pricingConstant.servicer;
                    results.stopPropagation = false;
                }
            }

            results.errors = errors;
            results.errorType = errorType;
            results.status = errors.length > 0 ? false : true;

            return results;
        }

    }
    public static validatePricingCriteria(pricingCriteriaSelection) {
        {
            var results = {
                errors: [],
                errorType: '',
                stopPropagation: true,
                status: true
            };

            var errors = [];
            var errorType = '';

            if (UtilHelper.isUndefinedOrNull(pricingCriteriaSelection.preferredPricingSources) ||
                UtilHelper.isDefinedOrNotNull(pricingCriteriaSelection.preferredPricingSources) && pricingCriteriaSelection.preferredPricingSources.length === 0) {
                errors.push('Select a Product from at least one pricing source');
                errorType = pricingConstant.product;
                results.stopPropagation = false;
            } else {
                var anyProductSelected = false;
                pricingCriteriaSelection.preferredPricingSources.forEach((preferredPricingSource) => {
                    if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
                        if (preferredPricingSource.pricingSourceDetail.value === pricingConstant.traditional) {

                            this.validationTraditional(preferredPricingSource, errors);
                        }
                        if (preferredPricingSource.pricingSourceDetail.value === pricingConstant.mpfXtra) {

                            this.validationMpfXtra(preferredPricingSource, errors);
                        }
                        if (preferredPricingSource.pricingSourceDetail.value === pricingConstant.mpfXtraMbs) {

                            this.validationMpfXtraMBS(preferredPricingSource, errors);
                        }
                        if (preferredPricingSource.pricingSourceDetail.value === pricingConstant.mpx) {

                            this.validationMPX(preferredPricingSource, errors);
                        }
                        if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.preferredProducts) && preferredPricingSource.preferredProducts.length > 0) {
                            anyProductSelected = true;
                        }
                    }
                });

                //check for at least one product selection from pricing source
                if (!anyProductSelected && errors.length === 0) {
                    errors.push('Select a Product from at least one pricing source');
                    errorType = pricingConstant.product;
                    results.stopPropagation = false;
                }
            }

            results.errors = errors;
            results.errorType = errorType;
            results.status = errors.length > 0 ? false : true;

            return results;
        }

    }
    public static validateSRPPricingCriteria(pricingCriteriaSelection) {
        {
            var results = {
                errors: [],
                errorType: '',
                stopPropagation: true,
                status: true
            };

            var errors = [];
            var errorType = '';

            if (UtilHelper.isUndefinedOrNull(pricingCriteriaSelection.preferredPricingSources) ||
                UtilHelper.isDefinedOrNotNull(pricingCriteriaSelection.preferredPricingSources) && pricingCriteriaSelection.preferredPricingSources.length === 0) {
                errors.push('Select a Servicer from at least one pricing source.');
                errorType = pricingConstant.servicer;
                results.stopPropagation = false;
            } else {
                var anyServicerSelected = false;
                pricingCriteriaSelection.preferredPricingSources.forEach((preferredPricingSource) => {
                    if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.pricingSourceDetail.value)) {
                        if (preferredPricingSource.pricingSourceDetail.value === pricingConstant.traditional) {

                            this.validationSRPTraditional(preferredPricingSource, errors);
                        }
                        if (preferredPricingSource.pricingSourceDetail.value === pricingConstant.mpfXtra) {

                            this.validationSRPMpfXtra(preferredPricingSource, errors);
                        }

                        if (UtilHelper.isDefinedOrNotNull(preferredPricingSource.servicers) && preferredPricingSource.servicers.length > 0) {
                            anyServicerSelected = true;
                        }
                    }
                });

                //check for at least one product selection from pricing source
                if (!anyServicerSelected && errors.length === 0) {
                    errors.push('Select a Servicer from at least one pricing source.');
                    errorType = pricingConstant.servicer;
                    results.stopPropagation = false;
                }
            }

            results.errors = errors;
            results.errorType = errorType;
            results.status = errors.length > 0 ? false : true;

            return results;
        }

    }

    public static validateDateRange(fromDateTime, toDateTime, numberOfDaysAllowed) {
        var results = {
            status: true,
            stopPropagation: true,
            errors: []
        };

        var errors = [];
        var errorType = '';

        if ((UtilHelper.isUndefinedOrNull(fromDateTime) || fromDateTime === '') && (UtilHelper.isUndefinedOrNull(toDateTime) || toDateTime === '')) {
            results.status = false;
            errors.push('Please select From and To date.');
        } else if (UtilHelper.isUndefinedOrNull(fromDateTime) || fromDateTime === '') {
            results.status = false;
            errors.push('Please select From date.');
        } else if (UtilHelper.isUndefinedOrNull(toDateTime) || toDateTime === '') {
            results.status = false;
            errors.push('Please select To date.');
        } else {
            var today = new Date();
            fromDateTime = new Date(fromDateTime);
            toDateTime = new Date(toDateTime);
            if (fromDateTime > today) {
                results.status = false;
                errors.push('Please select From date lesser than today\'s date.');
            } else if (toDateTime > today) {
                results.status = false;
                errors.push('Please select To date lesser than today\'s date.');
            } else if (fromDateTime > toDateTime) {
                results.status = false;
                errors.push('Please select a From date that is lesser than the To date.');
            } else if (toDateTime < fromDateTime) {
                results.status = false;
                errors.push('Please select a To date that is greater than the From date.');
            } else {

                var diffInDays = DateHelper.daysBetween(toDateTime, fromDateTime);
                if (diffInDays > numberOfDaysAllowed) {
                    results.status = false;
                    errors.push('Please select a date range that is less than or equal to ' + numberOfDaysAllowed + ' days.');
                }
            }
        }
        results.errors = errors;
        results.status = errors.length > 0 ? false : true;

        return results;
    }
}
