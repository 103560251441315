import { IPfiMerger, IMergeracquiredentities, IMergerOrgDetails } from '../models';
import {
    GetOrgDetailApiResponse, GetChildOrgApiResponse,
    GetPFIforMergerApiResponse, SaveMergerApiRequest
} from '@origination-shared/services/organization/models';
import { IKeyValuePair } from '@app-shared/models/key-value-pair.model';
import { ChildPfiMergerRequest } from '@origination-shared/services/organization/models/Inner';
import { HMDAReporterStatus } from '@origination-shared/services/organization/models/Types';

export function mapFromOrgDetailResponse(orgDetailResponse: GetOrgDetailApiResponse): IMergerOrgDetails {
    let successor = {} as IMergerOrgDetails;
    if (orgDetailResponse.response != null) {
        successor.id = orgDetailResponse.response[0].orgID;
        successor.name = orgDetailResponse.response[0].name;
        successor.orgKey = orgDetailResponse.response[0].orgKey;
        successor.streetAddress1 = orgDetailResponse.response[0].streetAddress1;
        successor.streetAddress2 = orgDetailResponse.response[0].streetAddress2;
        successor.city = orgDetailResponse.response[0].city;
        successor.state = orgDetailResponse.response[0].state;
        successor.zipCode = orgDetailResponse.response[0].zipCode;
        successor.taxId = orgDetailResponse.response[0].taxID;
        successor.cif = orgDetailResponse.response[0].cifNumber;
        successor.topHoldingCompanyNumber = orgDetailResponse.response[0].topHoldingCompanyNumber;
        successor.topHoldingCompanyName = orgDetailResponse.response[0].topHoldingCompanyName;
        successor.fhbDocketNumber = orgDetailResponse.response[0].fhfbDocketNumber;
        successor.fdicCertificate = orgDetailResponse.response[0].fdicCertNumber;
        successor.legalEntityIdentifier = orgDetailResponse.response[0].legalEntityIdentifier;
        successor.successorMpfBank = { key: orgDetailResponse.response[0].successorMPFBank, value: orgDetailResponse.response[0].successorMPFBankName };
        successor.hmdaReporterStatus = { key: orgDetailResponse.response[0].hmdaReporterStatus, value: HMDAReporterStatus[orgDetailResponse.response[0].hmdaReporterStatus] };
    }
    return successor;
}
export function mapFromChildOrgResponse(childOrgs: GetChildOrgApiResponse): IMergeracquiredentities[] {
    let childOrgList = {} as IMergeracquiredentities[];
    childOrgList = childOrgs.response.map(child => {
        let mapChild = {} as IMergeracquiredentities;
        mapChild.orgKey = child.orgKey;
        mapChild.id = child.orgID;
        mapChild.name = child.name;
        return mapChild;
    });
    return childOrgList;
}
export function mapFromPfiForMergerResponse(pfiForMerger: GetPFIforMergerApiResponse): IKeyValuePair[] {
    let pfiForMergerList = {} as IKeyValuePair[];
    pfiForMergerList = pfiForMerger.response.map(child => {
        let pfiName = {} as IKeyValuePair;
        pfiName.value = child.name;
        pfiName.key = child.orgKey;
        return pfiName;
    });
    return pfiForMergerList;
}
export function mapFromPfiForMergerResponseToList(pfiForMerger: GetPFIforMergerApiResponse): string[] {
    let pfiForMergerList = {} as string[];
    pfiForMergerList = pfiForMerger.response.map(child => {
        let pfiName = {} as string;
        pfiName = child.name;
        return pfiName;
    });
    return pfiForMergerList;
}
export function mapforSaveMergerRequest(createMergerModel: IPfiMerger, addedAcquiredPfiList: IKeyValuePair[]): SaveMergerApiRequest {
    let mergerRequest = {} as SaveMergerApiRequest;
    mergerRequest.successorOrgKey = createMergerModel.successor.orgKey;
    mergerRequest.name = createMergerModel.successorAfterMerge.name;
    mergerRequest.streetAddress1 = createMergerModel.successorAfterMerge.streetAddress1;
    mergerRequest.streetAddress2 = createMergerModel.successorAfterMerge.streetAddress2;
    mergerRequest.city = createMergerModel.successorAfterMerge.city;
    mergerRequest.state = createMergerModel.successorAfterMerge.state;
    mergerRequest.zipCode = createMergerModel.successorAfterMerge.zipCode;
    mergerRequest.taxID = createMergerModel.successorAfterMerge.taxId;
    mergerRequest.cifNumber = createMergerModel.successorAfterMerge.cif;
    mergerRequest.topHoldingCompanyNumber = createMergerModel.successorAfterMerge.topHoldingCompanyNumber;
    mergerRequest.topHoldingCompanyName = createMergerModel.successorAfterMerge.topHoldingCompanyName;
    mergerRequest.fhfbDocketNumber = createMergerModel.successorAfterMerge.fhbDocketNumber;
    mergerRequest.fdicCertNumber = createMergerModel.successorAfterMerge.fdicCertificate;
    mergerRequest.legalEntityIdentifier = createMergerModel.successorAfterMerge.legalEntityIdentifier;
    mergerRequest.successorMpfBankOrgkey = createMergerModel.successorAfterMerge.successorMpfBank.key;
    mergerRequest.hMDAReporterStatus = createMergerModel.successorAfterMerge.hmdaReporterStatus.key;
    mergerRequest.mergerType = createMergerModel.mergerType.key;
    mergerRequest.successorType = createMergerModel.successorType.key;
    mergerRequest.mergerReason = createMergerModel.mergerReason.key;
    mergerRequest.mergerTypeComments = createMergerModel.mergerTypeComment
    mergerRequest.mergerReasonComments = createMergerModel.mergerReasonComment;
    mergerRequest.effectiveDate = new Date(createMergerModel.acquisitionDate);
    mergerRequest.childPfiMergerRequest =
        addedAcquiredPfiList.map(addedChild => {
            let mapChild = {} as ChildPfiMergerRequest;
            mapChild.orgkey = addedChild.key;
            mapChild.isNewChild = true;
            return mapChild
        }
        )
    return mergerRequest;

}
