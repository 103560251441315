export interface ISendEmailToRequestModel {
    body?: string;
    processingType?: string;
    toAddresses?: string[];
    cCAddresses?: string[];
    properties?: IDescriptionValueType[]
    featureType?: EmailFeatureType;
    isXtraDC?: boolean;
    authorizeBehaviourKey?: number;

}
export interface IDescriptionValueType {
    description?: string;
    value?: string;
}
export enum EmailFeatureType {
    DCCreateConfirmation,
    DCExtensionConfirmation,
    DCReductionConfirmation,
    DCRequestedMpfInformation,
    LFCreateConfirmation,
    LPRCreateConfirmation,
}