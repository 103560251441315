import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGetMessagesApiResponse } from './messages/models/IGetMessagesApiResponse';
import { IDeleteMessagesApiResponse } from './messages/models/IDeleteMessagesApiResponse';
import { IMarkAsRead } from './messages/models/IMarkAsRead';
import { IMarkAsReadRequest } from './messages/models/IMarkAsReadRequest';
import { IDeleteMessagesRequestModel } from './messages/models/iDeleteMessagesRequestModel';
import { AppConfigService } from '@app-shared/services';
import { IGetLatestBannerMessageForUserResponse } from './messages/models/IGetLatestBannerMessageForUserResponse';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
    private MICROSERVICE_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;

    private BUILT_API_PATH = `${this.MICROSERVICE_PATH}/api/miscellaneous/ext`
    private API_SERVICE_EXT_PATH = `${this.BUILT_API_PATH}/messages`;
    
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient) { }

    getMessagesList(): Observable<IGetMessagesApiResponse> {
        return this.http.get<IGetMessagesApiResponse>(`${this.API_SERVICE_EXT_PATH}/${'getMessages'.toLowerCase()}`, this.options)
            .pipe(map(response => response)
            );
    }

    deleteMessages(messageIds: IDeleteMessagesRequestModel): Observable<IDeleteMessagesApiResponse> {
      return this.http.post<IDeleteMessagesApiResponse>(`${this.API_SERVICE_EXT_PATH}/${'deleteMessages'.toLowerCase()}`, messageIds, this.options)
          .pipe(map(response => response)
          );
  }

    markMessageAsRead(req: IMarkAsReadRequest): Observable<IMarkAsRead> {
         return this.http.post<IMarkAsRead>(`${this.API_SERVICE_EXT_PATH}/${'markMessageAsRead'.toLowerCase()}`, req, this.options)
             .pipe(map(response => response)
             );
     }

     GetLatestBannerMessageForUser(): Observable<IGetLatestBannerMessageForUserResponse> {
      return this.http.get<IGetLatestBannerMessageForUserResponse>(`${this.API_SERVICE_EXT_PATH}/${'GetLatestBannerMessageForUser'.toLowerCase()}`, this.options)
          .pipe(map(response => response)
          );
  }
}
