
export enum PagedOrgColumnSort {
    /// <summary>
    /// The unspecified
    /// </summary>
    Unspecified = 0,
    /// <summary>
    /// The org identifier
    /// </summary>
    OrgId,
    /// <summary>
    /// The name
    /// </summary>
    Name,
    /// <summary>
    /// The city
    /// </summary>
    City,
    /// <summary>
    /// The state
    /// </summary>
    State,
    /// <summary>
    /// The phone
    /// </summary>
    Phone,
    /// <summary>
    /// The fax
    /// </summary>
    Fax,
    /// <summary>
    /// The MPF owner
    /// </summary>
    MpfOwner
}
