
export enum SortDirection {
    /// <summary>
    /// Enum for sorting in Ascending Direction
    /// </summary>
    /// 
    Ascending = 0,
    /// <summary>
    /// Enum for sorting in Descending Direction
    /// </summary>
    /// 
    Descending = 1
}
