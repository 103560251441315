import { Component, Inject, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { fhlbConstants } from '@app-shared/constants/fhlb-constants';
import { AppConfigService } from '@app-shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { AuthEffects } from 'src/store/effects/auth.effects';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType, InteractionType, AccountInfo, SsoSilentRequest, IdTokenClaims, PromptValue } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LogInSuccessAction, RefreshTokensFailAction, RefreshTokensSuccessAction } from 'src/store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/store/reducers';
import { DecodeAzureB2CIDToken } from 'src/store/reducers/models/DecodeAzureB2CIDToken';
import { getSelectedProfileScopeId } from 'src/store/selectors';
import { isNullOrUndefined } from 'is-what';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
  title = 'empf-angular';
  showInteractiveHeader: boolean;
  showBreadCrumb: boolean;
  isIframe: boolean = false;
  profileScopeIDfromStore: string;
  private readonly _destroying$ = new Subject<void>();
  constructor(public router: Router,
    private modalService: NgbModal,
    private autheffects: AuthEffects,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private store: Store<AppState>) {
    moment.tz.setDefault("America/Chicago")
  }

  ngOnInit(): void {
    this.store.select(getSelectedProfileScopeId).pipe(map(x => { this.profileScopeIDfromStore = x })).subscribe();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        }
      });


    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        console.log("Inside app component msal in progress");
        this.checkAndSetActiveAccount();
      })

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log("Inside app component msal subject 2");
        let payload = result.payload as AuthenticationResult;
        console.log("payload", payload);
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
        console.log("idtoken", idtoken);

        let scopesArray: string[] = [];
        if (!isNullOrUndefined(this.profileScopeIDfromStore))
        {
            scopesArray = [AppConfigService.Settings.apiConfig.uri + this.profileScopeIDfromStore];
        }

        if (idtoken.acr?.toLowerCase() === AppConfigService.Settings.b2cPolicies.names.signUpSignIn.toLowerCase() || idtoken.tfp?.toLowerCase() === AppConfigService.Settings.b2cPolicies.names.signUpSignIn.toLowerCase()) {
          this.authService.instance.setActiveAccount(payload.account);
        }

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (idtoken.acr?.toLowerCase() === AppConfigService.Settings.b2cPolicies.names.editProfile.toLowerCase() || idtoken.tfp?.toLowerCase() === AppConfigService.Settings.b2cPolicies.names.editProfile.toLowerCase()) {

        // retrieve the account from initial sing-in to the app
        const originalSignInAccount = this.authService.instance.getAllAccounts()
          .find((account: AccountInfo) =>
            account.idTokenClaims?.oid === idtoken.oid
            && account.idTokenClaims?.sub === idtoken.sub
            && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === AppConfigService.Settings.b2cPolicies.names.signUpSignIn
              || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === AppConfigService.Settings.b2cPolicies.names.signUpSignIn)
          );

          let signUpSignInFlowRequest: SsoSilentRequest = {
            authority: AppConfigService.Settings.b2cPolicies.authorities.signUpSignIn.authority,
            scopes: scopesArray,
            account: originalSignInAccount
          };

          // silently login again with the signUpSignIn policy
          this.authService.ssoSilent({ ...this.msalGuardConfig.authRequest, ...signUpSignInFlowRequest })
            .subscribe(
              (response) => {
                  this.store.dispatch(new RefreshTokensSuccessAction({
                      encodedAzureB2CIDToken: response.idToken,
                      encodedAzureB2CAccessToken: response.accessToken,
                      updateEmpfSessionTime: false,
                      navigateToHome: null,
                      loginFlow: null
                  }));
              },
              (error) => {
                  console.error('Error from ssoSilent:', error);
                  this.store.dispatch(new RefreshTokensFailAction({ updateEmpfSessionTime: false }));
              }
          );
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (idtoken.acr?.toLowerCase() === AppConfigService.Settings.b2cPolicies.names.resetPassword.toLowerCase() || idtoken.tfp?.toLowerCase() === AppConfigService.Settings.b2cPolicies.names.resetPassword.toLowerCase()) {
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: AppConfigService.Settings.b2cPolicies.authorities.signUpSignIn.authority,
            scopes: scopesArray,
            prompt: PromptValue.LOGIN // force user to reauthenticate with their new password
          };

          this.login(signUpSignInFlowRequest);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: AppConfigService.Settings.b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        };
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  public componentAdded(component: object) {
    if (component.constructor.name === fhlbConstants.entryContainerComponentName) {
      //this.showInteractiveHeader = false;
    }
    else {
      //this.showInteractiveHeader = true;
    }
  }

  public componentBreadCrumb(component: object) {
    //this.showBreadCrumb = false;

    if (component.constructor.name === fhlbConstants.HomesContainerComponentName ||
      component.constructor.name === fhlbConstants.entryContainerComponentName) {
      //this.showBreadCrumb = false;
    }
    else {
      //this.showBreadCrumb = true;

    }
  }
}
