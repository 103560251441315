import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-view-pfi-list',
  templateUrl: './view-pfi-list.component.html',
  styleUrls: ['./view-pfi-list.component.sass']
})
export class ViewPfiListComponent implements OnInit {
  @Input() pfiListResponse;
  inputItem: string;
  listHidden: boolean;
  pfiList = this.getPfiGenericDropDownDefaultValues();
  @Output() pfiNumberChanged = new EventEmitter<object>();
  pfiNumber: number;

  constructor() { }

  ngOnInit() {
    this.inputItem = this.pfiList.availableOptions[0].name;
    this.listHidden = true;
    this.pfiNumber = 0;

    for (let i = 0; i < this.pfiListResponse.pfiList.length; i++) {
      this.pfiList.availableOptions.push({ id: parseInt(this.pfiListResponse.pfiList[i].id), name: this.pfiListResponse.pfiList[i].id + ' - ' + this.pfiListResponse.pfiList[i].name });
    }
  }
  toggleListDisplay(item) {
    this.listHidden = !this.listHidden;

  }

  getFilteredList() {

  }

  selectItem(selectedOption) {
    this.listHidden = true;
    this.pfiList.selectedOption = selectedOption;
    this.pfiNumber = this.pfiList.selectedOption.id;
    this.inputItem = this.pfiList.selectedOption.name

    var requestModel = {
      pfiNumber: this.pfiNumber
    };
    this.pfiNumberChanged.emit(requestModel)
  }
  getPfiGenericDropDownDefaultValues() {
    return {
      availableOptions: [{ id: 0, name: 'All – Non Specific PFI' }],
      selectedOption: { id: 0, name: 'All – Non Specific PFI' }
    };
  }

}
