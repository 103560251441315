<form name="rulesvalidation" id="rulesvalidation" #rulesvalidation="ngForm" [title]="title">

    <div class="card mt-3 defaultText" [ngClass]="{'border-red':isCollapsed}">
        <div class="row">
            <div class="col-12">
                <div class="col-12 card-header">
                    <div class="row">
                        <div class="col-10 ps-4 pt-1 ft">
                            <b>{{title}}</b>
                        </div>
                        <div class="col text-end">
                        <button class=" btn text-white" id="collapseBtn"
                            (click)="isCollapsed= !isCollapsed" [ngSwitch]="isCollapsed">
                            <i *ngSwitchCase="false" id="plus" class="fa fa-plus fa-lg"></i>
                            <i *ngSwitchCase="true" id="minus" class="fa fa-minus fa-lg"></i>
                        </button>
                        <button class="btn" id="cancel" (click)="close()">
                            <i class="fa fa-times fa-lg text-white"></i>
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="collapse row scrollbar style-2 pt-1 pb-0" id="collapseComment" [ngbCollapse]="!isCollapsed">
            <span class="col-12 card-body pt-0 pb-0 font-color" *ngIf="subheading">
                {{subheading}}
            </span>

            <span class="col-12 card-body pt-0 pb-0 font-color" *ngFor="let validation of rulesValidationError ">
                {{validation}}
            </span>
        </div>
    </div>
</form>
