import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'propertyFilter'
})
export class PropertyFilter implements PipeTransform {

    transform(items: Array<any>, props: { [key: string]: any }): Array<any> {
        if (!items || !props) {
            return items;
        }
        var out = [];
        var keys = Object.keys(props);
        items.forEach(function (item) {
            var itemMatches = false;
            for (var i = 0; i < keys.length; i++) {
                var prop = keys[i];
                var text = props[prop].toString().toLowerCase();
                if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                    itemMatches = true;
                    break;
                }
            }
            if (itemMatches) {
                out.push(item);
            }
        });
        return out;
    }
}

