export enum DidTransactionType {
    loanFunding = 1,
    interimInterest = 2,
    pairOffFee = 3,
    dcExtensionFee = 4,
    agentFeePremiumDiscount = 6,
    lossRecaptureSMI = 11,
    ceFeePayment = 9,
    smiPremiumPayment = 12,
    custodyFeePayment = 19,
    interestCollection = 8,
    principalCollection = 7,
    pandIRemittanceNonInterestBearing = 17,
    pandIRemittanceXMBSNonInterestBearing = 25,
    emiPremiumPayment = 16,
    saleToInvestorTrustee = 13,
    piRemittanceForTrust = 15,
    escrowPayment = 24,
    srpReceipt = 22,
}