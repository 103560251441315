import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ExecuteEndOfdayProcessResponse } from "./models/ExecuteEndOfdayProcessResponse";
import { AppConfigService } from "@app-shared/services";
import { ExecuteDtsxFileWatcherSSISPackageResponse } from "./models/ExecuteDtsxFileWatcherSSISPackageResponse";
import { ImportFileFromServicerResponse } from "./models/ImportFileFromServicerResponse";
import { HttpUtilHelper } from "@app-shared/helpers/http-util-helper";
import { RunMPFXtraBestEffortsCloseOutsApiResponse } from "./models/RunMPFXtraBestEffortsCloseOutsApiResponse";

@Injectable()
export class DailyProcessService {
    private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.API_PATH}/api/dayprocess`;
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/dayprocess`;
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient) { }

    ExecuteDtsxFileWatcherSSISPackage(): Observable<ExecuteDtsxFileWatcherSSISPackageResponse> {
        return this.http.post<ExecuteDtsxFileWatcherSSISPackageResponse>(`${this.API_SERVICE_PATH}/${"executeDtsxFileWatcherSSISPackage".toLowerCase()}`, this.options)
            .pipe(map(response => response));

    }

    ExecuteProcess(req): Observable<ImportFileFromServicerResponse> {
        return this.http.get<ImportFileFromServicerResponse>(`${this.API_SERVICE_PATH}/${"ExecuteProcess".toLowerCase()}?${HttpUtilHelper.ConvertQueryString({ExecutionName:req},true)}`, this.options)
            .pipe(map(response => response));
    }


    ExecuteEndOfdayProcess(): Observable<ExecuteEndOfdayProcessResponse> {
        return this.http.get<ExecuteEndOfdayProcessResponse>(`${this.API_SERVICE_PATH}/${"executeEndOfdayProcess".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    RunMPFXtraBestEffortsCloseOuts(): Observable<RunMPFXtraBestEffortsCloseOutsApiResponse> {
        return this.http.post<RunMPFXtraBestEffortsCloseOutsApiResponse>(`${this.API_SERVICE_PATH}/${"RunMPFXtraBestEffortsCloseOuts".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }
}
