import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationApp } from './models/ConfigurationApp';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AppConfigService {

    static Settings: ConfigurationApp;

    constructor(private injector: Injector, private http: HttpClient) { }

    load() {
        const jsonFile = `assets/appsettings.json`;

        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: ConfigurationApp) => {
                AppConfigService.Settings = response;
                resolve();
            }).catch((response) => {
                reject(`Could not load the config file`);
            });
        });
    }
}
