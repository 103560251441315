import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[selectDefaultValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SelectDefaultValidateDirective, multi: true }]
})

export class SelectDefaultValidateDirective implements Validator {
// tslint:disable-next-line: no-input-rename
    @Input('selectDefault') defaultValue: any;
    @Input('selectValidate') fieldValidator: string;
    
    
    constructor() { }

    validate(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value != null) {
            let controlValue = control.value;
            if (this.defaultValue == controlValue.value) {
                control.setErrors({ invalid: true });
                return { invalidoption: true };
            }
        }

        return null;
    }
}
