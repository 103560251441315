import { Directive, ElementRef, OnInit, Self, Input, OnChanges, Renderer2, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl, NG_VALIDATORS } from '@angular/forms';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[list-items-into-columns]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ListItemsIntoColumnsDirective, multi: true }]
})

export class ListItemsIntoColumnsDirective implements OnInit, OnChanges {
    @Input() columns;
    @Input('list-items-into-columns') listItemsIntoColumns
    private el: HTMLElement;
    initialContainer: any;
    columnItems: any;
    columnCollection: any[];
    parentContainer: any;
    @Input() public input: any;

    constructor(ref: ElementRef,
        private renderer: Renderer2, @Inject(DOCUMENT) private document,
        private router: Router, private route: ActivatedRoute) {
        this.el = ref.nativeElement;
    }

    ngOnInit() {

    }

    ngOnChanges(changes) {
        // setTimeout(() => {
        //     //if (changes.input.currentValue) {
        //     console.log('In');
        //     this.shuffleDom();
        //     //}
        // });
    }
    shuffleDom() {
        this.initialContainer = this.el;
        this.columnItems = (this.el.querySelectorAll('.columns li.gen-item'));
        this.columnCollection = [];

        this.parentContainer = this.initialContainer.parentNode;

        var existingUls = this.parentContainer.querySelectorAll('ul.columns');
        existingUls.forEach((ulEl) => {
            this.columnCollection.push((ulEl));
        });

        this.generateColumns();

        return true;

    }

    generateColumns() {

        for (let i = 0; i < this.columnItems.length; i++) {
            this.columnItems[i].remove();
        }

        var numOfReqCols = this.columns;
        if (this.columnCollection.length < numOfReqCols || numOfReqCols === 1) {
            var maxBsCols = 12;

            var bsColSpan = Math.floor(maxBsCols / numOfReqCols);
            this.parentContainer.classList.add('col-lg-' + maxBsCols)
            this.el.classList.add('col-lg-' + bsColSpan);

            var columnNum = 1; // account for initial column

            while (columnNum++ < numOfReqCols) {

                const ulList = this.renderer.createElement('ul');
                this.renderer.addClass(ulList, 'list-unstyled');
                this.renderer.addClass(ulList, 'columns')
                this.renderer.addClass(ulList, 'col-lg-' + bsColSpan)
                var lastColumnIndex = this.columnCollection.length - 1;

                this.columnCollection.push(ulList);
            }
        }

        this.sortListItemsIntoColumns();

    }
    sortListItemsIntoColumns() {

        var totalColumns = this.columnCollection.length; //number of columns
        var totalItems = this.columnItems.length; //number of items to rebalance
        var maxNumOfItemsInCol = Math.ceil(totalItems / totalColumns); //determine max number of items in a column
        var numOfColumnsWithExtraItems = totalItems % totalColumns; //determine number of columns which will ave additional items left to right

        var targetColNum = 0; //always start with column zero;

        this.columnItems.forEach((element, index) => {

            var numItemsInColumn = this.columnCollection[targetColNum].children.length; //current number of items in column.

            //If 'select all' and/or other static x number of items are present.
            if (index === 0 && numItemsInColumn > 0 && targetColNum === 0) {
                totalItems += numItemsInColumn; //recalc total items by adding static items.
                maxNumOfItemsInCol = Math.ceil(totalItems / totalColumns); //recalc max number of items in a column
                numOfColumnsWithExtraItems = totalItems % totalColumns; //recalc number of items with extra items.
            }

            //increment target column when items have met max items per column
            if (numItemsInColumn >= maxNumOfItemsInCol) {
                targetColNum++;
            }

            //Applies to columns right of numOfColumnsWithExtraItems only after items have targetted their columns
            if (numOfColumnsWithExtraItems > 0 && targetColNum >= numOfColumnsWithExtraItems) {
                numOfColumnsWithExtraItems = 0; //zero out to exit condition after items have generated in columns
                maxNumOfItemsInCol--; //decrease max number of columns.
            }

            this.columnCollection[targetColNum].append(element);
            this.el.parentNode.append(this.columnCollection[targetColNum]);
        });
    }



}
