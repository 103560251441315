
export enum MergerType {
    /// 
    /// </summary>
    InDistrictMerger = 1,
    /// <summary>
    /// 
    /// </summary>
    CrossDistrictMerger = 2,
    /// <summary>
    /// /
    /// </summary>
    NonMPFBankMerger = 3,
    /// <summary>
    /// 
    /// </summary>
    Other = 4
}
