import { Pipe, PipeTransform } from '@angular/core';
@Pipe({  name: 'orderBy' })
export class OrderByPipe implements PipeTransform {

    transform(records: Array<any>, args?: any): any {
        if(records)
        {
            return records.sort(function(a, b){
                var x,y;
            if(a instanceof Array){
                x = a[0][args.property];
                y = b[0][args.property];
            }
            else{
               x = a[args.property];
                    y = b[args.property];
            }
                    if (typeof x === 'string' && typeof y === 'string') {
                        x = x.toLowerCase();
                        y = y.toLowerCase();
                    }
                    
                let direction = args.direction? -1 : 1;
                    if(x < y){
                        return -1 * direction;
                    }
                    else if(x > y){
                        return 1 * direction;
                    }
                    else{
                        return 0;
                    }
            });
        }
      
    };
}