import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findMatches'
  })
  export class FindMatches implements PipeTransform {

         transform(items, pricingSourceName:string): any {
            {
                var filtered = [];

                if ((pricingSourceName == undefined) || pricingSourceName === '') {
                    return items;
                }

                //filter the items
                if( items != undefined && items.length>0){
                    items.forEach((item, index)=> {

                        if (item.pricingSourceDetail.value === pricingSourceName) {
                            filtered.push(item);
                        }
                    });
                }
               

                return filtered;
            }
      }
  }