<div class="row bottom-margin-30px fhlb-container">
    <div class="col-md-6">
        <h3 class="header-text font-size-7"><strong>Annual Certification Forms</strong></h3>
 </div>
 </div>
     <div class="bottom-margin-30px fhlb-container">
         <div class="row">
             <div class="col-sm-12">
                 <div class="panel-group" id="aec-report-panel">
                     <div class="panel panel-info-aec">
                         <div class="panel-heading">
                             <div class="panel-title">
                                 <div class="row">
                                     <div class="col-xs-6 col-sm-9 font-open-sans-bold">
                                         <span>Annual Certifications</span>
                                     </div>
                                 </div>
                             </div>
                         </div>
                         <div class="panel-body font-size-2">
                             <div class="row padding-top-30px" *ngIf="!isPFIUser">
                                 <div class="col-md-4 padding-top-5px text-lighter text-align-right">
                                     <strong>PFI Number:</strong>
                                 </div>
                                 <div class="col-md-6 padding-left-0px">
                                     <ng-select [selectOnTab]= "true" [items]="pfiList.availableOptions" bindLabel="name" placeholder="SELECT" [multiple]="false"
                                     [clearable]="false" id="pfiNumber" name="pfiNumber"
                                     [(ngModel)]="pfiList.selectedOption">
                                     </ng-select>
                                 </div>
                                   
                             </div>
                             <div class="row padding-top-20px">
                                 <div class="col-md-4 padding-top-5px text-lighter text-align-right">
                                     <strong>Form Year:</strong>
                                 </div>
                                 <div class="col-md-3 padding-left-0px padding-right-30px">
                                     <ng-select [selectOnTab]= "true" [items]="yearList.availableOptions" bindLabel="name" placeholder="SELECT" [multiple]="false"
                                     [clearable]="false" id="yearList" name="yearList" 
                                     [(ngModel)]="yearList.selectedOption">
                                     </ng-select>
                                 </div>
                             </div>
                             <div class="row padding-top-20px">
                                 <div class="col-sm-4 padding-top-5px text-lighter text-align-right">
                                     <strong>Status:</strong>
                                 </div>
                                 <div class="col-sm-3 padding-left-0px padding-right-30px">
                                     <div class="">
                                         <select id="status" name="status" theme="bootstrap"
                                         class="ui-select form-select col-sm dropdown-toggle ps-3 pe-3"
                                         [(ngModel)]="ogStatus.selectedOption.name"  #status="ngModel" >
                                             <option
                                                 *ngFor="let st of ogStatus.availableOptions"
                                                 [ngValue]="st.name"> {{st.name}}</option>

                                             </select>
                                     </div>
                                 </div>
                             </div>
                             <div class="row padding-bottom-20px padding-top-20px">
                                 <div class="col-md-12 text-center ">
                                     <input id="aec-report-submit" name="aec-report-submit" type="submit"
                                            class="btn btn-success feedback-form-button float-none col-xs-4 font-open-sans-bold fw-bold"
                                            value="SUBMIT" (click)="searchData()" />
                                 </div>
                             </div>

                             <div class="row min-height-50px ">
                                 <div class="col-sm-12">
                                         <table id="tabularReport" class="table table-striped table-bordered" *ngIf="searchList!= undefined && searchList.hasRecords">
                                             <thead  fix-head class="font-size-1 font-open-sans-bold text-uppercase text-center tbl-header">
                                                 <tr class="trBackgroundColor">
                                                     <th class="sort-container line-height-10px" 
                                                     [ngClass]="sortColumn('pfiID')" 
                                                     (click)="changeSorting('pfiID','ogFormType','acceptRejectDate')"
                                                     style="border-right-style:solid" *ngIf="!isPFIUser">PFI Number </th>
                                                     <th class="line-height-10px text-align-center pt-1Point4rem" *ngIf="!isPFIUser">PFI Name</th>
                                                     <th class="line-height-10px pt-1Point4rem">Form Year </th>
                                                     <th class="sort-container line-height-10px" [ngClass]="sortColumn('ogFormType')" 
                                                     (click)="changeSorting('ogFormType','pfiID','acceptRejectDate')">Form Type </th>
                                                     <th class="line-height-10px pt-1Point4rem">Status </th>
                                                     <th class="sort-container line-height-10px" [ngClass]="sortColumn('acceptRejectDate')" 
                                                     (click)="changeSorting('acceptRejectDate','pfiID','ogFormType')">Last Status Change</th>
                                                     <th class="line-height-10px"></th>
                                                 </tr>
                                             </thead>
                                             <tbody class="font-size-1 text-center aec-text text-lighter">
                                                 <ng-container
                                                     *ngFor="let report of searchList.reports | orderByMultiple: {property1: sort.column, property2: sort.column2, property3: sort.column3, direction: sort.descending}; let i = index;let odd=odd; let even=even; ">
                           
                                                     <tr>
                                                     <td *ngIf="!isPFIUser"><div>{{report.pfiID}}</div></td>
                                                     <td class="text-nowrap text-align-left" *ngIf="!isPFIUser"><div>{{report.reportName}}</div></td>
                                                     <td><div>{{report.ogYear}}</div></td>
                                                     <td><div>{{report.ogFormType}}</div></td>
                                                     <td><div>{{report.ogStatus}}</div></td>
                                                     <td><div>{{report.acceptRejectDate |date:'M/d/yyyy'}} {{report.acceptRejectDate |date:'h:mm a'}}</div></td>
                                                     <td><a class="font-color-dark-orange font-open-sans-bold" *ngIf="report.ogFormType == 'OG1'"
                                                        [routerLink]="['/empf/exhibits', 'og1form']"
                                                        [queryParams]="{pfiId: report.pfiID, pfiName: report.reportName, formType: report.ogFormType,
                                                            formYear: report.ogYear, formKey: report.ogFormKey}"><u>View</u></a>           
                                                     <a class="font-color-dark-orange font-open-sans-bold" *ngIf="report.ogFormType == 'OG2'"
                                                        [routerLink]="['/empf/exhibits', 'og2form']"
                                                        [queryParams]="{pfiId: report.pfiID, pfiName: report.reportName, formType: report.ogFormType,
                                                            formYear: report.ogYear, formKey: report.ogFormKey}"><u>View</u></a>
                                                     </td>
                                                 </tr>
                                             </ng-container>
                                             </tbody>
                                         </table>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>