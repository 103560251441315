import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[alphanumeric]'
})
export class AlphaNumericOnlyDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onInputChange(event) {
    var key = event.keyCode;
    if (event.shiftKey && key !== 9) {
      if (!(key >= 65 && key <= 90) || (key >= 35 && key <= 40)) {
        event.preventDefault();
      }
    } else {
      if (!((key === 8) || (key === 17) || (key === 32) || (key === 46) || (key === 9) || (key >= 35 && key <= 40) ||
        (key >= 65 && key <= 90) || (key >= 48 && key <= 57) || (key >= 96 && key <= 105))) {
        event.preventDefault();
      }
    }
  }

}