import { Directive, ElementRef, HostListener, Output, EventEmitter, Renderer2, Self, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgControl } from '@angular/forms';
import { BroadCastService } from '@app-shared/services/broadcast.service';
@Directive({
    selector: '[formatAsPercent]'
})

export class FormatAsPercentDirective implements OnInit {
    @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();
    private ngControl: NgControl;

    constructor(private el: ElementRef, private broadCastService: BroadCastService, public renderer: Renderer2, @Self() ngControl: NgControl) {
        this.ngControl = ngControl;
    }

    ngOnInit() {
        this.broadCastService.on('ceEstimatorFormatAsPercent', callback => {
            this.format();
          });
    }

    @HostListener('blur') onblur() {
        this.format();
    }

    format() {
        var value = this.el.nativeElement.value;
        let modelValue;

        if(value.includes('%')) {
            value = value.replace('%','').replace(' ','');
        }

        if (value != "" && !isNaN(Number(value.toString()))) {
            var parsedViewValue = parseFloat(value);
            if (parsedViewValue < 1) {
                modelValue = parsedViewValue.toFixed(6);
            }
            else {
                modelValue = (parsedViewValue / 100).toFixed(6);
            }


            this.ngControl.viewToModelUpdate(modelValue);
            modelValue = this.doCascadeRounding(modelValue, 5);

            let fixedDecimal = (modelValue * 100).toFixed(3) + ' %';
            this.renderer.setProperty(this.el.nativeElement, 'value', fixedDecimal);
            this.renderer.setProperty(this.el.nativeElement, 'val', fixedDecimal);
        }
    }

    doCascadeRounding(value: string, roundTo: number): string {

        // Check if value is a number
        if (Number(value)) {
            const numArray = Array.from(value);
            const decimalPt = numArray.indexOf('.');

            // Check if we need to worry about rounding
            if (decimalPt < 0 || decimalPt + roundTo > numArray.length - 1) {
                return value;
            }

            // Cascade round from the last array element to the desired number of decimal places
            for (let i = numArray.length - 1; i > decimalPt + roundTo; i--) {
                const lastDigit = parseInt(numArray[i], 0);
                let nextDigit = parseInt(numArray[i - 1], 0);
                if (lastDigit > 4) {
                    nextDigit++;
                }
                numArray[i] = '';
                numArray[i - 1] = nextDigit.toString();
            }
            return numArray.join('');
        }
        return '';
    }
}
