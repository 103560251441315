<div class="row mt-3">
  <div class="col-9" *ngIf="dcList && dcList.length>0">
    Showing {{totalItems==0? 0 : ((pageNo-1) * pageSize+1)}}
    to {{totalItems > (pageNo * pageSize) ? pageNo * pageSize : totalItems}}
    of {{totalItems}} items
   
  </div>
  <div class="col-9" *ngIf="!dcList">
    Showing No items
  </div>
  <div class="col-3 text-end" *ngIf="dcList && dcList.length>0">
    Show entries &nbsp; &nbsp;
    <select class="showentry" id="selectentry" [(ngModel)]="pageSize" 
      (ngModelChange)="pageSizeChange(pageSize)">
      <option [ngValue]="5">5</option>
      <option [ngValue]="10">10</option>
      <option [ngValue]="25">25</option>
      <option [ngValue]="50">50</option>
      <option [ngValue]="100">100</option>
    </select>
  </div>
</div>
<div class="row mt-2">
  <div class="col-sm-12">
    <table class="table table-striped">
      <thead class="font-bold">
        <th>DC No.</th>
        <th>MC No.</th>
        <th>PFI No.</th>
        <th>DC Amount</th>
        <th>Available Amount</th>
        <th>Product Code</th>
        <th>Subproduct Name</th>
        <th>Note Rate</th>
        <th></th>
      </thead>
      <tbody>
        <tr id="dcrow" *ngFor="let dcitems of dcList | orderBy: {property: sortingName, direction: isDesc} | slice: 0 : pageSize">
          <td class="font-bold"><a tabindex="0" id="dcNumber" class="font-light-blue" (click)="goToDC(dcitems.dcNum)">{{dcitems.dcNum}}</a></td>
          <td class="font-bold"><a tabindex="0" id="mcNumber" class="font-light-blue" (click)="goToMCView(dcitems.mcNumbr)">{{dcitems.mcNumbr}}</a></td>
          <td class="font-bold"><a tabindex="0" id="pfiNumber" class="font-light-blue" (click)="goToPFI(dcitems.pfiNumber)">{{dcitems.pfiNumber}}</a></td>
          <td id="dcAmount">{{dcitems.dcAmount | currency}}</td>
          <td id="availableAmount">{{dcitems.availableAmount | currency}}</td>
          <td id="prodCode">{{dcitems.productcode}}</td>
          <td id="subProdName">{{dcitems.subProductName}}</td>
          <td id="noteRate">{{dcitems.noteRate |  percent:'1.3-3' }}</td>
          <td class="font-bold column-width" >
            <ng-template #popTemplate>
              <a tabindex="0" *ngIf="!this.isInquiryUser" (click)="goToExtend(dcitems.dcNum, dcitems.deliveryStatus,dcitems.deliveryDate,dcitems.scheduleCode)">Extend</a><br  *ngIf="!this.isInquiryUser"/>
              <a tabindex="0" *ngIf="!this.isInquiryUser" (click)="goToReduce(dcitems.dcNum, dcitems.deliveryStatus,dcitems.deliveryDate,dcitems.scheduleCode)">Reduce</a><br *ngIf="!this.isInquiryUser" />
              <a tabindex="0" (click)="goToLoans(dcitems.dcNum)">View Loans</a><br *ngIf="!this.isInquiryUser" />
              <a *ngIf="!this.isInquiryUser" tabindex="0" (click)="goToEdit(dcitems.dcNum,dcitems.dcKey)">Edit</a>
            </ng-template>
            <a tabindex="0" id="popTemplate" [ngbPopover]="popTemplate" #p1="ngbPopover" [autoClose]="true"  
            placement="bottom-right" [ngSwitch]="dcitems.clickstate" (click)="manageDcStatus(dcitems)" (blur)="blurPopover(dcitems)" mdbRippleRadius>
              <i *ngSwitchCase="true" class="fa fa-ellipsis-h" aria-hidden="true"></i>
              <i *ngSwitchCase="false" class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="totalItems" [hidden]="totalItems <= pageSize"    
      class="row justify-content-center align-items-center font-size-2 pagination">
      <ngb-pagination id="pagination" [collectionSize]="totalItems" [maxSize]="10" [(page)]="pageNo" [pageSize]="pageSize"
      (pageChange)="pageChanged($event)" aria-label="Default pagination">
        <ng-template id = "previous" ngbPaginationPrevious class="font-size-2">
          &lt;Prev</ng-template>
            <ng-template id = "Next" ngbPaginationNext class="font-size-2">Next&gt;</ng-template>
      </ngb-pagination>
      
    </div>
    <div *ngIf="!dcList || dcList.length== 0" class="defaultText text-center ">
      No results could be found. Please try your search again.
    </div>
  </div>
</div>