import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { isNullOrUndefined } from 'is-what';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[selectValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SelectValidateDirective, multi: true }]
})

export class SelectValidateDirective implements Validator {
// tslint:disable-next-line: no-input-rename
    @Input('selectDefault') defaultValue: any;
    @Input('selectValidate') fieldValidator: string;
    @Input('isNotReq') isNotReq: boolean =false;
    
    constructor() { }

    validate(control: AbstractControl): { [key: string]: boolean } | null {
        if (!this.isNotReq) {
        if (control.value != null) {
            if (this.defaultValue == control.value) {
                control.setErrors({ invalid: true });
                return { invalidoption: true };
            }
            else if(!isNullOrUndefined(control.value.question) && this.defaultValue == control.value.question){
                control.setErrors({ invalid: true });
                return { invalidoption: true };
            }
        }
        }

        return null;
    }
}
