import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, UntypedFormControl, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[validDOB]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: ValidateDateRangeDirective, multi: true }
    ]
})
export class ValidateDateRangeDirective implements Validator {
    private validatorFunc: ValidatorFn;

    constructor() {
        this.validatorFunc = dateValidator();
    }

    validate(control: UntypedFormControl) {
        return this.validatorFunc(control);
    }

}

export function dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const dateStr = control.value;
        // Length of months (will update for leap years)
        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        // Object to return if date is invalid
        const invalidObj = { date: true };
        const invalidrangeObj = { daterange: true };


        if (dateStr) {
            //Check for Pattern            
            if (!/^\d{1,2}\/\d{1,2}\/\d{2,4}$/.test(dateStr)) {
                

                return invalidObj;
            }
            // Parse the date input to integers
            const parts = dateStr.split('/');
            const month = parseInt(parts[0], 10);
            const day = parseInt(parts[1], 10);
            const year = parseInt(parts[2], 10);
            const yearlength = year ? year.toString().length : 0;
            // Make sure date is in range
            if ((yearlength === 4) && (year < 1900 || year > 9999)) {
                
                return invalidrangeObj;
            }
            else if (month === 0 || month > 12) {
                return invalidObj;
            }
            else if (((yearlength === 2) && isNaN(year))
                || yearlength > 4 || yearlength === 3 ||
                yearlength < 2) {

                return invalidObj;
            }
            // Adjust for leap years
            if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
                monthLength[1] = 29;
            }
            // Check the range of the day
            if (!(day > 0 && day <= monthLength[month - 1])) {

                return invalidObj;
            }
        }

        return null;
    };
}