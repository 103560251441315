import { Directive, ElementRef, OnInit, Self, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';

@Directive({
// tslint:disable-next-line: directive-selector
    selector: '[show-errors]'
})

export class ShowErrorDirective implements OnInit {
    private el: HTMLElement;
    errorEventSubscription: Subscription;
    private ngControl: NgControl;

    constructor(ref: ElementRef, @Self() ngControl: NgControl) {
        this.el = ref.nativeElement;
        this.ngControl = ngControl;
    }

    ngOnInit() {
    }

    applyHasError() {
        if (this.ngControl.control.invalid && this.ngControl.control.touched) {
            this.el.classList.add('is-invalid');
        } else {
            this.el.classList.remove('is-invalid');
        }
    }

    @HostListener('ngModelChange', []) onModelChange() {
        this.applyHasError();
    }

    @HostListener('blur') onblur() {
        this.applyHasError();
    }
}
