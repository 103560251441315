import { Pipe, PipeTransform } from '@angular/core';
import { UtilHelper } from '../helpers/util-helper';
@Pipe({
    name: 'findDistinctNoteRates'
})
export class FindDistinctNoteRates implements PipeTransform {

    transform(items: any, args?: any): any {
        {

            if (UtilHelper.isUndefinedOrNull(items) || items.length === 0) {
                return items;
            }

            var filteredNoteRates = [];

            //iteration - commitmentTerms
            items.forEach((commitmentTerm) =>{
                if (UtilHelper.isDefinedOrNotNull(commitmentTerm.noteRate) && commitmentTerm.noteRate.length > 0) {
                    commitmentTerm.noteRate.forEach( (noteRate) =>{
                        if (filteredNoteRates.indexOf(noteRate.rate) === -1) {
                            filteredNoteRates.push(noteRate.rate);
                        }
                    });
                }
            });
           
            return filteredNoteRates;
        }
    }
}