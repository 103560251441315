import { CommonModule } from "@angular/common";
import { InjectionToken, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/guards/auth.guard";
import { fhlbConstants } from "@app-shared/constants/fhlb-constants";
import { SharedModule } from "@app-shared/shared.module";
import { OriginationContainerComponent } from "./origination.container.component";
import { OriginationSharedModule } from "./shared/origination-shared.module";
import { EditOrgGuard } from "@origination-shared/guards/edit-org.guard";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { interactiveSearchReducers, geoProcessReducers } from './store/reducers';
import { GeoProcessEffects } from "./store/effects/geo-process.effect";
import { provideBootstrapEffects } from "@app-shared/utils/ngrx-boostrap-effects";
import { OriginationRoutingHelper } from "@origination-shared/navigation/origination.navigation";
import { SiteErrorPageComponent } from "@app-shared/components/sites-wrapping/site-error-page/site-error-page.component";
import { MsalGuard } from "@azure/msal-angular";

export const ORIGINATION_BOOTSTRAP_EFFECTS = new InjectionToken('Origination Bootstrap Effects');

const originationRoutes: Routes = [
  {
    path: '', component: OriginationContainerComponent,
    canActivate: [MsalGuard,AuthGuard],
    data: {
      breadcrumb: {
        value: {
          name: 'Home',
          route: OriginationRoutingHelper.Home()
        }
      },
      losAuth: {
        roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfInquiryNoPII, fhlbConstants.MpfSuperuser]
      },
      empfAuth: {
        roles: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf, fhlbConstants.EmpfScuMpf],
        behaviors: [fhlbConstants.accessToEmpf],
      }
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard],
        data: {
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfInquiryNoPII, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'organization',
        loadChildren: () => import('./organization/org-module').then(m => m.OrgModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: {
            value: {
              name: 'Organization',
              route: OriginationRoutingHelper.SearchOrganization()
            }
          },
          empfAuth: {
            roles: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf],
            behaviors: [fhlbConstants.accessToEmpf, fhlbConstants.empfSecurityAdministrator]
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfInquiryNoPII, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'rulesandrulesets',
        loadChildren: () => import('./rules-and-rulesets/rules.module').then(m => m.RulesModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: {
            overridingBreadcrumb:[
              {
                name: 'Rules and Rulesets',
                route: OriginationRoutingHelper.Home()
              }
            ]
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'mastercommitment',
        loadChildren: () => import('./mastercommitment/mc-module').then(m => m.McModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: {
            value: {
              name: 'Master Commitments'
            }
          },
          empfAuth: {
            roles: [fhlbConstants.EmpfPfiMpf],
            behaviors: [fhlbConstants.accessToEmpf, fhlbConstants.empfSecurityAdministrator]
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'loanpresentment',
        loadChildren: () => import('./loanPresentment/lp-module').then(m => m.LpModule),
        canActivate: [AuthGuard],
        data:{
          breadcrumb: {
            value: {
              name: 'Loan Presentment',
              route: null
            }
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration-process/administration-process.module').then(m => m.AdministrationProcessModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb:{
            overridingBreadcrumb:[
              {
                name: 'Administration',
                route: OriginationRoutingHelper.Home()
              }
            ]
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'deliverycommitment',
        loadChildren: () => import('./deliverycommitment/dc-module').then(m => m.DcModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: {
            value: {
              name: 'Delivery Commitments',
              route: null
            }
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'loanfunding',
        loadChildren: () => import('./loan-funding/loan-funding.module').then(m => m.LoanFundingModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: {
            value: {
              name: 'Loan Funding',
              route: null
            }
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'ceestimator',
        loadChildren: () => import('./ceestimator/ceestimator.module').then(m => m.CEEstimatorModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb:{
            value: {
              name: 'CE Estimator',
            }     
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'batchprocess',
        loadChildren: () => import('./batch-process/batch-process.module').then(m => m.BatchProcessModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: {
            value: {
              name: 'Batch',
              route: null
            }
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfSuperuser]
          }
        }
      },
      {
        path: 'geographic-information',
        loadChildren: () => import('./geographic-information/geographic-information-module').then(m => m.GeographicInformationModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb:{
            overridingBreadcrumb:[
              {
                name: 'Geographic Information',
                route: OriginationRoutingHelper.Home()
              }
            ]
          },
          losAuth: {
            roles: [fhlbConstants.MpfAdmin, fhlbConstants.MpfInquiry, fhlbConstants.MpfSuperuser]
          }
        }
        },
        {
            path: 'error', component: SiteErrorPageComponent,
            data: {
                breadcrumb: {
                    value: null,
                    overridingBreadcrumb: []
                }
            }
        },
        {
            path: '**', redirectTo: 'error'
        }
    ]
  }
]

@NgModule({
  declarations: [OriginationContainerComponent],
  imports: [
    CommonModule,
    SharedModule,
    OriginationSharedModule,
    RouterModule.forChild(originationRoutes),
    StoreModule.forFeature("interactiveSearch", interactiveSearchReducers),
    StoreModule.forFeature("geoProcess", geoProcessReducers),
    EffectsModule.forFeature([])
  ],
  exports: [RouterModule, OriginationContainerComponent],
  providers: [
    EditOrgGuard,
    provideBootstrapEffects([GeoProcessEffects],ORIGINATION_BOOTSTRAP_EFFECTS),
  ]
})
export class OriginationModule { }