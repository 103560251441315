<div class="breadcrumb-section" *ngIf="breadcrumbs && breadcrumbs.length > 0">
  <ol class="breadcrumb">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; last as isLast">
      <li class="breadcrumb-item size" [hidden]="isLast && breadcrumb.name == ''"> <!--Bug-117945 :hidding last breadcrumb if name is empty.-->
        <a *ngIf="!isLast && !breadcrumbHasRoute(breadcrumb.route)" class="inactive">{{ breadcrumb.name }}</a>
        <a *ngIf="!isLast && breadcrumbHasRoute(breadcrumb.route)" class="active" [routerLink]="breadcrumb.route">{{
          breadcrumb.name }}</a>
        <span *ngIf="isLast" class="last-child">{{ breadcrumb.name }}</span>
      </li>
    </ng-container>
  </ol>
</div>