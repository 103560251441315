<div class="mt-3 ms-3" *ngIf="show" >
    <div class="col-md-12 succesMsg">
        <div class="row">
            <div class="col-md-0.5 bg-success">
                <div style="font-size:24px;">
                    <i class="fa fa-check-circle-o ps-2 textcolor"></i>
                </div>
            </div>
            <div class="col-md-11.5 p-2">
                <span class="p-2 mb-2">{{message}}</span></div>
        </div>
    </div>
</div>