import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { pricingHelper } from '../helpers/pricing-helper';
import { UtilHelper } from '../helpers/util-helper';


@Directive({
    selector: '[srpSelectionValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SRPSelectionValidatorDirective, multi: true }]
})
export class SRPSelectionValidatorDirective implements OnInit, OnChanges {

    constructor(private el: ElementRef, @Inject(DOCUMENT) private document: any) {
    }
    @Input() productSelectionOptions;
    @Input() selectValidatorTrigger;
    @Input() category;

    pricingValidationClass = 'pricing-panel-body-column-validation';

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.selectValidatorTrigger) {
            this.validate(this.selectValidatorTrigger.event, this.selectValidatorTrigger);
        }
    }

    validate(event, args) {
        {
            // Merges configuration set in directive with default one
            const configOptions = {};
            const mergedConfig = Object.assign({}, configOptions, (this.productSelectionOptions));

            // retrieve the criteria property
            const criteria = mergedConfig.criteria;
            const category = this.category;
            const pricingContainerId = mergedConfig.pricingContainerId;
            const pricingSourceName = mergedConfig.pricingSourceName;

            const jsonObj = args;

            // perform validation
            const results = pricingHelper.validateSRPPricingCriteriaBySource(criteria, pricingSourceName);
            if (!results.status && results.errorType === category) {
                if (results.errors.length > 0) {
                    if (results.stopPropagation) {
                        event.preventDefault();
                        this.removePricingValidationCss(event, pricingContainerId);
                    }
                    this.el.nativeElement.classList.add(this.pricingValidationClass);
                } else {
                    this.el.nativeElement.classList.remove(this.pricingValidationClass);
                }
            } else {
                this.el.nativeElement.classList.remove(this.pricingValidationClass);
            }
        }
    }

    removePricingValidationCss(event: any, pricingContainerId: any) {
        {
            // remove the CSS class from pricing main template
            const mainTmpl = document.getElementById(pricingContainerId);
            if (UtilHelper.isDefinedOrNotNull(mainTmpl)) {
                const matchingCssEls = this.el.nativeElement.querySelectorAll('.' + this.pricingValidationClass);
                if (UtilHelper.isDefinedOrNotNull(matchingCssEls)) {
                    const ngMatchingCssEls = this.document.matchingCssEls;
                    ngMatchingCssEls.forEach((e) => {
                        const ngEl = e;
                        ngEl.remove(this.pricingValidationClass);
                    });
                }
            }
        }
    }
}
