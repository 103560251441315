export enum RoleType{
  Unspecified = 0,
  MPFBank = 1,
  Originator,
  LoanServicer,
  Custodian,
  CreditEnhancer,
  FundingFacilitator,
  Investor,
  ServicingAggregator,
  Trustee,
  PrimaryMI,
  EnhancedMI,
  SupplementalMI,
  Trust,
  ProducePortfolioPricing,
  PricingEngineVendor,
  SubServicer,
  MasterServicer,
  InvestorServicer,
  MPFProvider
}

