import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@app-shared/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpUtilHelper } from 'src/shared/helpers/http-util-helper';
import { ISendEmailToRequestModel } from '../loan-funding/models/SendEmailToRequestModel';
import { ISendEmailToResponseModel } from '../loan-funding/models/SendEmailToResponseModel';
import { IGetLoanPresentmentsRequestModel } from '../loan-presentment/models/GetLoanPresentmentsRequestModel';
import { IGetLoanPresentmentsResponseModel } from '../loan-presentment/models/GetLoanPresentmentsResponseModel';
import { IGetBatchErrorResponseModel } from './loan-presentment/models/GetBatchErrorResponseModel';
import { IChangePfiLoanNumberRequestModel } from './loan-presentment/models/IChangePfiLoanNumberRequestModel';
import { IDownloadLprReportRequestModel } from './loan-presentment/models/IDownloadLprReportRequestModel';
import { IGetCeConfirmationRequestModel } from './loan-presentment/models/IGetCeConfirmationRequestModel';
import { IGetLoanStatisticsRequestModel } from './loan-presentment/models/IGetLoanStatisticsRequestModel';
import { IGetRecentLoanPresentmentResponseModel } from './loan-presentment/models/IGetRecentLoanPresentmentResponseModel';
import { ILoanPresentmentRequestModel } from './loan-presentment/models/ILoanPresentmentrequestModel';
import { ILoanPresentmentResponseModel } from './loan-presentment/models/ILoanPresentmentResponseModel';
import { ILprValidationErrorResponseModel } from './loan-presentment/models/ILprValidationErrorResponseModel';
import { IValidateMcRequestModel } from './loan-presentment/models/IValidateMcRequestModel';
import { IValidateMcResponseModel } from './loan-presentment/models/IValidateMcResponseModel';

@Injectable({
    providedIn: 'root'
})

export class LoanPresentmentService {
    private MICROSERVICE_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.MICROSERVICE_PATH}/api/loan/ext`;
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/loanpresentment`;
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient) {
    }

    getPropertyTypes(): Observable<ILoanPresentmentResponseModel> {
        return this.http.get<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/getpropertyinformation`, this.options)
            .pipe(map(response => response)
            );
    }

    getLoanStatisticsInformation(req: IGetLoanStatisticsRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.post<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/getloanstatisticsinformation`, req, this.options)
            .pipe(map(response => response)
            );
    }

    validateAll(req: ILoanPresentmentRequestModel): Observable<ILprValidationErrorResponseModel> {
        return this.http.post<ILprValidationErrorResponseModel>(`${this.API_SERVICE_PATH}/${'validateAll'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response)
            );
    }
    validateLoanpfiloannumber(req: ILoanPresentmentRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.get<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/checkvalidloanpfiloannumber?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response)
            );
    }
    validateLoan(req: ILoanPresentmentRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.get<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/checkvalidloan?${HttpUtilHelper.ConvertQueryString(req)}`)
            .pipe(map(response => response)
            );
    }
    getMcDetails(req: ILoanPresentmentRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.get<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/${'getMcDetails?'.toLowerCase()}${HttpUtilHelper.ConvertQueryString(req)}`)
            .pipe(map(response => response)
            );
    }
    getBorrowerInformation(): Observable<ILoanPresentmentResponseModel> {
        return this.http.get<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/getborrowerinformation`)
            .pipe(map(response => response)
            );
    }
    getPropertyInformation(): Observable<ILoanPresentmentResponseModel> {
        return this.http.get<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/getpropertyinformation`)
            .pipe(map(response => response)
            );
    }
    loanStatisticsRequest(req: IGetLoanStatisticsRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.post<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/getloanstatisticsinformation`, req)
            .pipe(map(response => response)
            );
    }
    getAntiPredatoryLendingInformation(): Observable<ILoanPresentmentResponseModel> {
        return this.http.get<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/getantipredatorylendinginformation`)
            .pipe(map(response => response)
            );
    }
    getLoanForExistingLpr(req: ILoanPresentmentRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.post<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/${'getLoanForExistingLpr'.toLowerCase()}`, req)
            .pipe(map(response => response)
            );
    }
    validateMC(validateMcRequestModel: IValidateMcRequestModel): Observable<IValidateMcResponseModel> {
        return this.http.get<IValidateMcResponseModel>(`${this.API_SERVICE_PATH}/validatemc?${HttpUtilHelper.ConvertQueryString(validateMcRequestModel)}`)
            .pipe(map(response => response)
            );
    }
    GetCEAmountDetails(req: IGetLoanStatisticsRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.post<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/${'GetCEAmountDetails'.toLowerCase()}`, req)
            .pipe(map(response => response)
            );
    }
    getBatchLoanErrors(req: ILoanPresentmentRequestModel): Observable<IGetBatchErrorResponseModel> {
        return this.http.post<IGetBatchErrorResponseModel>(`${this.API_SERVICE_PATH}/getbatchloanerrors`, req)
            .pipe(map(response => response)
            );
    }
    getPfiLoanData(): Observable<ILoanPresentmentResponseModel> {
        return this.http.get<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/populatepfiloaninfo`)
            .pipe(map(response => response)
            );
    }
    submitLpr(req: ILoanPresentmentRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.post<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/${'submitLpr'.toLowerCase()}`, req)
            .pipe(map(response => response)
            );
    }
    updatePfiLoanNumber(changePfiLoanNumberRequestModel: IChangePfiLoanNumberRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.get<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/${'updatePfiLoan?'.toLowerCase()}${HttpUtilHelper.ConvertQueryString(changePfiLoanNumberRequestModel)}`)
            .pipe(map(response => response)
            );
    }
    getLPRSummary(req: ILoanPresentmentRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.post<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/getlprsummary`, req)
            .pipe(map(response => response)
            );
    }
    downloadLPRReport(downloadLprReportRequestModel: IDownloadLprReportRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/downloadlprreport`, downloadLprReportRequestModel, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response));
    }
    sendEmail(sendEmailToRequestModel: ISendEmailToRequestModel): Observable<ISendEmailToResponseModel> {
        return this.http.post<ISendEmailToResponseModel>(`${this.API_SERVICE_PATH}/${'sendEmail'.toLowerCase()}`, sendEmailToRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
    getCEConfirmationDetails(req: IGetCeConfirmationRequestModel): Observable<ILoanPresentmentResponseModel> {
        return this.http.post<ILoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/getceconfirmationdetails`, req)
            .pipe(map(response => response)
            );
    }
    getRecentLoanPresentments(): Observable<IGetRecentLoanPresentmentResponseModel> {
        return this.http.get<IGetRecentLoanPresentmentResponseModel>(`${this.API_SERVICE_PATH}/getrecentloanpresentments`)
            .pipe(map(response => response)
            );
    }
    getLoanPresentments(getLoanPresentmentsRequestModel: IGetLoanPresentmentsRequestModel): Observable<IGetLoanPresentmentsResponseModel> {
        return this.http.post<IGetLoanPresentmentsResponseModel>(`${this.API_SERVICE_PATH}/${'getLoanPresentments'.toLowerCase()}`, getLoanPresentmentsRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
}
