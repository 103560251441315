import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PFILoanModel } from '../models/PFILoanModel';

@Component({
  selector: 'app-pfi-loan-list',
  templateUrl: './search-lpr-loan-list.component.html',
  styleUrls: ['./search-lpr-loan-list.component.sass']
})
export class PfiLoanListComponent implements OnInit {
  @Input() pfiLoanList: PFILoanModel[] = [];
  @Input() pageSize: number;
  @Input() pageNo: number;
  @Input() totalItems: number;
  @Input() totalPages: number;
  @Output() pageSizeChangeClick: EventEmitter<{ pageSize: number }> = new EventEmitter<{ pageSize: number }>();
  @Output() pageNumberChangeClick: EventEmitter<{ pageNo: number }> = new EventEmitter<{ pageNo: number }>();
  @Output() checkLoanFundingClick: EventEmitter<{ pfiLoan: PFILoanModel }> = new EventEmitter<{ pfiLoan: PFILoanModel }>();
  @Output() gotoViewPFIClick: EventEmitter<{ pfiNumber: number }> = new EventEmitter<{ pfiNumber: number }>();
  @Output() gotoViewMCClick: EventEmitter<{ mcNo: number }> = new EventEmitter<{ mcNo: number }>();
  @Output() gotoViewDCClick: EventEmitter<{ dcNo: number }> = new EventEmitter<{ dcNo: number }>();
  @Input() isInquiryUser: boolean;
  @Input() isLoanFunded: boolean;
  @Input() showMCNo: boolean;
  @Input() showDCNo: boolean;
  @Output() goToViewLPRClick: EventEmitter<{ pfiLoanNum: string, pfiNum: number }> =
    new EventEmitter<{ pfiLoanNum: string, pfiNum: number }>();
  @Output() goToEditLPRClick: EventEmitter<{ pfiLoanNum: string, pfiNum: number }> =
  new EventEmitter<{ pfiLoanNum: string, pfiNum: number }>();
  @Output() goToViewLoan: EventEmitter<number> = new EventEmitter<number>();
  @Output() goToViewLfClick: EventEmitter<{ mPFLoanNumber: number }> =
  new EventEmitter<{ mPFLoanNumber: number }>();
  constructor() { }
  

  ngOnInit() {
  }

  ngOnChanges() {

  }

  pageSizeChange(pageSize: number) {
    this.pageSizeChangeClick.emit({ pageSize });
  }
  pageNumberChange(pageNo: number) {
    this.pageNumberChangeClick.emit({ pageNo });
  }

  checkLoanFundingStatus(pfiLoan: PFILoanModel) {
    pfiLoan.clickstate = !pfiLoan.clickstate;
    if (pfiLoan.clickstate === true) {
      this.checkLoanFundingClick.emit({ pfiLoan });
    }

  }
  blurPopover(pfiLoan: PFILoanModel){

    setTimeout(() => {
      pfiLoan.clickstate=false;
    }, 200);
    
  }
  goToView(pfiNumber: number) {

    this.gotoViewPFIClick.emit({ pfiNumber });
  }

  goToViewMC(mcNo: number) {
    this.gotoViewMCClick.emit({ mcNo });
  }
  goToViewDC(dcNo: number) {
    this.gotoViewDCClick.emit({ dcNo });

  }
  viewLPR(pfiLoanNum: string, pfiNum: number) {
    this.goToViewLPRClick.emit({ pfiLoanNum, pfiNum });
  }
  editLPR(pfiLoanNum: string, pfiNum: number) {
    this.goToEditLPRClick.emit({ pfiLoanNum, pfiNum });
  }

  viewLoan(mPFLoanNumber: number) {
    this.goToViewLoan.emit(mPFLoanNumber);
  }

  viewLF(mPFLoanNumber: number) {
    this.goToViewLfClick.emit({mPFLoanNumber})
  }
}
