import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IExistingLoanFundingModel } from './loan-funding/models/ExistingLoanFundingModel';
import { IValidateLoanFundingRequestModel } from './loan-funding/models/ValidateLoanFundingRequestModel';
import { IGetLoanFundingSummaryRequestModel } from './loan-funding/models/GetLoanFundingSummaryRequestModel';
import { IDownloadLoanFundingRequestModel } from './loan-funding/models/DownloadLoanFundingRequestModel';
import { IPrintLoanFundingRequestModel } from './loan-funding/models/PrintLoanFundingRequestModel';
import { INewLoanFundingModel } from './loan-funding/models/NewLoanFundingModel';
import { ILoanFundingLPRRequestModel } from './loan-funding/models/LoanFundingLPRRequestModel';
import { ILoanFundingRequestModel } from './loan-funding/models/LoanFundingRequestModel';
import { ISendEmailToRequestModel } from './loan-funding/models/SendEmailToRequestModel';
import { IGetLoanFundingSummaryResponsetModel } from './loan-funding/models/GetLoanFundingSummaryResponseModel';
import { IValidateLoanFundingResponseModel } from './loan-funding/models/ValidateLoanFundingResponseModel';
import { ISendEmailToResponseModel } from './loan-funding/models/SendEmailToResponseModel';
import { ICalculateInterimInterestResponseModel } from './loan-funding/models/CalculateInterimInterestResponseModel';
import { IFundLoanResponseModel } from './loan-funding/models/FundLoanResponseModel';
import { IValidateCreateLoanFundingResponseModel } from './loan-funding/models/ValidateCreateLoanFundingResponseModel';
import { IGetLoanResponseModel } from './loan-funding/models/getLoanResponseModel';
import { IPrintLoanFundingDetailsResponseModel } from './loan-funding/models/PrintLoanFundingDetailsResponseModel';
import { IGetLoanFundingSummaryResponseModel } from './loan-funding/models/IGetLoanFundingSummaryResponseModel';
import { AppConfigService } from '@app-shared/services';

@Injectable({
    providedIn: 'root'
})
export class LoanFundingService {    
    private MICROSERVICE_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.MICROSERVICE_PATH}/api/loan/ext`;
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/loanfunding`;
      
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    existingLoanFundingModel: IExistingLoanFundingModel;
    newLoanFundingModel: INewLoanFundingModel;

    constructor(private http: HttpClient) {

    }
    getLoan(loanFundingLPRRequestModel: ILoanFundingLPRRequestModel): Observable<IGetLoanResponseModel> {
        return this.http.post<IGetLoanResponseModel>(`${this.API_SERVICE_PATH}/${'getLoan'.toLowerCase()}`, loanFundingLPRRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
    getLoanFundingSummary(getLoanFundingSummaryRequestModel: IGetLoanFundingSummaryRequestModel): Observable<IGetLoanFundingSummaryResponsetModel> {
        return this.http.post<IGetLoanFundingSummaryResponsetModel>(`${this.API_SERVICE_PATH}/${'getLoanFundingSummary'.toLowerCase()}`, getLoanFundingSummaryRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
    sendEmail(sendEmailToRequestModel: ISendEmailToRequestModel): Observable<ISendEmailToResponseModel> {
        return this.http.post<ISendEmailToResponseModel>(`${this.API_SERVICE_PATH}/sendemail`, sendEmailToRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
    validateLoanFunding(validateLoanFundingRequestModel: IValidateLoanFundingRequestModel): Observable<IValidateLoanFundingResponseModel> {
        return this.http.post<IValidateLoanFundingResponseModel>(`${this.API_SERVICE_PATH}/validateloanfunding`, validateLoanFundingRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
    validateCreateLoanFunding(validateLoanFundingRequestModel: IValidateLoanFundingRequestModel): Observable<IValidateCreateLoanFundingResponseModel> {
        return this.http.post<IValidateCreateLoanFundingResponseModel>(`${this.API_SERVICE_PATH}/validatecreateloanfunding`, validateLoanFundingRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
    downloadLoanFundingReport(downloadLoanFundingRequestModel: IDownloadLoanFundingRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/downloadloanfundingreport`, downloadLoanFundingRequestModel, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }
    printLoanFundingDetails(printLoanFundingRequestModel: IPrintLoanFundingRequestModel): Observable<IPrintLoanFundingDetailsResponseModel> {
        return this.http.post<IPrintLoanFundingDetailsResponseModel>(`${this.API_SERVICE_PATH}/${'printLoanFundingDetails'.toLowerCase()}`, printLoanFundingRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
    fundLoan(loanFundingRequestModel: ILoanFundingRequestModel): Observable<IFundLoanResponseModel> {
        return this.http.post<IFundLoanResponseModel>(`${this.API_SERVICE_PATH}/${'fundLoan'.toLowerCase()}`, loanFundingRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
    calculateInterimInterest(loanFundingRequestModel: ILoanFundingRequestModel): Observable<ICalculateInterimInterestResponseModel> {
        return this.http.post<ICalculateInterimInterestResponseModel>(`${this.API_SERVICE_PATH}/${'calculateInterimInterest'.toLowerCase()}`, loanFundingRequestModel, this.options)
            .pipe(map(response => response)
            );
    }
    getLoanFundings(): Observable<IGetLoanFundingSummaryResponseModel> {
        return this.http.get<IGetLoanFundingSummaryResponseModel>(`${this.API_SERVICE_PATH}/getloanfundings`)
            .pipe(map(response => response)
            );
    }
}
