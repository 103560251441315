<div class="row">
    <div class="col-sm-12 sitemappath-uppercase">
        <nav class="navbar">
            <ul class="nav nav-fill nav-justified w-100">
                <ng-container *ngFor="let link of navLinks; let isLastParentNode = last">
                    <li *ngIf="linkHasChildren(link)" class="nav-item dropdown"
                    id="{{link.name}}">    
                        <a site-map-menu-toggle class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                            <span>{{link.name}}</span>
                        </a>             
                        <ul class="font-size-2 dropdown-menu dropdown-menu-sites-menu"  id="{{link.name}}_Id" >
                            <ng-container *ngFor="let childlink of link.children">
                                <ng-container *ngIf="linkHasChildren(childlink)">         
                                    <li class="dropdown-item dropdown dropdown-submenu dropdown-item-sites-menu" >  
                                        <a site-map-menu-toggle class="dropdown-toggle" data-bs-toggle="dropdown" id="submenu-element">
                                            {{ childlink.name }}
                                            <span *ngIf="isLastParentNode" class="dropdown-submenu-arrow-left"></span>
                                            <span *ngIf="!isLastParentNode" class="dropdown-submenu-arrow-right"></span>                   
                                        </a>

                                        <ul class="font-size-2 dropdown-menu dropdown-menu-sites-menu" [class.dropdown-menu-left]="isLastParentNode">
                                            <ng-container *ngFor="let grandchildlink of childlink.children">
                                                <ng-container *ngIf="!linkHasChildren(grandchildlink)">
                                                    <li *ngIf="!isExternalLink(grandchildlink)" class="dropdown-item dropdown-item-sites-menu"><a (click)="collapseMenu(link.name)" [routerLink]="grandchildlink.route">{{grandchildlink.name}}</a></li>
                                                    <li *ngIf="isExternalLink(grandchildlink)" class="dropdown-item dropdown-item-sites-menu"><a target="_blank" [href]="grandchildlink.url">{{grandchildlink.name}}</a></li>
                                                </ng-container>
                                            </ng-container>
                                        </ul>

                                    </li>
                                </ng-container>
                                <ng-container *ngIf="!linkHasChildren(childlink)">
                                    <li *ngIf="!isExternalLink(childlink)" class="dropdown-item dropdown-item-sites-menu"><a (click)="collapseMenu(link.name)" [routerLink]="childlink.route">{{childlink.name}}</a></li>
                                    <li *ngIf="isExternalLink(childlink)" class="dropdown-item dropdown-item-sites-menu"><a target="_blank" [href]="childlink.url">{{childlink.name}}</a></li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </li>
                    <li id="{{link.name}}" *ngIf="!linkHasChildren(link) && !isExternalLink(link)" class="nav-item"><a (click)="collapseMenu(link.name)" class="nav-link" [routerLink]="link.route"><span>{{link.name}}</span></a>
                    </li>
                    <li id="{{link.name}}" *ngIf="!linkHasChildren(link) && isExternalLink(link)" class="nav-item"><a site-map-menu-toggle class="nav-link" target="_blank" [href]="link.url"><span>{{link.name}}</span></a>
                    </li>
                </ng-container>
            </ul>
        </nav>
    </div>
</div>