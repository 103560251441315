import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderByMultiple', pure: false })
export class OrderByMultiplePipe implements PipeTransform {

    transform(records: Array<any>, args?: any): any {

        if (records) {
            return records.sort(function (a, b) {
                var x, y, x1, y1, x2, y2;
                if (a instanceof Array) {
                    x = a[0][args.property1];
                    y = b[0][args.property1];

                    x1 = a[0][args.property2];
                    y1 = b[0][args.property2];

                    x2 = a[0][args.property3];
                    y2 = b[0][args.property3];
                }
                else {
                    x = a[args.property1];
                    y = b[args.property1];

                    x1 = a[args.property2];
                    y1 = b[args.property2];

                    x2 = a[args.property3];
                    y2 = b[args.property3];
                }
                if (typeof x === 'string' && typeof y === 'string') {
                    x = x.toLowerCase();
                    y = y.toLowerCase();
                }

                if (typeof x1 === 'string' && typeof y1 === 'string') {
                    x1 = x1.toLowerCase();
                    y1 = y1.toLowerCase();
                }

                if (typeof x2 === 'string' && typeof y2 === 'string') {
                    x2 = x2.toLowerCase();
                    y2 = y2.toLowerCase();
                }

                if (!isNaN(Date.parse(x)) && !isNaN(Date.parse(y))) {
                    x = new Date(x).getTime();
                    y = new Date(y).getTime();
                }

                if (!isNaN(Date.parse(x1)) && !isNaN(Date.parse(y1))) {
                    x1 = new Date(x1).getTime();
                    y1 = new Date(y1).getTime();
                }

                if (!isNaN(Date.parse(x2)) && !isNaN(Date.parse(y2))) {
                    x2 = new Date(x2).getTime();
                    y2 = new Date(y2).getTime();
                }


                let direction = args.direction ? -1 : 1;
                if (x < y) {
                    return -1 * direction;
                }
                else if (x > y) {
                    return 1 * direction;
                }
                else {
                    if (x1 < y1) {
                        return -1 * direction;
                    }
                    else if (x1 > y1) {
                        return 1 * direction;
                    }
                    else {
                        if (x2 < y2) {
                            return -1 * direction;
                        }
                        else if (x2 > y2) {
                            return 1 * direction;
                        }
                        else {
                            return 0;
                        }
                    }
                }
            });
        }

    };
}