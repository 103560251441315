import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[aliasValueValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: AliasValueValidatorDirective, multi: true }]
})

export class AliasValueValidatorDirective implements Validator {
// tslint:disable-next-line: no-input-rename
    @Input('aliasValueValidate') dataValueType: any;   
    allowedDateFormats = ['MM/DD/YYYY', 'M/D/YYYY', 'M/D/YY', 'YYYY/MM/DD',
    'MM.DD.YYYY', 'M.D.YYYY', 'M.D.YY', 'YYYY.MM.DD',
    'MM-DD-YYYY', 'M-D-YYYY', 'M-D-YY', 'YYYY-MM-DD'];
    numericPattern = /^[0-9]*$/
    
    constructor() {}

    validate(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value !== undefined) {
            if (this.dataValueType == 'Numeric') {
                let output = control.value.match(this.numericPattern);
                if(output === null){
                    control.setErrors({ invalid: true });
                    return { invalidoption: true };
                }
                else{
                    control.setErrors(null);
                    return null;
                }
            }
            else if (this.dataValueType == 'Date') {

                if (moment(control.value, this.allowedDateFormats, true).isValid()) {
                    control.setErrors(null);
                    return null;
                }
                else {
                    control.setErrors({ invalid: true });
                    return { invalidoption: true };
                }
            }
        }

        return null;
    }
}
