import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "@app-shared/services";
import { PrimaryCustodianResponseModel } from "./models/PrimaryCustodianResponseModel";
import { SystemParametersRequestModel } from "./models/SystemParametersRequestModel";
import { SystemParametersResponseModel } from "./models/SystemParametersResponseModel";
import { UpdateMpfSystemParametersResponse } from "./models/UpdateMpfSystemParametersResponse";
import { AUSCodesResponse } from "./models/AUSCodesResponse";
import { GetBusinessDayApiResponse } from "./models/GetBusinessDayApiResponse";
import { GetBusinessDayApiRequest } from "./models/GetBusinessDayApiRequest";
import { HttpUtilHelper } from "src/shared/helpers/http-util-helper";
import { GetProductCodeByProgramCodeApiRequest } from "./models/getProductCodeByProgramCodeApiRequest";
import { GetProductCodeByProgramCodeApiResponse } from "./models/getProductCodeByProgramCodeApiResponse";
import { GetProductsApiResponse } from "./models/getProductsApiResponse";

@Injectable()
export class LookupLosService {

    private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.API_PATH}/api/lookup`;
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/lookup`;
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient) { }

    GetSystemParameters(): Observable<SystemParametersResponseModel> {
        return this.http.get<SystemParametersResponseModel>(`${this.API_SERVICE_PATH}/${"GetSystemParametersAsmx".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    UpdateSystemParameters(req: SystemParametersRequestModel): Observable<UpdateMpfSystemParametersResponse> {
        return this.http.post<UpdateMpfSystemParametersResponse>(`${this.API_SERVICE_PATH}/${'UpdateSystemParametersAsmx'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }


    GetPrimaryCustodians(): Observable<PrimaryCustodianResponseModel> {
        return this.http.get<PrimaryCustodianResponseModel>(`${this.API_SERVICE_PATH}/${"GetPrimaryCustodians".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }


    GetAusDecisions(): Observable<AUSCodesResponse> {
        return this.http.get<AUSCodesResponse>(`${this.API_SERVICE_PATH}/${"GetAusDecisions".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    GetBusinessDay(req: GetBusinessDayApiRequest): Observable<GetBusinessDayApiResponse> {

        return this.http.get<GetBusinessDayApiResponse>(`${this.API_SERVICE_PATH}/${"GetBusinessDay".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }
    getProductCodeByProgramCode(req: GetProductCodeByProgramCodeApiRequest): Observable<GetProductCodeByProgramCodeApiResponse> {
        return this.http.get<GetProductCodeByProgramCodeApiResponse>(`${this.API_SERVICE_PATH}/${"GetProductCodeByProgramCode".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }
    getProducts() : Observable<GetProductsApiResponse>{
        return this.http.get<GetProductsApiResponse>(`${this.API_SERVICE_PATH}/${"GetProducts".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

}
