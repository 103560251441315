import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { UtilHelper } from '../helpers/util-helper';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[inactiveContactValidate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: InactiveContactValidate, multi: true }]
})

export class InactiveContactValidate implements Validator {
    @Input('inactiveContactValidate') validator: string;

    constructor() { }

    validate(control: AbstractControl): { [key: string]: boolean } | null {
        control.setErrors(null);
        if (UtilHelper.isDefinedOrNotNull(control.value)) {
            if (control.value[this.validator] === 'false') {
                control.setErrors({ invalid: true });
                return { inactiveContact: true };
            }
        }

        return null;
    }
}
