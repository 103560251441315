import * as moment from 'moment';

export class DateHelper {
    public static isDateValid(dateStr, enableAutoConversion) {
        var validDateTime = moment(dateStr,
            [
                'MM/DD/YYYY',
                'MM/D/YYYY',
                'MMM/DD/YYYY',
                'M/DD/YYYY',
                'M/D/YYYY',
                'M/DD/YY',
                'M/D/YY',
                'DD/MMM/YYYY',
                'DD/MM/YYYY',
                'DD/M/YYYY',
                'DD/M/YY',
                'YYYY/MMM/DD',
                'YYYY/MM/DD',
                'YYYY/M/DD',
                'YYYY/D/MMM',
                'YYYY/DD/MMM',
                'YYYY/DD/MM',
                'YYYY/DD/M',
                'YYYY/D/M',
                'MMM-DD-YYYY',
                'MM-DD-YYYY',
                'MM-D-YYYY',
                'M-DD-YYYY',
                'M-D-YYYY',
                'M-D-YY',
                'DD-MMM-YYYY',
                'DD-MM-YYYY',
                'DD-M-YYYY',
                'DD-M-YY',
                'YYYY-MMM-DD',
                'YYYY-MM-DD',
                'YYYY-M-DD',
                'YYYY-DD-MMM',
                'YYYY-D-MMM',
                'YYYY-DD-MM',
                'YYYY-DD-M',
                'YYYY-D-M',
                'MMM.DD.YYYY',
                'MM.DD.YYYY',
                'MM.D.YYYY',
                'M.DD.YYYY',
                'M.D.YYYY',
                'M.D.YY',
                'DD.MMM.YYYY',
                'DD.MM.YYYY',
                'DD.M.YYYY',
                'DD.M.YY',
                'YYYY.MMM.DD',
                'YYYY.MM.DD',
                'YYYY.M.DD',
                'YYYY.DD.MMM',
                'YYYY.DD.MM',
                'YYYY.DD.M',
                'YYYY.D.MMM',
                'YYYY.D.MM',
                'YYYY.D.M',
                'YY.D.M'
            ],
            true);

        var isValid = validDateTime.isValid();
        if (!isValid) {

            if (enableAutoConversion) {
                validDateTime = this.toLocalDateTime(dateStr);
            } else {
                validDateTime = this.toLocalSpecialDateTime(dateStr, moment.ISO_8601);
            }
            isValid = validDateTime.isValid();
        }

        var dateObj = {
            date: validDateTime,
            isValid: isValid
        };

        return dateObj;
    }
    public static isRealDate(dateStr) {
        var validDate = this.isDateValid(dateStr, true)

        if (validDate.isValid && validDate.date.year() > 1) {
            return true;
        }
        return false;
    }


    public static toLocalDateTime(dateStr) {
        var dateTimeObj = moment(dateStr);

        return dateTimeObj;
    }

    public static toLocalSpecialDateTime(dateStr, format) {
        if (format === null) {
            format = moment.ISO_8601;
        }

        var dateTimeObj = moment(dateStr, format);

        return dateTimeObj;
    }

    public static getTodayMidnightDateTime() {
        var utcDateTime = moment.utc().milliseconds(0);

        // convert to local datetime
        var localDateTime = utcDateTime.local();

        // convert local date time to  for midnight
        var localMidnight = localDateTime.local().startOf('day');

        return localMidnight;
    }

    public static getMidnightDateTime(dateObj) {
        var utcDateTime = moment.utc(dateObj).milliseconds(0);

        // convert to local datetime
        var localDateTime = utcDateTime.local();

        // convert local date time to  for midnight
        var localMidnight = localDateTime.local().startOf('day');

        return localMidnight;
    }

    public static compare(date1, date2) {
        return date1.diff(date2);
    }

    public static dateFormat(txtDate) {
        var utcDateTime = moment.utc(txtDate, moment.ISO_8601);
        return moment(utcDateTime).format('MM/DD/YYYY');
    }

    public static toCurrrentLocalDateTime() {
        return moment().milliseconds(0);
    }

    public static getSecondsBetweenDateTime(fromDatetime, toDatetime) {
        let timeElasped = moment.duration(fromDatetime.diff(toDatetime));

        return Math.round(timeElasped.asSeconds());
    }

    public static toLocalDate(date) {

        if (date instanceof Date) {
            date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
            date = date.toJSON();
        }

        let justDate = moment.isDate(date);

        if (justDate === null) {
            return null;
        }

        return new Date(justDate + ' 00:00:00');
    }

    public static getLocalDateTime(date) {
        var getDateTime = moment(date).milliseconds(0);

        // convert to local datetime
        var localDateTime = getDateTime.local();

        // convert local date time to  for midnight
        var localMidnight = localDateTime.local().startOf('day');

        return localMidnight;
    }

    public static daysBetween(date1, date2) {

        // The number of milliseconds in one day
        var oneDay = 1000 * 60 * 60 * 24;

        // Convert both dates to milliseconds
        var date1Ms = date1.getTime();
        var date2Ms = date2.getTime();

        // Calculate the difference in milliseconds
        var differenceMs = Math.abs(date1Ms - date2Ms);

        // Convert back to days and return
        return Math.round(differenceMs / oneDay);
    }

    public static addTimezoneOffsetToDate(date) {
        return date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
    }
}


