import { Action } from '@ngrx/store';

export enum GeoStatus {
  Na = 0,
  Processing = 1,
  Successful = 2,
  Error = 3
}

export enum GeoProcessActionTypes {
  RUN_GEO = '[GEO] RUN',
  COMPLETE_GEO = '[GEO] COMPLETE GEO'
}


export class RunGeoProcessAction implements Action {
  readonly type = GeoProcessActionTypes.RUN_GEO;
  constructor(public payload: { startDate: Date, endDate: Date }) {}
}

export class CompleteGeoProcessAction implements Action {
  readonly type = GeoProcessActionTypes.COMPLETE_GEO;
  constructor(public payload: { status: GeoStatus, errors?: string[] }) {}
}


export type All =
  | RunGeoProcessAction
  | CompleteGeoProcessAction;
