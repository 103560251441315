import { Pipe, PipeTransform } from '@angular/core';
import { UtilHelper } from '@app-shared/services';

@Pipe({
    name: 'filterSchedules'
})
export class FilterSchedules implements PipeTransform {

    transform(items: any[], args: any): any {
        const filteredSchedules = [];
        if (UtilHelper.isDefinedOrNotNull(args.subProduct)) {
            items.forEach((item, index) => {
                if (item.name === args.subProduct || item.name === '') {
                    filteredSchedules.push(item);
                }
            });
            return filteredSchedules;
        }
    }
}
