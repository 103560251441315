import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "@app-shared/services";
import { HttpUtilHelper } from "@app-shared/helpers/http-util-helper";
import { CallGeographicCentrusEODProcessApiRequest } from "./models/CallGeographicCentrusEODProcessApiRequest";
import { CallGeographicCentrusEODProcessApiResponse } from "./models/CallGeographicCentrusEODProcessApiResponse";
import { CheckLoanNumberExistsApiRequest } from "./models/CheckLoanNumberExistsApiRequest";
import { GetGeographicDetailsforLoanNumberApiRequest } from "./models/GetGeographicDetailsforLoanNumberApiRequest";
import { GetGeographicDetailsforLoanNumberApiResponse } from "./models/GetGeographicDetailsforLoanNumberApiResponse";
import { CheckLoanNumberExistsApiResponse } from "./models/CheckLoanNumberExistsApiResponse";
import { InsertOrUpdateGeographicInfoDataApiRequest } from "./models/InsertOrUpdateGeographicInfoDataApiRequest";
import { InsertOrUpdateGeographicInfoDataApiResponse } from "./models/InsertOrUpdateGeographicInfoDataApiResponse";
import { CallGeographicCentrusEODProcessRequest } from "./models/inner/CallGeographicCentrusEODProcessRequest";
import { PropertyDemographicsModel } from "src/empf/resources/property-demographics/models/PropertyDemographicsModel";
import { SearchModel } from "src/empf/resources/property-demographics/models/SearchModel";
import { GetPropertyDemographicsApiResponse } from "src/empf/resources/property-demographics/models/GetPropertyDemographicsApiResponse";

@Injectable()
export class GeographicInfoService {
        private API_PATH =  `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.API_PATH}/api/geographicinfo`;
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/geographicinfo`;
    private API_SERVICE_EXTPATH = `${this.BUILT_API_PATH}/ext/geographicinfo`;
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }
    constructor(private appConfig: AppConfigService, private http: HttpClient) { }


    callGeographicCentrusEODProcess(connectionId: string, req: CallGeographicCentrusEODProcessRequest): Observable<CallGeographicCentrusEODProcessApiResponse> {
        return this.http.get<CallGeographicCentrusEODProcessApiResponse>(`${this.API_SERVICE_PATH}/${"CallGeographicCentrusEODProcess".toLowerCase()}?startdate=${req.startDate}&enddate=${req.endDate}`, this.websocketOptions(connectionId))
            .pipe(map(response => response));
    }

    checkLoanNumberExists(req: CheckLoanNumberExistsApiRequest) {
        return this.http.get<CheckLoanNumberExistsApiResponse>(`${this.API_SERVICE_PATH}/${"CheckLoanNumberExists".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    getGeographicDetailsforLoanNumber(req: GetGeographicDetailsforLoanNumberApiRequest) {
        return this.http.get<GetGeographicDetailsforLoanNumberApiResponse>(`${this.API_SERVICE_PATH}/${"getGeographicDetailsforLoanNumber".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }

    insertOrUpdateGeographicInfoData(req: InsertOrUpdateGeographicInfoDataApiRequest) {
        return this.http.post<InsertOrUpdateGeographicInfoDataApiResponse>(`${this.API_SERVICE_PATH}/${"insertOrUpdateGeographicInfoData".toLowerCase()}`, req, this.options)
            .pipe(map(response => response));

    }

    getPropertyDemographics(req:PropertyDemographicsModel){
        return this.http.post<GetPropertyDemographicsApiResponse>(`${this.API_SERVICE_EXTPATH}/${"GetPropertyDemographics".toLowerCase()}`, req, this.options).pipe(map(response => response));
    }

 
}
