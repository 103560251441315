<div class="col-sm-8 pt-3">
  <div class="row">
    <div class="col-2 ps-0 padding-top-5px text-lighter">
      <strong>PFI Number:</strong>
    </div>
    <div class="col-7 padding-left-0px">
      <ng-select [selectOnTab]= "true" [items]="pfiList.availableOptions" bindLabel="name" placeholder="SELECT" [multiple]="false"
            id="pfiList" name="pfiList" [selectOnTab]="true" (change)="selectItem(pfiList.selectedOption)" [clearable]="false"
            [(ngModel)]="pfiList.selectedOption">
      </ng-select>
    </div>
  </div>
</div>