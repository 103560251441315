export enum ServicingIdentifierName{
    XtraServicingIdentifier,

    /// <summary>
    ///   The xtra bifurcated identifier
    /// </summary>
    XtraBifurcatedIdentifier,

    /// <summary>
    ///   The xtra co issue identifier
    /// </summary>
    XtraCoIssueIdentifier
}