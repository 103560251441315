import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';;
@Directive({
    selector: '[fixedHeaderTop]'
})

export class FixedHeaderTopDirective {

    paddingAtWhenTop: string = "350";
    fixedHeaderTop: string = "fix-to-top";
    constructor(private el: ElementRef) { }
    detectIE() {
        var ua = window.navigator.userAgent;

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return true;
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            return true;
        }
    }
    @HostListener('window:scroll', []) onScroll() {
        var topClass = this.fixedHeaderTop;// get CSS class from directive's attribute value
        var topPadding = parseInt(this.paddingAtWhenTop, 10);
        var offsetTop = this.detectIE() ? 1167 : 709;
        if (window.pageYOffset + topPadding >= offsetTop) {
            this.el.nativeElement.classList.add(topClass);
        } else {
            this.el.nativeElement.classList.remove(topClass);
        }
    }
}
