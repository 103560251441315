import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
    selector: 'rules-validation-errors',
    templateUrl: './rules-validation-error.component.html',
    styleUrls: ['./rules-validation-error.component.sass']
  })
  export class RulesValidationErrorComponent implements OnInit {
    isCollapsed: boolean = true;
    @Input() rulesValidationError:string[];
    @Input() subheading:string;
    @Output() rulesValidationHideEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('rulesvalidation', { static: true }) rulesvalidation: NgForm;
    @Input() title:string;
    constructor() {
    }
  
    ngOnInit() {
    }
  
    close(){
      this.rulesValidationHideEvent.emit(true);
    }
  
  
  }  