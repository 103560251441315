import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IMasterCommitmentList } from '../models/masterCommitmentList.model';
@Component({
  selector: 'app-search-mc-details',
  templateUrl: './search-mc-details.component.html',
  styleUrls: ['./search-mc-details.component.sass']
})
export class ViewMclistComponent implements OnInit {
  @Input() mcList: IMasterCommitmentList[];
  @Input() pageSize: number;
  @Output() goToViewMcClick: EventEmitter<number> = new EventEmitter<number>();
  @Output() goToViewPfiClick: EventEmitter<number> = new EventEmitter<number>();
  @Output() goToViewDCsClick: EventEmitter<number> = new EventEmitter<number>();
  @Output() goToViewLoansClick: EventEmitter<{pfiNum: number,mCId:number}> = new EventEmitter<{pfiNum: number,mCId:number}>();
  @Output() goToEditMcClick: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageSizeChangeClick: EventEmitter<{pageSize:number}> = new EventEmitter<{pageSize:number}>();
  @Output() pageNumberChangeClick:EventEmitter<{pageNumber:number}> = new EventEmitter<{pageNumber:number}>();
  @Input() pageNo: number;
  @Input() totalItems:number;
  @Input() showAddEditControls:boolean;  
  @Input() isCriteriaSearch:boolean;
  
  page = 1;

  ngOnInit() {
    // returning back to particular page from DC List
    this.page = this.pageNo !== null ? this.pageNo : this.page;
    this.pageSize = this.pageSize != null ? +this.pageSize : 25;   
  }
ngOnChanges(){
  this.page = this.pageNo !== null ? this.pageNo : this.page;
}
  goToView(mCId: number) {
    this.goToViewMcClick.emit(mCId);
  }

  goToPfi(dcId:number) {
    this.goToViewPfiClick.emit(dcId);
  }

  goToDCs(dcId:number) {
    this.goToViewDCsClick.emit(dcId);
  }

  goToLoans(pfiNum: number, mCId:number) {
    this.goToViewLoansClick.emit({pfiNum: pfiNum, mCId: mCId });
  }

  goToEdit(mCId: number) {
    this.goToEditMcClick.emit(mCId);
  }

  pageSizeChange(pageSize:number){   
    this.pageSizeChangeClick.emit({pageSize});
  }

  pageChanged(pageNumber:number){
    this.pageNumberChangeClick.emit({pageNumber})
  }
  blurPopover(mc: IMasterCommitmentList){
    setTimeout(() => {
      mc.clickstate=false;
    }, 200);
    
  }

}


