import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[convertToBps]'
})
export class ConvertToBpsDirective implements OnInit {
  private ngControl: NgControl;
  @Input('maxAllowedValue') maxAllowedValue: any;
  constructor(public el: ElementRef, public renderer: Renderer2, @Self() ngControl: NgControl) {
      this.ngControl = ngControl;
  }

  ngOnInit() {
      setTimeout(() => {
      this.format(this.el.nativeElement.value);
      }, 3000);
  }

  @HostListener('blur', ['$event.target.value']) onInput(e: string) {
      this.format(e);
  }

  format(val: string) {
      const replacedVal = val.replace(/[$ , bps %]/g, ''); 
      if (replacedVal != '' && !isNaN(+replacedVal) && +replacedVal < +this.maxAllowedValue) {        
        let val = replacedVal + ' bps';
        this.renderer.setProperty(this.el.nativeElement, 'value', val);
        this.ngControl.viewToModelUpdate(val);
      }
  }

}
