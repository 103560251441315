<div id="validation-popup-id" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="card">
    <div class="card-header">
      <h4 class="card-title font-color-white row">
        <span class="font-open-sans-bold font-size-6 col-9" [innerHtml]="title"></span>
        <div class="col-3">
          <i class="toastr-command pull-right font-size-7 fa fa-times padding-left-10px" (click)="remove()"></i>
          <i class="toastr-command pull-right font-size-7 fa" [ngClass]="{ 'fa-minus': accordionStatus=== true, 'fa-plus': accordionStatus=== false }"
            data-bs-toggle="collapse" data-bs-target="#card-body"  (click)="accordionStatus = !accordionStatus">
          </i>
        </div>
      </h4>
    </div>
    <div class="collapse show in" id="card-body" >
      <div  class="card card-body" [innerHtml]="message">
      </div>
    </div>
  </div>
</div>
