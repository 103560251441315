import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "@app-shared/shared.module";
import { EmpfContainerComponent } from "./empf.container.component";
import { EmpfSharedModule } from "./shared/empf.shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmpfRoutingHelper } from "@empf-shared/navigation/empf.navigation";
import { SiteErrorPageComponent } from "@app-shared/components/sites-wrapping/site-error-page/site-error-page.component";
import { AuthGuard } from "src/guards/auth.guard";
import { fhlbConstants } from "@app-shared/constants/fhlb-constants";
import { MsalGuard } from "@azure/msal-angular";

const eMpfroutes: Routes = [
  {
    path: '', component: EmpfContainerComponent,
    canActivate : [MsalGuard,AuthGuard],
    data: {
      empfAuth: {
        roles: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf, fhlbConstants.EmpfScuMpf],
        behaviors: [fhlbConstants.accessToEmpf],
      }
    },
    children: [
      {
        path: 'footer',
        loadChildren: () => import('./footer/footer-main.module').then(m => m.FooterModule)
      },
      {
        path: 'resources',
        loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule),
        data: {
          breadcrumb: {
            value: {
              name: 'Resources'
            }
          }
        }
      },
      {
        path: 'pricing',
        loadChildren: () => import('./pricing/pricing-main.module').then(m => m.PricingModule),
        data: {
          breadcrumb: {
            value: {
              name: 'Pricing'
            }
          }
        }
      },
      {
        path: 'treasury',
        loadChildren: () => import('./treasury/treasury.module').then(m => m.TreasuryModule),
        data: {
          breadcrumb: {
            value: {
              name: 'Treasury'
            }
          }
        }
      },
      {
        path: 'header',
        loadChildren: () => import('./header/header-module').then(m => m.HeaderModule),
      },
      {
        path: 'pricing',
        loadChildren: () => import('./pricing/pricing-main.module').then(m => m.PricingModule),
        data: {
          breadcrumb: {
            value: {
              name: 'Pricing'
            }
          }
        }
      },
      {
        path: 'transaction',
        loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),
        data: {
          preload: true,
          breadcrumb: {
            value: {
              name: 'Transactions'
            }
          }
        }
      },
      {
        path: 'exhibits',
        loadChildren: () => import('./exhibits/exhibits.module').then(m => m.ExhibitsModule),
        data: {
          breadcrumb: {
            value: {
              name: 'Exhibits'
            }
          }
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports-main.module').then(m => m.ReportsModule),
        data: {
          breadcrumb: {
            value: {
              name: 'Reports'
            }
          }
        }
      },
      {
        path: 'home',
        loadChildren: () => import('./empfHome/empf-home.module').then(m => m.EmpfHomeModule)
      },
      {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
        data: {
          breadcrumb: {
            value: {
              name: 'Upload',
              route: EmpfRoutingHelper.DocumentUploadLandingPath()
            }
          }
        }
      },
      {
        path: 'error', component: SiteErrorPageComponent,
        data: {
          breadcrumb: {
            value: null,
            overridingBreadcrumb: []
          }
        }
      },
      {
        path: '**', redirectTo: 'error'
      }
    ]
  }];

@NgModule({
  declarations: [EmpfContainerComponent],
  imports: [
    CommonModule,
    SharedModule,
    EmpfSharedModule,
    NgbModule,
    RouterModule.forChild(eMpfroutes)
  ],
  exports: [RouterModule, EmpfContainerComponent]
})
export class EmpfModule { }