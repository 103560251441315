import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import {
  CreateContactApiRequest, CreateContactApiResponse, UpdateContactApiRequest,
  GetContactsApiRequest,
  UpdateContactAccountActivityTimeApiRequest, GetContactByKeyApiRequest,
  GetContactByKeyApiResponse, GetContactsApiResponse, UpdateContactApiResponse,
  GetBankContactNameApiRequest, GetBankContactNameApiResponse,
  GetOrganizationUserTypeApiRequest,
  GetContactByOrgAndEmailApiRequest,
  GetContactByOrgAndEmailApiResponse,
  GetSalesforceContactByEmailApiRequest,
  GetSalesforceContactByEmailApiResponse
}
  from './models'
import { AppConfigService } from '@app-shared/services';

@Injectable()
export class ContactService {
  
    private API_PATH =`${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH = `${this.API_PATH}/api/organization`;
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/contact`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  createContact(req: CreateContactApiRequest): Observable<CreateContactApiResponse> {
    return this.http.post<CreateContactApiResponse>(`${this.API_SERVICE_PATH}/${"CreateContact".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  getContact(req: GetContactByKeyApiRequest): Observable<GetContactByKeyApiResponse> {
    return this.http.get<GetContactByKeyApiResponse>(`${this.API_SERVICE_PATH}/${"GetContact".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  /*
  User Story 255192: Ang v15 upgrade -> Due to an unintended JavaScript comparison in HttpUtilHelper.ConvertQueryString() where "0 == []" would evaluate as TRUE, 
  any input parameters getting passed to these HTTP GET APIs with a value of 0 or 0.0 would then always get ignored and not translated into the resulting API
  query string. To preserve the original API query string that is getting passed in as part of the API call, any explicit 0's in the API calls are removed so that
  they do not appear in the resulting query string either.
  */
  getContacts(req: GetContactsApiRequest): Observable<GetContactsApiResponse> {
    return this.http.get<GetContactsApiResponse>(`${this.API_SERVICE_PATH}/${"GetContacts".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getContactByOrgAndEmail(req: GetContactByOrgAndEmailApiRequest): Observable<GetContactByOrgAndEmailApiResponse> {
    return this.http.get<GetContactByOrgAndEmailApiResponse>(`${this.API_SERVICE_PATH}/${"GetContactByOrgAndEmail".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  getSalesforceContactByEmail(req: GetSalesforceContactByEmailApiRequest): Observable<GetSalesforceContactByEmailApiResponse> {
    return this.http.get<GetSalesforceContactByEmailApiResponse>(`${this.API_SERVICE_PATH}/${"GetSalesforceContactByEmail".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }
  updateContact(req: UpdateContactApiRequest): Observable<UpdateContactApiResponse> {
    return this.http.put<UpdateContactApiResponse>(`${this.API_SERVICE_PATH}/${"UpdateContact".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  getBankContactName(req: GetBankContactNameApiRequest): Observable<GetBankContactNameApiResponse> {
    return this.http.get<GetBankContactNameApiResponse>(`${this.API_SERVICE_PATH}/${"GetBankContactName".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  updateContactAccountActivityTime(req: UpdateContactAccountActivityTimeApiRequest): Observable<boolean> {
    return this.http.put<boolean>(`${this.API_SERVICE_PATH}/${"UpdateContactAccountActivityTime".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  getOrganizationUserType(req: GetOrganizationUserTypeApiRequest): Observable<boolean> {
    return this.http.get<boolean>(`${this.API_SERVICE_PATH}/${"GetOrganizationUserType".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }
}

