<div class="row mt-3">
    <div class="col-9 size mt-2" *ngIf="pfiLoanList  && pfiLoanList.length>0">
        Showing {{totalItems==0? 0 : ((pageNo-1) * pageSize+1)}}
        to {{totalItems > (pageNo * pageSize) ? pageNo * pageSize : totalItems}}
        of {{totalItems}} items
    </div>
    <div class="col-9" *ngIf="!pfiLoanList">
        Showing No items
    </div>
    <div class="col-3 text-end size"  *ngIf="pfiLoanList && pfiLoanList.length>0">
        Show entries &nbsp;
        <select id="selectentry" class="showentry" [(ngModel)]="pageSize"
            (ngModelChange)="pageSizeChange(pageSize)">
            <option [ngValue]="5">5</option>
            <option [ngValue]="10">10</option>
            <option [ngValue]="25">25</option>
            <option [ngValue]="50">50</option>
            <option [ngValue]="100">100</option>
        </select>
    </div>
</div>
<div class="row mt-2">
    <div class="col-sm-12">
        <table class="table table-striped">
            <thead id="th" class="font-bold">
                <th>PFI Loan No.</th>
                <th>PFI No.</th>
                <th *ngIf="showMCNo">MC No.</th>
                <th *ngIf="showDCNo">DC No.</th>
                <th>Full Name</th>
                <th>Loan Amount</th>
                <th>Interest Rate</th>
                <th>Product Code</th>
                <th></th>
            </thead>
            <tbody id="tbodycount">
                <tr id="pfiloanrow" *ngFor="let pl of pfiLoanList">

                    <td>
                        {{pl.pfiLoanNumber}}
                    </td>
                    <td class="font-bold">
                        <a tabindex="0" class="font-light-blue" id="pfiNumber{{pl.pfiNumber}}" (click)="goToView(pl.pfiNumber)">
                            {{pl.pfiNumber}}</a>
                    </td>
                    <td *ngIf="showMCNo" class="font-bold">
                        <a tabindex="0" class="font-light-blue" id="mcNumber{{pl.mcNumber}}"
                         (click)="goToViewMC(pl.mcNumber)"> {{pl.mcNumber}}</a></td>
                    <td *ngIf="showDCNo" class="font-bold">
                        <a tabindex="0" class="font-light-blue" id="dcNumber{{pl.dcNumber}}"
                         (click)="goToViewDC(pl.dcNumber)"> {{pl.dcNumber}} </a></td>
                    
                    <td class="Wordwrap"> {{pl.fullName}} </td>
                    <td> {{pl.loanAmount?.toFixed(2)| currency}} </td>
                    <td> {{pl.interestRate}} </td>
                    <td> {{pl.productCode}} </td>


                    <td class="font-bold column-width">
                        <ng-template #popTemplate><a tabindex="0" (click)="viewLPR(pl.pfiLoanNumber,pl.pfiNumber)" class="color-grey">View LPR </a><br />
                            <div [hidden]="isInquiryUser"> <a tabindex="0"  (click)="editLPR(pl.pfiLoanNumber,pl.pfiNumber)" class="color-grey"> Edit LPR </a>
                                <br />
                            </div>
                            <div [hidden]="!isLoanFunded">
                                <a tabindex="0" (click)="viewLoan(pl.mpfLoanNumber)" class="color-grey">View Loan</a>
                                <br /><a tabindex="0" (click)="viewLF(+pl.mpfLoanNumber)" class="color-grey">View LF</a>
                            </div>
                            <!--<div [innerHtml]="html"></div> Removed due to build error.  No html property on object, what is html supposed to be?-->
                        </ng-template>
                        <a tabindex="0" id="popTemplate" [ngbPopover]="popTemplate" #p1="ngbPopover" [autoClose]="true"
                            placement="bottom-right" (click)="checkLoanFundingStatus(pl)" (blur)="blurPopover(pl)" [ngSwitch]="pl.clickstate"
                            mdbRippleRadius>
                            <i *ngSwitchCase="true" class="fa fa-ellipsis-h" aria-hidden="true"></i>
                            <i *ngSwitchCase="false" class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="pfiLoanList && pfiLoanList.length>0" class="row justify-content-center align-items-center font-size-2 pagination"
            [hidden]="totalItems <= pageSize">
            <ngb-pagination id="pagination" [collectionSize]="totalItems" [maxSize]="10" [(page)]="pageNo"
                [pageSize]="pageSize" (pageChange)="pageNumberChange($event)" aria-label="Default pagination">
                <ng-template id="previous" ngbPaginationPrevious class="font-size-2">
                    &lt;Prev </ng-template>
                        <ng-template id="Next" ngbPaginationNext class="font-size-2">Next&gt;</ng-template>
            </ngb-pagination>
        </div>
        <div *ngIf="!pfiLoanList || pfiLoanList.length== 0" class="defaultText text-center  ">
            No results could be found. Please try your search again.
        </div>
        <br/>
    </div>
</div>
