import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { IAccountLogin } from '../models/account-login.model';
import { ILookup } from '../../models/lookup.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'entry-landing',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.sass']
})

export class EntryLandingComponent implements OnInit {
  @Input() accountLoginModel: IAccountLogin;
  @Input() hlbList: ILookup[];

  @Output() LoginUser: EventEmitter<IAccountLogin> = new EventEmitter<IAccountLogin>();
  
  @ViewChild('entryForm', { static: true }) form: NgForm;

  public mpfBankId: number = 0;
  formSubmitted: boolean;
  defaultDropDownValue = '0';

  constructor() { }

  ngOnInit() {
  }

  hlbTypeChanged() {
    this.accountLoginModel.fhlBankId = this.mpfBankId;
    this.accountLoginModel.fhlBankName= this.hlbList.find(x=>x.orgKey == this.mpfBankId).name

  }

  submit() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      return;
    } else {
      this.LoginUser.emit(this.accountLoginModel);
    }
  }

  trackByHlb(index: number, item: any)
  {
      return item?.orgKey;
  }
}
